import React from "react";
import CareersVideoTray from "./CareersVideoTray";
import CustomVideoTray from "./CustomVideoTray";

const CareersMasterVideoTray = ({ contentData }) => {
  let isCustom = true;
  return isCustom ? (
    <CustomVideoTray contentData={contentData} />
  ) : (
    <CareersVideoTray contentData={contentData} />
  );
};

export default CareersMasterVideoTray;
