import React from "react";
import stateCodes from 'states-us';
import "./EmploymentDetailsForm.css";
import CareerBreadcrumb from "../CareerBreadcrumb";

const EmploymentDetailsForm = ({
  updateFields,
  franchiseBaseUrl,
  franchiseName,
  errors,
  emp1Employer1,
  emp1EmployedFrom,
  emp1EmployedTo,
  emp1TypeofWorkPerformed,
  emp1StartingSalary,
  emp1EndingSalary,
  emp1ReasonForLeaving,
  emp1Address,
  emp1NameofSupervisor,
  emp1PhoneNumber,
  emp1Contact,
  emp2Employer2,
  emp2EmployedFrom,
  emp2EmployedTo,
  emp2TypeofWorkPerformed,
  emp2StartingSalary,
  emp2EndingSalary,
  emp2ReasonForLeaving,
  emp2Address,
  emp2NameofSupervisor,
  emp2PhoneNumber,
  emp2Contact,
  emp3Employer3,
  emp3EmployedFrom,
  emp3EmployedTo,
  emp3TypeofWorkPerformed,
  emp3StartingSalary,
  emp3EndingSalary,
  emp3ReasonForLeaving,
  emp3Address,
  emp3NameofSupervisor,
  emp3PhoneNumber,
  emp3Contact,
  emp4Employer4,
  emp4EmployedFrom,
  emp4EmployedTo,
  emp4TypeofWorkPerformed,
  emp4StartingSalary,
  emp4EndingSalary,
  emp4ReasonForLeaving,
  emp4Address,
  emp4NameofSupervisor,
  emp4PhoneNumber,
  emp4Contact,
}) => {
  const {
    emp1AddressLine1,
    emp1AddressLine2,
    emp1City,
    emp1State,
    emp1ZipCode,
  } = emp1Address;
  const {
    emp2AddressLine1,
    emp2AddressLine2,
    emp2City,
    emp2State,
    emp2ZipCode,
  } = emp2Address;
  const {
    emp3AddressLine1,
    emp3AddressLine2,
    emp3City,
    emp3State,
    emp3ZipCode,
  } = emp3Address;
  const {
    emp4AddressLine1,
    emp4AddressLine2,
    emp4City,
    emp4State,
    emp4ZipCode,
  } = emp4Address;
  return (
    <div className="employment-details">
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="Employment History" />
      <div className="form-group form-field-separator">
        <h3 className="form-title">Employment History</h3>
      </div>
      <>
        <div className="form-group">
          <input
            type="text"
            id="employer1"
            placeholder="Employer 1*"
            value={emp1Employer1}
            onChange={(e) => updateFields({ emp1Employer1: e.target.value })}
          />
          {errors.emp1Employer1 && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            //margin: "0 0 20px 0",
          }}
        >
          {errors.emp1Employer1}
        </p>}
        </div>
        <div className="form-group form-row" style = {{ position: "relative" }}>
          <input
            type="date"
            id="employer1-start-date"
            className="first-field"
            placeholder="Employed From*"
            //onFocus={(e) => (e.target.type = "date")}
            //onBlur={(e) => (e.target.type = "text")}
            value={emp1EmployedFrom || "Employed From*"}
            onChange={(e) => updateFields({ emp1EmployedFrom: e.target.value })}
          />
          {errors.emp1EmployedFrom && <p          
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
        >
          {errors.emp1EmployedFrom}
        </p>}
          <input
            type="date"
            id="employer1-end-date"
            placeholder="Employed To*"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
            value={emp1EmployedTo}
            onChange={(e) => updateFields({ emp1EmployedTo: e.target.value })}
          />
          {errors.emp1EmployedTo && <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
        >
          {errors.emp1EmployedTo}
        </p>}
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer1-type-of-work"
            placeholder="Type of Work Performed*"
            value={emp1TypeofWorkPerformed}
            onChange={(e) =>
              updateFields({ emp1TypeofWorkPerformed: e.target.value })
            }
          />
          {errors.emp1TypeofWorkPerformed && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            //margin: "0 0 20px 0",
          }}
        >
          {errors.emp1TypeofWorkPerformed}
        </p>}
        </div>
        <div className="form-group form-row" style={{position: "relative"}}>
          <input
            type="text"
            id="employer1-starting-salary"
            className="first-field"
            placeholder="Starting Salary*"
            value={emp1StartingSalary}
            onChange={(e) =>
              updateFields({ emp1StartingSalary: e.target.value })
            }
          />{errors.emp1StartingSalary && <p          
            style={{
              position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.emp1StartingSalary}
          </p>}
          <input
            type="text"
            id="employer1-ending-salary"
            placeholder="Ending Salary*"
            value={emp1EndingSalary}
            onChange={(e) => updateFields({ emp1EndingSalary: e.target.value })}
          />{errors.emp1EndingSalary && <p          
            style={{
              position: "absolute",
              left: "51%",
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.emp1EndingSalary}
          </p>}
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer1-leaving-reason"
            placeholder="Reason for Leaving?*"
            value={emp1ReasonForLeaving}
            onChange={(e) =>
              updateFields({ emp1ReasonForLeaving: e.target.value })
            }
          />{errors.emp1ReasonForLeaving && <p          
            style={{
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              //margin: "0 0 20px 0",
            }}
          >
            {errors.emp1ReasonForLeaving}
          </p>}
        </div>
        <div className="form-group employer1-address">
          <input
            type="text"
            id="employer1-addressLine1"
            className="form-control"
            placeholder="Address Line 1*"
            value={emp1AddressLine1}
            onChange={(e) =>
              updateFields({ emp1AddressLine1: e.target.value }, "emp1Address")
            }
          />
          {errors.emp1AddressLine1 && <p          
            style={{              
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              marginTop: "-20px",
              //margin: "0 0 20px 0",
            }}
          >
            {errors.emp1AddressLine1}
          </p>}
          <input
            type="text"
            id="employer1-addressLine2"
            className="form-control"
            placeholder="Address Line 2*"
            value={emp1AddressLine2}
            onChange={(e) =>
              updateFields({ emp1AddressLine2: e.target.value }, "emp1Address")
            }
          />{errors.emp1AddressLine2 && <p          
            style={{
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              marginTop: "-20px",
              //margin: "0 0 20px 0",
            }}
          >
            {errors.emp1AddressLine2}
          </p>}
          <div className="form-group form-row" style={{position:"relative"}}>
            <input
              type="text"
              id="employer1-city"
              className="first-field"
              placeholder="City*"
              value={emp1City}
              onChange={(e) =>
                updateFields({ emp1City: e.target.value }, "emp1Address")
              }
            />{errors.emp1City && <p          
              style={{
                position: "absolute",
                left: 0,
                bottom: "-20px",
                textAlign: "left",
                fontSize: ".70rem",
                lineHeight: "2.1em",
                color: "red",
                margin: "0",
              }}
            >
              {errors.emp1City}
            </p>}
            <select
              id="employer1-state"
              value={emp1State}
              onChange={(e) =>
                updateFields({ emp1State: e.target.value }, "emp1Address")
              }
            >
              <option value="" disabled selected>
                State*
              </option>
              {stateCodes.map(state => (
                <option value={state.abbreviation}>{state.name}</option>
              ))}
            </select>
            {errors.emp1State && <p          
              style={{
                position: "absolute",
                left: "51%",
                bottom: "-20px",
                textAlign: "left",
                fontSize: ".70rem",
                lineHeight: "2.1em",
                color: "red",
                margin: "0",
              }}
            >
              {errors.emp1State}
            </p>}
          </div>
          <div className="form-group form-row employer1-zipcode">
            <input
              type="text"
              id="employer1-zipcode"
              placeholder="ZipCode"
              value={emp1ZipCode}
              onChange={(e) =>
                updateFields({ emp1ZipCode: e.target.value }, "emp1Address")
              }
            />
          </div>
        </div>
        <div className="form-group form-row" style={{position:"relative"}}>
          <input
            type="text"
            id="employer1-supervisor"
            className="first-field"
            placeholder="Name of Supervisor*"
            value={emp1NameofSupervisor}
            onChange={(e) =>
              updateFields({ emp1NameofSupervisor: e.target.value })
            }
          />{errors.emp1NameofSupervisor && <p          
            style={{
              position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.emp1NameofSupervisor}
          </p>}
          <input
            type="text"
            id="employer1-phone-number"
            placeholder="Phone Number*"
            value={emp1PhoneNumber}
            onChange={(e) => updateFields({ emp1PhoneNumber: e.target.value })}
          />
          {errors.emp1PhoneNumber && <p          
            style={{
              position: "absolute",
              left: "51%",
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.emp1PhoneNumber}
          </p>}
        </div>
        <div className="contact-reference form-group">
          <div className="">
            <label>May We Contact This Employer for a Reference?*</label>
            <div>
              <input
                type="radio"
                id="employer1-reference-yes"
                name="employer1-reference"
                value="yes"
                checked={emp1Contact !== "" && emp1Contact === "yes"}
                onChange={(e) => updateFields({ emp1Contact: e.target.value })}
              />
              <label for="employer1-reference-yes">Yes</label>
            </div>
            <div>
              <input
                type="radio"
                id="employer1-reference-no"
                name="employer1-reference"
                value="no"
                checked={emp1Contact !== "" && emp1Contact === "no"}
                onChange={(e) => updateFields({ emp1Contact: e.target.value })}
              />
              <label for="employer1-reference-no">No</label>
            </div>
            {errors.emp1Contact && <p          
              style={{
                textAlign: "left",
                fontSize: ".70rem",
                lineHeight: "2.1em",
                color: "red",
                //margin: "0 0 20px 0",
              }}
            >
              {errors.emp1Contact}
            </p>}
          </div>
        </div>
      </>
      <>
        <div className="form-group">
          <input
            type="text"
            id="employer2"
            placeholder="Employer 2"
            value={emp2Employer2}
            onChange={(e) => updateFields({ emp2Employer2: e.target.value })}
          />
        </div>
        <div className="form-group form-row">
          <input
            type="date"
            id="employer2-start-date"
            className="first-field"
            placeholder="Employed From"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
            value={emp2EmployedFrom}
            onChange={(e) => updateFields({ emp2EmployedFrom: e.target.value })}
          />
          <input
            type="date"
            id="employer2-end-date"
            placeholder="Employed To"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
            value={emp2EmployedTo}
            onChange={(e) => updateFields({ emp2EmployedTo: e.target.value })}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer2-type-of-work"
            placeholder="Type of Work Performed"
            value={emp2TypeofWorkPerformed}
            onChange={(e) =>
              updateFields({ emp2TypeofWorkPerformed: e.target.value })
            }
          />
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer2-starting-salary"
            className="first-field"
            placeholder="Starting Salary"
            value={emp2StartingSalary}
            onChange={(e) =>
              updateFields({ emp2StartingSalary: e.target.value })
            }
          />
          <input
            type="text"
            id="employer2-ending-salary"
            placeholder="Ending Salary"
            value={emp2EndingSalary}
            onChange={(e) => updateFields({ emp2EndingSalary: e.target.value })}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer2-leaving-reason"
            placeholder="Reason for Leaving?"
            value={emp2ReasonForLeaving}
            onChange={(e) =>
              updateFields({ emp2ReasonForLeaving: e.target.value })
            }
          />
        </div>
        <div className="form-group employer1-address">
          <input
            type="text"
            id="employer2-addressLine1"
            className="form-control"
            placeholder="Address Line 1"
            value={emp2AddressLine1}
            onChange={(e) =>
              updateFields({ emp2AddressLine1: e.target.value }, "emp2Address")
            }
          />
          <input
            type="text"
            id="employer2-addressLine2"
            className="form-control"
            placeholder="Address Line 2"
            value={emp2AddressLine2}
            onChange={(e) =>
              updateFields({ emp2AddressLine2: e.target.value }, "emp2Address")
            }
          />
          <div className="form-group form-row">
            <input
              type="text"
              id="employer2-city"
              className="first-field"
              placeholder="City"
              value={emp2City}
              onChange={(e) =>
                updateFields({ emp2City: e.target.value }, "emp2Address")
              }
            />
            <select
              id="employer2-state"
              value={emp2State}
              onChange={(e) =>
                updateFields({ emp2State: e.target.value }, "emp2Address")
              }
            >
              <option value="" disabled selected>
                State
              </option>
              {stateCodes.map(state => (
                <option value={state.abbreviation}>{state.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group form-row employer2-zipcode">
            <input
              type="text"
              id="employer2-zipcode"
              placeholder="ZipCode"
              value={emp2ZipCode}
              onChange={(e) =>
                updateFields({ emp2ZipCode: e.target.value }, "emp2Address")
              }
            />
          </div>
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer2-supervisor"
            className="first-field"
            placeholder="Name of Supervisor"
            value={emp2NameofSupervisor}
            onChange={(e) =>
              updateFields(
                { emp2NameofSupervisor: e.target.value }
              )
            }
          />
          <input
            type="text"
            id="employer2-phone-number"
            placeholder="Phone Number"
            value={emp2PhoneNumber}
            onChange={(e) =>
              updateFields({ emp2PhoneNumber: e.target.value })
            }
          />
        </div>
        <div className="contact-reference form-group">
          <div className="">
            <label>May We Contact This Employer for a Reference?</label>
            <div>
              <input
                type="radio"
                id="employer2-reference-yes"
                name="employer2-reference"
                value="yes"
                onChange={(e) => updateFields({ emp2Contact: e.target.value })}
              />
              <label for="employer2-reference-yes">Yes</label>
            </div>
            <div>
              <input
                type="radio"
                id="employer2-reference-no"
                name="employer2-reference"
                value="no"
                onChange={(e) => updateFields({ emp2Contact: e.target.value })}
              />
              <label for="employer2-reference-no">No</label>
            </div>
          </div>
        </div>
      </>
      <>
        <div className="form-group">
          <input type="text" id="employer3" placeholder="Employer 3" />
        </div>
        <div className="form-group form-row">
          <input
            type="date"
            id="employer3-start-date"
            className="first-field"
            placeholder="Employed From"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
          />
          <input
            type="date"
            id="employer3-start-date"
            placeholder="Employed To"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer3-type-of-work"
            placeholder="Type of Work Performed"
          />
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer3-starting-salary"
            className="first-field"
            placeholder="Starting Salary"
          />
          <input
            type="text"
            id="employer3-ending-salary"
            placeholder="Ending Salary"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer3-leaving-reason"
            placeholder="Reason for Leaving?"
          />
        </div>
        <div className="form-group employer1-address">
          <input
            type="text"
            id="employer3-addressLine1"
            className="form-control"
            placeholder="Address Line 1"
          />
          <input
            type="text"
            id="employer3-addressLine2"
            className="form-control"
            placeholder="Address Line 2"
          />
          <div className="form-group form-row">
            <input
              type="text"
              id="employer3-city"
              className="first-field"
              placeholder="City"
            />
            <select id="employer3-state">
              <option value="" disabled selected>
                State
              </option>
              {stateCodes.map(state => (
                <option value={state.abbreviation}>{state.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group form-row employer3-zipcode">
            <input type="text" id="employer3-zipcode" placeholder="ZipCode" />
          </div>
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer3-supervisor"
            className="first-field"
            placeholder="Name of Supervisor"
          />
          <input
            type="text"
            id="employer3-phone-number"
            placeholder="Phone Number"
          />
        </div>
        <div className="contact-reference form-group">
          <div className="">
            <label>May We Contact This Employer for a Reference?</label>
            <div>
              <input
                type="radio"
                id="employer3-reference-yes"
                name="employer3-reference"
              />
              <label for="employer3-reference-yes">Yes</label>
            </div>
            <div>
              <input
                type="radio"
                id="employer3-reference-no"
                name="employer3-reference"
              />
              <label for="employer3-reference-no">No</label>
            </div>
          </div>
        </div>
      </>
      <>
        <div className="form-group">
          <input type="text" id="employer4" placeholder="Employer 4" />
        </div>
        <div className="form-group form-row">
          <input
            type="date"
            id="employer4-start-date"
            className="first-field"
            placeholder="Employed From"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
          />
          <input
            type="date"
            id="employer4-start-date"
            placeholder="Employed To"
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer4-type-of-work"
            placeholder="Type of Work Performed"
          />
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer4-starting-salary"
            className="first-field"
            placeholder="Starting Salary"
          />
          <input
            type="text"
            id="employer4-ending-salary"
            placeholder="Ending Salary"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="employer4-leaving-reason"
            placeholder="Reason for Leaving?"
          />
        </div>
        <div className="form-group employer4-address">
          <input
            type="text"
            id="employer4-addressLine1"
            className="form-control"
            placeholder="Address Line 1"
          />
          <input
            type="text"
            id="employer4-addressLine2"
            className="form-control"
            placeholder="Address Line 2"
          />
          <div className="form-group form-row">
            <input
              type="text"
              id="employer4-city"
              className="first-field"
              placeholder="City"
            />
            <select id="employer4-state">
              <option value="" disabled selected>
                State
              </option>
              {stateCodes.map(state => (
                <option value={state.abbreviation}>{state.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group form-row employer4-zipcode">
            <input type="text" id="employer4-zipcode" placeholder="ZipCode" />
          </div>
        </div>
        <div className="form-group form-row">
          <input
            type="text"
            id="employer4-supervisor"
            className="first-field"
            placeholder="Name of Supervisor"
          />
          <input
            type="text"
            id="employer4-phone-number"
            placeholder="Phone Number"
          />
        </div>
        <div className="contact-reference form-group">
          <div className="">
            <label>May We Contact This Employer for a Reference?</label>
            <div>
              <input
                type="radio"
                id="employer4-reference-yes"
                name="employer4-reference"
              />
              <label for="employer4-reference-yes">Yes</label>
            </div>
            <div>
              <input
                type="radio"
                id="employer4-reference-no"
                name="employer4-reference"
              />
              <label for="employer4-reference-no">No</label>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EmploymentDetailsForm;
