import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";


import { fetchHomePage } from '../../actions/fetchData'
import YourTeamBanner from "./YourTeamBanner";
import YourTeamBreadcrumbs from "./YourTeamBreadcrumbs";
import YourTeamContentWrap from "./YourTeamContentWrap";
import HeaderWrapper from "../common/HeaderWrapper";

import { FooterTop } from "../common/Footer";
import { fetchHeaderPage } from "../../actions/fetchHeaderData"
import FooterWrapper from "../common/FooterWrapper";
import { getResultsArray, loadApiData, saveApiData } from "../../config/utility";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import ZipChooser from "../common/ZipChooser";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";
import CopyAside from "../common/CopyAside";

class YourTeamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData('x31'),
      localOfficeArray: loadApiData('localoffice')
    };
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(this.props?.headerItem, "GlobalSiteControl");
    const localOfficeResults = this?.props?.localOfficeItem;
    this.props.link && this.props.dispatch(fetchHomePage(this.props.link))

    if ((this.state.globalSiteCrl?.length === 0 || this.state.globalSiteCrl === undefined) && headerItemData?.length === 0) {
      this.props.dispatch(fetchHeaderPage('x4878'));
    }
    if ((this.state.localOfficeArray?.length === 0 || this.state.localOfficeArray === undefined) && localOfficeResults?.length === 0) {
      this.props.dispatch(fetchLocalOfficeData('localoffice/orderby/state'));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.link !== nextProps.link) {
      this.props.dispatch(fetchHomePage(nextProps.link))
      window.scrollTo(0, 0);
    }
  }
  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    const franchiseHomePage = getResultsArray(this.props?.franchiseHomeItem, "FranchiseHomePage");
    const headerData = getResultsArray(this.props?.headerItem, "GlobalSiteControl");
    const localOffices = this?.props?.localOfficeItem;

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData('x31');
    let localOfficeResults = loadApiData('localoffice');

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if ((headerItemData === undefined || headerItemData?.length === 0) && headerData) {
      saveApiData('x31', headerData);
      headerItemData = loadApiData('x31');
    }
    if ((localOfficeResults === undefined || localOfficeResults?.length === 0) && localOffices) {
      saveApiData('localoffice', localOffices);
      localOfficeResults = loadApiData('localoffice');
    }

    let allowedZipcodes = getResultsArray(localOfficeResults, "AllowedZipcodes");
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix = headerItemData && headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        <Helmet>
           {/* removed "| Ace Hardware Plumbing Services" in title */}
          <title>{pageTitle ? `${pageTitle}` : browserSuffix}</title>
          <meta name="description" content={homeItemData[0]?.Description?.Value} />
        </Helmet>
        <HeaderWrapper headerItemData={headerItemData} />

        {homeItemData[0]?.HeroWithOverlay !== undefined ?
          <CareersHeroWithOverlayTray
            BackgroundImage={homeItemData[0]?.HeroWithOverlay?.BackgroundImage?.Value}
            Heading={homeItemData[0]?.HeroWithOverlay?.Heading?.Value}
            Subheading={homeItemData[0]?.HeroWithOverlay?.Subheading?.Value}
            PageLink={homeItemData[0]?.HeroWithOverlay?.Page?.Link}
            PageUrl={homeItemData[0]?.HeroWithOverlay?.Page?.URL}
            TextBoxBackgroundColor={homeItemData[0]?.HeroWithOverlay?.TextBoxBackgroundColor?.Value}
            TextBoxAlignment={homeItemData[0]?.HeroWithOverlay?.TextBoxAlignment?.Value} />
          :
          <>
            <YourTeamBanner heading={homeItemData[0]?.Heading} />
            <YourTeamBreadcrumbs contentData={homeItemData[0]} />
          </>
        }

        {
          (homeItemData[0]?.Copy || homeItemData[0]?.AsideImage) &&
          <CopyAside contentData={homeItemData[0]} />
        }

        {/* <YourTeamContentWrap teamContent={homeItemData[0]} /> */}
        {homeItemData[0]?.Trays?.map((tray, index) => {
          if (Object.keys(corpDynamicTrayList)?.find(trayName => trayName === tray?.TrayName)) {

            let combinedProps;

            if (tray?.TrayName === "ScheduleandEstimateTabTray" || tray?.TrayName === "ScheduleandEstimateTray") {
              combinedProps = { ...{ ...tray, ...{ zipCodeList: localOfficeZipcodes }, ...{ localOfficeList: localOfficeResults }, ...{ franchiseDataToGetEstimate } } }
            }
            else if (tray?.TrayName === "ServicesTray") {
              combinedProps = { ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } } }
            }
            else if (tray?.TrayName === "FindAnOffice") {
              combinedProps = { ...{ ...tray, ...{ zipCodeList: localOfficeZipcodes }, ...{ localOfficeList: localOfficeResults } } }
            }
            else {
              combinedProps = { ...tray }
            }

            return (
              <CorpDynamicTrays
                trayName={tray?.TrayName}
                contentData={combinedProps}
                key={index}
              />
            )
          }
        })}

        <FooterWrapper headerItemData={headerItemData} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(YourTeamPage);