import React from "react";
import { Helmet } from "react-helmet";

import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";

const FranchiseSeasonalServices = (props) => {
    const { contentData } = props;
    return (
        <>
            <Helmet>
                <title>{contentData?.Title?.Value}
                </title>
                <meta name="description" content={contentData?.Description?.Value} />
            </Helmet>
            {contentData?.Trays?.map((tray, index) => {
                if (Object.keys(dynamicTrayList)?.find(trayName => trayName === tray?.TrayName)) {
                    return (
                        <DynamicTrays
                            trayName={tray?.TrayName}
                            contentData={tray}
                            key={index}
                        />
                    )
                }
            })}
        </>
    )
}

export default FranchiseSeasonalServices;
