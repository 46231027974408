//This utility function used to pass data from functional component to a class component
import React, { useRef } from "react";
import { useLocation } from 'react-router-dom';

export const withRouter = WrappedComponent => props => {
    const location = useLocation();
    
    const apiRef = useRef();

    //to address the page refresh scenario.
    //apiRef assigned value from the link state if it exists
    //otherwise the apiPath will be taken from the pathname
    apiRef.current = location?.state ? location?.state : location?.pathname;

    return (
        <WrappedComponent
            {...props}
            apiRef={apiRef.current}
        />
    );
};

export default withRouter;