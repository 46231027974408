import React, { useState, useEffect, useCallback } from "react";
import { Markup } from "interweave";
import "./campaignFranchise.css";
import { ingeniuxURL } from "../../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../../config/utility";
import "./compaignPageBanner.css";
import WhyChooseUsBanner from "../../WhyChooseUsPage/WhyChooseUsBanner";

export const formatPhoneforCampaign = (phoneNumberString) => {
  var onlyDigits = ("" + phoneNumberString).replace(/\D/g, "");
  var match = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ")" + " " + match[2] + "-" + match[3];
  }
  return null;
};

export const CampaignPageBanner = (props) => {
  const {
    bannerContent,
    headingContent,
    headerContent,
    otherProps = [],
    paintStrokes,
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = props;
  const [otherProp, frnachiseBaseURL] = otherProps;
  const [swapPhone, setSwapPhone] = useState("");
  return bannerContent?.Value ? (
    <section id="detail-banner">
      <div
        className="detail-page-banner"
        style={{
          position: "relative",
          height: "350px",
          backgroundImage: bannerContent?.Value && `url(${ingeniuxURL}${bannerContent?.Value})`,
        }}
      >
        <div className="container">
          <div className="row twelve">
            <div className="logo col-sd-5 col-md-6 col-dd-3">
              <div style={{
                position: "absolute", top
                  : "0"
              }}>
                <a href={frnachiseBaseURL}>
                  {headerContent?.Logo?.Value ? (
                    <img
                      src={`${ingeniuxURL}${headerContent?.Logo?.Value}`}
                      alt={headerContent?.Logo?.AlternateText}
                      width="230"
                      height="90"
                    />
                  ) : (
                      ""
                    )}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-phone franchise-home">
          <div
            className="corporate-local"
            style={{ display: "none" }}
          >
            <span className="yo-local-phone yo-local-href-phone"></span>
          </div>
          {headerContent && (
            <div className="campaign-corporate">
              <button style={{ backgroundColor: "rgba(204,204,204,1)", display: "block", borderRadius: "5px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "10px", paddingBottom: "10px" }}><a
                href={
                  swapPhone
                    ? `tel:${swapPhone}`
                    : otherProp?.TelephoneScript?.Value
                      ? `tel:${otherProp?.TelephoneScript?.Value}`
                      : `tel:${otherProp?.PhoneNumber?.Value}`
                } style={{ color: "black" }}
              >
                {swapPhone
                  ? "CALL " + formatPhoneforCampaign(swapPhone)
                  : "CALL " + formatPhoneforCampaign(otherProp?.PhoneNumber?.Value)}
              </a>
              </button>
            </div>
          )}
        </div>
        <div className="container">
          <div className="banner-heading-container">
            <h1
              className="header h1"
            >
              <Markup
                content={headingContent?.Value?.replace("{City}", franchiseCity)
                  ?.replace("{State}", franchiseState)
                  ?.replace("{FranchiseName}", franchiseName)
                  ?.replace("{StateAbbreviation}", stateAbbr)
                  ?.replace(
                    "{Phone}",
                    formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                  )}
              />
            </h1>
          </div>
        </div>
      </div>
    </section>
  ) : (
      <div className="why-choose-us-franchise">
        <WhyChooseUsBanner contentData={headingContent?.Value} />
      </div>
    );
};
