import React from "react";
import "./ReferenceDetailsForm.css";
import CareerBreadcrumb from "../CareerBreadcrumb";

const ReferenceDetailsForm = ({
  updateFields,
  errors,
  handleFileUpload,
  ref1Name,
  ref1EmailId,
  ref1PhoneNumber,
  ref2Name,
  ref2EmailId,
  ref2PhoneNumber,
  ref3Name,
  ref3EmailId,
  ref3PhoneNumber,
  currentlyEmployed,
  contactPresentEmployer,
  resume,
  franchiseName,
  franchiseBaseUrl,
}) => {
  const { ref1FirstName, ref1LastName } = ref1Name;
  const { ref2FirstName, ref2LastName } = ref2Name;
  const { ref3FirstName, ref3LastName } = ref3Name;

  return (
    <div className="reference-details">
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="References" />
      <div className="form-group form-field-separator">
        <h3 className="form-title">References</h3>
      </div>
      <>
      <div className="form-group form-row" style={{position:"relative"}}>
        <input
          type="text"
          id="reference1-firstname"
          className="first-field"
          placeholder="Reference 1 First Name*"
          value={ref1FirstName}
          onChange={(e) => updateFields({ ref1FirstName: e.target.value }, "ref1Name")}
        />
        {errors.ref1FirstName && (
          <p          
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref1FirstName}
          </p>
        )}
        <input
          type="text"
          id="reference1-lastname"
          placeholder="Reference 1 Last Name*"
          value={ref1LastName}
          onChange={(e) =>
            updateFields({ ref1LastName: e.target.value }, "ref1Name")
          }
        />
        {errors.ref1LastName && (
          <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref1LastName}
          </p>
        )}
      </div>
      <div className="form-group form-row" style={{position:"relative"}}>
        <input
          type="text"
          id="reference1-email"
          className="first-field"
          placeholder="Refernce 1 Email*"
          value={ref1EmailId}
          onChange={(e) => updateFields({ ref1EmailId: e.target.value })}
        />
        {errors.ref1EmailId && (
          <p          
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref1EmailId}
          </p>
        )}
        <input
          type="text"
          size="20"
          id="reference1-phone-number"
          placeholder="Reference 1 Phone*"
          value={ref1PhoneNumber}
          onChange={(e) => updateFields({ ref1PhoneNumber: e.target.value })}
        />
        {errors.ref1PhoneNumber && (
          <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref1PhoneNumber}
          </p>
        )}
      </div>
      </>
      <>
      <div className="form-group form-row" style={{position:"relative"}}>
        <input
          type="text"
          id="reference2-firstname"
          className="first-field"
          placeholder="Reference 2 First Name*"
          value={ref2FirstName}
          onChange={(e) =>
            updateFields({ ref2FirstName: e.target.value }, "ref2Name")
          }
        />
        {errors.ref2FirstName && (
          <p          
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref2FirstName}
          </p>
        )}
        <input
          type="text"
          id="reference2-lastname"
          placeholder="Reference 2 Last Name*"
          value={ref2LastName}
          onChange={(e) =>
            updateFields({ ref2LastName: e.target.value }, "ref2Name")
          }
        />
        {errors.ref2LastName && (
          <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref2LastName}
          </p>
        )}
      </div>
      <div className="form-group form-row" style={{position:"relative"}}>
        <input
          type="text"
          id="reference2-email"
          className="first-field"
          placeholder="Refernce 2 Email*"
          value={ref2EmailId}
          onChange={(e) => updateFields({ ref2EmailId: e.target.value })}
        />
        {errors.ref2EmailId && (
        <p          
        style={{
          position: "absolute",
          left: 0,
          bottom: "-20px",
          textAlign: "left",
          fontSize: ".70rem",
          lineHeight: "2.1em",
          color: "red",
          margin: "0",
        }}
        >
          {errors.ref2EmailId}
        </p>
      )}
        <input
          type="text"
          id="reference2-phone-number"
          placeholder="Reference 2 Phone*"
          size="20"
          value={ref2PhoneNumber}
          onChange={(e) => updateFields({ ref2PhoneNumber: e.target.value })}
        />
        {errors.ref2PhoneNumber && (
        <p          
        style={{
          position: "absolute",
          left: "51%",
          bottom: "-20px",
          textAlign: "left",
          fontSize: ".70rem",
          lineHeight: "2.1em",
          color: "red",
          margin: "0",
        }}
        >
          {errors.ref2PhoneNumber}
        </p>
      )}
      </div>
      </>
      <>
        <div className="form-group form-row" style={{position:"relative"}}>
          <input
            type="text"
            id="reference3-firstname"
            className="first-field"
            placeholder="Reference 3 First Name*"
            value={ref3FirstName}
            onChange={(e) =>
              updateFields({ ref3FirstName: e.target.value }, "ref3Name")
            }
          />
          {errors.ref3FirstName && (
          <p
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref3FirstName}
          </p>
        )}
          <input
            type="text"
            id="reference3-lastname"
            placeholder="Reference 3 Last Name*"
            value={ref3LastName}
            onChange={(e) =>
              updateFields({ ref3LastName: e.target.value }, "ref3Name")
            }
          />
          {errors.ref3LastName && (
          <p
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref3LastName}
          </p>
        )}
        </div>
        <div className="form-group form-row" style={{position:"relative"}}>
          <input
            type="text"
            id="reference3-email"
            className="first-field"
            placeholder="Reference 3 Email*"
            value={ref3EmailId}
            onChange={(e) => updateFields({ ref3EmailId: e.target.value })}
          />
           {errors.ref3EmailId && (
          <p
          style={{
            position: "absolute",
            left: 0,
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref3EmailId}
          </p>
        )}
          <input
            type="text"
            id="reference3-phone-number"
            placeholder="Reference 3 Phone*"
            size="20"
            value={ref3PhoneNumber}
            onChange={(e) => updateFields({ ref3PhoneNumber: e.target.value })}
          />
          {errors.ref3PhoneNumber && (
          <p
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
          >
            {errors.ref3PhoneNumber}
          </p>
        )}
        </div>        
      </>

      <div className="form-group currently-employed">
        <label>Are You Currently Employed?</label>
        <div>
          <input
            type="radio"
            id="currently-employed-yes"
            name="currently-employed"
            value="yes"
            checked={currentlyEmployed !== "" && currentlyEmployed === "yes"}
            onChange={(e) =>
              updateFields({ currentlyEmployed: e.target.value })
            }
          />
          <label for="currently-employed-yes">Yes</label>
        </div>
        <div>
          <input
            type="radio"
            id="currently-employed-no"
            name="currently-employed"
            value="no"
            checked={currentlyEmployed !== "" && currentlyEmployed === "no"}
            onChange={(e) =>
              updateFields({ currentlyEmployed: e.target.value })
            }
          />
          <label for="currently-employed-no">No</label>
        </div>
      </div>
      <div className="form-group contact-current-employer">
        <label>If So, May We Contact Your Present Employer?</label>
        <div>
          <input
            type="radio"
            id="contact-current-employer-yes"
            name="current-employer"
            value="yes"
            checked={
              contactPresentEmployer !== "" && contactPresentEmployer === "yes"
            }
            onChange={(e) =>
              updateFields({ contactPresentEmployer: e.target.value })
            }
          />
          <label for="contact-current-employer-yes">Yes</label>
        </div>
        <div>
          <input
            type="radio"
            id="contact-current-employer-no"
            name="current-employer"
            value="no"
            checked={
              contactPresentEmployer !== "" && contactPresentEmployer === "no"
            }
            onChange={(e) =>
              updateFields({ contactPresentEmployer: e.target.value })
            }
          />
          <label for="contact-current-employer-no">No</label>
        </div>
      </div>
      <div className="form-group">
        <label for="resume">Please Upload Your Resume</label>
        <div>
          <input
            type="file"
            id="resume"
            className="file-upload"
            placeholder="Please Upload Your Resume"
            onChange={(e) => handleFileUpload(e)}
          />
        </div>
        <label>Max file size: 50MB</label>
      </div>
    </div>
  );
};

export default ReferenceDetailsForm;
