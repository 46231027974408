import React from "react";
import { Markup } from "interweave";
import { getFranchiseDataFromLS, getStickyHeaderHeight } from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import "./HomeBanner.css";

const HomeBanner = ({ homeBannerContent = {} ,calculateBannerPadding}) => {
  const blockPlacement = homeBannerContent?.ContentBlockPlacement;

  const posBanner = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "pos-right";
    } else if (pos === "Center") {
      cssClass = "pos-center";
    } else cssClass = "pos-left";
    return cssClass;
  };

  const bannerMargin = (pos) => {
    let cssClass;
    if (pos === "Right") {
      cssClass = "mr-50";
    } else if (pos === "Center") {
      cssClass = "";
    } else cssClass = "ml-50";
    return cssClass;
  };

  return (
    <section
      id="banner"
      className={
        getFranchiseDataFromLS("recentFranchisePath") !== undefined
          ? "banner-logo"
          : ""
      }
      style={{paddingTop:`${getStickyHeaderHeight()+5}px`}}
    >
      <div
        className={`banner-slide home display-flex ${posBanner(
          blockPlacement?.Value
        )}`}
        style={{
          background: homeBannerContent?.BackgroundImage?.Value
            ? `#efeff0 url(${ingeniuxURL}${homeBannerContent?.BackgroundImage?.Value}) no-repeat top / cover`
            : "#efeff0",
        }}
      >
        <div
          className={`home-banner-container ${bannerMargin(
            blockPlacement?.Value
          )}`}
        >
          <div className="banner-text">
            <div className="banner-home-wrap">
              <div className="banner-logo">
                {homeBannerContent?.ForegroundLogo?.Value && (
                  <img
                    src={
                      homeBannerContent?.ForegroundLogo?.Value &&
                      `${ingeniuxURL}${homeBannerContent?.ForegroundLogo?.Value}`
                    }
                    alt={homeBannerContent?.ForegroundLogo?.AlternateText}
                    title={homeBannerContent?.ForegroundLogo?.AlternateText}
                    width="300"
                    height="120"
                  />
                )}
              </div>

              <div className="header">
                <h1 className="headline">
                  <Markup content={homeBannerContent?.Heading?.Value} />
                </h1>
                <div className="headline h1">
                  <Markup
                    content={homeBannerContent?.Copy?.Value}
                    tagName="div"
                  />
                </div>
                <h1 className="headline">
                  <a href="/offices" className="find-local-plumber">
                    <span>Find your local Plumber</span>
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeBanner;
