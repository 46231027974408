import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./WhyChooseUsBreadcrumbs.css";

const WhyChooseUsBreadcrumbs = ({ contentData }) => {
  return (
    <div className="whychooseusbreadcrumbs-body-wrap">
      <section id="content" className="content-section twelve">
        <div className="breadcrumbs">
          <div className="container">
            <ul>
              {contentData?.map((breadCrum, i, row) => {
                if (row.length === 1 && breadCrum.Name !== "Home") {
                  return (
                    <>
                      <li key={i} className="home">
                        <Link to={`/`}> Home</Link>
                        {" | "}
                      </li>
                      <li key={i}>
                        <span> {breadCrum?.Name}</span>
                      </li>
                    </>
                  );
                } else if (i === 0 && breadCrum.Name !== "Home") {
                  return (
                    <>
                      <li key={i} className="home">
                        <Link to={`/`}> Home</Link>
                        {" | "}
                      </li>
                      <li key={i} className="home">
                      <Link to={breadCrum?.URL.startsWith("/")?`${breadCrum?.URL}`:`/${breadCrum?.URL}`}> {breadCrum?.Name === "Ace Plumbing Services North Oklahoma City" ? "NORTH OKLAHOMA CITY" : breadCrum?.Name}</Link>
                      {i + 1 === row.length ? "" : " | "}
                    </li>
                    </>
                  );
                } else if (i + 1 === row.length) {
                  return (
                    <li key={i}>
                      <span> {breadCrum?.Name}</span>
                    </li>
                  );
                } else {
                  return (
                    <li key={i} className="home">
                      <Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link>
                      {i + 1 === row.length ? "" : " | "}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUsBreadcrumbs;
