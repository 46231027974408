import React from 'react';
import { Link } from 'react-router-dom';

const CareerBreadcrumb = ({ franchise, franchiseUrl, component }) => {
  return (
    
    <div className="breadcrumb">
    <div className="breadcrumb-container">{console.log('franchisenameurl', franchiseUrl)}
      <ul>
        <li>
          <Link to="/">HOME</Link>
        </li>
        {franchiseUrl && (
            <>
            <li> | </li>
            <li>
                <Link to={franchiseUrl}>
                  {/* ACE PLUMBING SERVICES&nbsp; */}
                  {franchise && franchise?.replace("Ace Hardware Plumbing Services ", "")}</Link>
            </li>
          </>
        )}
        <li className="breadcrumb-separator">|</li>
        <li className="breadcrumb-item">
          <Link to={`${franchiseUrl}/careers`}>Careers</Link>
        </li>
        {/* {component && (
          <>
            <li className="breadcrumb-separator">|&nbsp;&nbsp;</li>
            <li className="breadcrumb-item active" aria-current="page">
              {component}
            </li>
          </>
        )} */}
      </ul>
    </div>
    
    </div>
  );
};

export default CareerBreadcrumb;
