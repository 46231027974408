import React, { useState } from "react";
import { Markup } from "interweave";
import { useLocation, useNavigate } from "react-router-dom";
import { azureEmailingUrl, ingeniuxURL } from "../../config/urls";
import axios from "axios";
import { generateUniqueId } from "../../config/utility";
import ImageCompressor from "image-compressor.js";
import "./index.css";

import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  emptyCheck,
  aceBusinessName,
  readCookieValue,
  extractPath,
  getFranchiseDataFromLS,
  storeFranchiseDataToLS,
} from "../../config/utility";
import "../common/getEstimate.css";
import LocalOfficeNonExistent from "../common/LocalOfficeNonExistent";

const GetEstimateJotFrom = ({
  viewPortWidth = "",
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
  contentData: estimateData,
  handymanmatters,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const initStatePrjImgs = {
    images: {
      q8_picture1: "",
      q10_picture2: "",
      q11_picture3: "",
    },
    errors: "",
  };
  const initStateYotrackData = {
    formID: "91254806635157",
    website: "",
    simple_spc: "91254806635157-91254806635157",
    q12_typeA: "",
    q13_send_to: "",
    q14_from_page: "",
    q15_business_name: "",
    q17_territory: "not-swapped",
    q19_uid: "",
    event_id: "",
  };
  const [phoneValue, setPhoneValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidProject, setIsValidProject] = useState(true);
  const [zipcodeValue, setZipcodeValue] = useState("");
  const [isValidZipcode, setIsValidZipcode] = useState(true);
  const [zipLenErr, setZipLenErr] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [LastNameValue, setLastNameValue] = useState("");
  const [isPopulatedFirstName, setIsPopulatedFirstName] = useState(true);
  const [isPopulatedLastName, setIsPopulatedLastName] = useState(true);
  const [isPopulatedCity, setIsPopulatedCity] = useState(true);
  const [isPopulatedState, setIsPopulatedState] = useState(true);
  const [projectValue, setProjectValue] = useState("");
  const [addressLine1Value, setAddressLine1Value] = useState("");
  const [addressLine2Value, setAddressLine2Value] = useState("");
  const [isPopulatedProject, setIsPopulatedProject] = useState(true);
  const [isPopulatedAddressLine1, setIsPopulatedAddressLine1] = useState(true);
  const [isPopulatedAddressLine2, setIsPopulatedAddressLine2] = useState(true);
  const [isErrorFree, setIsErrorFree] = useState(true);
  const [projectImg, setProjectImg] = useState(initStatePrjImgs);
  const [projectImgPreview, setProjectImgPreview] = useState(initStatePrjImgs);
  const [yotrackData, setYotrackData] = useState(initStateYotrackData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [serviceDownMessage, setServiceDownMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [matchLoc, setMatchLoc] = useState("");
  const [franchiseEmailId, setFranchiseEmailId] = useState("");

  const handymanId = process.env.REACT_APP_HANDYMANID;
  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;

  const handleFirstNameChange = (e) => {
    setFirstNameValue(e.target.value);
  };

  const handleFirstNameFocus = (e) => {
    const firstName = e.target;
    const label = document.querySelector('label[for="' + firstName.id + '"]');

    if (!firstName.value || firstName.value === label.innerHTML) {
      setIsPopulatedFirstName(true);
      setFirstNameValue("");
    }
  };

  const handleLastNameChange = (e) => {
    setLastNameValue(e.target.value);
  };

  const handleLastNameFocus = (e) => {
    const LastName = e.target;
    const label = document.querySelector('label[for="' + LastName.id + '"]');
    if (!LastName.value || LastName.value === label.innerHTML) {
      setIsPopulatedLastName(true);
      setLastNameValue("");
    }
  };

  const handleCityChange = (e) => {
    setCityValue(e.target.value);
  };

  const handleCityFocus = (e) => {
    const city = e.target;
    const label = document.querySelector('label[for="' + city.id + '"]');
    if (!city.value || city.value === label.innerHTML) {
      setIsPopulatedCity(true);
      setCityValue("");
    }
  };

  const handleStateChange = (e) => {
    setStateValue(e.target.value);
  };

  const handleStateFocus = (e) => {
    const state = e.target;
    const label = document.querySelector('label[for="' + state.id + '"]');
    if (!state.value || state.value === label.innerHTML) {
      setIsPopulatedState(true);
      setStateValue("");
    }
  };

  const formatPhoneWithBracket = (phone) => {
    const x = phone?.replace(/\D/g, "");
    if (x.length > 3 && x.length < 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3);
    } else if (x.length >= 7) {
      return "(" + x.slice(0, 3) + ") " + x.slice(3, 6) + "-" + x.slice(6);
    }
    return x;
  };

  const handlePhoneChange = (e) => {
    let formattedValue = regxUSPhone.test(e.target.value)
      ? e.target.value
      : formatPhoneWithBracket(e.target.value);

    if (formattedValue.length > 14) {
      formattedValue = formattedValue.slice(0, 14);
    }
    if (!isValidPhone) {
      setIsValidPhone(true);
    }
    setPhoneValue(formattedValue);
  };

  const handlePhoneFocus = (e) => {
    const phoneVal = e.target;
    const label = document.querySelector('label[for="' + phoneVal.id + '"]');
    if (!phoneVal.value || phoneVal.value === label.innerHTML) {
      setIsValidPhone(true);
      setPhoneValue("");
    }
  };

  const handleEmailChange = (e) => {
    if (!isValidEmail) {
      setIsValidEmail(true);
    }
    setEmailValue(e.target.value);
  };
  const handleEmailFocus = (e) => {
    const emailVal = e.target;
    const label = document.querySelector('label[for="' + emailVal.id + '"]');

    if (!emailVal.value || emailVal.value === label.innerHTML) {
      setIsValidEmail(true);
      setEmailValue("");
    }
  };

  var matchingZipcode = "";

  const handleZipcodeChange = (e) => {
    e.preventDefault();

    let isValidZip;
    const zipVal = e.target.value;
    setZipcodeValue(zipVal);
    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }
    for (let i = 0; i < estimateData?.zipCodeList?.length; i++) {
      for (let j = 0; j < estimateData?.zipCodeList[i]?.length; j++) {
        if (zipVal === estimateData?.zipCodeList[i][j]) {
          matchingZipcode = zipVal;
        }
      }
    }

    if (matchingZipcode !== "") {
      setShowModal(false);
    } else if (matchingZipcode === "" && isValidZip) {
      setShowModal(true);
      // boolean set to true to show modal
    }

    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZipcode) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = estimateData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );

      storeFranchiseDataToLS(
        "recentEstimateSubmissionPath",
        `/${locationMatch[0]?.HomePageLink?.URL}`
      );
      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      zipLocationMatch && setMatchLoc(zipLocationMatch);
      const franchiseClientId = locationMatch && locationMatch[0]?.ClientId;
      if (zipLocationMatch) {
        getFranchiseEmailId(zipLocationMatch);
      }

      //siteTrackingId available as part of localoffice api, assigning to
      //siteTrackId fromt he matched location
      const siteTrackId = locationMatch && locationMatch[0]?.SiteTrackingId;
      if (siteTrackId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q14_from_page: siteTrackId,
        }));
      }

      //siteId either FranchiseClientId or corpId
      let siteId;
      if (franchiseClientId) {
        siteId = franchiseClientId;
      } else siteId = corpClientId;

      //YoTrack script added in the index.html, hence accessible only vis window object.
      window.YoTrack(handymanId, siteId, function (err, api) {
        console.log("err", err, "api", api);
      });

      if (locationMatch?.length > 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${franchiseName}, ${stateAbbr}`,
          q17_territory: "no-location-returned",
        }));
      } else if (locationMatch?.length > 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: aceBusinessName,
          q17_territory: "fma-loc-not-found",
        }));
      }
      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const handleZipcodeFocus = (e) => {
    const zipVal = e.target;
    const label = document.querySelector('label[for="' + zipVal.id + '"]');
    if (!zipVal.value || zipVal.value === label.innerHTML) {
      setIsValidZipcode(true);
      setZipcodeValue("");
    }
  };

  const handleProjectChange = (e) => {
    setProjectValue(e.target.value);
    //reading cookie's UUID and updating the yoTrack data
    const uidCookieValue = readCookieValue("uid");

    setYotrackData((prevState) => ({
      ...prevState,
      q13_send_to: pathname,
      q19_uid: uidCookieValue ? uidCookieValue : "",
    }));
  };

  const handleProjectFocus = (e) => {
    const prjVal = e.target;
    const label = document.querySelector('label[for="' + prjVal.id + '"]');

    if (!prjVal.value || prjVal.value === label.innerHTML) {
      setIsPopulatedProject(true);
      setProjectValue("");
    }
  };

  const handleAddressLine1 = (e) => {
    setAddressLine1Value(e.target.value);
  };

  const handleAddressLine1Focus = (e) => {
    const addressLine1Val = e.target;
    const label = document.querySelector(
      'label[for="' + addressLine1Val.id + '"]'
    );
    if (!addressLine1Val.value || addressLine1Val.value === label.innerHTML) {
      setIsPopulatedAddressLine1(true);
      setAddressLine1Value("");
    }
  };

  const handleAddressLine2 = (e) => {
    setAddressLine2Value(e.target.value);
  };

  const handleAddressLine2Focus = (e) => {
    const addressLine2Val = e.target;
    const label = document.querySelector(
      'label[for="' + addressLine2Val.id + '"]'
    );
    if (!addressLine2Val.value || addressLine2Val.value === label.innerHTML) {
      setIsPopulatedAddressLine2(true);
      setAddressLine2Value("");
    }
  };

  const handleReadURL = async (e, prjImg) => {
    const input = e.target;

    if (input.files && input.files[0]) {
      if (input.files[0].size / 1024 > 20000) {
        setProjectImg((prevState) => ({
          ...prevState,
          errors:
            "Selected image is more than 20 MB, please choose a less sized image file.",
        }));
        return;
      }

      const compressedFile = await new ImageCompressor().compress(
        input.files[0]
      );

      setProjectImg((prevState) => ({
        ...prevState,
        images: {
          ...prevState.images,
          [prjImg]: input.files[0],
        },
        errors: "",
      }));

      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setProjectImgPreview((prevState) => ({
            ...prevState,
            images: {
              ...prevState.images,
              [prjImg]: reader.result,
            },
            errors: "",
          }));
        }
      };
    }
  };

  const requiredCheck = (field) => {
    if (!field.value.match(/^[\s\t\r\n]*\S+/gi)) {
      return false;
    }
    return true;
  };

  const getFranchiseEmailId = (franchisePath) => {
    const apiURL = `api/page?path=/${franchisePath}`;
    let franchiseEmailId = "";
    axios.get(`${ingeniuxURL}${apiURL}`).then((response) => {
      const resData = response?.data?.results;
      if (resData) {
        const franchiseCorpControl =
          resData[0]?.FranchiseHomePage?.FranchiseCorporateControl;
        franchiseEmailId = franchiseCorpControl?.EmailID?.Value;
        setFranchiseEmailId(franchiseEmailId);
      }
    });
  };

  const handleSubmit = async (e) => {
    // prevent page from refreshing
    e.preventDefault();
    setIsServiceDown(false);
    setIsLoading(true);
    setServiceDownMessage("");
    const isValidPhone = regxUSPhone.test(phoneValue);
    const isValidEmail = regxEmail.test(emailValue);
    const isValidZipcode = regxUSZipcode.test(zipcodeValue);
    const isPopulatedFirstName =
      emptyCheck.test(firstNameValue) && firstNameValue !== "First Name";
    const isPopulatedProject = projectValue !== "";
    const isPopulatedAddressLine1 =
      addressLine1Value !== "" && addressLine1Value !== "Address Line 1";
    if (!isValidPhone || phoneValue === "") {
      setIsValidPhone(false);
    }
    if (!isValidEmail || emailValue === "") {
      setIsValidEmail(false);
    }
    if (!isValidZipcode || zipcodeValue === "") {
      setIsValidZipcode(false);
      setZipcodeValue("Zip Code");
    }
    if (!firstNameValue || firstNameValue === "") {
      setIsPopulatedFirstName(false);
      setFirstNameValue("First Name");
    }
    if (!LastNameValue || LastNameValue === "") {
      setIsPopulatedLastName(false);
      setLastNameValue("Last Name");
    }
    if (!cityValue || cityValue === "") {
      setIsPopulatedCity(false);
      setCityValue("City");
    }
    if (!stateValue || stateValue === "") {
      setIsPopulatedState(false);
      setStateValue("State");
    }
    if (!projectValue || projectValue === "") {
      setIsValidProject(false);
      setIsPopulatedProject(false);
      setProjectValue("");
    }
    if (addressLine1Value === "") {
      setIsPopulatedAddressLine1(false);
      setAddressLine1Value("Address Line 1");
    }
    if (
      isValidPhone === false ||
      isValidEmail === false ||
      isValidZipcode === false ||
      isPopulatedFirstName === false ||
      isPopulatedProject === false ||
      isPopulatedState === false ||
      isPopulatedCity === false ||
      isPopulatedAddressLine1 === false
    ) {
      setIsErrorFree(false);
      setIsLoading(false);
    } else {
      setIsErrorFree(true);
      let payloadData = {};

      payloadData = {
        firstName: firstNameValue,
        lastname: LastNameValue,
        phoneNumber: phoneValue,
        emailAddress: emailValue,
        addressLine1: addressLine1Value,
        addressLine2: addressLine2Value,
        city: cityValue,
        state: stateValue,
        zipCode: zipcodeValue,
        tellUs: projectValue,
        ...projectImg.images,
        franchiseemailId: franchiseEmailId,
        source: "plumbing-get-an-estimate",
      };

      const formData = new FormData();
      Object.keys(payloadData).forEach((key) =>
        formData.append(key, payloadData[key])
      );

      setIsSubmitting(true);

      axios
        .post(azureEmailingUrl, formData)
        .then((res) => {
          setIsLoading(true);
          setTimeout(() => {
            const recentFranchisePath = getFranchiseDataFromLS(
              "recentFranchisePath"
            );
            const recentEstimateSubmissionPath = getFranchiseDataFromLS(
              "recentEstimateSubmissionPath"
            );

            const rgxforSlashes = /^\/|\/$/g;

            if (matchLoc && recentFranchisePath) {
              if (recentFranchisePath === recentEstimateSubmissionPath) {
                const franchisePath = recentFranchisePath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              } else if (recentFranchisePath !== recentEstimateSubmissionPath) {
                const franchisePath = recentEstimateSubmissionPath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              }
            } else if (matchLoc) {
              const franchisePath = recentEstimateSubmissionPath?.replace(
                rgxforSlashes,
                ""
              );
              navigate(`/${franchisePath}/contact_success`);
            } else {
              navigate("/contact_success");
            }
          }, 3000);
        })
        .catch((err) => {
          setIsSubmitting(false);
          console.log("AXIOS ERROR: ", err);
          setIsServiceDown(true);
          setServiceDownMessage(
            "oops, something went wrong, Please try again later"
          );
          setIsLoading(false);
        });
    }
  };

  const submitButtonId = generateUniqueId("submit-btn");

  return (
    <>
      <div className="col-md-12 col-dd-6 content">
        <div className="get-quote-wrap">
          <div className="form" id="quote-form">
            <h3>{estimateData?.DemographicsFormHeading?.Value}</h3>
            <p></p>
            <div id="field-wrap" className="col-sd-12 col-md-12 col-dd-12">
              <form
                className="jotform-form photo-quote-form"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                name="form_91254806635157"
              >
                {isLoading && (
                  <div className="loadergif">
                    <div className="spinner"></div>
                    <p className="loading-text">Loading...</p>
                  </div>
                )}
                <div role="main" className="form-all">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                    }}
                  >
                    <div
                      className="col-sd-12  col-md-6 form-field"
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_3"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_3"
                          htmlFor="input_3"
                        >
                          {estimateData?.FirstName?.Value}
                        </label>
                        <div
                          id="cid_3"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedFirstName ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_3"
                            name="firstname"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.FirstName?.Value}
                            area-label="First Name"
                            data-component="textbox"
                            onChange={handleFirstNameChange}
                            onFocus={handleFirstNameFocus}
                            value={firstNameValue}
                          />
                          <p
                            className={`${
                              isPopulatedFirstName ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                              margin: viewPortWidth ? "0 0 20px 0" : "0",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-sd-12  col-md-6 form-field"
                      style={{ marginLeft: viewPortWidth ? "0" : "18px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_ln"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_ln"
                          htmlFor="input_ln"
                        >
                          {estimateData?.LastName?.Value}
                        </label>
                        <div
                          id="cid_ln"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedLastName ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_ln"
                            name="lastname"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.LastName?.Value}
                            area-label="Last Name"
                            data-component="textbox"
                            onChange={handleLastNameChange}
                            onFocus={handleLastNameFocus}
                            value={LastNameValue}
                          />
                          <p
                            className={`${
                              isPopulatedLastName ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                              margin: viewPortWidth ? "0 0 20px 0" : "0",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                    }}
                  >
                    <div
                      className="col-sd-12  col-md-6 form-field"
                      style={{ marginRight: "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_5"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_5"
                          htmlFor="input_5"
                        >
                          {estimateData?.EmailAddress?.Value}
                        </label>
                        <div
                          id="cid_5"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidEmail ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_5"
                            name="q5_emailAddress"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.EmailAddress?.Value}
                            aria-label="Email Address"
                            data-component="textbox"
                            onChange={handleEmailChange}
                            onFocus={handleEmailFocus}
                            value={emailValue}
                          />
                          <p
                            className={`${isValidEmail ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {emailValue
                              ? "Please enter a valid emailID"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sd-12  col-md-6 form-field"
                      style={{ marginLeft: viewPortWidth ? "0" : "18px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_4"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_4"
                          htmlFor="input_4"
                        >
                          {estimateData?.PhoneNumber?.Value}
                        </label>
                        <div
                          id="cid_4"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidPhone ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_4"
                            name="q4_phoneNumber"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.PhoneNumber?.Value}
                            aria-label="Phone Number"
                            data-component="textbox"
                            onChange={handlePhoneChange}
                            onFocus={handlePhoneFocus}
                            value={phoneValue}
                          />
                          <p
                            className={`${isValidPhone ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            {phoneValue
                              ? "Please enter a valid phone number"
                              : "This field is required."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      className="col-sd-12 form-field"
                      style={{ width: "100%", paddingRight: "0" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textarea"
                        id="id_a1"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_a1"
                          htmlFor="input_a1"
                        >
                          {estimateData?.AddressLine1?.Value}
                        </label>

                        <div
                          id="cid_a1"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedAddressLine1 ? "" : "error"
                          }`}
                        >
                          <input
                            id="input_a1"
                            className="form-textarea validate[required] custom-hint-group form-custom-hint"
                            required=""
                            name="addressLine1"
                            cols="40"
                            rows="6"
                            data-component="textarea"
                            aria-label="Address Line 1*"
                            data-customhint="Address Line 1"
                            customhinted="true"
                            placeholder={estimateData?.AddressLine1?.Value}
                            spellCheck="false"
                            onChange={handleAddressLine1}
                            onFocus={handleAddressLine1Focus}
                            value={addressLine1Value}
                            style={{ height: "42px;" }}
                          ></input>
                          <p
                            className={`${
                              isPopulatedAddressLine1 ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "1.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: viewPortWidth ? "column" : "row",
                    }}
                  >
                    <div className="col-sd-12  col-md-4 form-field">
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_city"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_city"
                          htmlFor="input_city"
                        >
                          {estimateData?.City?.Value}
                        </label>
                        <div
                          id="cid_city"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedCity ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_city"
                            name="City"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.City?.Value}
                            aria-label="City"
                            data-component="textbox"
                            onChange={handleCityChange}
                            onFocus={handleCityFocus}
                            value={cityValue}
                          />
                          <p
                            className={`${isPopulatedCity ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sd-12  col-md-4 form-field"
                      style={{ marginLeft: viewPortWidth ? "0" : "10px" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_state"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_state"
                          htmlFor="input_state"
                        >
                          {estimateData?.State?.Value}
                        </label>
                        <div
                          id="cid_state"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedState ? "" : "error"
                          }`}
                        >
                          <input
                            type="text"
                            id="input_state"
                            name="State"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required]"
                            size="20"
                            placeholder={estimateData?.State?.Value}
                            aria-label="State"
                            data-component="textbox"
                            onChange={handleStateChange}
                            onFocus={handleStateFocus}
                            value={stateValue}
                          />
                          <p
                            className={`${isPopulatedState ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div id="cid-zip" className="col-sd-12 col-md-4 form-field">
                      <div
                        className="form-line jf-required"
                        data-type="control_textbox"
                        id="id_6"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_6"
                          htmlFor="input_6"
                        >
                          {estimateData?.ZipCode?.Value}
                        </label>
                        <div
                          id="cid_6"
                          className={`form-input-wide no-error-message jf-required ${
                            isValidZipcode ? "" : "error"
                          } ${zipLenErr ? "error" : ""}`}
                        >
                          <input
                            type="text"
                            id="input_6"
                            name="q6_zipCode"
                            data-type="input-textbox"
                            required=""
                            className="form-textbox validate[required, Zipcode]"
                            size="20"
                            placeholder={estimateData?.ZipCode?.Value}
                            area-label="Zip Code"
                            data-component="textbox"
                            onChange={handleZipcodeChange}
                            onFocus={handleZipcodeFocus}
                            value={zipcodeValue}
                            maxLength="5"
                            autoComplete="off"
                          />
                          <p
                            className={`${isValidZipcode ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                          <p
                            className={`${!zipLenErr ? "hideField" : ""}`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "2.1em",
                              color: "red",
                            }}
                          >
                            This field must contain a 5-digit zip code
                          </p>
                        </div>
                      </div>
                      <LocalOfficeNonExistent
                        showModal={showModal}
                        setShowModal={setShowModal}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className="col-sd-12 form-field"
                      style={{ width: "100%", paddingRight: "0" }}
                    >
                      <div
                        className="form-line jf-required"
                        data-type="control_textarea"
                        id="id_7"
                      >
                        <label
                          className="form-label form-label-top form-label-auto form-label-hide"
                          id="label_7"
                          htmlFor="input_7"
                        >
                          {estimateData?.TellUsAbout?.Value}
                        </label>
                        <div
                          id="cid_7"
                          className={`form-input-wide no-error-message jf-required ${
                            isPopulatedProject ? "" : "error"
                          }`}
                        >
                          <textarea
                            id="input_7"
                            className="form-textarea validate[required] custom-hint-group form-custom-hint"
                            required=""
                            name="q7_tellUs"
                            cols="40"
                            rows="6"
                            data-component="textarea"
                            aria-label="Tell us about your project*"
                            data-customhint="Tell us about your project"
                            customhinted="true"
                            placeholder={estimateData?.TellUsAbout?.Value}
                            spellCheck="false"
                            onChange={handleProjectChange}
                            onFocus={handleProjectFocus}
                            value={projectValue}
                          ></textarea>
                          <p
                            className={`${
                              isPopulatedProject ? "hideField" : ""
                            }`}
                            style={{
                              textAlign: "left",
                              fontSize: ".70rem",
                              lineHeight: "1.1em",
                              color: "red",
                            }}
                          >
                            This field is required.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sd-12 form-field"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="form-line"
                      data-type="control_text"
                      id="id_13"
                    >
                      <div id="cid_13" className="form-input-wide">
                        <div
                          id="text_13"
                          className="form-html"
                          data-component="text"
                        >
                          <h3>{estimateData?.PhotoFormHeading?.Value}</h3>

                          <Markup
                            context={estimateData?.PhotoFormCopy?.Value}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row no-margin">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: viewPortWidth ? "column" : "row",
                          marginBottom: "-10px",
                        }}
                      >
                        <div
                          className="form-line col-sd-12 col-md-4"
                          data-type="control_fileupload"
                          id="id_8"
                        >
                          <div
                            id="cid_8"
                            className="form-input-wide jotform-img-container"
                          >
                            <input
                              type="file"
                              id="input_8"
                              name="q8_picture1"
                              className="form-upload validate[upload]"
                              accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                              aria-label="Project Photo 1"
                              onChange={(e) => handleReadURL(e, "q8_picture1")}
                            />

                            <label
                              className="form-label form-label-top file-label"
                              id="label_8"
                              htmlFor="input_8"
                            >
                              {projectImgPreview?.images?.q8_picture1 ? (
                                <img
                                  src={projectImgPreview?.images?.q8_picture1}
                                  alt="image1"
                                  title="Project image 1"
                                  className="img-preview"
                                  width="186"
                                  height="73"
                                />
                              ) : (
                                <>
                                  <span className="icon">
                                    <i
                                      className={`${estimateData?.PhotoIconClass?.Value}`}
                                    ></i>
                                  </span>
                                  <span className="photo-text">
                                    {estimateData?.AddaPhoto?.Value}
                                  </span>
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="form-line col-sd-12 col-md-4"
                          data-type="control_fileupload"
                          id="id_10"
                        >
                          <div
                            id="cid_10"
                            className="form-input-wide jotform-img-container"
                          >
                            <input
                              type="file"
                              id="input_10"
                              name="q10_picture2"
                              className="form-upload validate[upload]"
                              accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                              aria-label="Project Photo 2"
                              onChange={(e) => handleReadURL(e, "q10_picture2")}
                            />
                            <label
                              className="form-label form-label-top file-label"
                              id="label_10"
                              htmlFor="input_10"
                            >
                              {projectImgPreview?.images?.q10_picture2 ? (
                                <img
                                  src={projectImgPreview?.images?.q10_picture2}
                                  className="img-preview"
                                  alt="image2"
                                  title="Project image 2"
                                  width="186"
                                  height="73"
                                />
                              ) : (
                                <>
                                  <span className="icon">
                                    <i
                                      className={`${estimateData?.PhotoIconClass?.Value}`}
                                    ></i>
                                  </span>
                                  <span className="photo-text">
                                    {estimateData?.AddaPhoto?.Value}
                                  </span>
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="form-line col-sd-12 col-md-4"
                          data-type="control_fileupload"
                          id="id_11"
                        >
                          <div
                            id="cid_11"
                            className="form-input-wide jotform-img-container"
                          >
                            <input
                              type="file"
                              id="input_11"
                              name="q11_picture3"
                              className="form-upload validate[upload]"
                              accept="image/png, image/jpeg, image/jpg, image/gif, image/tif"
                              aria-label="Project Photo 3"
                              onChange={(e) => handleReadURL(e, "q11_picture3")}
                            />
                            <label
                              className="form-label form-label-top file-label"
                              id="label_11"
                              htmlFor="input_11"
                            >
                              {projectImgPreview?.images?.q11_picture3 ? (
                                <img
                                  src={projectImgPreview?.images?.q11_picture3}
                                  className="img-preview"
                                  alt="image3"
                                  title="Project image 3"
                                  width="186"
                                  height="73"
                                />
                              ) : (
                                <>
                                  <span className="icon">
                                    <i
                                      className={`${estimateData?.PhotoIconClass?.Value}`}
                                    ></i>
                                  </span>
                                  <span className="photo-text">
                                    {estimateData?.AddaPhoto?.Value}
                                  </span>
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "0.8rem",
                    }}
                  >
                    {projectImg?.errors ? projectImg?.errors : ""}
                  </div>
                  <div
                    className="col-sd-12 form-field"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="form-line"
                      data-type="control_button"
                      id="id_16"
                    >
                      <div id="cid_16" className="form-input-wide">
                        <div className="form-buttons-wrapper">
                          <button
                            id={submitButtonId}
                            type="submit"
                            className="form-submit-button submit btn simpli-btn simpli-contact"
                            data-component="button"
                          >
                            {!isSubmitting
                              ? estimateData?.SubmitButtonText?.Value
                                ? estimateData?.SubmitButtonText?.Value
                                : "Submit"
                              : "Please wait..."}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${isErrorFree ? "hideField" : ""}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    There are errors on the form. Please fix them before
                    continuing.
                  </div>
                  <div
                    className={`${isServiceDown ? "" : "hideField"}`}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    {serviceDownMessage}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEstimateJotFrom;
