import React, { useEffect } from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../../../config/urls";
import "./CorpImageCopyTrayLeftAlignment.css";
import "../index.css";
import { useLocation } from "react-router-dom";

const viewPortWidth = window.screen.width;

const NewCorpImageCopyTrayLeftAlignment = ({ contentData }) => {
  const isVideo = false;
  const vimeoUrl = "https://player.vimeo.com/video/548521485?h=23cb3572ae";
  const youtubeUrl = "";

  let location = useLocation();

  useEffect(() => {
    const hashValue = location?.hash?.replace(/^#/, "");
    if (hashValue) {
      setTimeout(() => {
        const name = document.getElementById(hashValue);

        window.scroll({
          top: parseInt(name.offsetTop-(170)),
          left: 0,
          behavior: "smooth",
        });

        // name.scrollIntoView({
        //   behavior: "smooth",
        //   block: "start",
        //   inline: "start",
        // });
        // window.scrollBy(0, -170);
      }, 370);
    }
  }, [location?.hash]);

  return (
    <section
      id={contentData?.Heading?.Value?.replaceAll(" ", "_")}
      className="remodel bg-grey twelve img-copy-tray"
      style={{
        // changed gray color from #d8d8da to #e9e9e9
        background:
          contentData?.BackgroundColor?.Value === "Gray"
            ? "#e9e9e9"
            : contentData?.BackgroundColor?.Value === "White"
            ? "#fff"
            : contentData?.BackgroundColor?.Value === "Gray_Gradient"
            ? "linear-gradient(to bottom,#fff 0,#d9d9d9 100%)"
            : "none",
      }}
    >
      <div className="container container-1040">
        <div className="row image-tray-container">
          <div
            className="tray-img"
            style={{
              boxShadow: `${
                contentData?.AddShadow?.Value === "True"
                  ? "0 4px 10px rgb(0 0 0 / 50%)"
                  : "none"
              }`,
            
              flexBasis: `${
                contentData?.DisplayGrid?.Value == "50-50" ? "50%" : "30%"
              }`,
            }}
          >
            {isVideo ? (
              <iframe
                className="image-copy-Tray"
                src={vimeoUrl}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : (
              contentData?.Image?.Value && (
                <img
                  className="lazy loaded"
                  data-src="images/home-remodel.jpg"
                  alt={contentData?.Image?.AlternateText}
                  title={contentData?.Image?.AlternateText}
                  loading="lazy"
                  src={`${ingeniuxURL}${contentData?.Image?.Value}`}
                  data-ll-status="loaded"
                  width="200"
                  height="150"
                />
              )
            )}
          </div>
          <div
            className="tray-text-left"
            style={{
              flexBasis: `${
                contentData?.DisplayGrid?.Value == "50-50" ? "50%" : "70%"
              }`,
            }}
          >
            <h3> {contentData?.Heading?.Value}</h3>
            {/* added the replace code for the why choose us graphic image to display */}
            {/* changed .replace("Corporate%20Site", `${ingeniuxURL}Corporate%20Site`)
              to .replace("Images", `${ingeniuxURL}Images`) for image to display
              */}
            <Markup
              content={contentData?.Copy?.Value?.replace(
                "Images",
                `${ingeniuxURL}Images`
              )}
            />
            {contentData?.Page && (
              <a className="btn" target="_blank" href={contentData?.Page?.URL}>
                {contentData?.Page?.Link}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewCorpImageCopyTrayLeftAlignment;
