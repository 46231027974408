import React from "react";
import { ingeniuxURL } from "../../../../config/urls";
import "./CorpLeftIconPlacement.css";

const CorpLeftIconPlacement = ({ contentData }) => {
  return (
    <div className="whychooseuslife-body-wrap">
      <section id="content" className="content-section twelve">
        <div className="container row">
          <ul className="reasons">
            {contentData?.IconWithLabel?.map((iconData, index) => {
              return (
                <li key={index} className={Number(contentData?.IconsPerRow?.Value) === 4 ? "col-sd-12 col-md-6 col-dd-3" : "col-sd-12 col-md-6 col-dd-4"}>
                  {iconData?.Icon?.Value && <img
                    src={`${ingeniuxURL}${iconData?.Icon?.Value}`}
                    alt={iconData?.Icon?.AlternateText}
                    title={iconData?.Icon?.AlternateText}
                  />}
                  <span>{iconData?.Caption?.Value}</span>
                </li>
              )
            })}

          </ul>
        </div>
      </section>
    </div>
  );
};

export default CorpLeftIconPlacement;