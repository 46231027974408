import React from "react";

import ProfessionalServices from "../FranchiseHomePage/ProfessionalServices";
import Promises from "../FranchiseCommon/Promises";
import PopularServices from "../FranchiseHomePage/PopularServices";
import ScheduleItGetEstimate from "./ScheduleItGetEstimate";
import ServicesPackages from "../FranchiseHomePage/ServicesPackages";
import ImageCopyTray from "../FranchiseCommon/ImageCopyTray";
import ReviewsMaster from "../FranchiseReviews/ReviewsMaster";

import CorpPopularServices from "../HomePage/PopularServices";
import CorpPackages from "../common/HalfFullDayPackagesImageCopyTray";
import CorpImageCopyTray from "../common/CorpImageCopyTray";
import CorpBodyTray from "../WhyChooseUsPage/BodyCon";
import CorpAccordionTray from "../FAQsPage/FAQContentWrap";
import CorpVideoTray from "./MasterVideoTray";
import ImageGallery from "../common/ImageGallery/ImageGallery";
import CorpTestimonialWithImage from "../CareersPage/CareersCommunity";
import CorpTeamMemberBioTray from "../YourTeamPage/YourTeamContentWrap";
import CorpIconTray from "./CorpIconTray";
import ZipChooser from "./ZipChooser";
import { FindAnOfficeTray } from "./Footer";
import ServicesTrayMaster from "../common/CorpServicesTray/ServicesTrayMaster";


export const dynamicTrayList = {
  MostPopularServices: PopularServices,
  ServicesTray: ServicesTrayMaster,
  FranchisePackages: ServicesPackages, //otherprops
  ScheduleandEstimateTabTray: ScheduleItGetEstimate, //otherprops
  ScheduleandEstimateTray: ScheduleItGetEstimate, //otherprops
  SEOTemplate: ProfessionalServices,
  BodyCopyTray: ProfessionalServices,
  ImageCopywithSlider: Promises,
  FooterSliderSmall: Promises,
  // ImageCopyTray: ImageCopyTray,
  ImageCopyTray: CorpImageCopyTray,
  ReviewCarousel: ReviewsMaster,

  AccordionTray: CorpAccordionTray,
  VideosTray: CorpVideoTray,
  ImageGallery: ImageGallery,
  TestimonialwithImage: CorpTestimonialWithImage,
  TeamMemberBioTray: CorpTeamMemberBioTray,
  IconTray: CorpIconTray,
  FindAnOffice: ZipChooser,
}

export const corpDynamicTrayList = {
  MostPopularServices: CorpPopularServices,
  ServicesTray: ServicesTrayMaster,
  FranchisePackages: CorpPackages, //otherprops
  ScheduleandEstimateTabTray: ScheduleItGetEstimate, //otherprops
  ScheduleandEstimateTray: ScheduleItGetEstimate, //otherprops
  BodyCopyTray: CorpBodyTray,
  ImageCopyTray: CorpImageCopyTray,
  ReviewCarousel: ReviewsMaster,
  AccordionTray: CorpAccordionTray,
  VideosTray: CorpVideoTray,
  ImageGallery: ImageGallery,
  TestimonialwithImage: CorpTestimonialWithImage,
  TeamMemberBioTray: CorpTeamMemberBioTray,
  IconTray: CorpIconTray,
  ImageCopywithSlider: Promises,
  FindAnOffice: FindAnOfficeTray
}

//this utility not moved to the utility folder
//as DynamicTray related component and utilities
//combined in this file
export const combineCommonTrayProps = (originalProps, ...otherProps) => {
  let combinedProps;
  //mergin objects data
  const mergeData = (...objects) => objects.reduce((acc, cur) => ({ ...acc, ...cur }))
  if (otherProps) {
      let mergedObj;
      for (let i = 0, len = otherProps?.length; i < len; i++) {
          mergedObj = {...mergedObj, ...mergeData(otherProps[i])}
      }
      combinedProps = {...originalProps, ...mergedObj}
  } else {
      combinedProps = originalProps
  }
  return combinedProps;
}

const DynamicTrays = (props) => {
  const SelectComponent = dynamicTrayList[props?.trayName];
  return <SelectComponent contentData={props?.contentData} />;
}

export const CorpDynamicTrays = (props) => {
  const SelectComponent = corpDynamicTrayList[props?.trayName];
  return <SelectComponent contentData={props?.contentData} />;
}

export default DynamicTrays;