import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getStickyHeaderHeight } from "../../config/utility";
import "./blogAside.css";

const BlogAside = ({ headerItemData, recentData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  var revArr = [];
  var currentYear = new Date().getFullYear();
  var archiveYears = [];
  for (let i = currentYear; i >= 2020; i--) {
    archiveYears.push(i);
  }

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.pathname = `blog/search/${searchTerm}`;
  };
  return (
    <div id="page" className="site" style={{paddingTop:`${getStickyHeaderHeight()+35}px`}}>
      <div className="site-inner">
        <a className="skip-link screen-reader-text" href="#content">
          Skip to content
        </a>
        <div id="blog-content" className="site-content">
          <aside
            id="secondary"
            className="sidebar widget-area"
            role="complementary"
          >
            <section id="search-2" className="widget-bar widget_search">
              <form
                onSubmit={handleSubmit}
                role="search"
                method="get"
                className="search-forms"
              >
                <div style={{ flexDirection: "row" }}>
                  <label>
                    <span className="screen-reader-text">Search for:</span>
                    <input
                      type="search"
                      className="search-field"
                      placeholder="Search …"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                  </label>
                  <button type="submit" className="search-submit">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <span className="screen-reader-text">Search</span>
                  </button>
                </div>
              </form>
            </section>
            <section
              id="recent-posts-2"
              className="widget widget_recent_entries"
            >
              <div className="widget-title">Recent Posts</div>
              {!recentData?.length ? (
                "Loading...."
              ) : (
                <ul>
                  {Array.isArray(recentData) ? (
                    recentData?.map((rec, idx) => {
                      const { BlogPost } = rec;
                      const { Heading, SURL } = BlogPost;
                      return (
                        idx < 3 && (
                          <>
                            <li key={idx}>
                              <a href={`${SURL}`}> {Heading?.Value}</a>
                            </li>
                          </>
                        )
                      );
                    })
                  ) : (
                    <li>
                      <a href={`/${recentData?.SURL}`}>
                        {" "}
                        {recentData?.Heading?.Value}
                      </a>
                    </li>
                  )}
                </ul>
              )}
              <div>
                <div className="singleBorder featured-package">
                  <div className="widget-title1">
                    Upfront Pricing.
                    <br />
                    Outstanding Expertise.
                  </div>
                  <p>
                    Our licensed plumbers bring deep industry knowledge and
                    technical proficiency to your home.
                    <br />
                    <br />
                    Contact us now for plumbing solutions with transparent
                    pricing and upfront communication every step of the way.
                  </p>

                  {Array.isArray(headerItemData[0]?.Header?.Page) ? (
                    <div className="time-estimate">
                      <Link
                        to={`/#${headerItemData[0]?.Header?.Page[1]?.Name}`}
                        data-tab="get-a-quote"
                      >
                        <span>
                          {headerItemData[0]?.Header?.Page[1]?.Link?.toUpperCase()}
                        </span>
                      </Link>
                    </div>
                  ) : (
                    <div className="time-estimate">
                      <Link
                        to={`/#${headerItemData[0]?.Header?.Page?.Name}`}
                        data-tab="get-a-quote"
                      >
                        <span>
                          {headerItemData[0]?.Header?.Page?.Link?.toUpperCase()}
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default BlogAside;
