import {
    FETCH_FRANCHISE_HOME_REQUEST,
    FETCH_FRANCHISE_HOME_SUCCESS,
    FETCH_FRANCHISE_HOME_ERROR
} from "../actions/actionType"

let initialState = {
    loading: false,
    franchseHome: {},
    error: null
};

function franchiseHomePageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FRANCHISE_HOME_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_FRANCHISE_HOME_SUCCESS:
            return {
                ...state,
                loading: false,
                franchseHome: action.item,
                error: null
                
            };
        case FETCH_FRANCHISE_HOME_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                franchseHome: {}
            };
        default:
            return state;
    }
}

export default franchiseHomePageReducer;
