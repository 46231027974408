import React, { useState, useEffect, useRef, useMemo } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Markup } from "interweave";

import MenuBar from "./MenuBar";
import DynamicLinkList from "./DynamicLinks";
import LastFranchiseVisitedRibbon from "./LastFranchiseVisitedRibbon";
import {
  getFranchiseDataFromLS,
  isExternalUrl,
  headerPhoneNumberData,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import { useLocation } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import "./header.css";

const Header = ({
  alertContent,
  headerContent,
  recentFranchise,
  calculateBannerPadding = () => {},
  setGlobalCorporateNumber = () => {},
  setNumberSelectionStatus = () => {},
  corporateNumber,
}) => {
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [showLastVisitedFranchiseBanner, setLastVisitedFranchiseBanner] =
    useState(false);
  const [calculatePadding, togglePadding] = useState(false);
  const headerArray = [];
  let location = useLocation();
  const partition = (array) => {
    if (array) {
      let pass = [];
      let fail = [];
      array.forEach((element) => {
        if (element?.Name === "TopLink") {
          pass.push(element);
        } else {
          fail.push(element);
        }
      });
      return [pass, fail];
    }
  };
  let headerData = headerContent?.MenuTab?.map((headerData) => {
    if (Array.isArray(headerData?.Page)) {
      const [pass, fail] = partition(headerData?.Page);
      headerArray.push({
        Link: pass[0]?.Link,
        URL: pass[0]?.URL,
        SubLink: fail,
      });
    } else {
      if (headerData?.Page?.Name === "TopLink") {
        headerArray.push({
          Link: headerData?.Page?.Link,
          URL: headerData?.Page?.URL,
        });
      }
    }
    return headerArray;
  });

  const [showNav, setShowNav] = useState(true);
  const phoneNumberDropDownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!phoneNumberDropDownRef?.current?.contains(event.target)) {
        setToggleDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [phoneNumberDropDownRef]);

  useEffect(() => {
    const previousPage = localStorage.getItem("previousPath");
    const LastVisitedBanner =
      document.getElementsByClassName("last-yo-local")[0];
    

    if (
      previousPage &&
      previousPage === "franchise" &&
      recentFranchise &&
      Object.keys(recentFranchise)?.length &&
      getFranchiseDataFromLS("recentFranchisePath") !== undefined &&
      !LastVisitedBanner
    ) {
    
      handlePhoneNumberChange(headerPhoneNumberData, false);
    }
  }, [recentFranchise]);

  useEffect(() => {
    if (
      getFranchiseDataFromLS("recentFranchisePath") !== undefined &&
      recentFranchise?.Name === corporateNumber?.id
    ) {
      setLastVisitedFranchiseBanner(true);
    } else {
      setLastVisitedFranchiseBanner(false);
    }
  }, [recentFranchise, corporateNumber]);

  useEffect(() => {
    const locationHash = window.location.hash;
    setTimeout(() => {
      if (locationHash) {
        const splitAtHash = locationHash.split("#");
        const domElement =
          splitAtHash && document.getElementById(splitAtHash[1]);
        const stickyHeader =
          document.getElementById("sticky-wrapper").offsetHeight;
        window.scroll({
          top: parseInt(domElement.offsetTop - stickyHeader),
          left: 0,
          behavior: "smooth",
        });
      }
    }, 370);
  }, [location]);

  useEffect(() => {
    if (location.hash === "#Find-Your-Local-Office") {
      const findAnOfficeTray = document.getElementById(
        "Find-Your-Local-Office"
      );

      const scrollValue = parseInt(findAnOfficeTray.offsetTop - 100);
      setTimeout(() => {
        window.scrollTo({ top: `${scrollValue}` });
      }, 100);
    }
  }, [location?.hash]);

  useEffect(() => {
    const previousPage = localStorage.getItem("previousPath");
    calculateBannerPadding();
    if (previousPage && previousPage === "corporate") {
      retainNumberOnPagerefresh();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calculateBannerPadding();
    }, 500);
  }, [calculatePadding]);

  const handlePhoneNumberChange = (data, isChangeHandler = true) => {
    if (isChangeHandler) {
      setGlobalCorporateNumber(data);
      setToggleDropDown(false);
      togglePadding(!calculatePadding);
      localStorage.setItem("selectedNumber", JSON.stringify(data));
      localStorage.setItem("previousPath", "corporate");
    } else if (
      getFranchiseDataFromLS("recentFranchisePath") !== undefined &&
      recentFranchise
    ) {
      // set according to Last visited Franchise
      const selectedPhoneNumber = data.filter(
        (item) => item.id === recentFranchise?.Name
      )[0];
      setGlobalCorporateNumber(selectedPhoneNumber);
      togglePadding(!calculatePadding);
    }
  };

  const retainNumberOnPagerefresh = () => {
    const selectedNumber = JSON.parse(localStorage.getItem("selectedNumber"));
    if (
      selectedNumber &&
      Object.keys(selectedNumber)?.length &&
      corporateNumber?.value !== selectedNumber?.value
    ) {
      setGlobalCorporateNumber(selectedNumber);
      setNumberSelectionStatus(true);
    }
  };

  return (
    <div>
      <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
        <header style={{ width: "100%" }}>
          {alertContent?.DisplayAlert?.Value === "True" && (
            <div
              id="alert-bar"
              style={{
                display: `${
                  alertContent?.AlertText?.Value === "" ||
                  !alertContent?.AlertText?.Value
                    ? "none"
                    : "block"
                }`,
              }}
            >
              <div className="home-container fluid max">
                <div className="row">
                  <div className="col-12">
                    <p>
                      <center>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={alertContent?.Page?.URL}
                        >
                          <Markup content={alertContent?.AlertText?.Value} />
                        </a>
                      </center>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* changed null to undefined */}
          {showLastVisitedFranchiseBanner && (
            <LastFranchiseVisitedRibbon recentFranchise={recentFranchise} />
          )}

          <div className="yo-local" style={{ display: "none" }}>
            <div className="topbar-wrap row twelve">
              <div className="home-container">
                <div className="topbar col-sd-12 col-md-12">
                  <div className="name yo-local-city"></div>
                  <div className="local-nav yo-local-nav"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="head-wrap home-container row twelve">
            <div className="logo col-sd-5 col-md-6 col-dd-3">
              {headerContent ? (
                <a href="/">
                  {headerContent?.HeaderLogo?.Value && (
                    <img
                      src={`${ingeniuxURL}${headerContent?.HeaderLogo?.Value}`}
                      alt={
                        headerContent?.HeaderLogo?.AlternateText
                          ? headerContent?.HeaderLogo?.AlternateText
                          : "Ace Hardware Plumbing Services logo"
                      }
                      title={
                        headerContent?.HeaderLogo?.AlternateText
                          ? headerContent?.HeaderLogo?.AlternateText
                          : "Ace Hardware Plumbing Services logo"
                      }
                    />
                  )}
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="header-top-wrap col-sd-7 col-md-6 col-dd-9">
              <div className="header-top-container">
                <div className="header-top" style={{ textAlign: "right" }}>
                  <div className="anchors">
                    {headerContent &&
                    headerContent.Page &&
                    Array.isArray(headerContent.Page) ? (
                      <>
                        <strong>
                          <Link
                            to={`/#${headerContent?.Page[0]?.Name}`}
                            data-tab="book-online"
                          >
                            {headerContent?.BookOnlineIcon?.Value && (
                              <img
                                src={`${ingeniuxURL}${headerContent?.BookOnlineIcon?.Value}`}
                                // changed maxWidth and maxHeight each from 16px to 30 px to match footer icons
                                style={{ maxWidth: "30px", maxHeight: "30px" }}
                                alt={
                                  headerContent?.BookOnlineIcon?.AlternateText
                                }
                                title={
                                  headerContent?.BookOnlineIcon?.AlternateText
                                }
                              />
                            )}
                            <span>
                              {headerContent?.Page[0]?.Link.toUpperCase()}
                            </span>
                          </Link>
                        </strong>
                        <Link
                          to={`/#${headerContent?.Page[1]?.Name}`}
                          data-tab="get-a-quote"
                        >
                          {headerContent?.GetEstimateIcon?.Value && (
                            <img
                              src={`${ingeniuxURL}${headerContent?.GetEstimateIcon?.Value}`}
                              // changed maxWidth and maxHeight each from 16px to 30 px to match footer icons
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                              alt={
                                headerContent?.GetEstimateIcon?.AlternateText
                              }
                              title={
                                headerContent?.GetEstimateIcon?.AlternateText
                              }
                            />
                          )}
                          <span>
                            {headerContent?.Page[1]?.Link.toUpperCase()}
                          </span>
                        </Link>
                      </>
                    ) : (
                      <Link
                        to={`/#${headerContent?.Page?.Name}`}
                        data-tab="get-a-quote"
                      >
                        {headerContent?.GetEstimateIcon?.Value && (
                          <img
                            src={`${ingeniuxURL}${headerContent?.GetEstimateIcon?.Value}`}
                            // changed maxWidth and maxHeight each from 16px to 30 px to match footer icons
                            style={{ maxWidth: "30px", maxHeight: "30px" }}
                            alt={headerContent?.GetEstimateIcon?.AlternateText}
                            title={
                              headerContent?.GetEstimateIcon?.AlternateText
                            }
                          />
                        )}
                        <span>{headerContent?.Page?.Link.toUpperCase()}</span>
                      </Link>
                    )}
                  </div>

                  {/* Phone Number Dropdown Start*/}

                  <div
                    className="number-dropdown-container"
                    ref={phoneNumberDropDownRef}
                  >
                    <div className="display-selected-value">
                      <div
                        onClick={() => setToggleDropDown(!toggleDropDown)}
                        className="selected-city-name"
                      >
                        <p>{corporateNumber?.id}</p>
                      </div>
                      <div className="selected-phone-number">
                        <p>
                          <a href={`tel:${corporateNumber?.value}`}>
                            {corporateNumber?.value}
                          </a>
                          <span
                            onClick={() => setToggleDropDown(!toggleDropDown)}
                            id="dropdown-arrow"
                            className={`${toggleDropDown ? "rotate-up" : ""}`}
                          >
                            <BsChevronDown />
                          </span>
                        </p>
                      </div>
                    </div>
                    <ul
                      className={`number-dropdown ${
                        toggleDropDown ? "open" : "close"
                      }`}
                    >
                      {headerPhoneNumberData.map((number) => {
                        const isSelected =
                          corporateNumber?.value === number.value;

                        return (
                          <li
                            key={number.id}
                            onClick={() => handlePhoneNumberChange(number)}
                            className={`${
                              isSelected ? "selected-list-item" : ""
                            }`}
                          >
                            {number.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* Phone Number Dropdown End */}
                </div>
              </div>

              <div id="page-wrapper">
                <div id="mobile-nav">
                  <i
                    className="fa-solid fa-bars"
                    onClick={() => setShowNav(!showNav)}
                  >
                    <MenuBar
                      showNav={showNav}
                      setShowNav={setShowNav}
                      headerContent={headerData && headerData[0]}
                    >
                      <div
                        id="content-wrapper"
                        style={{
                          left: showNav ? "280px" : "65px",
                          width: showNav
                            ? "calc(100% - 280px)"
                            : "calc(100% - 55px)",
                        }}
                      ></div>
                    </MenuBar>
                  </i>
                </div>
              </div>

              <nav className="topnav">
                <ul className="first-level">
                  {headerData &&
                    headerData[0]?.map((menuItem, index) => {
                      const isExternal = isExternalUrl(menuItem?.URL);
                      return (
                        <li key={index}>
                          <DynamicLinkList
                            pagePath={
                              isExternal
                                ? `${menuItem?.URL}`
                                : `/${menuItem?.URL}`
                            }
                            isExternal={isExternal}
                            pageApiUrl={`${menuItem?.URL}`}
                            pageName={menuItem?.Link}
                          />
                          {menuItem?.SubLink && (
                            <div className="dropdown">
                              <div className="home-container">
                                <div className="header col-md-4">
                                  <DynamicLinkList
                                    pagePath={`/${menuItem?.URL}`}
                                    pageApiUrl={`/${menuItem?.URL}`}
                                    pageName={menuItem?.Link}
                                  />
                                </div>
                                <div className="dropdown-nav col-sd-12 col-md-8">
                                  <ul>
                                    {menuItem?.SubLink?.map(
                                      (subLink, index) => {
                                        return (
                                          <li key={index}>
                                            <DynamicLinkList
                                              pagePath={`/${subLink?.URL}`}
                                              pageApiUrl={`/${subLink?.URL}`}
                                              pageName={subLink?.Link}
                                            />
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
