import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { bgPatternTriangles } from "../../config/utility";
import ZipChooser from "../common/ZipChooser";
import "./BathroomRRServiceSpecific.css";

const BathroomRRServiceSpecific = ({ contentData, zipCodeList, localOfficeList }) => {
  return (
    <body className="bathroomrrservicespecific">
      <div className="bathroomrrservicespecific-body-wrap" style={{ backgroundImage:bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})` }}>
        <section id="content" className="content-section twelve">
          <div className="service-specific">
            <div className="br-container row">
              <div className="content-left col-sd-12 col-dd-7 pad-right">
                <h2 className="heading"></h2>
                <Markup content={contentData?.ServicesDetail?.IntroCopy?.Value} />
              </div>
              <div className="content-right splash col-sd-12 col-dd-5">
                {contentData?.ServicesDetail?.CopyImageFrame?.Value && <img
                  className="frame"
                  src={`${ingeniuxURL}${contentData?.ServicesDetail?.CopyImageFrame?.Value}`}
                  alt={contentData?.ServicesDetail?.CopyImageFrame?.AlternateText ? contentData?.ServicesDetail?.CopyImageFrame?.AlternateText : "Bathroom Repair and Refresh"}
                  title={contentData?.ServicesDetail?.CopyImageFrame?.AlternateText ? contentData?.ServicesDetail?.CopyImageFrame?.AlternateText : "Bathroom Repairand Refresh"}
                />}
                <figure>
                  {contentData?.ServicesDetail?.RightSideCopyImage?.Value && <img
                    className="item"
                    src={`${ingeniuxURL}${contentData?.ServicesDetail?.RightSideCopyImage?.Value}`}
                    alt={contentData?.ServicesDetail?.RightSideCopyImage?.AlternateText}
                    title={contentData?.ServicesDetail?.RightSideCopyImage?.AlternateText}
                  />}
                </figure>
              </div>
              <ZipChooser
              // submitAlignment={"InlineRight"}
              // headingAlignment={"InlineLeft"}
                submitAlignment={contentData?.ServicesDetail?.FindAnOffice?.SubmitAlignment?.Value}
                headingAlignment={contentData?.ServicesDetail?.FindAnOffice?.HeadingAlignment?.Value}
                zipInputs={{
                  PlaceholderText: contentData?.ServicesDetail?.FindAnOffice?.PlaceholderText?.Value?.replace("zip", "ZIP"),
                  TextBelowInput: contentData?.ServicesDetail?.FindAnOffice?.TextBelowInput?.Value,
                  Heading: contentData?.ServicesDetail?.FindAnOffice?.Heading?.Value,
                  HandymanMatters: contentData?.ServicesDetail?.FindAnOffice?.HandymanMatters?.Value
                }}
                zipCodeList={zipCodeList} localOfficeList={localOfficeList}
                toSchedulingPage={false}
              />
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default BathroomRRServiceSpecific;