import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import DynamicLinkList from "../common/DynamicLinks";
import SvgPhoneIcon from "../common/SvgPhoneIcon";
import {
  formatPhone,
  bgPatternTriangles,
  getStickyHeaderHeight,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import "./offices.css";
import { newStates } from "./Constants.js";

const Offices = ({ states, ctaData, localOffices, zipcodes }) => {
  //Merging localoffices with new offices
  localOffices = [...localOffices, ...newStates];

  //state names have space between, creating id from state names
  const createCustomStateId = (stateName) =>
    stateName?.replace(/[\W_]/g, "-")?.toLowerCase();

  const existingStates = () => {
    const states = [];
    for (var i = 0, len = localOffices.length; i < len; i++) {
      states.push(localOffices[i]?.State);
    }
    newStates.map((el) => {
      states.push(el.City);
    });

    states.sort();
    return [...new Set(states)];
  };
  const compareStateNames = (a, b) => {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  };

  let combinedProps;
  combinedProps = {
    ...{
      ...ctaData?.FindAnOffice,
      ...{ zipCodeList: zipcodes },
      ...{ localOfficeList: localOffices },
    },
  };

  return (
    <div
      className="body-wrap"
      style={{
        backgroundImage:
          bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})`,
      }}
    >
      <section id="offices-banner">
        <div
          className="banner-slide default"
          style={{ paddingTop: `${getStickyHeaderHeight() + 5}px` }}
        ></div>
      </section>

      <section id="content" className="content-section twelve">
        <div id="state-list">
          <div className="offices-container">
            <h1 className="offices-heading">
              Locations of Ace Hardware Plumbing Services
            </h1>
          </div>
        </div>

        <div className="container">
          {[...existingStates()].map((USAState, index) => {
            const stateId = createCustomStateId(USAState);
            if(USAState === 'Oklahoma') {
              return;
            }            
            return (
              <div key={index} className="country row twelve">
                <div className="offices-container">
                  <div className="state row" id={stateId}>
                    <div className="offices-header">
                      {USAState?.replace("_", " ")}
                    </div>
                    {Array.isArray(localOffices) &&
                      localOffices
                        ?.sort(compareStateNames)
                        ?.map((localOffice, index) => {                          
                          return (
                            localOffice?.State === USAState && (
                              <div
                                className="location col-sd-12 col-md-6 col-ddd-6"
                                key={index}
                              >
                                {localOffice.Flag === true ? (
                                  <a
                                    href={localOffice.HomePageLink.URL}
                                    target="_blank"
                                    className="name"
                                  >
                                    {localOffice.FranchiseName}
                                  </a>
                                ) : (
                                  <Link
                                    className="name"
                                    to={`/${localOffice?.HomePageLink?.URL}`}
                                  >
                                    {localOffice?.FranchiseName}
                                  </Link>
                                )}

                                {localOffice?.Flag !== true && (
                                  <div className="service-areas">
                                    Servicing{" "}
                                    {localOffice?.ServiceAreaCities?.map(
                                      (city, index) => {
                                        const limit = 3;
                                        return (
                                          <span key={index}>
                                            {index < limit && (
                                              <>
                                                {city}
                                                {index + 1 === limit ||
                                                index + 1 ===
                                                  localOffice?.ServiceAreaCities
                                                    ?.length
                                                  ? " "
                                                  : ", "}
                                              </>
                                            )}
                                          </span>
                                        );
                                      }
                                    )}
                                    {localOffice?.ServiceAreaCities?.length >
                                      3 && (
                                      <>
                                        &amp;{" "}
                                        <DynamicLinkList
                                          pagePath={`/${localOffice?.HomePageLink?.URL}`}
                                          pageApiUrl={
                                            localOffice?.HomePageLink?.URL
                                          }
                                          pageName={"MORE"}
                                          basePageURL={"/offices"}
                                          pagePhone={localOffice?.PhoneNumber}
                                        />
                                      </>
                                    )}
                                  </div>
                                )}
                                {localOffice?.PhoneNumber && (
                                  <a
                                    className="phone"
                                    href={`tel:${formatPhone(
                                      localOffice?.PhoneNumber
                                    )}`}
                                  >
                                    <span>
                                      <SvgPhoneIcon />
                                    </span>
                                    {formatPhone(localOffice?.PhoneNumber)}
                                  </a>
                                )}
                                <div className="links">
                                  {/* schedule appointment dynamically generated link */}
                                  <a
                                    href={
                                      localOffice?.ExternalOrganizationId
                                        ? localOffice?.SchedulePageLink?.URL
                                        : localOffice?.SchedulePageLink?.URL
                                    }
                                    target="_blank"
                                  >
                                    {localOffice?.SchedulePageLink?.LinkName}
                                  </a>
                                  {localOffice.Flag === true ? (
                                    <a
                                      href={localOffice.HomePageLink.URL}
                                      target="_blank"
                                    >
                                      {localOffice.HomePageLink.LinkName}
                                    </a>
                                  ) : (
                                    <DynamicLinkList
                                      pagePath={`/${localOffice?.HomePageLink?.URL}`}
                                      pageApiUrl={
                                        localOffice?.HomePageLink?.URL
                                      }
                                      pageName={"View website"}
                                      basePageURL={"/offices"}
                                      pagePhone={localOffice?.PhoneNumber}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                          );
                        })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Offices;
