import { Routes, Route } from "react-router-dom";
import Services from "./Services";
import WhyChooseUsPage from "./WhyChooseUsPage";
import YourTeamPage from "./YourTeamPage";
import CommunityMattersPage from "./CommunityMattersPage";
import OurStoryPage from "./OurStoryPage";
import ServiceDetailPage from "./BathroomRefreshRepairPage";
import FranchiseServiceCityDetailPage from "./FranchiseServiceCityDetailPage";
import CareersSubmission from "./FranchiseCareers/CareerSubmission";
import FindACraftsman from "./FindACraftsman";
import FranchiseHomePage from "./FranchiseHomePage";
import FranchiseDetailPage from "./FranchiseDetailPage";
import FranchiseCareersPage from "./FranchiseCareers";
import Packages from "./Packages";
import BlogPage from "./BlogPage";
import CareersPage from "./CareersPage";
import Home from "./Home";
import EstimateSubmissionPage from "./EstimateSubmissionPage";
import Page404 from "./page-404";

const Main = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      {/* WhyChooseUs Start*/}
      <Route
        exact
        path="/why-choose-us"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/why-choose-us/who-we-are"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/why-choose-us/our-team"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="why-choose-us/in-the-community"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/WhyChooseUs/MeetTheTeam"
        element={<YourTeamPage link={"/WhyChooseUs/MeetTheTeam"} />}
      ></Route>
      <Route
        exact
        path="/WhyChooseUs/CommunityMatters"
        element={
          <CommunityMattersPage link={"/WhyChooseUs/CommunityMatters"} />
        }
      ></Route>
      <Route
        exact
        path="/WhyChooseUs/OurStory"
        element={<OurStoryPage link={"/WhyChooseUs/OurStory"} />}
      ></Route>
      {/* WhyChooseUs End */}
      {/* Our Services Start*/}
      <Route
        exact
        path="/our-services"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/sewer-and-drain-repair"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/bathroom-plumbing"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/drain-cleaning"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/gas-leaks-and-installation"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/water-heaters"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/kitchen-plumbing"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/leak-detection-and-repair"
        element={<WhyChooseUsPage />}
      ></Route>
      <Route
        exact
        path="/our-services/light-commercial"
        element={<WhyChooseUsPage />}
      ></Route>
      {/* Our Services End}
      {/*Services Route*/}
      <Route path={"/Services"} element={<Services />} />
      <Route exact path="/Services/:id" element={<ServiceDetailPage />}></Route>
      <Route
        exact
        path="/acehandymandss/Services/:id/Doors"
        element={<ServiceDetailPage />}
      ></Route>
      <Route
        exact
        path="/Services/:id/Doors"
        element={<ServiceDetailPage />}
      ></Route>

      <Route
        exact
        path="/acehandymandss/Services/:id/Windows"
        element={<ServiceDetailPage />}
      ></Route>
      <Route
        exact
        path="/Services/:id/Windows"
        element={<ServiceDetailPage />}
      ></Route>
      {/*Services Route*/}
      <Route exact path="/Packages" element={<Packages />}></Route>
      {/* Franchise Routes*/}
      <Route exact path="/offices" element={<FindACraftsman />}></Route>
      <Route exact path="/offices/:id" element={<FranchiseHomePage />}></Route>
      <Route
        exact
        path="/offices/:id/contact_success"
        element={<EstimateSubmissionPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/our-team"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/our-work"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/promotions"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/promotions/gold-plan"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/campaign/:campaignId"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/services/:serviceId"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/join-our-team"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/offers"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/blog/:blogId"
        element={<FranchiseDetailPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/careers"
        element={<FranchiseCareersPage />}
      ></Route>
      <Route
        exact
        path="/offices/:id/careers/application_success"
        element={<CareersSubmission />}
      ></Route>
      <Route
        exact
        path="/offices/:id/city/:cityid"
        element={<FranchiseServiceCityDetailPage />}
      ></Route>

      <Route
        exact
        path="/offices/:id/financing"
        element={<FranchiseDetailPage />}
      ></Route>

      <Route
        exact
        path="/offices/:id/referral-program-terms-and-conditions"
        element={<FranchiseDetailPage />}
      ></Route>

      <Route
        exact
        path="/careers"
        element={<CareersPage link={"/careers"} />}
      ></Route>
      {/* Blog Page */}
      <Route exact path="/blog" element={<BlogPage />}></Route>
      <Route exact path="/blog/page/:id" element={<BlogPage />}></Route>

      <Route exact path="/blog/search/:id" element={<BlogPage />}></Route>
      <Route
        exact
        path="/blog/page/:id/search/:sid"
        element={<BlogPage />}
      ></Route>

      <Route exact path="/blog/category/:id" element={<BlogPage />}></Route>
      <Route
        exact
        path="/blog/category/:id/page/:pid"
        element={<BlogPage />}
      ></Route>

      <Route exact path="/blog/author/:id" element={<BlogPage />}></Route>
      <Route
        exact
        path="/blog/author/:id/page/:pid"
        element={<BlogPage />}
      ></Route>

      <Route exact path="/blog/year/:id" element={<BlogPage />}></Route>
      <Route
        exact
        path="/blog/year/:id/page/:pid"
        element={<BlogPage />}
      ></Route>
      <Route exact path="/blog/:id" element={<BlogPage />}></Route>
      {/* Resources Start*/}
      <Route exact path="/resources" element={<WhyChooseUsPage />} />
      <Route exact path="/resources/site-map" element={<WhyChooseUsPage />} />
      <Route exact path="/resources/privacy-policy" element={<WhyChooseUsPage />} />
      <Route exact path="/resources/terms-and-conditions" element={<WhyChooseUsPage />} />
      {/* Resources End*/}
      <Route
        exact
        path="/contact_success"
        element={<EstimateSubmissionPage />}
      ></Route>
    </Routes>
  );
};

export default Main;
