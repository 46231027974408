import React from "react";
import { Markup } from "interweave";
import { ingeniuxURL } from "../../../../config/urls";
import "./CorpTopIconPlacement.css";

// const ingeniuxURL = "https://services.ingeniux.com/acehandymandss/";

// contentData = { element }
// Heading = { element?.Heading?.Value }
// Subheading = { element?.Subheading?.Value }
// ImageValue = { element?.Image?.Value }
// AlternateText = { element?.Image?.AlternateText }
// IconWithLabel = { element?.IconWithLabel }
// IconsPerRow = { element?.IconsPerRow?.Value }

const CorpTopIconPlacement = ({ contentData }) => {
    return (
        <section id="careers-brand-content" className="content-section twelve">
            <div className="row careers-brand">
                <div className="container">
                    <div className="row parent">
                        {contentData?.Image?.Value &&
                            <div className="children-1">
                                {contentData?.Image?.Value && <img
                                    src={`${ingeniuxURL}${contentData?.Image?.Value}`}
                                    alt={contentData?.Image?.AlternateText}
                                    title={contentData?.Image?.AlternateText}
                                />}
                            </div>}
                        <div className="children-2">

                            <h3>
                                {contentData?.Heading?.Value}
                                <span>
                                    {contentData?.Subheading?.Value}
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="flex-and-wrap">
                        {contentData?.IconWithLabel?.map((iconData, index) => {
                            return (
                                <div key={index} className={Number(contentData?.IconsPerRow?.Value) === 4 ? "col-sd-12 col-md-6 col-dd-3" : "col-sd-12 col-md-6 col-dd-4"}>
                                    <div className={`icon-wrap icon-padding-sm row1${Number(contentData?.IconsPerRow?.Value) === 4 && (index === 0 ? ' p-t-100' : index === 1 ? ' p-t-80' : index === 2 ? ' p-t-60' : '')}`}>
                                        {/* <CareersIconWithLabel
                                    Icon={iconData?.Icon}
                                    Label={iconData?.Label?.Value}
                                    Caption={iconData?.Caption?.Value} /> */}

                                        <div>
                                            {iconData?.Icon?.Value && <img
                                                src={`${ingeniuxURL}${iconData?.Icon?.Value}`}
                                                alt={iconData?.Icon?.AlternateText}
                                                title={iconData?.Icon?.AlternateText}
                                            />}
                                            <h3><Markup content={iconData?.Label?.Value?.replace("<br>", "")} /></h3>
                                            <span><Markup content={iconData?.Caption?.Value} /></span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorpTopIconPlacement;
