import { Markup } from "interweave";
import React, { useState, useEffect } from "react";
import "./CustomVideoTray.css";
import "./careersVideoTray.css";

const CustomVideoTray = ({ contentData }) => {
  const [singleVideo, setSingleVideo] = useState([]);

  useEffect(() => {
    if (contentData) {
      // setSingleVideo([...contentData.Video.slice(0, 2)]);
      setSingleVideo([...contentData.Video]);

    }
  }, [contentData]);

  const youtubeUrl = "https://www.youtube.com/embed/CK1J_54KEPk";
  const isSingleVideo = true;

  return (
    <section id="careers-culture-content" className="content-section twelve">
      <div className="row careers-culture">
        <div className="custom-video-container">
          <div
            className={`col-sd-12 ${
              singleVideo?.length === 1 ? "single-video-text" : ""
            }`}
          >
            <div
              className="culture-text"
              style={{
                background: `${
                  contentData?.TextBackgroundColor?.Value === "Light"
                    ? "rgba(255,255,255,0.8)"
                    : "rgba(0,0,0,0.8)"
                }`,
              }}
            >
              <h3
                style={{
                  color: `${
                    contentData?.TextBackgroundColor?.Value === "Light"
                      ? "#000"
                      : "#fff"
                  }`,
                }}
              >
                {contentData?.Heading?.Value}
                <span>{contentData?.Subheading?.Value}</span>
              </h3>
              <Markup content={contentData?.Copy?.Value} />
            </div>
          </div>
          {console.log("SINGLE VIDEO:", singleVideo)}
          <div
            className={`video-display-container ${
              singleVideo?.length > 1
                ? "multi-videos multi-video-container-width"
                : "single-video-container-width"
            }`}
          >
            {singleVideo &&
              singleVideo.map((videoTray) => {
                return (
                  <iframe
                    // src={youtubeUrl}
                    src={videoTray?.VimeoEmbedURL?.Value}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomVideoTray;
