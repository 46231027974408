import React from "react";
import "./campaignForm.css";
const Footer = () => {
  return (
    <div className="red-bg ">
      <div className="container main-cont">
        <div className="logo-div">
          <img
            src={
              "https://aceservices-plumbing-dev-dss.igxdev.com//Images/Plumbing/Corporate%20Site/Logos/White%20APLS%20Logo%20Small.png"
            }
          ></img>
        </div>
        <div className="about-us">
          <h3>ABOUT US</h3>
          <span>
            From the initial phone call to completion of your job, Ace Hardware
            Plumbing Services strives to be professional, courteous and
            competent.
          </span>
        </div>
        <div className="location">
          <h3>LOCATION</h3>
          <span>1509 W Britton Rd, Oklahoma City, OK 73120</span>
          <br/>
          <span className="2nd">Oklahoma License #050327</span>
        </div>
      </div>
      <div className="termsandcondition">
            <span>© Ace Hardware Plumbing Services . All rights reserved</span>
      </div>
    </div>
  );
};

export default Footer;
