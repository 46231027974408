import {START_HANDLE_ERRORBOUNDARY,END_HANDLE_ERRORBOUNDARY, HANDLE_ERRORBOUNDARY} from "../actions/actionType";
let initialState = {
    error_loading: false,
    item: [],
    error: null,
  };
  
  function errorBoundaryReducer(state = initialState, action) {
    switch (action.type) {
      case START_HANDLE_ERRORBOUNDARY:
        return {
          ...state,
          error_loading: true,
          error: null
        };
      case END_HANDLE_ERRORBOUNDARY:
        return {
          error_loading: false,
        };
      case HANDLE_ERRORBOUNDARY:
        return {
          ...state,
          error_loading: false,
          error: action.payload.error,
          item: [],
        };
      default:
        return state;
    }
  }
  
  export default errorBoundaryReducer;
  