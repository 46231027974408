import React from "react";
import "./CorpEstimateSubmissionBanner.css";

const CorpEstimateSubmissionBanner = (props) => {
    return (
        <section id="corp-estimate-submission-banner">
            <div className="banner-slide contact-success">
                <div className="container">
                    <div className="banner-text">
                        <h1 className="header">{props?.contentData?.Heading?.Value}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorpEstimateSubmissionBanner;
