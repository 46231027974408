import { Markup } from "interweave";
import React from "react";
import "../../page-404/index.css";
import "./index.css";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import {
  door,
  bathroom,
  carpentry,
  drywall,
  fencing,
  floor,
  painting,
} from "./images";

const Page503 = () => {
  const phone = "(866)-808-8401";
  return (
    <>
      <Header phone={phone} />
      <Helmet>
        <title>Service Down | Ace Handyman Services</title>
        <meta
          name="description"
          content="Call us at location at 405-785-4550 or request a quote online. You can also schedule an appointment using our online scheduling tool."
        />
        {/* <!-- Google Tag Manager 404 Tracking --> */}
        <script>
          {`(function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),
    dl=l!='dataLayer'?'&l='+l:'';
  j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', ${process.env.REACT_APP_GTM_CONTAINER_ID});
`}
        </script>
        {/* <!-- End Google Tag Manager 404 Tracking --> */}

        {/* <!--GA4 Page not found script--> */}
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'page_not_found'
            });
          `}
        </script>
        {/* <!--End of GA4 Page not found script--> */}
      </Helmet>
      <div className="page-not-found page-503">
        <section id="banner"></section>
        <section id="content" className=" content-section twelve">
          <div className="not-found-wrap">
            <div className="not-found container">
              <div className="finder">
                <div className="heading">Oops...</div>
                <p>Something went wrong on our end. We will be back soon.</p>
                <div className="call-cta">
                  or call us at
                  <div className="phone">
                    <a style={{ color: "inherit" }} href={`tel:${phone}`}>
                      {phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="most-popular-services row">
          <div className="container">
            <div className="heading-wrap toggle-heading">
              <div className="heading">Most Popular Services</div>
              <span className="fa fa-angle-down arrow"></span>
            </div>
            <div className="services content">
              <div className="item col-sd-6">
                <a href="/services/doors-and-windows">
                  <img src={door} alt="serviceDoor" title="serviceDoor" />
                  <span>Doors</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="floors.html">
                  <img src={floor} alt="serviceFloor" title="serviceFloor" />
                  <span>Floors</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/">
                  <img src={bathroom} alt="serviceBath" title="serviceBath" />
                  <span>Bathroom</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/services/painting-and-staining">
                  <img
                    src={painting}
                    alt="servicePaintroller icon"
                    title="servicePaintroller icon"
                  />
                  <span>Painting</span>
                </a>
              </div>

              <div className="item col-sd-6">
                <a href="/services/fence">
                  <img src={fencing} alt="serviceFence" title="serviceFence" />
                  <span>Fencing</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="/services/drywall-repair">
                  <img
                    src={drywall}
                    alt="serviceDrywall"
                    title="serviceDrywall"
                  />
                  <span>Drywall</span>
                </a>
              </div>
              <div className="item col-sd-6">
                <a href="/services/carpentry">
                  <img
                    src={carpentry}
                    alt="serviceCarpentry"
                    title="serviceCarpentry"
                  />
                  <span>Carpentry</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer phone={phone} />
      </div>
    </>
  );
};

export default Page503;
