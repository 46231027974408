import { Markup } from "interweave";
import React from "react";
import { ingeniuxURL } from "../../config/urls";
import "./WhyChooseUsLife.css";

const BodyCon = ({ contentData }) => {
  let serviceHeading = ''; 
  const tempElement = document.createElement('div');
  tempElement.innerHTML = contentData.Copy.Value;
  const h2Element = tempElement.querySelector('h2');
  if (h2Element) {
    const spanElement = h2Element.querySelector('span');
    if (spanElement) {
      serviceHeading = spanElement.textContent.trim().replace(/ /g, '_');
    }
  }
 
  return (
    <div className="whychooseuslife-body-wrap" id={serviceHeading}>
      <section id="content" className="content-section twelve">
        <div className="container row">
          {contentData?.AddOutline?.Value === "Yes" && (
            <p className="choose-subheading">
              <h3
                style={{
                  textAlign: `${
                    contentData?.HeadingAlignment?.Value === "Left"
                      ? "left"
                      : contentData?.HeadingAlignment?.Value === "Center"
                      ? "center"
                      : "right"
                  }`,
                }}
              >
                {contentData?.Heading?.Value}
              </h3>
              <Markup
                content={contentData?.Copy?.Value?.replaceAll(
                  "Images/Corporate%20Site/",
                  `${ingeniuxURL}/Images/Corporate%20Site/`
                )}
              />
              {contentData?.Page?.Name === "CTA" && (
                <div style={{ textAlign: "center" }}>
                  <a
                    className="bodycopytray-cta"
                    target="_blank"
                    href={contentData?.Page?.URL}
                  >
                    {contentData?.Page?.Link}
                  </a>{" "}
                </div>
              )}
            </p>
          )}
          {contentData?.AddOutline?.Value === "No" && (
            <blockquote>
              <h3
                style={{
                  textAlign: `${
                    contentData?.HeadingAlignment?.Value === "Left"
                      ? "left"
                      : contentData?.HeadingAlignment?.Value === "Center"
                      ? "center"
                      : "right"
                  }`,
                }}
              > {contentData?.Heading?.Value}
              </h3>
              <Markup
                content={contentData?.Copy?.Value?.replaceAll(
                  "Images/Corporate%20Site/",
                  `${ingeniuxURL}/Images/Corporate%20Site/`
                )}
              />
              {contentData?.Page?.Name === "CTA" && (
                <a
                  className="bodycopytray-cta"
                  target="_blank"
                  href={contentData?.Page?.URL}
                >
                  {contentData?.Page?.Link}
                </a>
              )}
            </blockquote>
          )}
        </div>
      </section>
    </div>
  );
};

export default BodyCon;