import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const DynamicLinkList = ({
  pageApiUrl = "",
  isExternal = false,
  pageName = "",
  pagePath = "",
  basePageURL = "",
  pagePhone = "",
}) => {
  return isExternal && pageName !== "Careers" ? (
    <a href={pagePath} target="_blank" rel="noopener noreferrer">
      {pageName}
    </a>
  ) : (
    <Link
      to={
        ["Careers", "Career"].includes(pageName)
          ? `${basePageURL}/careers`
          : pagePath
      }
      state={{ pagePath, apiPath: pageApiUrl, basePageURL, pagePhone }}
    >
      {pageName}
    </Link>
  );
};

export default DynamicLinkList;
