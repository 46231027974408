import React, { useState } from "react";
import "./menuBar.css";
import { HashLink as Link } from 'react-router-hash-link';
import { extractPath, isExternalUrl } from "../../config/utility";


const ChildMenuBar = ({ menuItem, menuOpen, setMenuOpen, setMenuItems, showNav, setShowNav, franchiseBaseURL }) => {
    const isExternal = isExternalUrl(menuItem?.URL);
    const extractedPth = extractPath(menuItem?.URL);
    return (
        <div className="dropdown-wrap">
            <ul>
                <li className="top back" onClick={() => setMenuOpen(true)}><a><i className="fa-solid fa-angle-left" /> Back</a></li>
                <li className="link category">
                    <Link
                        to={`${franchiseBaseURL}${extractedPth}`}
                    >
                        {menuItem?.Link}
                    </Link>
                </li>
                {menuItem?.SubLink?.map((subLink, index) => {
                    const extractedSubPath = extractPath(subLink?.URL);
                    return (
                        <li className="subpage" key={index}>
                            <Link
                                to={`${franchiseBaseURL}${extractedSubPath}`}
                                onClick={() => { setMenuOpen(!menuOpen); setShowNav(showNav) }}
                            >
                                {subLink?.Link}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ChildMenuBar;
