import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./homeCarousel.css";
import { ingeniuxURL } from "../../config/urls";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const CarouselHeading = ({ headingData }) => {
    return (
        <div className="text-wrap">
            <div className="cta-wrap">
                <div className="cta small">
                    {headingData?.Heading?.Value}
                </div>
            </div>
        </div>
    )
};

const CarouselFigure = ({ figureData }) => {
    return (
        <figure>
            {figureData?.Image?.Value &&
                <img src={`${ingeniuxURL}${figureData?.Image?.Value}`}
                    alt={figureData?.Image?.AlternateText}
                    title={figureData?.Image?.AlternateText}
                    width="600" height="350"
                />
            }
        </figure>
    )
}

const CarouselSlider = ({ contentData }) => {
    return (
        <div className="banner-slide cycle-slide cycle-sentinel" style={{ position: "static", top: "0px", left: "0px", zIndex: "100", opacity: "1", display: "block" }}>
            <div className="flex-wrap">
                {
                    contentData?.HeadingPosition?.Value === "Left" ?
                        <>
                            <CarouselHeading headingData={contentData} />
                            <CarouselFigure figureData={contentData} />
                        </>
                        :
                        <>
                            <CarouselFigure figureData={contentData} />
                            <CarouselHeading headingData={contentData} />
                        </>
                }
            </div>
        </div>
    )
}

const HomeCarousel = ({ contentData }) => {
    return (
        <section id="franchise-banner">
            <div className="custom-slider">
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    ssr={true}
                    infinite={true}
                    autoPlay={true}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    tabIndex={-1}
                >
                    {Array.isArray(contentData) ?
                        contentData?.map((slideData, index) => {
                            return (
                                <CarouselSlider contentData={slideData} key={index} />
                            )
                        })
                        :
                        <CarouselSlider contentData={contentData} />
                    }
                </Carousel>
            </div>
        </section>
    );
};

export default HomeCarousel;