import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import withRouter from "../common/withRouter";
import PageLoader from "../common/PageLoader";
import { ingeniuxURL } from "../../config/urls";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { fetchHomePage } from "../../actions/fetchData";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import {
  getResultsArray,
  storeFranchiseDataToLS,
  getFranchiseDataFromLS,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  formatPhone,
} from "../../config/utility";
import Page404 from "../page-404";
import CareersForm from "./CareersForm";

import "./index.css";

class FranchiseCareers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
      currentForm: "Basic Details",
    };
  }

  //extarct the base URL of the page from page path when there is an undefined error
  extractBaseURL(apiPath = "") {
    const split = apiPath?.split("/");
    const baseURL = split.slice(0, split.length - 1).join("/");
    return baseURL;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // deleteItemFromLS("recentFranchisPhone");
    const franchiseDetailPageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const franchiseBaseUrl = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this?.extractBaseURL(this.props?.apiRef);
    console.log("FRANCHISE BASE URL:", franchiseBaseUrl);
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const corpHomeItem = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    //To eliminate the hash url, splitting the path.
    const rgxforSlashes = /^\/|\/$/g;
    const franchiseAbsPath = franchiseBaseUrl?.split("/#");
    storeFranchiseDataToLS("recentFranchisePath", franchiseAbsPath[0]);
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("recentFranchisePath");
    
    // this.initPageApi(franchiseDetailPageApiPath);

    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(fetchFranchiseHome(franchiseBaseUrl?.toLowerCase()));
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      corpHomeItem?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  // componentDidUpdate(prevProps) {
  //   console.log("Current Form:", this.state.currentForm);
  // }
  

  getHeaderCTAData = (dynamicTrays) => {
    const scheduleEstimateTray = dynamicTrays.filter((tray) =>
      ["ScheduleandEstimateTray", "ScheduleandEstimateTabTray"].includes(
        tray.TrayName
      )
    );

    let scheduleAppointmentCTA;
    let getEstimateCTA;
    if (Object.keys(scheduleEstimateTray[0]).length) {
      const { scheduleTabText, EstimateTabText } = scheduleEstimateTray[0];
      scheduleAppointmentCTA = Boolean(scheduleTabText?.Value);
      getEstimateCTA = Boolean(EstimateTabText?.Value);
    }
    return { scheduleAppointmentCTA, getEstimateCTA };
  };

  getCurrentForm = (currentForm) => {
    this.setState({ currentForm });
  };

  render() {
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const franchiseDetailPage = getResultsArray(
      this.props?.detailPage,
      "FranchiseDetailPage"
    );
    const corpHomeData = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    console.log("Local Office Results:", localOfficeResults);

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      corpHomeData
    ) {
      saveApiData("x31", corpHomeData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
        
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const franchiseCity = franchiseCorpCtrl?.City?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseEmailID = franchiseCorpCtrl?.EmailID?.Value;

    const franchiseBaseUrl = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : this?.extractBaseURL(this.props?.apiRef);
    
    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;

    const franchiseHeadMetaTag = franchiseCorpCtrl?.HeadScripts?.MetaData;
    const franchiseHeadScriptd = franchiseCorpCtrl?.HeadScripts?.Script;
    const franchiseFooterScript = franchiseCorpCtrl?.FooterScripts?.Script;
    const franchiseFooterNoScript =
      franchiseCorpCtrl?.FooterScripts?.NoScriptTag;

    let isScheduleAppointmentCTA;
    let isGetEstimateCTA;

    if (franchiseHomePage && franchiseHomePage[0]?.Trays) {
      const { scheduleAppointmentCTA, getEstimateCTA } = this.getHeaderCTAData(
        franchiseHomePage[0].Trays
      );
      isScheduleAppointmentCTA = scheduleAppointmentCTA;
      isGetEstimateCTA = getEstimateCTA;
    }

    return (
      <>
        {franchiseDetailPage.length === 0 &&
        this.props?.detailPage?.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
               {`Submit a Job Application for Ace Hardware Plumbing in ${franchiseName}`}
              </title>
              <meta
                name="description"
                content={`Take your first step for an exciting career with Ace Hardware Plumbing Services ${franchiseName} ,by submitting a job application`}
              />
              {/* Adding BreadcrumbsStructuredData to Head of the page */}
              {breadcrumbsStructuredData && (
                  (() => {
                    try {
                      const data = JSON.parse(breadcrumbsStructuredData);
                      return (
                        <script type="application/ld+json">
                          {JSON.stringify(data).replace("context", "@context").replaceAll("type", "@type")}
                        </script>
                      );
                    } catch {
                      return null;
                    }
                  })()
                )}
                {/* Adding LocalBusinessStructuredData to Head of the page */}
                {localBusinessStructuredData && (
                  (() => {
                    try {
                      const data = JSON.parse(localBusinessStructuredData);
                      return (
                        <script type="application/ld+json">
                          {JSON.stringify(data).replace("context", "@context").replaceAll("type", "@type")}
                        </script>
                      );
                    } catch {
                      return null;
                    }
                  })()
                )}
            </Helmet>
            <HeaderFranchise
              metaData={corpHomeItem[0]?.SEO}
              franchiseMetaData={[
                franchiseHeadMetaTag,
                franchiseHeadScriptd,
                franchiseFooterScript,
                franchiseFooterNoScript,
              ]}
              alertContent={corpHomeItem[0]?.Alerts}
              // alertContent={franchiseCorpCtrl?.Alerts}
              headerContent={franchiseCorpCtrl?.FranchiseHeader}
              otherProps={[franchiseCorpCtrl, franchiseBaseUrl]}
              corpHeaderProps={[
                corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
                corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
                corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
                corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
                corpHomeItem && corpHomeItem[0]?.GlobalSettings,
              ]}
              scheduleAppointmentCTA={isScheduleAppointmentCTA}
              getEstimateCTA={isGetEstimateCTA}
            />
            
            <CareersForm
              franchiseBaseUrl={franchiseBaseUrl}
              franchiseName={franchiseHomePage ? franchiseHomePage[0]?.Name : ''}
              franchiseEmailID={franchiseEmailID}
              setCurrentForm={this.getCurrentForm}
            />
            <FootersFranchise
              footerContent={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseFooter
              }
              otherProps={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl
              }
              corpFooterProps={[
                corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
                corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
                corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
                corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
                corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
                franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseName
                    ?.Value,
                franchiseHomePage && franchiseHomePage[0]?.SURL,
              ]}
              headerContentForMenu={
                franchiseHomePage &&
                franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
                  ?.MenuTab
              }
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detailPage: state?.franchiseDetailPageReducer?.franchseDetailPage,
  loading: state?.franchiseDetailPageReducer?.loading,
  error: state?.franchiseDetailPageReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  corpHomeItem: state?.headerReducer?.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default withRouter(connect(mapStateToProps)(FranchiseCareers));
