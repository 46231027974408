import React from 'react';
import PromiseLeftLayout from "./PromiseLeftLayout/PromiseLeftLayout";
import { ingeniuxURL } from '../../../config/urls'
import PromiseRightLayout from './PromiseRightLayout/PromiseRightLayout';

const Promises = ({ contentData }) => {
    const alignment = contentData?.ImageAlignment?.Value;
    return (
        <>
            {
                alignment === "Left" ? <PromiseLeftLayout contentData={contentData} bgImage={`url(${ingeniuxURL}${contentData?.Image?.Value})`} /> :
                    <PromiseRightLayout contentData={contentData} bgImage={`url(${ingeniuxURL}${contentData?.Image?.Value})`} />
            }
        </>
    );
};

export default Promises;