import React from "react";
import "./EducationDetailsForm.css";
import CareerBreadcrumb from "../CareerBreadcrumb";

const EducationDetailsForm = ({
  updateFields,
  errors,
  HighSchoolNameLocation,
  DiplomaorGED,
  CollegeNameLocation,
  MajorCoursesStudied,
  HowManyYearsDidYouAtten,
  DiplomaOrDegree,
  TradeSchoolNameLocation,
  TradeSchoolYearsAtten,
  TradeSchoolDiplomaOrDegree,
  ListOfOtherQualifications,
  armyServiceDescription,
  franchiseBaseUrl,
  franchiseName,
}) => {
  return (
    <div className="education-details">
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="Educational Details" />
      <div className="form-group form-field-separator">
        <h3 className="form-title">Education</h3>
      </div>
      <div className="form-group">
        <input
          type="text"
          id="school"
          placeholder="High School (Name and Location)"
          value={HighSchoolNameLocation}
          onChange={(e) =>
            updateFields({ HighSchoolNameLocation: e.target.value })
          }
        />
      </div>
      <div className="form-group degree-type">
        <label>Diploma or GED?</label>
        <div>
          <input
            type="radio"
            id="degree-type-yes"
            name="degree-type"
            value="yes"
            checked={DiplomaorGED !== "" && DiplomaorGED === "yes"}
            onChange={(e) => updateFields({ DiplomaorGED: e.target.value })}
          />
          <label for="degree-type-yes">Yes</label>
        </div>
        <div className="form-group">
          <input
            type="radio"
            id="degree-type--no"
            name="degree-type"
            value="no"
            checked={DiplomaorGED !== "" && DiplomaorGED === "no"}
            onChange={(e) => updateFields({ DiplomaorGED: e.target.value })}
          />
          <label for="degree-type-No">No</label>
        </div>
      </div>
      <div className="form-group">
        <input
          type="text"
          id="college"
          placeholder="College (Name and Location)"
          value={CollegeNameLocation}
          onChange={(e) =>
            updateFields({ CollegeNameLocation: e.target.value })
          }
        />
      </div>
      <div className="form-group form-row">
        <input
          type="text"
          id="major"
          className="first-field"
          placeholder="Major Courses Studied"
          value={MajorCoursesStudied}
          onChange={(e) =>
            updateFields({ MajorCoursesStudied: e.target.value })
          }
        />
        <input
          type="text"
          id="years-of-study"
          placeholder="How Many Years Did You Attend?"
          value={HowManyYearsDidYouAtten}
          onChange={(e) =>
            updateFields({ HowManyYearsDidYouAtten: e.target.value })
          }
        />
      </div>
      <div className="form-group diploma-degree">
        <label>Diploma or Degree?</label>
        <div>
          <input
            type="radio"
            id="diploma-degree-yes"
            name="diploma-degree"
            value="yes"
            checked={DiplomaOrDegree !== "" && DiplomaOrDegree === "yes"}
            onChange={(e) => updateFields({ DiplomaOrDegree: e.target.value })}
          />
          <label for="diploma-degree-yes">Yes</label>
        </div>
        <div className="form-group">
          <input
            type="radio"
            id="diploma-degree-no"
            name="diploma-degree"
            value="no"
            checked={DiplomaOrDegree !== "" && DiplomaOrDegree === "no"}
            onChange={(e) => updateFields({ DiplomaOrDegree: e.target.value })}
          />
          <label for="diploma-degree-no">No</label>
        </div>
      </div>
      <div className="form-group">
        <input
          type="text"
          id="trade-school"
          placeholder="Trade School (Name and Location)"
          value={TradeSchoolNameLocation}
          onChange={(e) =>
            updateFields({ TradeSchoolNameLocation: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          id="trade-school-duration"
          placeholder="How Many Years Did You Attend?"
          value={TradeSchoolYearsAtten}
          onChange={(e) =>
            updateFields({ TradeSchoolYearsAtten: e.target.value })
          }
        />
      </div>
      <div className="form-group trade-school-diploma-degree">
        <label>Diploma or Degree?</label>
        <div>
          <input
            type="radio"
            id="trade-school-diploma-degree-yes"
            name="trade-school-diploma-degree"
            value="yes"
            checked={
              TradeSchoolDiplomaOrDegree !== "" &&
              TradeSchoolDiplomaOrDegree === "yes"
            }
            onChange={(e) =>
              updateFields({ TradeSchoolDiplomaOrDegree: e.target.value })
            }
          />
          <label for="trade-school-diploma-degree-yes">Yes</label>
        </div>
        <div className="form-group">
          <input
            type="radio"
            id="trade-school-diploma-degree-no"
            name="trade-school-diploma-degree"
            value="no"
            checked={
              TradeSchoolDiplomaOrDegree !== "" &&
              TradeSchoolDiplomaOrDegree === "yes"
            }
            onChange={(e) =>
              updateFields({ TradeSchoolDiplomaOrDegree: e.target.value })
            }
          />
          <label for="trade-school-diploma-degree-no">No</label>
        </div>
      </div>
      <div className="form-group">
        <textarea
          id="degree-certifications-list"
          placeholder="List Any Other Degrees, Licenses, or Certifications Held"
          value={ListOfOtherQualifications}
          onChange={(e) =>
            updateFields({ ListOfOtherQualifications: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <textarea
          id="served-in-army"
          placeholder="If you served in the United States Armed Forces, briefly describe the skills you acquired"
          value={armyServiceDescription}
          onChange={(e) =>
            updateFields({ armyServiceDescription: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export default EducationDetailsForm;
