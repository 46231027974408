import React from "react";
import "./CertificateAndSignatureForm.css";
import CareerBreadcrumb from "../CareerBreadcrumb";

const CertificateAndSignatureForm = ({
  updateFields,
  errors,
  genuineAnswersAcknowledgement,
  termsAndConditionsAcknowledgement,
  franchiseBaseUrl,
  franchiseName,
}) => {
  return (
    <div className="certificate-and-signature">
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="Certification and Signature" />
      <div className="form-group form-field-separator">
        <h3 className="form-title">Certification and Signature</h3>
      </div>
      <div className="form-group form-field-separator bottom-padding">
        <label>
          I hereby certify that the answers given by me to the foregoing
          questions and statements are true and correct to the best of my
          knowledge without consequential omissions of any kind. I agree that
          the company shall not be held liable in any respect, if my employment
          is rejected or subsequently terminated, regardless of time of
          discovery, because of false statements, answers or omissions made by
          me in this application. I understand that any misleading or incorrect
          statements may render this application void, and if employed, may lead
          to employment termination. I consent to a drug test and a criminal
          history background check, as a part of the regular pre-employment
          screening. I also voluntarily and knowingly authorize the companies;
          schools or persons named herein to give any information requested
          regarding my former employment, character and qualifications. I hereby
          voluntarily and knowingly fully release and discharge, absolve,
          indemnify, and hold harmless said companies, schools or persons from
          any and all liability for any damages for issuing this information,
          except for the malicious and willful disclosure of derogatory facts
          concerning my employment made for the express purpose of preventing me
          from obtaining employment, I agree to conform to the rules and
          regulations of this organization. I further authorize that the company
          I am applying for with this application to rely upon and use, as it
          sees fit, any information received from such contacts. Information
          contained on this application may be disseminated to other agencies,
          nongovernmental organizations or systems on a need-to-know basis for
          good cause shown as determined by the company head or designee. I
          understand that any information regarding customers of Legacy Plumbing
          is confidential. I agree to keep this information in strict confidence
          and I will not use or disclose the information for the benefit of any
          person or entity other than Legacy Plumbing. I further agree that I
          will not pursue employment or contract opportunities with any
          customers of Legacy Plumbing discussed during my interview process for
          a minimum of ninety (90) days following the date of this application.
          Your employment relationship is based upon the mutual consent of you
          and the company and you are employed on an at-will basis. Employment
          at-will, compensation and this agreement may be terminated with or
          without notice at any time by the employee or the company. Nothing in
          the employee manual shall limit the right to terminate at-will
          employment.
        </label>
      </div>
      <div className="form-group accurate-answers-aknowledgement">
        <label>
          By checking this box, I affirm that all of the answers given by me
          above are true and accurate as to the best of my knowledge. I
          acknowledge and I have read the above statements and accept all
          conditions.*
        </label>
        <div>
          <input
            type="checkbox"
            id="accurate-answers"
            name="accurate-answers-acknowledgement"
            checked={genuineAnswersAcknowledgement}
            onChange={(e) => {
              updateFields({
                genuineAnswersAcknowledgement: !genuineAnswersAcknowledgement,
              });
            }}
          />
          <label for="accurate-answers-acknowledgement">I Affirm</label>
          {errors.genuineAnswersAcknowledgement && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            //margin: "0 0 20px 0",
          }}
        >
          {errors.genuineAnswersAcknowledgement}
        </p>}
        </div>
      </div>
      <div className="form-group policy-accept-acknowledge">
        <label>
          By checking this box, I acknowledge I have read the above statement
          and accept this condition.*
        </label>
        <div>
          <input
            type="checkbox"
            id="accept-policy"
            name="policy-accept-acknowledge"
            checked={termsAndConditionsAcknowledgement}
            onChange={(e) => {
              updateFields({
                termsAndConditionsAcknowledgement:
                  !termsAndConditionsAcknowledgement,
              });
            }}
          />
          <label for="policy-accept-acknowledge">I Accept</label>
          {errors.termsAndConditionsAcknowledgement && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            //margin: "0 0 20px 0",
          }}
        >
          {errors.termsAndConditionsAcknowledgement}
        </p>}
        </div>
      </div>
      <div className="form-group">
        <label>
          Legacy Plumbing, Inc. is a drug free workplace. All new employees are
          drug tested. This shall be paid by the company, but this cost will
          deducted from the last paycheck of any employee who does not remain
          with company for a 90-day period.
        </label>
      </div>
    </div>
  );
};

export default CertificateAndSignatureForm;
