import React, { useState } from "react";
import "./PersonalDetailsForm.css";
import CareerBreadcrumb from "../CareerBreadcrumb";

const PersonalDetailsForm = ({
  updateFields,
  errors,
  LegallyAuth,
  LegalAge,
  crimeConvicted,
  crimeDetails,
  franchiseBaseUrl,
  franchiseName,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <div className="personal-details">
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="Personal Information" />
      <div className="form-group form-field-separator">
        <h3 className="form-title">Personal Information</h3>
      </div>
      <div className="form-group work-authorization">
        <label>
          <strong>Are You Legally Authorized to Work in US?*</strong>
        </label>
        <div>
          <input
            type="radio"
            id="authorized-to-work-yes"
            name="authorized-to-work"
            value="yes"
            onChange={(e) => updateFields({ LegallyAuth: e.target.value })}
            checked={LegallyAuth !== "" && LegallyAuth === "yes"}
          />
          <label for="authorized-to-work-yes">Yes</label>
        </div>
        <div className="form-group">
          <input
            type="radio"
            id="authorized-to-work-no"
            name="authorized-to-work"
            value="no"
            onChange={(e) => updateFields({ LegallyAuth: e.target.value })}
            checked={LegallyAuth !== "" && LegallyAuth === "no"}
          />
          <label for="authorized-to-work-no">No</label>
        </div>
        {errors.LegallyAuth && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0 0 20px 0",
          }}
        >
          {errors.LegallyAuth}
        </p>}
        <i>
          Note: you will be required to furnish documents to verify your
          eligibility for employment in accordance with the Immigration Reform
          and Control Act and your employment is contingent upon furnishing such
          documents.
        </i>
      </div>
      <div className="form-group age-limit">
        <label>
          <strong>Are You At Least 18 Years of Age?*</strong>
        </label>
        <div>
          <input
            type="radio"
            id="age-limit-yes"
            name="age-limit"            
            value="yes"
            onChange={(e) => updateFields({ LegalAge: e.target.value })}
            checked={LegalAge !== "" && LegalAge === "yes"}
          />
          <label for="age-limit-yes">Yes</label>
        </div>
        <div className="form-group">
          <input
            type="radio"
            id="age-limit-no"
            name="age-limit"
            value="no"
            onChange={(e) => updateFields({ LegalAge: e.target.value })}
            checked={LegalAge !== "" && LegalAge === "no"}
          />
          <label for="age-limit-No">No</label>
        </div>
        {errors.LegalAge && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0 0 20px 0",
          }}
        >
          {errors.LegalAge}
        </p>}
        <i>
          Note: you will be required to furnish documents to verify your
          eligibility for employment in accordance with the Immigration Reform
          and Control Act and your employment is contingent upon furnishing such
          documents.
        </i>
      </div>
      <div className="form-group crime-conviction">
        <label>
          <strong>
            Have you ever been convicted of any crime (including DUI and DWI),
            submitted a plea of Guilty, No Contest, Prayer for Judgement, or
            similar plea, or served probation as a result of deferred
            prosecution, pretrial intervention, or other similar agreement?*
          </strong>
        </label>
        <div>
          <input
            type="radio"
            id="convicted-yes"
            name="crime-conviction"
            value="yes"
            onChange={(e) => {
              updateFields({ crimeConvicted: e.target.value });
              setIsDisabled(false);
            }}
            checked={crimeConvicted !== "" && crimeConvicted === "yes"}
          />
          <label for="convicted-yes">Yes</label>
        </div>
        <div>
          <input
            type="radio"
            id="convicted-no"
            name="crime-conviction"
            value="no"
            onChange={(e) => {
              updateFields({ crimeConvicted: e.target.value });
              setIsDisabled(true);
            }}
            checked={crimeConvicted !== "" && crimeConvicted === "no"}
          />
          <label for="convicted-no">No</label>
        </div>
        {errors.crimeConvicted && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0 0 20px 0",
          }}
        >
          {errors.crimeConvicted}
        </p>}
      </div>
      <div className="form-group">
        <div className="form-group">
          <textarea
            id="conviction-explaination"
            value={crimeDetails}
            onChange={(e) => updateFields({ crimeDetails: e.target.value })}
            disabled={isDisabled}
            placeholder="If yes, please explain each conviction and state whether it was a felony or misdemeanor. Provide dates of any convictions."
          />
        </div>
        <i>
          Please note: Failure to list any convictions or special judgements may
          disqualify an applicant from consideration of employment. If any such
          omission is found during any period of subsequent employment,
          disciplinary action up to termination may result. Answering yes does
          not necessarily disqualify an applicant from employment.
        </i>
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
