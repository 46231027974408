let initialState = {
    subServiceLoading: false,
    subServiceItem: [],
    subServiceError: null
  };
  
  function subServiceReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_SUB_SERVICES_REQUEST":
        return {
          ...state,
          subServiceLoading: true,
          subServiceError: null
        };
      case "FETCH_SUB_SERVICES_SUCCESS":
        return {
          ...state,
          subServiceLoading: false,
          subServiceItem: action.item
        };
      case "FETCH_SUB_SERVICES_ERROR":
        return {
          ...state,
          subServiceLoading: false,
          subServiceError: action.payload.error,
          subServiceItem: []
        };
      default:
        return state;
    }
  }
  
  export default subServiceReducer;
  