export const ingeniuxURL= process.env.REACT_APP_API_BASE_URL;
// export const ingeniuxURL = "https://services.ingeniux.com/acehandymandss/";
//export const ingeniuxURL = "https://acehandyman-dev-dss-staging.igxdev.com/";
// export const ingeniuxURL = apiBaseUrl;
//export const ingeniuxURL = "https://httpstat.us/503/";

export const yoTrackURL = "https://labs.natpal.com/multisite/phonenumbers";

//read and write review base urls are the same, 
//for write - extra path appended with clientId + /new
//for read - extra path appended with clientId + /summary
export const rateaBizReviewLink = "https://www.rateabiz.com/reviews/";

//amazonaws comlete link - rateaBizReviewsJsonLink + clientId + "/reviews.json"
export const rateaBizReviewsDataLink = "https://s3.amazonaws.com/cdn.rateabiz.com/reviews/";

//JotForm related
export const jotFormURL = "https://submit.jotform.us/submit/91254806635157/";

export const azureEmailingUrl = process.env.REACT_APP_AZURE_EMAILING_URL;

//For getting the current zipcode's siteTrackingId
export const siteTrackingIdURL = "https://labs.natpal.com/api/v1/client/ids?clientId="