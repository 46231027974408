import React, { useRef, useEffect } from "react";
import { ingeniuxURL } from "../../../../config/urls";
import { Markup } from "interweave";
import { HashLink as Link } from "react-router-hash-link";
import "./ImageGalleryFullWidth.css";
import { useLocation } from "react-router-dom";

const ImageGalleryFullWidth = ({
  contentData,
  AddCaptions,
  ImagesPerRow,
  FullWidth,
}) => {
  const location = useLocation();

  return (
    <section id="careers-collage-content" className="content-section twelve">
      <div className={`row careers-collage home-gallery`}>
        <div
          className={
            FullWidth === "False" && contentData?.ImageLink?.length < 4
              ? "carrier space-even"
              : "carrier"
          }
        >
          {contentData?.ImageLink?.map((imgL) => {
            let imgURL = "";
            if (
              location?.pathname === "/our-services" ||
              location?.pathname === "/" ||
              location?.pathname.startsWith("/offices")
            ) {
              imgURL = `/${imgL?.Page?.URL}`;
            } else if (location?.pathname.startsWith("/our-services/")) {
              imgURL = `${location?.pathname}${imgL?.Page?.URL.replace(
                "/",
                ""
              )}`;
            } else {
              imgURL = `/${imgL?.Page?.URL}`;
            }
            return imgL?.Page?.URL ? (
              <Link to={imgURL}>
                <div
                  className={
                    FullWidth === "False"
                      ? ImagesPerRow !== "2"
                        ? "list category space"
                        : ""
                      : ImagesPerRow !== "2"
                      ? "list category non-space"
                      : ""
                  }
                >
                  <img
                    src={`${ingeniuxURL}${imgL?.Image?.Value}`}
                    alt={imgL?.Image?.AlternateText}
                    title={imgL?.Image?.AlternateText}
                    width="300"
                    height="200"
                  />
                  <div
                    className={
                      AddCaptions === "False" ? "no-caption" : "add-caption"
                    }
                  >
                    {imgL?.Page?.Name === "Link" && imgL?.Page.URL ? (
                      <Link to={imgURL}>{imgL?.Caption?.Value}</Link>
                    ) : (
                      <Markup content={imgL?.Caption?.Value} />
                    )}
                  </div>
                </div>
              </Link>
            ) : (
              <div
                className={
                  FullWidth === "False"
                    ? ImagesPerRow !== "2"
                      ? "list category space"
                      : ""
                    : ImagesPerRow !== "2"
                    ? "list category non-space"
                    : ""
                }
              >
                {imgL?.Image?.Value && (
                  <img
                    src={`${ingeniuxURL}${imgL?.Image?.Value}`}
                    alt={imgL?.Image?.AlternateText}
                    title={imgL?.Image?.AlternateText}
                    width="300"
                    height="200"
                  />
                )}
                <div
                  id="add-caption"
                  className={
                    AddCaptions === "False" ? "no-caption" : "add-caption"
                  }
                >
                  {imgL?.Page?.Name === "Link" && imgL?.Page.URL ? (
                    <Link
                      to={
                        location?.pathname === "/our-services"
                          ? `/${imgL?.Page?.URL}`
                          : `${imgL?.SURL}${imgL?.Page?.URL}`
                      }
                    >
                      {imgL?.Caption?.Value}
                    </Link>
                  ) : (
                    <Markup content={imgL?.Caption?.Value} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ImageGalleryFullWidth;
