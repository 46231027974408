import React, { useState } from "react";
import { FooterTop, ZipNextToHeading, ZipNextToHeading2 } from "./Footer";
import LongZip from "./LongZip";
import ShortZip from "./ShortZip";

const ZipChooser = (props) => {
    return (
        // there are 4 components to switch to based on the submit and heading alignment configuration
        // because of the styling difference each of them have
        // to SchedulingPage is a boolean that we ourselves specify. it's to determine
        // if when the form is submitted, we should go to the scheduling link or not
        <div>
            {props.submitAlignment === "CenteredBelow" && props.headingAlignment === "CenteredTop" &&
                <div>
                    <ShortZip zipData={props?.zipInputs} zipCodeList={props?.zipCodeList} 
                    localOfficeList={props?.localOfficeList} toSchedulingPage={props?.toSchedulingPage}
                    />
                </div>
            }
            {
                props.submitAlignment === "InlineRight" && props.headingAlignment === "CenteredTop" &&
                <div>
                    <LongZip zipData={props?.zipInputs}  zipCodeList={props?.zipCodeList} 
                    localOfficeList={props?.localOfficeList} toSchedulingPage={props?.toSchedulingPage} />
                </div>
            }
            {
                props.submitAlignment === "InlineRight" && props.headingAlignment === "InlineLeft" &&
                <div>
                    {/* <FooterTop footerContent={headerItemData[0]?.Footer} zipCodeList={localOfficeZipcodes} localOfficeList={localOfficeResults} toSchedulingPage={false} /> */}
                    {/* footerContent, zipCodeList, localOfficeList, toSchedulingPage */}
                    <ZipNextToHeading zipData={props?.zipInputs}  zipCodeList={props?.zipCodeList} 
                    localOfficeList={props?.localOfficeList} toSchedulingPage={props?.toSchedulingPage} />
                </div>
            }
            {
                props.submitAlignment === "CenteredBelow" && props.headingAlignment === "InlineLeft" &&
                <div>
                    <ZipNextToHeading2 zipData={props?.zipInputs}  zipCodeList={props?.zipCodeList} 
                    localOfficeList={props?.localOfficeList} toSchedulingPage={props?.toSchedulingPage} />
                </div>
            }
        </div>
    )
}

export default ZipChooser;