import React, { useEffect } from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./professionalServices.css";
import { useLocation } from "react-router-dom";
import SvgPhoneIcon from "../common/SvgPhoneIcon";

const ProfessionalServices = (props) => {
  const { franchiseCity, franchiseName, franchiseState, stateAbbr } =
    props.contentData;

  let location = useLocation();

  useEffect(() => {
    const hashValue = location?.hash?.replace(/^#/, "");

    if (hashValue === "local-services") {
      setTimeout(() => {
        const localServices = document.querySelectorAll(
          "section.content-section"
        )[4];
        window.scroll({
          top: parseInt(localServices.offsetTop - 150),
          left: 0,
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [location]);

  const isHomeDetailsSEOTray = () => {
    let isHomeSEOTray = Object.keys(props.contentData).includes(
      "isHomeDetailsSEOTray"
    );
    return isHomeSEOTray;
  };

  let isHomeSEOTray = isHomeDetailsSEOTray();

  return props.contentData?.Copy?.Value ? (
    <section id="pro-services-content" className="content-section twelve">
      <div className="content-wrap main-content" id="redefining-services">
        <div className="professional-service-container row">
          <div className="col-dd-12">
            {props?.contentData?.Image && !isHomeSEOTray && (
              <div className="float-container image hide-sm">
                <figure>
                  {props?.contentData?.Image?.Value && (
                    <img
                      className="lazy loaded"
                      src={`${ingeniuxURL}${props?.contentData?.Image?.Value}`}
                      alt={props?.contentData?.Image?.AlternateText}
                      title={props?.contentData?.Image?.AlternateText}
                      width="300"
                      height="200"
                    />
                  )}
                </figure>
              </div>
            )}
            <div
              className={`truncate ${
                isHomeSEOTray ? "custom-seo-headings" : ""
              }`}
            >
              <div className="truncate-span">
                {props?.contentData?.Heading?.Value && (
                  <h2 className="seo-heading">
                    {props?.contentData?.Heading?.Value}
                  </h2>
                )}
                <Markup
                  content={props?.contentData?.Copy?.Value?.replaceAll(
                    "{City}",
                    franchiseCity
                  )
                    ?.replace(
                      "{Phone}",
                      formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                    )
                    ?.replace("{FranchiseName}", franchiseName)
                    ?.replace("{State}", franchiseState)
                    ?.replace("{StateAbbreviation}", stateAbbr)}
                />
                
                {window.location.pathname.includes("campaign") && props?.contentData?.Page?.Name && (
                  <div className="call-us">
                    <p>
                      Call us at
                      <span className="phone">
                        {" "}
                        <SvgPhoneIcon attributes={{ fill: "#d40029" }} />
                        <a href={`tel:${props?.contentData?.Page?.URL}`}>
                          {props?.contentData?.Page?.URL}
                        </a>
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default ProfessionalServices;
