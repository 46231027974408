import React, { useEffect } from "react";
import { Markup } from 'interweave';

import { ingeniuxURL } from '../../config/urls';
import { bgPatternTriangles } from "../../config/utility";
import "./packagesTabOptions.css";


const PackagesTabOptions = ({ data, currentHash }) => {


    return (
        <div >
            <div className="body-wrap" style={{ backgroundImage: bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})` }}>
                <section id="package-content" className="content-section twelve">
                    <div className="white">
                        <div className="package-container row">
                            <div id={currentHash} className="packages-grid">
                                {data?.map((packageData, index) => {

                                    if (index % 2 === 0) {
                                        return packageData?.Schema === "PackageDetail" &&
                                            <div className="colm-sd-12 col-md-6 col-dddd-6 item general" style={{ left: "0px", top: "0.016px" }}>
                                                <figure>
                                                    {packageData?.PackageImage && <img
                                                        src={`${ingeniuxURL}${packageData?.PackageImage}`}
                                                        width="200" height="100"
                                                    />}
                                                </figure>
                                                <h5 className="title">{packageData?.Name}</h5>

                                                <Markup content={packageData?.PackageIntroCopy} />
                                                <ul className="accent-sq">
                                                    <Markup content={packageData?.PackageWhatYouGet} />
                                                    <ul className="accent-sq">
                                                        <li><a href={`${packageData?.URL}`}>Learn More</a></li>
                                                    </ul>
                                                </ul>
                                            </div>
                                    }
                                    else {
                                        return packageData?.Schema === "PackageDetail" &&
                                            <div className="colm-sd-12 col-md-6 col-dddd-6 item general">
                                                <figure>
                                                    <img
                                                        src={`${ingeniuxURL}${packageData?.PackageImage}`}
                                                        width="200" height="100"
                                                    />
                                                </figure>
                                                <h5 className="title">{packageData?.Name}</h5>
                                                <Markup content={packageData?.PackageIntroCopy} />
                                                <ul className="accent-sq">
                                                    <Markup content={packageData?.PackageWhatYouGet} />
                                                    <ul className="accent-sq">
                                                        <li><a href={`${packageData?.URL}`}>Learn More</a></li>
                                                    </ul>
                                                </ul>
                                            </div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PackagesTabOptions;