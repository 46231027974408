import React from "react";
import { Markup } from "interweave";
import "./CommunityMattersContentWrap.css";
import WhyChooseUsPromise from "../WhyChooseUsPage/WhyChooseUsPromise";
import TrayLeft from "../common/TrayLeft";
import { ingeniuxURL } from '../../config/urls';

const CommunityMattersContentWrap = ({ contentData }) => {
    return (
        <body className="philanthropy-content">
            <div className="body-wrap">
                <section id="content" className="content-section twelve">
                    <div className="content-wrap">
                        {contentData?.DetailPage?.Trays?.map(element => {
                            if (element?.TrayName === "ImageCopyTray") {
                                if (element?.Copy?.Value) {
                                    if (element?.AddOutline?.Value === 'Yes') {
                                        return <WhyChooseUsPromise contentData={element} />
                                    } else if (element?.ImageAlignment?.Value === "Right") {
                                        return <>
                                            <div className="container row">
                                                <div className="col-dd-8 pad-right-lg">
                                                    <Markup content={element?.Copy?.Value} />
                                                </div>
                                                <div className="col-dd-4">
                                                    <figure>
                                                        {element?.Image?.Value && <img
                                                            src={`${ingeniuxURL}${element?.Image?.Value}`}
                                                            alt={element?.Image?.AlternateText}
                                                            title={element?.Image?.AlternateText}
                                                            width="250" height="150"
                                                        />}
                                                    </figure>
                                                </div>
                                            </div></>
                                    }
                                    else if (element?.ImageAlignment?.Value === "Left") {
                                        return <TrayLeft
                                            CTA={(element?.CTAStyle)}
                                            displyGrd={element?.DisplayGrid?.Value}
                                            bgColor={element?.BackgroundColor?.Value}
                                            img={element?.Image?.Value}
                                            heading={element?.Heading?.Value}
                                            leftTrayContent={(element?.Copy?.Value)} />
                                    }
                                }
                            }
                        })}

                    </div>
                </section>
            </div>
        </body >
    );
};

export default CommunityMattersContentWrap;