let initialState = {
    loading: false,
    item: [],
    error: null
  };

  const filterPlumbingItems = (data)=>{
    const excludeClientIds = ['Home_Services', 'Home_Services_Plumbing', 'Home_Services_HVAC', 'Home_Services_Electrical']
    let filteredData = data?.filter(dataItem=>!excludeClientIds.includes(dataItem.ClientId));
    return filteredData;
  }
  
  function localOfficeReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_LOCAL_OFFICE_REQUEST":
        return {
          ...state,
          loading: true,
          error: null
        };
      case "FETCH_LOCAL_OFFICE_SUCCESS":
        return {
          ...state,
          loading: false,
          item: filterPlumbingItems(action.item)
        };
      case "FETCH_LOCAL_OFFICE_ERROR":
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          item: []
        };
      default:
        return state;
    }
  }
  
  export default localOfficeReducer;
  