import {
    FETCH_FRANCHISE_DETAILPAGE_REQUEST,
    FETCH_FRANCHISE_DETAILPAGE_SUCCESS,
    FETCH_FRANCHISE_DETAILPAGE_ERROR
} from "../actions/actionType"

let initialState = {
    loading: false,
    franchseDetailPage: [],
    error: null
};

function franchiseDetailPageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FRANCHISE_DETAILPAGE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_FRANCHISE_DETAILPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                franchseDetailPage: action.item
            };
        case FETCH_FRANCHISE_DETAILPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                franchseDetailPage: []
            };
        default:
            return state;
    }
}

export default franchiseDetailPageReducer;
