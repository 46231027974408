import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./FranchiseEstimateSubmissionBreadcrumbs.css";

export const FranchiseEstimateSubmissionBreadcrumbs = ({ breadcrumbData }) => {
  const filteredArray = breadcrumbData?.filter(
    (breadCrumb) =>
      breadCrumb?.Schema !== "Folder" && breadCrumb?.Schema !== "PageFolder"
  );
  return (
    <div className="franchise-estimate-submission-breadcrumb">
      <div className="breadcrumb-container">
        <ul>
          {filteredArray?.map((breadCrumb, index) => {
            console.log("=========", breadCrumb);
            return (
              <li key={index}>
                {index + 1 === 1 && breadCrumb?.Name !== "Home" && (
                  <Link to={`/`}>Home {"|"}</Link>
                )}
                {index + 1 === filteredArray?.length ? (
                  <Link to={`/${breadCrumb?.URL}`}>
                    {/* {`${
                    breadCrumb?.Schema === "FranchiseHomePage"
                      ? "Ace Plumbing Services "
                      : ""
                  }`} */}
                    {breadCrumb?.Name?.split("-").join(" ") ===
                      "Ace Plumbing Services North Oklahoma City" ||
                    breadCrumb?.Name?.split("-").join(" ") ===
                      "North Oklahoma City"
                      ? "ACE HARDWARE PLUMBING SERVICES NORTH OKLAHOMA CITY"
                      : breadCrumb?.Name?.split("-").join(" ")}
                  </Link>
                ) : (
                  breadCrumb?.Name !== "Offices" && (
                    <Link to={`/${breadCrumb?.URL}`}>
                      {`${
                        breadCrumb?.Schema === "FranchiseHomePage"
                          ? // "Ace Plumbing Services " +
                            breadCrumb?.Name?.split("-").join(" ") ===
                            "Ace Handyman Services North Oklahoma City"
                            ? "ACE HARDWARE PLUMBING SERVICES NORTH OKLAHOMA CITY"
                            : breadCrumb?.Name?.split("-").join(" ") ===
                              "Ace Plumbing Services North Oklahoma city"
                            ? "ACE HARDWARE PLUMBING SERVICES NORTH OKLAHOMA CITY"
                            : breadCrumb?.Name?.split("-").join(" ")
                          : breadCrumb?.Name?.split("-").join(" ") ===
                            "Ace Handyman Services North Oklahoma City"
                          ? "ACE HARDWARE PLUMBING SERVICES NORTH OKLAHOMA CITY"
                          : breadCrumb?.Name?.split("-").join(" ") ===
                            "Ace Plumbing Services North Oklahoma city"
                          ? "ACE HARDWARE PLUMBING SERVICES NORTH OKLAHOMA CITY"
                          : breadCrumb?.Name?.split("-").join(" ")
                      }`}
                    </Link>
                  )
                )}
                {index + 1 === filteredArray?.length ? " | Contact Us" : ""}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FranchiseEstimateSubmissionBreadcrumbs;
