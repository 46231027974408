import React from "react";
import { Markup } from 'interweave';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ingeniuxURL } from '../../../config/urls';
import "./Reviews.css";

const settings = {
    dots: false,
    fade: true,
    centerMode: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000
};

const Reviews = ({ contentData }) => {
    const { reviews: { error, reviewsData }, readReviewURL, writeReviewURL, reviewsTrays } = contentData;
    const { BackgroundImage: reviewBgImg, Copy, Heading } = reviewsTrays[0];
    return (
        <div className="franchise-reviews-content-wrap">
            <div id="franchise-reviews-wrap" className="franchise-reviews-wrap row lazy-bg"
                style={{
                    backgroundColor: "#808080", backgroundImage:reviewBgImg?.Value && `url(${ingeniuxURL}${reviewBgImg?.Value})`, backgroundSize: "cover"
                }}>
                {contentData ?
                    <div className="franchise-reviews-container">
                        <h2 className="heading">{Heading?.Value}</h2>
                        <div className="underline subhead">
                            <Markup content={Copy?.Value} />{" "}
                            <div className="links">
                                <a href={`${writeReviewURL}`} target="_blank" rel="noopener noreferrer" className="write-review">Write a Review</a>{" | "}
                                {reviewsData?.length > 0 &&
                                    <a href={`${readReviewURL}`} target="_blank" rel="noopener noreferrer" className="read-review">Read All Reviews</a>
                                }
                            </div>
                        </div>
                        {reviewsData?.length > 0 ?
                            <div id="full-reviews">
                                <div id="review-slides" >
                                    <Slider {...settings}>
                                        {reviewsData?.map((review, index) => {
                                            const fisrtName = review?.authorFirstName?.charAt(0).toUpperCase() + review?.authorFirstName?.slice(1);
                                            const lastNmae = review?.authorLastName?.length === 1 ? `${review?.authorLastName}.` : review?.authorLastName;
                                            return (
                                                <div className="review-item" key={index}>
                                                    <div className="rating">
                                                        {[...Array(Number(review?.rating)).keys()]?.map((elem, index) => <span className="r-star" key={index}></span>)}
                                                    </div>
                                                    <div className="review-title">
                                                        {review?.title}
                                                    </div>
                                                    <div className="text">
                                                        <Markup content={review?.text} />
                                                    </div>
                                                    <div className="name">
                                                        {`${fisrtName} ${lastNmae[0]}.`}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </div>
                            :
                            error &&
                                // <div className="review-custom-msg">
                                //     <h3>{error}</h3></div> :
                                <div className="review-custom-msg">
                                    <h3>We appreciate your feedback</h3>
                                    <p>Be the first to{" "}
                                        <a href={`${writeReviewURL}`} target="_blank" rel="noopener noreferrer">leave us a review!</a>
                                    </p>
                                </div>
                        }
                    </div> : ""}
            </div>
        </div>
    )
}

export default Reviews;