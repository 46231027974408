import React, { Component } from "react";
import { Helmet } from "react-helmet";

// import { fetchHomePage } from '../../actions/fetchData'
import PackageTab from "./PackageTab";
// import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  getFranchiseDataFromLS,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
} from "../../config/utility";
import PackagesHowItWorksFeatured from "../FranchiseCommon/PackagesHowItWorksFeatured/PackagesHowItWorksFeatured";
// import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
// import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { FindAnOfficeTray } from "../common/Footer";
import axios from "axios";
import { ingeniuxURL } from "../../config/urls";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteData: null,
      localOfficeData: null,
      homePageData: null,
      // franchiseHomeData: loadApiData('franchiseHomeData')
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // const headerItemData = getResultsArray(this.props?.headerItem, "GlobalSiteControl");
    // const localOfficeResults = this?.props?.localOfficeItem;
    // const franchiseHomePage = getResultsArray(this.props?.franchiseHomeItem, "FranchiseHomePage");
    // const rgxforSlashes = /^\/|\/$/g;
    // const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");

    // this.props.dispatch(fetchHomePage('/Packages'));

    // if ((this.state.globalSiteCrl?.length === 0 || this.state.globalSiteCrl === undefined) && headerItemData?.length === 0) {
    //   this.props.dispatch(fetchHeaderPage('x4878'));
    // }
    // if ((this.state.localOfficeArray?.length === 0 || this.state.localOfficeArray === undefined) && localOfficeResults?.length === 0) {
    //   this.props.dispatch(fetchLocalOfficeData('localoffice/orderby/state'));
    // }

    // if (recentFranchisePath) {
    //   const localStateSURL = this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;

    //   //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //   //then load the franchisehomepage api again.

    //   //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //   //are no the same, removed the cached data from the Session storage and
    //   //fetch the api again.
    //   if ((this.state.franchiseHomeData?.length === 0 || this.state.franchiseHomeData === undefined) && franchiseHomePage?.length === 0) {
    //     this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
    //   } else if (this.state.franchiseHomeData && (localStateSURL?.replace(rgxforSlashes, '') !== recentFranchisePath?.replace(rgxforSlashes, ''))) {
    //     deleteItemFromSS('franchiseHomeData');
    //     this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
    //   }
    // }

    this.fetchPackagesHomePage();
    this.fetchx31Data();
    this.fetchLocalOfficeData();
  }

  fetchx31Data() {
    axios
      .get(`${ingeniuxURL}api/pages?pageIds=x31`)
      .then((response) => {
        console.log("response:", response);
        this.setState({ globalSiteData: response.data.results });
      })
      .catch((err) => {
        console.log("Error:", err);
        throw new Error("Fetch X31 Failed");
      });
  }

  fetchLocalOfficeData() {
    axios
      .get(`${ingeniuxURL}api/localoffice/orderby/state`)
      .then((response) => {
        this.setState({ localOfficeData: response.data });
      })
      .catch((err) => {
        throw new Error("Fetch Local Office Failed");
      });
  }

  fetchPackagesHomePage() {
    axios
      .get(`${ingeniuxURL}api/page?path=/Packages`)
      .then((response) => {
        this.setState({ homePageData: response.data.results });
      })
      .catch((err) => {
        throw new Error("Fetch Home Failed");
      });
  }

  render() {
    const { localOfficeData, globalSiteData, homePageData } = this.state;
    // const homeItemData = getResultsArray(this.props.homeItem, "PackagesIndex");
    // const linkedItemData = getResultsArray(this.props?.linkedData, "PackageDetail");
    // const headerData = getResultsArray(this.props?.headerItem, "GlobalSiteControl");
    // const localOffices = this?.props?.localOfficeItem;
    // const franchiseHomeData = getResultsArray(this.props?.franchiseHomeItem, "FranchiseHomePage");

    // //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    // let headerItemData = loadApiData('x31');
    // let localOfficeResults = loadApiData('localoffice');
    // let franchiseHomePage = loadApiData('franchiseHomeData');

    // // saving GlobalSiteControl, localOffices to LocalStorage
    // // if the LS content is empty and Redux cached data available
    // if ((headerItemData === undefined || headerItemData?.length === 0) && headerData) {
    //   saveApiData('x31', headerData);
    //   headerItemData = loadApiData('x31');
    // }
    // if ((localOfficeResults === undefined || localOfficeResults?.length === 0) && localOffices) {
    //   saveApiData('localoffice', localOffices);
    //   localOfficeResults = loadApiData('localoffice');
    // }

    // //saving franchiseHomePage data to sessionStorage if it is not available in
    // //sessionStorage from the redux state
    // if ((franchiseHomePage === undefined || franchiseHomePage?.length === 0) && franchiseHomeData) {
    //   const localStorageFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
    //   const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
    //   const rgxforSlashes = /^\/|\/$/g;
    //   if (localStorageFranchisePath?.replace(rgxforSlashes, '') === apiFranchisePath?.replace(rgxforSlashes, '')) {
    //     console.log(localStorageFranchisePath?.replace(rgxforSlashes, '') === apiFranchisePath?.replace(rgxforSlashes, ''))
    //     saveApiData('franchiseHomeData', franchiseHomeData);
    //     franchiseHomePage = loadApiData('franchiseHomeData');
    //   }
    // }

    let allowedZipcodes = [];
    let ownedZipcodes = [];
    let localOfficeZipcodes = [];

    if (localOfficeData) {
      allowedZipcodes = getResultsArray(localOfficeData, "AllowedZipcodes");
      ownedZipcodes = getResultsArray(localOfficeData, "OwnedZipcodes");
      localOfficeZipcodes = allowedZipcodes;
      for (let i = 0; i < localOfficeZipcodes.length; i++) {
        localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(
          ownedZipcodes[i]
        );
      }
    }

    // var localPackages = [];
    // for (let i = 0, len = franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseOwnersControl?.Packages?.LocalPackages?.length; i < len; i++) {
    //   localPackages.push(franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseOwnersControl?.Packages?.LocalPackages[i]?.Name);
    // }

    const pageTitle =
      homePageData && homePageData[0].PackagesIndex?.Title?.Value;
    const browserSuffix =
      globalSiteData &&
      globalSiteData[0].GlobalSiteControl?.GlobalSettings?.BrowserTitleSuffix
        ?.Value;

    let combinedPropsFindOffice;
    if (globalSiteData) {
      combinedPropsFindOffice = {
        ...{
          ...globalSiteData[0].GlobalSiteControl?.Footer?.FindAnOffice,
          ...{ zipCodeList: localOfficeZipcodes },
          ...{ localOfficeList: localOfficeData },
        },
      };
    }

    // var filteredData = homeItemData[0]?.TaxonomyNavigation?.Packages?.map((currentTab) => {
    //   return { ...currentTab, LocalPage: currentTab?.Pages?.Page?.filter((currentPackage) => localPackages?.includes(currentPackage?.Name)) }
    // });

    var allCorpPackagesArray = [];
    if(homePageData){
      for (var i = 0; i < homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages?.length; i++) {
        for (var j = 0; j < homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages[i]?.Pages?.Page?.length; j++) {
          allCorpPackagesArray.push(homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages[i]?.Pages?.Page[j]);
        }
      }
    }
    
    return (
      <>
        <Helmet>
          <title>
             {/* removed "| Ace Hardware Plumbing Services" in title */}
            {pageTitle ? `${pageTitle}` : browserSuffix}
          </title>
          <meta
            name="description"
            content={
              homePageData && homePageData[0].PackagesIndex?.Description?.Value
            }
          />
        </Helmet>
        <HeaderWrapper headerItemData={globalSiteData} />
        {homePageData && (
          <PackagesHowItWorksFeatured
            title={homePageData[0].PackagesIndex?.Heading?.Value}
            bread={homePageData[0]}
            heading={homePageData[0].PackagesIndex?.IntroHeading?.Value}
            content={homePageData[0].PackagesIndex?.IntroCopy?.Value}
            // boxTitle={homePageData[0].PackagesIndex?.FeaturedPackage?.Value}
            // boxHeading={homePageData[0].PackagesIndex?.Page?.Link}
            // boxIntro={linkedItemData[0].PackagesIndex?.IntroCopy?.Value}
            // boxList={linkedItemData[0].PackagesIndex?.WhatYouGet?.Value}
            // boxURL={homePageData[0].PackagesIndex?.Page?.URL}
            boxTitle={
              homePageData[0].PackagesIndex?.FeaturedPackageHeading?.Value
            }
            boxHeading={
              homePageData[0].PackagesIndex?.FeaturedPackages[0]?.Name
            }
            boxIntro={
              homePageData[0].PackagesIndex?.FeaturedPackages[0]
                ?.PackageIntroCopy
            }
            boxList={
              homePageData[0].PackagesIndex?.FeaturedPackages[0]
                ?.PackageWhatYouGet
            }
            boxURL={homePageData[0].PackagesIndex?.FeaturedPackages[0]?.URL}
          />
        )}

        <PackageTab
          // contentData={franchiseHomePage && franchiseHomePage[0] ?
          //   filteredData
          //   :
          //   homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages
          // }
          contentData={
            homePageData&&homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages
          }
          // allData={franchiseHomePage && franchiseHomePage[0] ?
          //   // allCorpPackagesArray?.filter(currentPackage => localPackages?.includes(currentPackage?.Name))
          //   [...new Set(allCorpPackagesArray?.map(JSON.stringify))].map(JSON.parse)?.filter(currentPackage => localPackages?.includes(currentPackage?.Name))
          //   :
          //   // allCorpPackagesArray
          //   [...new Set(allCorpPackagesArray?.map(JSON.stringify))]?.map(JSON.parse)
          // }
          allData={[...new Set(allCorpPackagesArray?.map(JSON.stringify))]?.map(
            JSON.parse
          )}
          categories={
            homePageData &&
            homePageData[0].PackagesIndex?.TaxonomyNavigation?.Packages
          }
          // before it was below:
          // categories={homeItemData[0]?.TaxonomyNavigation?.Packages}
        />
        <div className="findoffice col-">
          <FindAnOfficeTray contentData={combinedPropsFindOffice} />
        </div>

        {/* {homeItemData[0]?.Trays?.map((tray, index) => {
          if (Object.keys(corpDynamicTrayList)?.find(trayName => trayName === tray?.TrayName)) {

            let combinedProps;

            if (tray?.TrayName === "ScheduleandEstimateTabTray" || tray?.TrayName === "ScheduleandEstimateTray") {
              combinedProps = { ...{ ...tray, ...{ zipCodeList: localOfficeZipcodes }, ...{ localOfficeList: localOfficeResults } } }
            }
            else if (tray?.TrayName === "ServicesTray") {
              combinedProps = { ...{ ...tray, ...{ franchiseData: franchiseHomePage && franchiseHomePage[0] } } }
            }
            else if (tray?.TrayName === "FindAnOffice") {
              combinedProps = { ...{ ...tray, ...{ zipCodeList: localOfficeZipcodes }, ...{ localOfficeList: localOfficeResults } } }
            }
            else {
              combinedProps = { ...tray }
            }

            return (
              <CorpDynamicTrays
                trayName={tray?.TrayName}
                contentData={combinedProps}
                key={index}
              />
            )
          }
        })} */}

        <FooterWrapper headerItemData={globalSiteData} />
      </>
    );
  }
}

export default Packages;
