import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import HomeBanner from "./HomePage/HomeBanner";
import HeaderWrapper from "./common/HeaderWrapper";
import { fetchHomePage } from "../actions/fetchData";
import { fetchHeaderPage } from "../actions/fetchHeaderData";
import FooterWrapper from "./common/FooterWrapper";
import { getResultsArray, loadApiData, saveApiData } from "../config/utility";
import { fetchLocalOfficeData } from "../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "./common/DynamicTrays";
import {
  setCorporateNumber,
} from "../actions/setCorporatePhoneNumber";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );

    const error = this?.props?.error;
    if (error) {
      console.log(error);
    }
    const localOfficeResults = this?.props?.localOfficeItem;
    this.props.dispatch(fetchHomePage("/"));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }
  componentDidUpdate() {
    if (this?.props?.error?.status === 503) {
      console.log("page error");
    }
  }

  calculateBannerPadding = () => {
    this.setState({ calculateBannerPadding: true });
  };

  setCorporatePhoneNumber = (number) => {
    this.props.dispatch(setCorporateNumber(number));
  };

 

  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "HomePage");
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");

    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0, len = localOfficeZipcodes.length; i < len; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    let trayArray = [];
    for (let i = 0; i <= this.props.homeItem?.results?.length; i++) {
      for (
        let j = 0;
        j <= this.props.homeItem?.results[i]?.HomePage?.Trays?.length;
        j++
      ) {
        trayArray.push(this.props.homeItem?.results[i]?.HomePage?.Trays[j]);
      }
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        <Helmet>
          <title>
            {/* removed "| Ace Hardware Plumbing Services" in title */}
            {pageTitle ? `${pageTitle}` : browserSuffix}
          </title>
          <script type="application/ld+json">
            {`{
                  "@context":"https://schema.org",
                  "@type":"Organization",
                  "name":"Ace Hardware Plumbing Services",
                  "legalName":"Ace Plumbing Operating LLC",
                  "url":"https://acehardwareplumbing.com/",
                  "logo":"https://aceplumbing-dev-dss.igxdev.com//Images/Corporate%20Site/Logos/franchise_logo.png",
                  "description":"If you are looking for a better plumbing experience, from transparent pricing to expertise on a range of problems, rely on Ace Hardware Plumbing Services. If you are looking for a licensed plumber with a helpful and friendly attitude, trust Ace Hardware Plumbing Services. Our team has extensive training on water heater installation, troubleshooting water leaks, gas lines, slab leaks, sewer backups and more. We also handle garbage disposal replacement, toilet overflow to faucet installs. Give us a call for upfront pricing and superior expertise!",
                  "mainEntityOfPage":"https://en.wikipedia.org/wiki/Plumber",
                  "parentOrganization":{
                      "@type":"Organization",
                      "name":"Ace Hardware",
                      "url":"https://www.acehardware.com/",
                      "@id":"https://www.wikidata.org/wiki/Q4672981"
                  },
                  "foundingLocation":[
                      {
                        "@type":"Place",
                        "geo":{
                            "@type":"GeoCoordinates",
                            "latitude":"35.5663416",
                            "longitude":"-97.541395"
                        },
                        "name":"Ace Hardware Plumbing Services"
                      }
                  ],
                  "actionableFeedbackPolicy":"https://devplumbing.azurewebsites.net/offices/ace-hardware-plumbing-services-north-oklahoma-city/join-our-team#GetEstimateLink",
                  "knowsAbout":[
                      "plumbing",
                      "slab leak detection",
                      "water heater installation",
                      "water leak",
                      "sewer line repair",
                      "water pressure",
                      "drain cleaning",
                      "gas leak",
                      "gas line repair",
                      "water heater repair",
                      "repiping",
                      "toilet installation",
                      "plumbing repair",
                      "bathroom plumbing",
                      "kitchen plumbing"
                  ],
                  "address":{
                      "@type":"PostalAddress",
                      "streetAddress":"1509 W Britton Rd",
                      "addressLocality":"Oklahoma City",
                      "addressRegion":"OK",
                      "postalCode":"73120",
                      "addressCountry":"USA"
                  },
                  "contactPoint":{
                      "@type":"ContactPoint",
                      "contactType":"customer support",
                      "telephone":"[+1405-785-4550]"
                  },
                  "sameAs":[
                      "https://www.yelp.com/biz/ace-hardware-plumbing-services-oklahoma-city",
                      "https://www.linkedin.com/company/ace-hardware-plumbing-services/",
                      "https://www.facebook.com/AceHardwarePlumbing/",
                      "https://www.bbb.org/us/ok/oklahoma-city/profile/plumbing-and-heating/ace-hardware-plumbing-services-0995-90108607"
                  ]
                }`}
          </script>
        </Helmet>
        <HeaderWrapper
          headerItemData={{
            ...headerItemData,
            metaDescription: homeItemData[0]?.Description?.Value,
          }}
          calculateBannerPadding={this.calculateBannerPadding}
          setGlobalCorporateNumber={this.setCorporatePhoneNumber}
          setNumberSelectionStatus={this.setNumberSelectionStatus}
          corporateNumber={this.props.corporatePhoneNumber}
          isNumberSelected={this.props.isNumberSelected}
        />
        <section className="content home-page">
          <HomeBanner
            homeBannerContent={homeItemData[0]?.CorporateHeroTray}
            zipCodeList={localOfficeZipcodes}
            localOfficeList={localOfficeResults}
          />

          {homeItemData[0]?.Trays?.map((tray, index) => {
            if (
              Object.keys(corpDynamicTrayList)?.find(
                (trayName) => trayName === tray?.TrayName
              )
            ) {
              let combinedProps;

              if (
                tray?.TrayName === "ScheduleandEstimateTabTray" ||
                tray?.TrayName === "ScheduleandEstimateTray"
              ) {
                combinedProps = {
                  ...{
                    ...tray,
                    ...{ zipCodeList: localOfficeZipcodes },
                    ...{ localOfficeList: localOfficeResults },
                    ...{ franchiseDataToGetEstimate },
                  },
                };
              } else if (tray?.TrayName === "ServicesTray") {
                combinedProps = {
                  ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } },
                };
              } else if (tray?.TrayName === "FindAnOffice") {
                combinedProps = {
                  ...{
                    ...tray,
                    ...{ zipCodeList: localOfficeZipcodes },
                    ...{ localOfficeList: localOfficeResults },
                  },
                };
              } else {
                combinedProps = { ...tray };
              }

              return (
                <CorpDynamicTrays
                  trayName={tray?.TrayName}
                  contentData={combinedProps}
                  key={index}
                />
              );
            }
          })}
        </section>
        <FooterWrapper
          headerItemData={headerItemData}
          corporateNumber={this.props.corporatePhoneNumber}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  corporatePhoneNumber:
    state?.corporatePhoneNumberReducer?.corporatePhoneNumber,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,

  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(Home);
