import FranchiseWhyChooseUs from "../FranchiseWhyChooseUs";
import FranchiseOurTeam from "../FranchiseOurTeam";
import FranchiseOurWork from "../FranchiseOurWork";
import ReviewsMaster from "../FranchiseReviews/ReviewsMaster"
import FranchiseNewsAndEvents from "../FranchiseNewsAndEvents";
import FranchiseOffers from "../FranchiseOffers";
import FranchiseSeasonalServices from "../FranchiseSeasonalServices";
//list of Dynamic components predefined 
//as object to match with the detail page api
export const dynamicComponentTree = {
    WhyChooseUs: FranchiseWhyChooseUs,
    OurWork: FranchiseOurWork,
    OurTeam: FranchiseOurTeam,
    Reviews: ReviewsMaster,
    NewsEvents: FranchiseNewsAndEvents,
    Offers: FranchiseOffers,
    SeasonalServices: FranchiseSeasonalServices
}