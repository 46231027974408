// Field List as per backend.
export const BASIC_INFO = {
  Position: "",
  Name: {
    firstName: "",
    middleName: "",
    lastName: "",
  },
  Address: {
    addressLine1: "",
    addressLine2: "",
    City: "",
    State: "",
    zipCode: "",
  },
  yacr: "",
  previousAddress: {
    previousAddressLine1: "",
    previousAddressLine2: "",
    previousCity: "",
    previousState: "",
    previousZipCode: "",
  },
  yapa: "",
  EmailAddress: "",
  PhoneNumber: "",
  SalaryExpected: "",
  DateAvailable: "",
  ReferredBy: "",
  appliedEarlier: "",
  appliedDate: "",
  jobSource: "",
  knowledgeSkills: "",
};

export const PERSONAL_INFO = {
  LegallyAuth: "",
  LegalAge: "",
  crimeConvicted: "",
  crimeDetails: "",
};

export const EMPLOYMENT_INFO = {
  emp1Employer1: "",
  emp1EmployedFrom: "",
  emp1EmployedTo: "",
  emp1TypeofWorkPerformed: "",
  emp1StartingSalary: "",
  emp1EndingSalary: "",
  emp1ReasonForLeaving: "",
  emp1Address: {
    emp1AddressLine1: "",
    emp1AddressLine2: "",
    emp1City: "",
    emp1State: "",
    emp1ZipCode: "",
  },
  emp1NameofSupervisor: "",
  emp1PhoneNumber: "",
  emp1Contact: "",
  emp2Employer2: "",
  emp2EmployedFrom: "",
  emp2EmployedTo: "",
  emp2TypeofWorkPerformed: "",
  emp2StartingSalary: "",
  emp2EndingSalary: "",
  emp2ReasonForLeaving: "",
  emp2Address: {
    emp2AddressLine1: "",
    emp2AddressLine2: "",
    emp2City: "",
    emp2State: "",
    emp2ZiCode: "",
  },
  emp2NameofSupervisor: "",
  emp2PhoneNumber: "",
  emp2Contact: "",
  emp3Employer3: "",
  emp3EmployedFrom: "",
  emp3EmployedTo: "",
  emp3TypeofWorkPerformed: "",
  emp3StartingSalary: "",
  emp3EndingSalary: "",
  emp3ReasonForLeaving: "",
  emp3Address: {
    emp3AddressLine1: "",
    emp3AddressLine2: "",
    emp3City: "",
    emp3State: "",
    emp3ZiCode: "",
  },
  emp3NameofSupervisor: "",
  emp3PhoneNumber: "",
  emp3Contact: "",
  emp4Employer4: "",
  emp4EmployedFrom: "",
  emp4EmployedTo: "",
  emp4TypeofWorkPerformed: "",
  emp4StartingSalary: "",
  emp4EndingSalary: "",
  emp4ReasonForLeaving: "",
  emp4Address: {
    emp4AddressLine1: "",
    emp4AddressLine2: "",
    emp4City: "",
    emp4State: "",
    emp4ZiCode: "",
  },
  emp4NameofSupervisor: "",
  emp4PhoneNumber: "",
  emp4Contact: "",
};

export const EDUCATION_INFO = {
  HighSchoolNameLocation: "",
  DiplomaorGED: "",
  CollegeNameLocation: "",
  MajorCoursesStudied: "",
  HowManyYearsDidYouAtten: "",
  DiplomaOrDegree: "",
  TradeSchoolNameLocation: "",
  TradeSchoolYearsAtten: "",
  TradeSchoolDiplomaOrDegree: "",
  ListOfOtherQualifications: "",
  armyServiceDescription: "",
};

export const REFERENCES_INFO = {
  ref1Name: {
    ref1FirstName: "",
    ref1LastName: "",
  },
  ref1EmailId: "",
  ref1PhoneNumber: "",
  ref2Name: {
    ref2FirstName: "",
    ref2LastName: "",
  },
  ref2EmailId: "",
  ref2PhoneNumber: "",
  ref3Name: {
    ref3FirstName: "",
    ref3LastName: "",
  },
  ref3EmailId: "",
  ref3PhoneNumber: "",
  currentlyEmployed: "",
  contactPresentEmployer: "",
  resume: "",
};

export const CERTIFICATE_AND_SIGNATURE_INFO = {
  genuineAnswersAcknowledgement: false,
  termsAndConditionsAcknowledgement: false,
};
