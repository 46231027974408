import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import WhyChooseUsBanner from "./WhyChooseUsBanner";
import WhyChooseUsBreadcrumbs from "./WhyChooseUsBreadcrumbs";
import HeaderWrapper from "../common/HeaderWrapper";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
  bgPatternTriangles,
} from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import {
  setCorporateNumber,
} from "../../actions/setCorporatePhoneNumber";

import withRouter from "../common/withRouter";
import CopyAside from "../common/CopyAside";
import CareersHeroWithOverlayTray from "../common/CareersHeroWithOverlayTray";
import Page404 from "../page-404";

class WhyChooseUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      loading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const pageApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const localOfficeResults = this?.props?.localOfficeItem;
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    this.props.dispatch(fetchHomePage(pageApiPath));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.apiRef?.apiPath !== this.props?.apiRef?.apiPath ||
      prevProps?.apiRef !== this.props?.apiRef
    ) {
      const pageApiPath = this.props?.apiRef?.apiPath
        ? this.props?.apiRef?.apiPath
        : this.props?.apiRef;
      this.props.dispatch(fetchHomePage(pageApiPath));
    }
    
    window.scrollTo(0, 0);
  }

  calculateBannerPadding = () => {
    this.setState({ calculateBannerPadding: true });
  };

  setCorporatePhoneNumber = (number) => {
    this.props.dispatch(setCorporateNumber(number));
  };

 

  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "DetailPage");
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };
    var trayArray = [];

    for (let i = 0; i <= this.props.homeItem?.results?.length; i++) {
      for (
        let j = 0;
        j <= this.props.homeItem?.results[i]?.DetailPage?.Trays?.length;
        j++
      ) {
        trayArray.push(this.props.homeItem?.results[i]?.DetailPage?.Trays[j]);
      }
    }
    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    const currentUrl = window.location.pathname;
    let structuredData;
    // Check if the current URL matches a specific pattern
    if (
      currentUrl === "/why-choose-us" ||
      currentUrl === "/why-choose-us/who-we-are"
    ) {
      // Render your script here
      structuredData = (
        <script type="application/ld+json">
          {`{
                  "@context":"https://schema.org",
                  "@type":"Organization",
                  "name":"Ace Hardware Plumbing Services",
                  "legalName":"Ace Plumbing Operating LLC",
                  "url":"https://acehardwareplumbing.com/",
                  "logo":"https://aceplumbing-dev-dss.igxdev.com//Images/Corporate%20Site/Logos/franchise_logo.png",
                  "description":"If you are looking for a better plumbing experience, from transparent pricing to expertise on a range of problems, rely on Ace Hardware Plumbing Services. If you are looking for a licensed plumber with a helpful and friendly attitude, trust Ace Hardware Plumbing Services. Our team has extensive training on water heater installation, troubleshooting water leaks, gas lines, slab leaks, sewer backups and more. We also handle garbage disposal replacement, toilet overflow to faucet installs. Give us a call for upfront pricing and superior expertise!",
                  "mainEntityOfPage":"https://en.wikipedia.org/wiki/Plumber",
                  "parentOrganization":{
                      "@type":"Organization",
                      "name":"Ace Hardware",
                      "url":"https://www.acehardware.com/",
                      "@id":"https://www.wikidata.org/wiki/Q4672981"
                  },
                  "foundingLocation":[
                      {
                        "@type":"Place",
                        "geo":{
                            "@type":"GeoCoordinates",
                            "latitude":"35.5663416",
                            "longitude":"-97.541395"
                        },
                        "name":"Ace Hardware Plumbing Services"
                      }
                  ],
                  "actionableFeedbackPolicy":"https://devplumbing.azurewebsites.net/offices/ace-hardware-plumbing-services-north-oklahoma-city/join-our-team#GetEstimateLink",
                  "knowsAbout":[
                      "plumbing",
                      "slab leak detection",
                      "water heater installation",
                      "water leak",
                      "sewer line repair",
                      "water pressure",
                      "drain cleaning",
                      "gas leak",
                      "gas line repair",
                      "water heater repair",
                      "repiping",
                      "toilet installation",
                      "plumbing repair",
                      "bathroom plumbing",
                      "kitchen plumbing"
                  ],
                  "address":{
                      "@type":"PostalAddress",
                      "streetAddress":"1509 W Britton Rd",
                      "addressLocality":"Oklahoma City",
                      "addressRegion":"OK",
                      "postalCode":"73120",
                      "addressCountry":"USA"
                  },
                  "contactPoint":{
                      "@type":"ContactPoint",
                      "contactType":"customer support",
                      "telephone":"[+1405-785-4550]"
                  },
                  "sameAs":[
                      "https://www.yelp.com/biz/ace-hardware-plumbing-services-oklahoma-city",
                      "https://www.linkedin.com/company/ace-hardware-plumbing-services/",
                      "https://www.facebook.com/AceHardwarePlumbing/",
                      "https://www.bbb.org/us/ok/oklahoma-city/profile/plumbing-and-heating/ace-hardware-plumbing-services-0995-90108607"
                  ]
                }`}
        </script>
      );
    }

    return (
      <>
        {this.props?.homeItem.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                {pageTitle ? `${pageTitle}` : browserSuffix}
              </title>
              <meta
                name="description"
                content={homeItemData[0]?.Description?.Value}
              />
              {structuredData}
            </Helmet>
            <HeaderWrapper
              headerItemData={headerItemData}
              calculateBannerPadding={this.calculateBannerPadding}
              setGlobalCorporateNumber={this.setCorporatePhoneNumber}
              setNumberSelectionStatus={this.setNumberSelectionStatus}
              corporateNumber={this.props.corporatePhoneNumber}
              isNumberSelected={this.props.isNumberSelected}
            />
            <div
              className="whyChooseUs-main"
              style={{
                backgroundImage: `url(${
                  headerItemData[0]?.GlobalSettings?.GeneralPageBackground
                    ?.Value &&
                  `${ingeniuxURL}${headerItemData[0]?.GlobalSettings?.GeneralPageBackground?.Value}`
                })`,
              }}
            >
              {homeItemData[0]?.HeroWithOverlay !== undefined ? (
                <CareersHeroWithOverlayTray
                  BackgroundImage={
                    homeItemData[0]?.HeroWithOverlay?.BackgroundImage?.Value
                  }
                  Heading={homeItemData[0]?.HeroWithOverlay?.Heading?.Value}
                  Subheading={
                    homeItemData[0]?.HeroWithOverlay?.Subheading?.Value
                  }
                  PageLink={homeItemData[0]?.HeroWithOverlay?.Page?.Link}
                  PageUrl={homeItemData[0]?.HeroWithOverlay?.Page?.URL}
                  TextBoxBackgroundColor={
                    homeItemData[0]?.HeroWithOverlay?.TextBoxBackgroundColor
                      ?.Value
                  }
                  TextBoxAlignment={
                    homeItemData[0]?.HeroWithOverlay?.TextBoxAlignment?.Value
                  }
                  Page={homeItemData[0]?.HeroWithOverlay?.Page}
                  Copy={homeItemData[0]?.HeroWithOverlay?.Copy?.Value}
                />
              ) : (
                <>
                  <WhyChooseUsBanner
                    contentData={homeItemData[0]?.Heading?.Value}
                  />
                </>
              )}
              <WhyChooseUsBreadcrumbs
                contentData={homeItemData[0]?.BreadcrumbNavigation}
              />
              {(homeItemData[0]?.Copy?.Value ||
                homeItemData[0]?.AsideImage?.Value) && (
                <CopyAside contentData={homeItemData[0]} />
              )}

              {homeItemData[0]?.Trays?.map((tray, index) => {
                if (
                  Object.keys(corpDynamicTrayList)?.find(
                    (trayName) => trayName === tray?.TrayName
                  )
                ) {
                  let combinedProps;

                  if (
                    tray?.TrayName === "ScheduleandEstimateTabTray" ||
                    tray?.TrayName === "ScheduleandEstimateTray"
                  ) {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                        ...{ franchiseDataToGetEstimate },
                      },
                    };
                  } else if (tray?.TrayName === "ServicesTray") {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{
                          franchiseData:
                            franchiseHomePage && franchiseHomePage[0],
                        },
                      },
                    };
                  } else if (tray?.TrayName === "FindAnOffice") {
                    combinedProps = {
                      ...{
                        ...tray,
                        ...{ zipCodeList: localOfficeZipcodes },
                        ...{ localOfficeList: localOfficeResults },
                      },
                    };
                  } else {
                    combinedProps = { ...tray };
                  }

                  return (
                    <CorpDynamicTrays
                      trayName={tray?.TrayName}
                      contentData={combinedProps}
                      key={index}
                    />
                  );
                }
              })}

              <FooterWrapper
                headerItemData={headerItemData}
                corporateNumber={this.props.corporatePhoneNumber}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  corporatePhoneNumber:
    state?.corporatePhoneNumberReducer?.corporatePhoneNumber,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default withRouter(connect(mapStateToProps)(WhyChooseUsPage));
