import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./index.css";

const MemberProfile = (props) => {
  const {
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = props.cmsDynamicContent;
  return (
    <div className="bio">
      <div className="col-md-3 col-dd-2 photo">
        {props?.contentData?.ProfileImage?.Value && (
          <img
            className="bio6280945478"
            src={`${ingeniuxURL}${props?.contentData?.ProfileImage?.Value}`}
            alt={props?.contentData?.ProfileImage?.AlternateText}
            title={props?.contentData?.ProfileImage?.AlternateText}
            width="300" height="200"
          />
        )}
      </div>
      <div className="col-md-9 col-dd-3 title">
        <h2 className="heading">
          {props?.contentData?.Name?.Value}
          <p> {props?.contentData?.Title?.Value} </p>
        </h2>
      </div>
      <div className="col-dd-7 full-bio">
        <Markup
          content={props?.contentData?.Copy?.Value?.replaceAll(
            "{City}",
            franchiseCity
          )
            ?.replace(
              "{Phone}",
              formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
            )
            ?.replace("{FranchiseName}", franchiseName)
            ?.replace("{State}", franchiseState)
            ?.replace("{StateAbbreviation}", stateAbbr)}
        />
      </div>
    </div>
  );
};

export const FranchiseOurTeam = (props) => {
  const { contentData = {} } = props;
  const { dataforReplaceMethod = {} } = props?.contentData;
  return (
    <div className="our-team-content-wrap">
      <div className="container row">
        <div className="bios-wrap">
          {Array.isArray(contentData?.TeamMemberBioTray) ? (
            contentData?.TeamMemberBioTray?.map((bioData) => {
              return (
                <MemberProfile
                  contentData={bioData}
                  cmsDynamicContent={dataforReplaceMethod}
                />
              );
            })
          ) : (
              <MemberProfile
                contentData={contentData?.TeamMemberBioTray}
                cmsDynamicContent={dataforReplaceMethod}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default FranchiseOurTeam;
