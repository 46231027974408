import React from "react";
import ImageGalleryFullWidth from "./ImageGalleryFullWidth/index";
import ImageGalleryHalfWidth from "./ImageGalleryHalfWidth/ImageGalleryHalfWidthNew";

const ImageGallery = ({ contentData }) => {
  const FullWidth = contentData?.FullWidth?.Value;
  const ImagesPerRow = contentData?.ImagesPerRow?.Value;
  const AddCaptions = contentData?.AddCaptions?.Value;
  return (
    <>
      {ImagesPerRow === "4" ? (
        <ImageGalleryFullWidth
          contentData={contentData}
          ImagesPerRow={ImagesPerRow}
          AddCaptions={AddCaptions}
          FullWidth={FullWidth}
        />
      ) : (
        <ImageGalleryHalfWidth
          contentData={contentData}
          FullWidth={FullWidth}
          ImagesPerRow={ImagesPerRow}
          AddCaptions={AddCaptions}
        />
      )}
    </>
  );
};

export default ImageGallery;
