import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Markup } from "interweave";
import Collapsible from "react-collapsible";
import { BsChevronDown } from "react-icons/bs"; //react-icon
import "./BathroomRRServiceDetails.css";
import { extractPath, formatPhone, getFranchiseDataFromLS, bgPatternTriangles } from "../../config/utility";
import {ingeniuxURL} from "../../config/urls";
import SvgPhoneIcon from "../common/SvgPhoneIcon"

const BathroomRRServiceDetails = ({ contentData, phoneData = {}, franchiseSubServices }) => {
  const { mainPhone, telToDisp, telIcon } = phoneData;
  let location = useLocation();
  const hashValue = location?.hash?.replace(/^#/, "")
  const localStoragePhone = getFranchiseDataFromLS("recentFranchisPhone");
  useEffect(() => {
    if (hashValue) {
      setTimeout(() => {
        const name = document.getElementById(hashValue)
        name.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        window.scrollBy(0, -200);
        // window.scrollTo({ top: name.offsetHeight , left: 0, behavior: 'smooth' });
      }, 500)
    }
  }, [location.hash])

  return (
    <body className="bathroomrr-body">
      <div className="bathroomrrservicedetails-body-wrap" style={{backgroundImage:bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})`}}>
        <section id="content" className="content-section twelve">
          <div className="content-wrap twelve">
            <div className="service-details row br-container">
              <div className="content-left col-sd-12 col-md-3">
                <div className="content-list">
                  <div className="heading toggle-heading headingnormal">{contentData?.ServicesDetail?.LinkSet[0]?.Title}</div>
                  <div className="content">
                    <Markup content={contentData?.ServicesDetail?.RelatedServicesCopy?.Value} />
                    <p>
                      <span>
                        <SvgPhoneIcon attributes={{ width: "16", height: "16", margin: "0 5px 0 0", fill: "#d40029" }} />
                        <a href={
                          localStoragePhone ? `tel:${localStoragePhone}`
                            : `tel:${mainPhone?.Value}`}>
                          {localStoragePhone ? formatPhone(localStoragePhone) : telToDisp?.Value}
                        </a>
                      </span>
                    </p>
                    <ul>
                      {contentData?.ServicesDetail?.LinkSet[0]?.Page?.map(relatedService => {
                        return (
                          <><li><a href={`/${relatedService?.URL}`}> {relatedService?.Name}</a></li></>
                        )
                      })}
                    </ul>
                  </div>

                  <div className="heading toggle-heading headingiphone">
                    <Collapsible
                      trigger={[contentData?.ServicesDetail?.LinkSet[0]?.Title, <BsChevronDown />]}
                    >
                      <div className="content">
                        <Markup content={contentData?.ServicesDetail?.RelatedServicesCopy?.Value} />
                        <p>
                          {/* Just because itâ€™s not listed doesnâ€™t mean that we
                    donâ€™t do it! Check the list below or give us a call for
                    more answers,{" "} */}
                          <span>
                            <i className="fa-solid fa-phone"></i>{" "}
                            <a href="tel:18663496946">866-FIX-MY-HOME</a>
                          </span>
                        </p>
                        <ul>
                          {contentData?.ServicesDetail?.LinkSet[0]?.Page?.map(relatedService => {
                            return (
                              <><li><a href={`/${relatedService?.URL}`}> {relatedService?.Name}</a></li></>
                            )
                          })}
                        </ul>
                      </div>
                    </Collapsible>
                  </div>

                  <div className="heading toggle-heading headingiphone">
                    <Collapsible
                      trigger={[contentData?.ServicesDetail?.LinkSet[1]?.Title, <BsChevronDown />]}
                    >

                      <div className="content">
                        <ul>
                          {contentData?.ServicesDetail?.LinkSet[1]?.Page?.map(relatedPackage => {
                            return (
                              <><li><a href={`/${relatedPackage?.URL}`}> {relatedPackage?.Name}</a></li></>
                            )
                          })}
                        </ul>
                      </div>
                    </Collapsible>
                  </div>

                  <div className="heading toggle-heading headingnormal">{contentData?.ServicesDetail?.LinkSet[1]?.Title}</div>
                  <div className="content">
                    <ul>
                      {contentData?.ServicesDetail?.LinkSet[1]?.Page?.map(relatedPackage => {
                        return (
                          <><li><a href={`/${relatedPackage?.URL}`}> {relatedPackage?.Name}</a></li></>
                        )
                      })}
                    </ul>
                  </div>

                </div>
              </div>
              <div className="blank col-md-1"></div>
              <div className="content-right col-sd-12 col-md-8">
                <div className="accordion">
                  {contentData?.ServicesDetail?.ServicesDetail &&
                    // replaced ServicesDetail with ServicesAbstract below to fix error
                    contentData?.ServicesDetail?.ServicesDetail?.ServicesAbstract?.map(serviceList => {
                      return <div className="title heading" id="shower-and-tub-repair">
                        <Collapsible
                          trigger={[serviceList?.ServiceName?.Value, <BsChevronDown />]}
                          triggerWhenOpen={[<p style={{ color: '#d40029' }} >{serviceList?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                        >
                          <div className="content">
                            {/* doors and windows click here link */}

                            <Markup content={serviceList?.Abstract?.Value} />
                          </div>
                        </Collapsible>
                      </div>
                    })
                  }
                  {
                    contentData?.ServicesDetail?.ServiceName?.Value === "Doors & Windows" ?
                      <>
                        <div className="title heading" id="doors" >

                          <Collapsible
                            open={hashValue === "doors" ? true : false}
                            trigger={[contentData?.ServicesDetail?.ServicesDetail[0]?.Title?.Value, <BsChevronDown />]}
                            triggerWhenOpen={[<p style={{ color: '#d40029' }} >{contentData?.ServicesDetail?.ServicesDetail[0]?.Title?.Value}</p>, <BsChevronDown color="red" />]}
                          >
                            <div className="content">
                              <p>Doors are the gateway into your home and are a critical component of your home's functionality. If you are not pleased with the look of your doors, give us a call to get the job done. The interior and exterior door services we provide add to your home's value and appeal.</p>

                              <p>Installing doors, can be confusing and complicated. If it isn't done properly, you can have air leads or water penetration. Our Craftsmen have the tools and experience to make sure every door in your home is working properly and looks great!</p>

                              <p>For more information on Doors, <a style={{ color: '#d40029' }} href={`${contentData?.ServicesDetail?.ServicesDetail[0]?.SURL}`}>click here</a></p>

                              <p>Trust the Craftsmen at Ace Handyman Services to get the job done right, on budget, and on time!</p>
                            </div>
                          </Collapsible>
                        </div>
                        <div className="title heading" id="windows" >

                          <Collapsible
                            open={hashValue === "windows" ? true : false}
                            trigger={[contentData?.ServicesDetail?.ServicesDetail[1]?.Title?.Value, <BsChevronDown />]}
                            triggerWhenOpen={[<p style={{ color: '#d40029' }} >{contentData?.ServicesDetail?.ServicesDetail[1]?.Title?.Value}</p>, <BsChevronDown color="red" />]}
                          >
                            <div className="content">
                              <p>Whether you have window frame repair needs, or you want to weather proof your windows, our fully insured Craftsmen will provide you the quality work to get the job done right. From repairing cracked and peeling window sills to repairing rotten exterior trim, we have you covered.</p>

                              <p>For more information on windows services <a style={{ color: '#d40029' }} href={`${contentData?.ServicesDetail?.ServicesDetail[1]?.SURL}`}>click here</a></p>

                              <p>If you are experiencing any window trouble reach out to our local office.</p>
                            </div>
                          </Collapsible>
                        </div>
                      </>

                      :

                      franchiseSubServices?.length > 0 ?
                        contentData?.ServicesDetail?.ServiceAbstract?.ServiceName && 
                        franchiseSubServices?.includes(contentData?.ServicesDetail?.ServiceAbstract?.ServiceName) ?
                          <div className="title heading" id="shower-and-tub-repair">
                            <Collapsible
                              open={true}
                              trigger={[contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value, <BsChevronDown />]}
                              triggerWhenOpen={[<p style={{ color: '#d40029' }} >{contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                            >
                              <div className="content">
                                <Markup content={contentData?.ServicesDetail?.ServiceAbstract?.Abstract?.Value} />
                              </div>
                            </Collapsible>
                          </div> :
                          Array.isArray(contentData?.ServicesDetail?.ServiceAbstract) ?

                            contentData?.ServicesDetail?.ServiceAbstract?.filter(subService =>
                              franchiseSubServices?.includes(subService?.ServiceName?.Value))?.map((serviceList,idx) => {
                                const extractedPth = extractPath(serviceList?.SURL).replace("/", "").toLowerCase();

                                return <div className="title heading" id={extractedPth} >

                                  <Collapsible
                                    open={hashValue === extractedPth || (!hashValue && idx === 0) ? true : false}
                                    trigger={[serviceList?.ServiceName?.Value, <BsChevronDown />]}
                                    triggerWhenOpen={[<p style={{ color: '#d40029' }} >{serviceList?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                                  >
                                    <div className="content">
                                      <Markup content={serviceList?.Abstract?.Value} />
                                    </div>
                                  </Collapsible>
                                </div>
                              }
                              )
                            :

                            franchiseSubServices?.includes(contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value) &&
                            // const extractedPth = extractPath(contentData?.ServicesDetail?.ServiceAbstract?.SURL).replace("/", "").toLowerCase();

                            <div className="title heading" id={extractPath(contentData?.ServicesDetail?.ServiceAbstract?.SURL).replace("/", "").toLowerCase()} >

                              <Collapsible
                                open={hashValue === extractPath(contentData?.ServicesDetail?.ServiceAbstract?.SURL).replace("/", "").toLowerCase() ? true : false}
                                trigger={[contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value, <BsChevronDown />]}
                                triggerWhenOpen={[<p style={{ color: '#d40029' }} >{contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                              >
                                <div className="content">
                                  <Markup content={contentData?.ServicesDetail?.ServiceAbstract?.Abstract?.Value} />
                                </div>
                              </Collapsible>
                            </div>

                        :
                        contentData?.ServicesDetail?.ServiceAbstract?.ServiceName ?
                          <div className="title heading" id="shower-and-tub-repair">
                            <Collapsible
                              open={true}
                              trigger={[contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value, <BsChevronDown />]}
                              triggerWhenOpen={[<p style={{ color: '#d40029' }} >{contentData?.ServicesDetail?.ServiceAbstract?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                            >
                              <div className="content">
                                <Markup content={contentData?.ServicesDetail?.ServiceAbstract?.Abstract?.Value} />
                              </div>
                            </Collapsible>
                          </div> :

                          contentData?.ServicesDetail?.ServiceAbstract?.map((serviceList,idx) => {
                            const extractedPth = extractPath(serviceList?.SURL).replace("/", "").toLowerCase();

                            return <div className="title heading" id={extractedPth} >

                              <Collapsible
                                open={hashValue === extractedPth  || (!hashValue && idx === 0) ? true : false}
                                trigger={[serviceList?.ServiceName?.Value, <BsChevronDown />]}
                                triggerWhenOpen={[<p style={{ color: '#d40029' }} >{serviceList?.ServiceName?.Value}</p>, <BsChevronDown color="red" />]}
                              >
                                <div className="content">
                                  <Markup content={serviceList?.Abstract?.Value} />
                                </div>
                              </Collapsible>
                            </div>
                          }
                          )
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default BathroomRRServiceDetails;
