import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

import "./CommunityMattersBreadcrumbs.css";

const CommunityMattersBreadcrumbs = ({contentData}) => {
    return (
        <body className="philanthropy-breadcrumbs">
            <div className="body-wrap">
                <section id="content" className="content-section twelve">
                    <div className="breadcrumbs">
                        <div className="container">
                            <ul>
                            {contentData?.DetailPage?.BreadcrumbNavigation?.map((breadCrum, i, row) => {
                if (i + 1 === row.length) {
                  return (
                    <li><span> {breadCrum?.Name}</span></li>
                )
                } else {
                  return (
                    <li><Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link> {" | "}</li>
                )
                }       
                        })}
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </body >
    );
};

export default CommunityMattersBreadcrumbs;