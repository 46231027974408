export const newStates = [
  {
    City: "Alabama",
    Flag: true,
    State: "Alabama",
    FranchiseName: "Jackson Plumbing, Heating & Cooling",
    PhoneNumber: "(256) 353-0051",
    HomePageLink: {
      LinkName: "View website",
      URL: "https://www.jacksonplumbingheatingandcooling.com/",
    },
    SchedulePageLink: {
      LinkName: "Get An Estimate",
      URL: "https://www.jacksonplumbingheatingandcooling.com/",
    },
    ServiceAreaCities: ["Alabama"],
  },
  {
    City: "Georgia",
    Flag: true,
    FranchiseName: "A-Total Plumbing",
    State: "Georgia",
    PhoneNumber: "(770) 507-5424",
    HomePageLink: {
      LinkName: "View website",
      URL: "https://www.a-totalplumbing.com/",
    },
    SchedulePageLink: {
      LinkName: "Get An Estimate",
      URL: "https://www.a-totalplumbing.com/",
    },
    ServiceAreaCities: ["Georgia"],
  },
  {
    City: "Virginia",
    Flag: true,
    FranchiseName: "Parrish Services",
    PhoneNumber: "(703) 659-6069",
    State: "Virginia",
    HomePageLink: {
      LinkName: "View website",
      URL: "https://www.parrishservices.com/",
    },
    SchedulePageLink: {
      LinkName: "Get An Estimate",
      URL: "https://www.parrishservices.com/form/",
    },
    ServiceAreaCities: ["Virginia"],
  },
];
