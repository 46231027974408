import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./components/Main.js";
import "./styles.css";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducers";
import ErrorBoundary from "./components/common/ErrorBoundary/index.js";
import CannonicalTagProvider from "./Providers/CannonicalTagProvider";
import CannonicalTagWrapper from "./Wrappers/CanonicalTagWrapper.js"
import { BrowserRouter as Router } from "react-router-dom";

// import reducer from "./reducers/reducers.js";
// const store = createStore(rootReducer, applyMiddleware(thunk));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const Spinner = () => {
  return <p>Loading.....</p>;
};

function App() {
  return (
    <Provider store={store}>
      <React.Suspense fallback={<Spinner />}>
        <div className="App">
          <ErrorBoundary>
            
            <CannonicalTagWrapper>
              <CannonicalTagProvider>
              <Main />
              </CannonicalTagProvider>
            </CannonicalTagWrapper>
            
          </ErrorBoundary>
        </div>
      </React.Suspense>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

//https://redux.js.org/introduction/examples
