import React from "react";

import {
  extractPath,
  isExternalUrl,
  getFranchiseDataFromLS,
} from "../../config/utility";
import DynamicLinkList from "./DynamicLinks";
import "./LastFranchiseVisitedRibbon.css";

const LastFranchiseVisitedRibbon = ({ recentFranchise }) => {
  const isPageArray = (Page) => Array.isArray(Page);
  const noBorder = { border: "none" };

  const renderPageData = (Page) => {
    if (isPageArray(Page)) {
      return Page.map((link, index) => {
        const isExternal = isExternalUrl(link?.URL);
        const extractedPth = extractPath(link?.URL);
        return (
          <li key={index}>
            <DynamicLinkList
              pagePath={
                isExternal
                  ? `${link?.URL}`
                  : `${recentFranchise?.SURL}${extractedPth}`
              }
              isExternal={isExternal}
              pageApiUrl={`${recentFranchise?.SURL}${extractedPth}`}
              pageName={link?.Link}
              basePageURL={recentFranchise?.SURL}
            />
          </li>
        );
      });
    }
    const isExternal = isExternalUrl(Page?.URL);
    const extractedPth = extractPath(Page?.URL);

    return (
      <li key={Page?.URL}>
        <DynamicLinkList
          pagePath={
            isExternal
              ? `${Page?.URL}`
              : `${recentFranchise?.SURL}${extractedPth}`
          }
          isExternal={isExternal}
          pageApiUrl={`${recentFranchise?.SURL}${extractedPth}`}
          pageName={Page?.Link}
          basePageURL={recentFranchise?.SURL}
        />
      </li>
    );
  };
  return (
    <>
      {recentFranchise ? (
        <div className="last-yo-local">
          <div className="topbar-wrap row twelve">
            <div className="last-franchise-container">
              <div className="topbar col-sd-12 col-md-12">
                <div
                  className="name yo-local-city"
                  style={
                    recentFranchise?.FranchiseCorporateControl?.FranchiseHeader
                      ?.Page
                      ? ""
                      : { ...noBorder }
                  }
                >
                  <a href={getFranchiseDataFromLS("recentFranchisePath").startsWith("/") ? getFranchiseDataFromLS("recentFranchisePath") : `/${getFranchiseDataFromLS("recentFranchisePath")}`}>
                    Ace Plumbing Services {recentFranchise?.Name}
                  </a>
                </div>
                <div className="local-nav yo-local-nav">
                  <ul>
                    {recentFranchise?.FranchiseCorporateControl?.FranchiseHeader
                      ?.Page &&
                      renderPageData(
                        recentFranchise.FranchiseCorporateControl
                          .FranchiseHeader.Page
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LastFranchiseVisitedRibbon;
