import React from "react";

import TrayRight from "./TrayRight";
import TrayLeft from "./TrayLeft";


const HalfFullDayPackagesImageCopyTray = ({ element }) => {
    
        if (element?.TrayName === "ImageCopyTray") {
            if (element?.Copy?.Value) {
                if (element?.ImageAlignment?.Value === "Right") {
                    return <TrayRight
                        displyGrd={element?.DisplayGrid?.Value}
                        bgColor={element?.BackgroundColor?.Value}
                        img={element?.Image?.Value}
                        heading={element?.Heading?.Value}
                        rightTrayContent={(element?.Copy?.Value)} />
                }
                else if (element?.ImageAlignment?.Value === "Left") {
                    return <TrayLeft
                        CTA={(element?.Page)}
                        displyGrd={element?.DisplayGrid?.Value}
                        bgColor={element?.BackgroundColor?.Value}
                        img={element?.Image?.Value}
                        heading={element?.Heading?.Value}
                        leftTrayContent={(element?.Copy?.Value)} />
                }
            }
        }
    
}

export default HalfFullDayPackagesImageCopyTray;