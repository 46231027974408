import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Page404Banner from "./Page404Banner";
import HeaderWrapper from "../common/HeaderWrapper";
import { fetch404Page } from "../../actions/fetchPage404Data";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  loadApiData,
  saveApiData,
} from "../../config/utility";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import { FindAnOfficeTray } from "../common/Footer";

class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = getResultsArray(this?.props?.localOfficeItem);
    this.props.dispatch(fetch404Page("Resources/404"));

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(
        fetchLocalOfficeData("localoffice/orderby/state")
      );
    }
  }

  render() {
    const homeItemData = getResultsArray(this.props?.item404, "DetailPage");
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = getResultsArray(this?.props?.localOfficeItem);

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");

    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0, len = localOfficeZipcodes.length; i < len; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    let trayArray = [];
    for (let i = 0; i <= this.props.item404?.results?.length; i++) {
      for (
        let j = 0;
        j <= this.props.item404?.results[i]?.HomePage?.Trays?.length;
        j++
      ) {
        trayArray.push(this.props.item404?.results[i]?.HomePage?.Trays[j]);
      }
    }

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...homeItemData[0]?.CorporateHeroTray?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOffices },
      },
    };

    return (
      <>
        <Helmet>
          <title>
             {/* removed "| Ace Hardware Plumbing Services" in title */}
            {pageTitle ? `${pageTitle}` : browserSuffix}
          </title>
          <meta
            name="robots"
            // content={homeItemData[0]?.Description?.Value}
            content = "noindex"
          />

          {/* <!--GA4 Page not found script--> */}
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'IGX_page_not_found'
            });
          `}
          </script>
          {/* <!--End of GA4 Page not found script--> */}
        </Helmet>
        <HeaderWrapper headerItemData={headerItemData} />
        <section className="page-not-found">
          <Page404Banner
            homeBannerContent={homeItemData[0]?.CorporateHeroTray}
            zipCodeList={localOfficeZipcodes}
            localOfficeList={localOfficeResults}
          >
            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate }
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{ ...tray, ...{ franchiseData: franchiseHomePage[0] } },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                } else {
                  combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}
          </Page404Banner>
        </section>
        {/* <div className="findoffice col-">
          <FindAnOfficeTray contentData={combinedPropsFindOffice} />
        </div> */}
        <FooterWrapper headerItemData={headerItemData} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  item404: state.page404Reducer.item404,
  loading: state.page404Reducer.loading,
  error: state.page404Reducer.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,

  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default connect(mapStateToProps)(Page404);
