import React from "react";
import FranchiseReviews from "./index";
import CorpReviews from "../common/ReviewTile";

const ReviewsMaster = ({ contentData }) => {
    return (
        <>
            {contentData?.AddOwnerManagedContent?.Value ?
                <FranchiseReviews contentData={contentData} />
                : <CorpReviews contentData={contentData} />
            }
        </>

    )
}

export default ReviewsMaster;