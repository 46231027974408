import React from 'react';
import { Markup } from 'interweave';

import { ingeniuxURL } from "../../../../config/urls";

const Header = ({phone}) => {
  
    return (
    <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
        <header style={{width:"100%"}}>
            {/* Main Blocl 1 */}
            <div id="alert-bar" style={{display: "block;"}}>
                <div className="home-container fluid max">
                    <div className="row">
                        <div className="col-12">
                            <p>
                                <center>
                                    <a target="_blank" href="/">
                                            <span>Ace Handyman Services always follows CDC best-practices safety guidelines. Masks are no longer in those guidelines. We will ask all customers, when scheduling their work, if they would like us to wear a mask — We want you to be comfortable with how we work in your home..</span>
                                    </a>
                                </center>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Blocl 1 */}
            {/* Main Blocl 2 */}
            <div className="yo-local" style={{display: "none;"}}>
                <div className="topbar-wrap row twelve">
                    <div className="home-container">
                        <div className="topbar col-sd-12 col-md-12">
                            <div className="name yo-local-city"></div>
                            <div className="local-nav yo-local-nav"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Blocl 2 */}
            {/* Main Blocl 3 */}
            <div className="head-wrap home-container row twelve">
                {/* Menu Block one */}
                <div className="logo col-sd-5 col-md-6 col-dd-3">
                        <a href="/">
                        <img src={`${ingeniuxURL}/Images/Corporate%20Site/Logos/franchise_logo.svg`} alt="Ace logo" title="Ace logo" style={{maxWidth: "66%"}} />
                        </a>
                </div>
                {/* Menu Block two */}
                <div className="header-top-wrap col-sd-7 col-md-6 col-dd-9">
                    <div className="header-top-container">
                        <div className="header-top" style={{textAlign: "right"}}>
                            <div className="anchors">
                                <strong>
                                    <a data-tab="book-online" href="/#BookOnlineLink">
                                        <img src={`${ingeniuxURL}/Images/Corporate%20Site/Icons/iconSchedule.svg`} 
                                        style={{maxWidth: "30px", maxHeight:"30px"}} 
                                        alt="Schedule Appointment" 
                                        title="Schedule Appointment" 
                                        width="30" height="30"
                                        />
                                            <span>SCHEDULE APPOINTMENT</span>
                                    </a>
                                </strong>
                                <a data-tab="get-a-quote" href="/#GetEstimateLink">
                                    <img src={`${ingeniuxURL}/Images/Corporate%20Site/Icons/iconQuote.svg`} 
                                    style={{maxWidth: "30px", maxHeight:"30px"}} 
                                    alt="Get an Estimate" 
                                    title="Get an Estimate" 
                                    width="30" height="30"
                                    />
                                        <span>GET AN ESTIMATE</span>
                                </a>
                            </div>
                        <div className="header-phone">
                            <div className="yo-local" style={{display: "none"}}>
                                <span className="yo-local-phone yo-local-href-phone"></span>
                            </div>
                            <div className="yo-corporate">
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                        </div>
                    </div>
            
         
            {/* Menu block three */}
            <div id="page-wrapper">
                <div id="mobile-nav">
                    <i className="fa-solid fa-bars">
                        <div className="whole-content">
                            <div className=" mobile-nav-slide">
                                <div className="content">
                                    <nav className="mobile-nav-slide active">
                                        <ul></ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </i>
                </div>
            </div>
            {/* Menu block three */}
            {/* Menu block five */}
            <nav className="topnav">
                <ul className="first-level">
                    <li>
                        <a href="/why-choose-us">Why Choose Us</a>
                        <div className="dropdown">
                            <div className="home-container">
                                <div className="header col-md-4">
                                    <a href="/why-choose-us">Why Choose Us</a>
                                </div>
                                <div className="dropdown-nav col-sd-12 col-md-8">
                                    <ul>
                                        <li>
                                            <a href="/why-choose-us/our-story">Our Story</a>
                                        </li>
                                        <li>
                                            <a href="/why-choose-us/your-team">Your Team</a>
                                        </li>
                                        <li>
                                            <a href="/why-choose-us/community-matters">Community Matters</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/services">Services</a>
                        <div className="dropdown">
                            <div className="home-container">
                                <div className="header col-md-4">
                                    <a href="/services">Services</a>
                                </div>
                                <div className="dropdown-nav col-sd-12 col-md-8">
                                    <ul>
                                        <li>
                                            <a href="/Services#search-by-room">Search By Room</a>
                                        </li>
                                        <li><a href="/Services#search-by-area">Search By Area</a></li>
                                        <li><a href="/Services#search-by-service">Search By Service</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/packages">Packages</a>
                        <div className="dropdown">
                            <div className="home-container">
                                <div className="header col-md-4">
                                    <a href="/packages">Packages</a>
                                </div>
                                <div className="dropdown-nav col-sd-12 col-md-8">
                                    <ul>
                                        <li>
                                            <a href="/Packages#all">All Packages</a>
                                        </li>
                                        <li>
                                            <a href="/Packages#Interior">Interior Packages</a>
                                            </li>
                                        <li>
                                            <a href="/Packages#Exterior">Exterior Packages</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/offices">Find A Craftsman</a>
                    </li>
                    <li>
                        <a href="/resources">Resources</a>
                    </li>
                    <li>
                        <a href="/careers">Careers</a>
                    </li>
                    <li>
                        <a href="/blog">Blog</a>
                    </li>
                </ul>
            </nav>
            {/* Menu block five */}
            </div>
        </div>

            {/* third Menu Block */}
            </div>
        </header>
    </div>
    );
};

export default Header;

