import React from "react";
import "./BathroomRRBanner.css";
import { ingeniuxURL } from "../../config/urls";

const BathroomRRBanner = ({ contentData }) => {

  return (
    <section id="detailBanner">
      <div className="detail-page-banner"
        style={{
          backgroundImage: contentData?.ServicesDetail?.BannerImage?.Value && `url(${ingeniuxURL}${contentData?.ServicesDetail?.BannerImage?.Value})`
        }}>
        <div className="container">
          <div className="banner-heading-container">
            <div className="paintstroke-left" style={{
              backgroundImage: contentData?.ServicesDetail?.LeftCapPaintStroke?.Value && `url(${ingeniuxURL}${contentData?.ServicesDetail?.LeftCapPaintStroke?.Value})`
            }}></div>
            <h1 className="header" style={{
              backgroundImage:contentData?.ServicesDetail?.MiddlePaintStroke?.Value && `url(${ingeniuxURL}${contentData?.ServicesDetail?.MiddlePaintStroke?.Value})`
            }}>
              {contentData?.ServicesDetail?.Title?.Value}
            </h1>
            <div className="paintstroke-right" style={{
              backgroundImage:contentData?.ServicesDetail?.RightCapPaintStroke?.Value && `url(${ingeniuxURL}${contentData?.ServicesDetail?.RightCapPaintStroke?.Value})`
            }}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BathroomRRBanner;