import React, { useState } from "react";
import "./BasicDetailsForm.css";
import CareerBreadcrumb from '../CareerBreadcrumb'
import stateCodes from 'states-us';
const BasicDetailsForm = ({
  updateFields,
  errors,
  Position,
  Name,
  Address,
  yacr,
  previousAddress,
  yapa,
  EmailAddress,
  PhoneNumber,
  SalaryExpected,
  DateAvailable,
  ReferredBy,
  appliedEarlier,
  appliedDate,
  jobSource,
  knowledgeSkills,
  franchiseName,
  franchiseBaseUrl,
}) => {
  const { firstName, middleName, lastName } = Name;
  const { addressLine1, addressLine2, City, State, zipCode } = Address;
  const {
    previousAddressLine1,
    previousAddressLine2,
    previousCity,
    previousState,
    previousZipCode,
  } = previousAddress;

  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <div className="basic-details">      
      <CareerBreadcrumb franchise={franchiseName} franchiseUrl={franchiseBaseUrl} component="Basic Information" />

      <div className="form-group form-field-separator">
        <h3 className="form-title">Basic Information</h3>
      </div>
      <div className="form-group position">
        <input
          type="text"
          id="position"
          data-type="input-textbox"
          value={Position}
          placeholder={"Which Position Are You Interested In?*"}
          area-label="Position"
          data-component="textbox"
          onChange={(e) => updateFields({ Position: e.target.value })}
        />
        {errors.Position && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            //margin: "0 0 20px 0",
          }}
        >
          {errors.Position}
        </p>}
      </div>
      <div className="form-group form-row" style={{ position: "relative" }}>      
        <input
          type="text"
          id="firstName"
          name="bd-firstname"
          data-type="input-textbox"
          className="first-field"
          size="20"
          placeholder={"First Name*"}
          area-label="First Name"
          data-component="textbox"
          onChange={(e) => updateFields({ firstName: e.target.value }, "Name")}
          //   onFocus={handleFirstNameFocus}
          value={firstName}
        />
        {errors.firstName && (
          <p
            style={{
              position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.firstName && !errors.middleName && !errors.lastName && "Please enter first name"}
            {!errors.firstName && errors.middleName && errors.lastName && "Please enter middle name and last name"}
            {!errors.firstName && errors.middleName && !errors.lastName && "Please enter last name with middle name"}
            {errors.firstName && errors.middleName && !errors.lastName && "Please enter last name"}
            {errors.firstName && errors.middleName && errors.lastName && "Please enter firstname, middlename and lastname"}

          </p>
        )}
      
       <input
          type="text"
          id="middleName"
          name="bd-middlename"
          data-type="input-textbox"
          className="second-field"
          size="20"
          placeholder={"Middle Name*"}
          area-label="Middle Name"
          data-component="textbox"
          onChange={(e) => updateFields({ middleName: e.target.value }, "Name")}
          //   onFocus={handleFirstNameFocus}
          value={middleName}
        />
        <input
          type="text"
          id="lastName"
          name="bd-lastname"
          data-type="input-textbox"
          className="form-textbox validate[required]"
          size="20"
          placeholder={"Last Name*"}
          area-label="Last Name"
          data-component="textbox"
          onChange={(e) => updateFields({ lastName: e.target.value }, "Name")}
          //   onFocus={handleFirstNameFocus}
          value={lastName}
        />
      </div>
      <div className="form-group current-address">
        <input
          type="text"
          id="currentAddressLine1"
          className="form-control"
          placeholder="Current Address Line 1*"
          value={addressLine1}
          onChange={(e) =>
            updateFields({ addressLine1: e.target.value }, "Address")
          }
        />
        {errors.addressLine1 && <p          
          style={{
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0 0 20px 0",
          }}
        >
          {errors.addressLine1}
        </p>}
        <input
          type="text"
          id="currentAddressLine2"
          className="form-control"
          placeholder="Current Address Line 2"
          value={addressLine2}
          onChange={(e) =>
            updateFields({ addressLine2: e.target.value }, "Address")
          }
        />
        <div className="form-group form-row" style={{ position: "relative" }}>
          <input
            type="text"
            id="current-city"
            className="first-field"
            placeholder="Current City*"
            value={City}
            onChange={(e) => updateFields({ City: e.target.value }, "Address")}
          />{errors.City && <p          
            style={{
              position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.City}
          </p>}
          <select
            id="current-state"
            value={State}
            onChange={(e) => updateFields({ State: e.target.value }, "Address")}
          >
            <option value="" disabled selected>
              Current State*
            </option>
            
            {stateCodes.map(state => (
              <option value={state.abbreviation}>{state.name}</option>
            ))}
          </select>{errors.State && <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
        >
          {errors.State}
        </p>}
        </div>
        <div className="form-row" style = {{ position: "relative" }}>
          <input
            type="text"
            id="current-zipcode"
            placeholder="Current ZipCode*"
            className="first-field"
            value={zipCode}
            onChange={(e) =>
              updateFields({ zipCode: e.target.value }, "Address")
            }
          />{errors.zipCode && <p          
            style={{
              position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
            }}
          >
            {errors.zipCode}
          </p>}
          
          <input
            type="text"
            id="current-address-duration"
            placeholder="Years at Current Address*"
            value={yacr}
            onChange={(e) => updateFields({ yacr: e.target.value })}
          />
          {errors.yacr && <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
        >
          {errors.yacr}
        </p>}
        
        </div>
      </div>
      <div className="form-group previous-address">
        <input
          type="text"
          id="previousAddressLine1"
          className="form-control"
          placeholder="Previous Address Line 1"
          value={previousAddressLine1}
          onChange={(e) =>
            updateFields(
              { previousAddressLine1: e.target.value },
              "previousAddress"
            )
          }
        />
        <input
          type="text"
          id="previousAddressLine2"
          className="form-control"
          placeholder="Previous Address Line 2"
          value={previousAddressLine2}
          onChange={(e) =>
            updateFields(
              { previousAddressLine2: e.target.value },
              "previousAddress"
            )
          }
        />
        <div className="form-group form-row">
          <input
            type="text"
            id="previous-city"
            className="first-field"
            placeholder="Previous City"
            value={previousCity}
            onChange={(e) =>
              updateFields({ previousCity: e.target.value }, "previousAddress")
            }
          />
          <select
            id="previous-state"
            value={previousState}
            onChange={(e) =>
              updateFields({ previousState: e.target.value }, "Address")
            }
          >
            <option value="" disabled selected>
              Previous State
            </option>
            {stateCodes.map(state => (
              <option value={state.abbreviation}>{state.name}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <input
            type="text"
            id="previous-zipcode"
            placeholder="Previous ZipCode"
            className="first-field"
            value={previousZipCode}
            onChange={(e) =>
              updateFields(
                { previousZipCode: e.target.value },
                "previousAddress"
              )
            }
          />
          <input
            type="text"
            id="previous-address-duration"
            placeholder="Years at Previous Address"
            value={yapa}
            onChange={(e) => updateFields({ yapa: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group form-row" style={{ position: "relative" }}>
        <input
          type="text"
          id="email"
          className="first-field"
          placeholder="Email*"
          value={EmailAddress}
          onChange={(e) => updateFields({ EmailAddress: e.target.value })}
        />{errors.EmailAddress && <p          
          style={{
            position: "absolute",
              left: 0,
              bottom: "-20px",
              textAlign: "left",
              fontSize: ".70rem",
              lineHeight: "2.1em",
              color: "red",
              margin: "0",
          }}
        >
          {errors.EmailAddress}
        </p>}
        <input
          type="text"
          id="phone-number"
          placeholder="Phone Number*"
          size="20"
          value={PhoneNumber}
          onChange={(e) => updateFields({ PhoneNumber: e.target.value })}
        />{errors.PhoneNumber && <p          
          style={{
            position: "absolute",
            left: "51%",
            bottom: "-20px",
            textAlign: "left",
            fontSize: ".70rem",
            lineHeight: "2.1em",
            color: "red",
            margin: "0",
          }}
        >
          {errors.PhoneNumber}
        </p>}
      </div>
      <div className="form-group form-row">
        <input
          type="text"
          id="salary"
          className="first-field"
          placeholder="Salary Expected"
          value={SalaryExpected}
          onChange={(e) => updateFields({ SalaryExpected: e.target.value })}
        />
        <input
          type="text"
          id="available-date"
          placeholder="Available Date"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          value={DateAvailable}
          onChange={(e) => updateFields({ DateAvailable: e.target.value })}
        />
      </div>
      <div className="form-group">
        <input
          id="referred-by"
          type="text"
          className="form-control"
          placeholder="Referred By"
          value={ReferredBy}
          onChange={(e) =>
            updateFields({ ReferredBy: e.target.value })
          }
        />
      </div>
      <div className="form-group form-row applied-before">
        <div className="first-field">
          <label>Have You Applied With Us Before?</label>
          <div>
            <input
              type="radio"
              id="applied-before-yes"
              name="applied-before"
              value="yes"
              onChange={(e) => {updateFields({ appliedEarlier: e.target.value }); setIsDisabled(false);}}
              checked={appliedEarlier !== "" && appliedEarlier === "yes"}
            />
            <label for="applied-before">Yes</label>
          </div>
          <div>
            <input
              type="radio"
              id="applied-before-no"
              name="applied-before"
              value="no"
              onChange={(e) => {updateFields({ appliedEarlier: e.target.value }); setIsDisabled(true); }}
              checked={appliedEarlier !== "" && appliedEarlier === "no"}
            />
            <label for="applied-before-No">No</label>
          </div>
        </div>
        <input
          type="text"
          id="applied-date"
          className="applied-date"
          placeholder="If So, When?"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          value={appliedDate}
          onChange={(e) => updateFields({ appliedDate: e.target.value })}
          disabled={isDisabled}
        />
      </div>
      <div className="form-group">
        <textarea
          id="job-info-source"
          placeholder="How did you become aware about this job opening?"
          value={jobSource}
          onChange={(e) => updateFields({ jobSource: e.target.value })}
        />
      </div>
      <div className="form-group">
        <textarea
          id="skills"
          placeholder="Highlight your knowledge,skills and abilities which best demonstrate about your qualification?"
          value={knowledgeSkills}
          onChange={(e) => updateFields({ knowledgeSkills: e.target.value })}
        />
      </div>
    </div>
  );
};

export default BasicDetailsForm;
