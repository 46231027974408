import React, {useEffect, useState} from "react";
import { Markup } from 'interweave';
import {useLocation} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import SearchByRoom from "../../Services/SearchByRoom";
import { ingeniuxURL } from "../../../config/urls";
import { tabNames, hashParams, bgPatternTriangles } from "../../../config/utility";
import "./CorpServicesTray.css";

const TabSelector = ({ tabs, currentTab, changeTab, counts }) => {
	return (
		<div className="tab-selector-container">
			<div className="tab-options">
				{tabs.map((tab, key) => {
					return typeof tab === typeof "" ? (
						<button
							className="tab-option"
							key={key}
							onClick={() => {
								changeTab(tab);
							}}
						>
							<h2 tabIndex={0} className={currentTab === tab ? " tabb-underline" : "tabbs-underline"}><span className="small">Search</span>{tab}</h2>
							{counts[key] > 0 && <div>{counts[key]}</div> } 
						</button>
					) : (
						<div
							className="tab-option"
							key={key}
							onClick={() => {
								changeTab(tab);
							}}
						>
                            <span className="small" >Search</span>
							<h2 tabIndex={0} className={currentTab === tab ? " tab-underline" : ""}>{tab}</h2>
							{counts[key] > 0 && <div>{counts[key]}</div>}
						</div>
					);
				})}
			</div>
		</div>
	);
};

const CorpServicesTray = ({ contentData = {} }) => {
    const { franchiseData = {}} = contentData;

    const [tab, setTab] = useState(tabNames.BYROOM);
	const [hash, setHash] = useState(hashParams.BYROOM);
	var corpRoomServices = [];
	for(let i = 0; i < contentData?.Navigation?.Page[0]?.Page?.length; i++){
		corpRoomServices.push(contentData?.Navigation?.Page[0]?.Page[i]?.Name);
	}
	var corpAreaServices = [];
	for(let i = 0; i < contentData?.Navigation?.Page[1]?.Page?.length; i++){
		corpAreaServices.push(contentData?.Navigation?.Page[1]?.Page[i]?.Name);
	}
	var corpServices = [];
	for(let i = 0; i < contentData?.Navigation?.Page[2]?.Page?.length; i++){
		corpServices.push(contentData?.Navigation?.Page[2]?.Page[i]?.Name);
	}
	// console.log("corpRoomServices is: ",corpRoomServices);
	// console.log("passed data is: ",franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControl?.FranchiseServices?.Linkset?.filter(serviceCategory => 
	// 	corpRoomServices?.includes(serviceCategory?.Title)));
	// console.log("linkset is: ", franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControl?.FranchiseServices?.LinkSet);

	
	let location= useLocation();
	 
	useEffect(() => {
		const hashValue = location?.hash?.replace(/^#/, "")
		let tabVal, hashId;
		if ( hashValue === hashParams.BYROOM) {
			tabVal = tabNames.BYROOM
			hashId = hashParams.BYROOM
		} else if (hashValue === hashParams.BYAREA) {
			tabVal = tabNames.BYAREA
			hashId = hashParams.BYAREA
		} else if (hashValue === hashParams.BYSERVICE) {
			tabVal = tabNames.BYSERVICE
			hashId = hashParams.BYSERVICE }
			else {
				tabVal = tabNames.BYROOM
				hashId = ""
			}
		setHash(hashId)
		setTab(tabVal)

		setTimeout(() => {
            const name = document.getElementById(hashId)
            if (window.screen.width > 500) {
                window.scrollTo({ top: name?.offsetTop - 286, left: 0, behavior: 'smooth' });
            } else {
                window.scrollTo({ top: name?.offsetTop - 272, left: 0, behavior: 'smooth' });
            }
        }, 500)
		
	},[location])


    return(
        <div className="body-wrap" style={{ backgroundImage:bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})` }}>
            <section id="content" className="content-section twelve" >
                <div className="content-wrap" >
                    <div className="tabb-line row">						
                            <TabSelector tabs={[tabNames.BYROOM, tabNames.BYAREA, tabNames.BYSERVICE]} currentTab={tab} changeTab={setTab} counts={[0, 0]} />
								{ tab === tabNames.BYROOM && <SearchByRoom currentHash={hash} 
								subData={franchiseData ? franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControlNew?.PlumbingFranchiseServices?.LinkSet?.filter(serviceType => 
								corpRoomServices?.includes(serviceType?.Title)) : contentData?.Navigation?.Page[0]} /> }
                                { tab === tabNames.BYAREA && <SearchByRoom currentHash={hash} subData={franchiseData ? franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControlNew?.PlumbingFranchiseServices?.LinkSet?.filter(serviceType => 
								corpAreaServices?.includes(serviceType?.Title)) : contentData?.Navigation?.Page[1]} /> }
                                { tab === tabNames.BYSERVICE && <SearchByRoom currentHash={hash} subData={franchiseData ? franchiseData?.FranchiseCorporateControl?.FranchiseOwnersControlNew?.PlumbingFranchiseServices?.LinkSet?.filter(serviceType => 
								corpServices?.includes(serviceType?.Title)) : contentData?.Navigation?.Page[2]} /> }
                    </div>
					<div className="services-container row small">
						<Markup content={contentData?.Footnotes?.Value} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CorpServicesTray;