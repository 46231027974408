import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Collapsible from "react-collapsible";
import { BsChevronDown } from "react-icons/bs"; //react-icon

import "./popular-services.css";
import { ingeniuxURL } from '../../config/urls'

const PopularList = ({ listData }) => {
    return (
        <>
            {
                Array?.isArray(listData) ?
                    listData?.map((child, index) => {
                        return <div className="item col-sd-6" key={index}>
                            <a href={`/${child?.Page?.URL}`}>
                                {child?.Image?.Value && <img
                                    src={`${ingeniuxURL}${child?.Image?.Value}`}
                                    alt={child?.Image?.AlternateText}
                                    title={child?.Image?.AlternateText}
                                    width="200" height="100"
                                />}
                                <span>
                                    {child?.Caption?.Value}
                                </span>
                            </a>
                        </div>
                    })
                    :
                    <div className="item col-sd-6">
                        <a href={`/${listData?.Page?.URL}`}>
                            <img
                                src={`${ingeniuxURL}${listData?.Image?.Value}`}
                                alt={listData?.Image?.AlternateText}
                                title={listData?.Image?.AlternateText}
                                width="200" height="100"
                            />
                            <span>
                                {listData?.Caption?.Value}
                            </span>
                        </a>
                    </div>
            }


        </>
    )
}

const PopularServices = (props) => {
    let location = useLocation();

    useEffect(() => {
        const hashValue = location?.hash?.replace(/^#/, "")

        if (hashValue === "local-services") {

            setTimeout(() => {
                const localServices = document.getElementById("local-services")
                localServices.scrollIntoView(true);
                window.scrollBy(0, -192);
                if (window.screen.width < 800) {
                    // changed from - 100 to - 185
                    window.scrollTo({ top: localServices.offsetTop - 185, left: 0, behavior: "smooth" })
                }
            }, 370)
        }

    }, [location])

    return (
        <div id="local-services" className="most-popular-services row" style={{ position: 'static' }}>
            <div className="most-popular-services-container">
                <div className="heading-wrap toggle-heading notiphone">
                    <div className="heading-text">
                        {props?.contentData?.Heading?.Value}
                    </div>
                    <span className="fa fa-angle-down arrow"></span>
                </div>
                <div className="services content notiphone">
                    <PopularList listData={props?.contentData?.ImageLink} />

                </div>

                <Collapsible
                    trigger={<div className="heading-wrap toggle-heading iphoneview" style={{ marginLeft: '20%' }}>
                        <div className="heading-text">{props?.contentData?.Heading?.Value}</div>
                        <span style={{ color: "#fff", fontSize: "22px", marginLeft: "15px" }}>
                            <BsChevronDown style={{ marginTop: "22px" }} />
                        </span>
                    </div>}
                >
                    <div className="services content iphoneview">
                        <PopularList listData={props?.contentData?.ImageLink} />
                    </div>
                </Collapsible>
            </div>
        </div >
    );
};

export default PopularServices;