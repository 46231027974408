import React, { useEffect, useState } from "react";
import axios from "axios";

import Reviews from "../FranchiseCommon/Reviews/Reviews";
import { rateaBizReviewLink, rateaBizReviewsDataLink } from "../../config/urls";

const FranchiseReviews = (props) => {
    const { contentData: { rateaBiz, reviewsTrays } } = props;
    const rabId = rateaBiz?.Value;
    const initState = {
        reviewsData: {},
        error: ""
    }
    const [reviewsData, setReviewsData] = useState(initState);
    const rabReadReviewURL = `${rateaBizReviewLink}${rabId}/summary`;
    const rabWriteReviewURL = `${rateaBizReviewLink}${rabId}/new`;

    //fetch the reviews
    useEffect(() => {
        if (rabId) {
            const rabURL = `${rateaBizReviewsDataLink}${rabId}/reviews.json`;
            const rating = 5;
            const total = 5;
            let count = 0;
            axios.get(rabURL)
                .then(res => {
                    if (res.status === 200) {
                        const filteredTopReviews = res?.data?.reviews?.filter((el) => {
                            if (count < total && el?.rating === rating) {
                                count++;
                                return true;
                            }
                            return false;
                        });
                        setReviewsData(prevState => ({
                            ...prevState,
                            reviewsData: filteredTopReviews
                        }));
                    }
                })
                .catch(err => {
                    if (err?.response?.status > 400) {
                        setReviewsData(prevState => ({
                            ...prevState,
                            error: "Oops! something went wrong in getting the reviews."
                        }));
                    }
                })
        }
    }, [rabId]);

    const dynamicData = {
        reviewsTrays,
        "reviews": reviewsData,
        "readReviewURL": rabReadReviewURL,
        "writeReviewURL": rabWriteReviewURL
    }
    return (
        <>
            <Reviews contentData={dynamicData} />
        </>
    )
}

export default FranchiseReviews;
