import { Markup } from 'interweave';
import React from 'react';

const Footer = ({phone}) => {
  
    return (
         <>
         <footer>
   <div className="foot-wrap">
      <div className="container row twelve">
         <div id="foot-left" className="col-dd-5 col-md-12 col-sd-12">
            <a className="logo" href="index.html">
            <img src="" title=""/>
            </a>
            <div className="call-cta">
               <span>Call us today</span>
               <div className="footer-phone">
                  <div className="yo-local" style={{display: "none"}}>
                  <span className="yo-local-phone yo-local-href-phone"></span>
               </div>
               <div className="yo-corporate"><a href={`tel:${phone}`}>{phone}</a></div>
            </div>
         </div>
      </div>
      <div id="foot-mid" className="col-sd-12 col-md-10 col-dd-6">
         <div className="row">
            <nav className="resources col-md-6 col-dd-6">
               <div className="heading">Resources</div>
               <ul>
                  <li><a href="sell_your_home_checklist">Sell Your Home Checklist</a></li>
                  <li><a href="contractor_checklist">Contractor Checklist</a></li>
                  <li><a href="home_tips_and_tricks">Home Tips &amp; Tricks</a></li>
                  <li><a href="service_promise">Service Promise</a></li>
               </ul>
            </nav>
            <nav className="resources col-md-6 col-dd-6">
               <div className="heading">Ace Handyman Services</div>
               <ul>
                  <li><a href="faqs">FAQs</a></li>
                  <li><a href="partners">Partners</a></li>
                  <li><a href="https://www.acehandymanfranchising.com/" target="_blank">Franchise Opportunities</a></li>
                  <li><a href="careers">Careers</a></li>
                  <li><a href="goals">Goals</a></li>
               </ul>
            </nav>
         </div>
      </div>
      <div id="foot-right" className="col-sd-2 col-md-2 col-dd-1">
         <span className="back-to-top-wrap">
         <img src="" title="Back" />
         <span>BACK<br/>TO<br/>TOP</span>
         </span>
      </div>
      <div id="foot-bottom" className="col-sd-12">
         <div className="copy-wrap col-sd-12 col-md-12 col-dd-9">
            <p>Locally Owned &amp; Independently Operated Franchise.<br/>* Franchise Owners do their best to handle each job with employees.  At times, based on the type and/or size of a job, a sub-contractor may need to be involved.<br/>** Services listed may not be available at all locations.<br/>** All qualified fixture and installation work is referred to our register of insured professionals.<br/><br/><span>© 2022 Ace Handyman Services</span> <span className="divider">|</span> <span>All Rights Reserved</span> <span className="divider">|</span> <span><a href="sitemap.html">Site Map</a> | <a href="privacy_policy.html">Privacy Policy</a> | <a href="terms_and_conditions.html">Terms &amp; Conditions</a> | <a href="DNSMPI.html">Do Not Sell My Personal Information</a></span></p>
         </div>
         <div className="social-wrap col-sd-12 col-md-12 col-dd-3">
            <div id="social-wrap">
               <ul className="social-links">
                  <li className="social" id="facebook-f"><a href="https://www.facebook.com/AceHandymanSvcs" target="_blank"><i className="fab fa-facebook-f">facebook-f</i></a></li>
                  <li className="social" id="twitter"><a href="https://twitter.com/AceHandymanSvcs" target="_blank"><i className="fab fa-twitter">twitter</i></a></li>
                  <li className="social" id="youtube"><a href="https://www.youtube.com/acehandymanservices" target="_blank"><i className="fab fa-youtube">youtube</i></a></li>
                  <li className="social" id="pinterest-p"><a href="https://www.pinterest.com/acehandymanservices" target="_blank"><i className="fab fa-pinterest-p">pinterest-p</i></a></li>
                  <li className="social" id="linkedin"><a href="https://www.linkedin.com/company/ace-handyman-services-inc/" target="_blank"><i className="fab fa-linkedin">linkedin</i></a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   </div>
</footer>
         </>
    );
};

export default Footer;

