import axios from "axios";
import {handleErrorBoundary,startHandleErrorBoundary,endHandleErrorBoundary, fetchLastVistedFranchisePageRequest, fetchLastVistedFranchisePageSuccess, fetchLastVistedFranchisePageError } from "./action";
import { ingeniuxURL } from "../config/urls";

export function fetchLastVistedFranchise(pageLink) {
  return dispatch => {
    dispatch(fetchLastVistedFranchisePageRequest());

    axios
    .get(
      `${ingeniuxURL}api/page?path=${pageLink}`
      )
      .then(response => {
        dispatch(fetchLastVistedFranchisePageSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchLastVistedFranchisePageError(error));
      });
  };
}
