import React from "react";
import "./FranchiseEstimateSubmissionAddressWrap.css";

const FranchiseEstimateSubmissionAddressWrap = (props) => {
    return (
        <div className="franchise-estimate-submission-address-wrap">
            <h3>Ace Hardware Plumbing {props?.contentData?.Name}</h3>
            <p>  {props?.contentData?.FranchiseCorporateControl?.Address?.Value}<br />
                {props?.contentData?.FranchiseCorporateControl?.City?.Value}, {props?.contentData?.FranchiseCorporateControl?.StateAbbreviation?.Value} {props?.contentData?.FranchiseCorporateControl?.ZipCode?.Value}
                <br /> </p>
            <div><span className="phone-number simpli-btn simpli-call" id="loc:8897099629"><a href={`tel:${props?.contentData?.FranchiseCorporateControl?.PhoneNumber?.Value}`}>{props?.contentData?.FranchiseCorporateControl?.PhoneNumber?.Value}</a></span></div>
        </div>
    );
};

export default FranchiseEstimateSubmissionAddressWrap;
