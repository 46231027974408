import React from 'react';
import CorpLeftIconPlacement from './CorpLeftIconPlacement/CorpLeftIconPlacement';
import CorpTopIconPlacement from './CorpTopIconPlacement/CorpTopIconPlacement';

// import { ingeniuxURL } from '../../../config/urls'
// import ImageCopyTrayRightAlignment from './ImageCopyTrayRightAlignment/ImageCopyTrayRightAlignment';
// import ImageCopyTrayLeftAlignment from './ImageCopyTrayLeftAlignment/ImageCopyTrayLeftAlignment';

const CorpIconTray = ({ contentData = {} }) => {
    const { IconPlacement } = contentData;
    return (
        <>
            {
                IconPlacement?.Value === "Left" ? <CorpLeftIconPlacement contentData={contentData} /> :
                    <CorpTopIconPlacement contentData={contentData} />
            }
        </>
    );
};

export default CorpIconTray;