import axios from "axios";
import {handleErrorBoundary, fetchIndividualBlogRequest, fetchIndividualBlogSuccess, fetchIndividualBlogError } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchIndividualBlog(pageLink) {
  return dispatch => {
    dispatch(fetchIndividualBlogRequest());
    axios
      .get(
        `${ingeniuxURL}api/page?path=${pageLink}`
      )
      .then(response => {
        dispatch(fetchIndividualBlogSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchIndividualBlogError(error));
      });
  };
}
