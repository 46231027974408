import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../../../config/urls";
import "./CorpImageCopyTrayRightAlignment.css";
import "../index.css";

const viewPortWidth = window.screen.width;

const NewCorpImageCopyTrayRightAlignment = ({ contentData }) => {
  const isVideo = false;
  const youtubeUrl = "https://www.youtube.com/embed/CK1J_54KEPk";
  return (
    <section
      className="changing bg-grey twelve two img-copy-tray"
      style={{
        background:
          contentData?.BackgroundColor?.Value === "Gray"
            ? "#efeff0"
            : contentData?.BackgroundColor?.Value === "White"
            ? "#fff"
            : contentData?.BackgroundColor?.Value === "Gray_Gradient"
            ? "linear-gradient(to bottom,#fff 0,#d9d9d9 100%)"
            : "none",
      }}
    >
      <div className="container container-1040">
        <div className="row image-tray-container">
          <div
            className="tray-text-right"
            style={{
              flexBasis: `${
                contentData?.DisplayGrid?.Value == "50-50" ? "50%" : "70%"
              }`,
            }}
          >
            <h3>{contentData?.Heading?.Value}</h3>
            <Markup content={contentData?.Copy?.Value} />
            {contentData?.Page && (
              <a className="btn" target="_blank" href={contentData?.Page?.URL}>
                {contentData?.Page?.Link}
              </a>
            )}
          </div>
          <div
            className="tray-img"
            style={{
              boxShadow: `${
                contentData?.AddShadow?.Value === "True"
                  ? "0 4px 10px rgb(0 0 0 / 50%)"
                  : "none"
              }`,
              flexBasis: `${
                contentData?.DisplayGrid?.Value == "50-50" ? "50%" : "30%"
              }`,
            }}
          >
            {isVideo ? (
              <iframe
                className="image-copy-Tray"
                src={youtubeUrl}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="custom"
              ></iframe>
            ) : (
              contentData?.Image?.Value && (
                <img
                  className="lazy loaded"
                  data-src="images/home-maintenance.jpg"
                  alt={contentData?.Image?.AlternateText}
                  title={contentData?.Image?.AlternateText}
                  loading="lazy"
                  src={`${ingeniuxURL}${contentData?.Image?.Value}`}
                  data-ll-status="loaded"
                  width="200"
                  height="150"
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewCorpImageCopyTrayRightAlignment;
