import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHomePage } from "../../actions/fetchData";
import ScheduleItGetEstimate from "../common/ScheduleItGetEstimate";
import BathroomRRBanner from "./BathroomRRBanner";
import BathroomRRBreadcrumbs from "./BathroomRRBreadcrumbs";
import BathroomRRServiceDetails from "./BathroomRRServiceDetails";
import BathroomRRServiceSpecific from "./BathroomRRServiceSpecific";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  ExtractingFirstPath,
  getResultsArray,
  removeExtraPath,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  getFranchiseDataFromLS,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { FindAnOfficeTray } from "../common/Footer";
import Page404 from "../page-404";
import axios from 'axios';
import { ingeniuxURL } from "../../config/urls"

class BathroomRefreshRepairPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      // added this for localized service detail pages (to show localized subservices for a service type)
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const serviceApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    const firstPath = ExtractingFirstPath(serviceApiPath);

    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;

    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");

    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }

    if (serviceApiPath && firstPath !== "/acehandymandss") {
      this.props.dispatch(fetchHomePage(serviceApiPath));

      if (
        (this.state.globalSiteCrl?.length === 0 ||
          this.state.globalSiteCrl === undefined) &&
        headerItemData?.length === 0
      ) {
        this.props.dispatch(fetchHeaderPage("x4878"));
      }
    } else {
      const removingExtraPath = removeExtraPath(serviceApiPath);
      this.props.dispatch(fetchHomePage(removingExtraPath));

      if (
        (this.state.globalSiteCrl?.length === 0 ||
          this.state.globalSiteCrl === undefined) &&
        headerItemData?.length === 0
      ) {
        this.props.dispatch(fetchHeaderPage("x4878"));
      }
    }

    // added this for service detail pages localization
    if (recentFranchisePath) {
      // this.props.dispatch(fetchFranchiseHome(recentFranchise));

      const localStateSURL =
        this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
      //then load the franchisehomepage api again.

      //scenario 2 - in else block if the cached data and the visited franchise SURLS
      //are no the same, removed the cached data from the Session storage and
      //fetch the api again.

      if (
        (this.state.franchiseHomeData?.length === 0 ||
          this.state.franchiseHomeData === undefined) &&
        franchiseHomePage?.length === 0
      ) {
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      } else if (
        this.state.franchiseHomeData &&
        localStateSURL?.replace(rgxforSlashes, "") !==
        recentFranchisePath?.replace(rgxforSlashes, "")
      ) {
        deleteItemFromSS("franchiseHomeData");
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.link !== nextProps.link) {
      this.props.dispatch(fetchHomePage(nextProps.link));
      window.scrollTo(0, 0);
    }
  }
 
  render() {
    const homeItemData = getResultsArray(
      this.props?.homeItem,
      "ServicesDetail"
    );
    const resultsData = getResultsArray(this.props?.homeItem);

    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    // changed from franchiseHomePage to franchiseHomeData
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");
    // added for localization
    let franchiseHomePage = loadApiData("franchiseHomeData");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        // console.log(
        //   localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        //     apiFranchisePath?.replace(rgxforSlashes, "")
        // );
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");

    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    let combinedProps;
    combinedProps = {
      ...{
        ...homeItemData[0]?.ScheduleandEstimateTabTray,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
        ...{ franchiseDataToGetEstimate }
      },
    };

    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...headerItemData[0]?.Footer?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
      },
    };

    const mainPhone = headerItemData && headerItemData[0]?.MainTelephoneNumber;
    const telToDisp =
      headerItemData && headerItemData[0]?.TelephoneNumberDisplay;
    const pageTitle = homeItemData[0]?.Title?.Value;
    const phoneData = { telToDisp, mainPhone };
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;

    var franchiseSubServicesArray = [];
    for (
      var i = 0;
      i <
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseOwnersControlNew
        ?.PaintingFranchiseServices?.LinkSet?.length;
      i++
    ) {
      if (
        franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseOwnersControlNew
          ?.PaintingFranchiseServices?.LinkSet[i]?.Title ===
        resultsData[0]?.ServicesDetail?.ServiceName?.Value
      ) {
        if (
          Array.isArray(
            franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
              ?.FranchiseOwnersControlNew?.PaintingFranchiseServices?.LinkSet[i]?.Page
          )
        ) {
          for (
            var j = 0;
            j <
            franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
              ?.FranchiseOwnersControlNew?.PaintingFranchiseServices?.LinkSet[i]?.Page[j];
            j++
          ) {
            franchiseSubServicesArray.push(
              franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
                ?.FranchiseOwnersControlNew?.PaintingFranchiseServices?.LinkSet[i]?.Page[j]
                ?.Name
            );
          }
        } else {
          franchiseSubServicesArray.push(
            franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
              ?.FranchiseOwnersControlNew?.PaintingFranchiseServices?.LinkSet[i]?.Page
              ?.Name
          );
        }
      }
    }

    return (
      <>
        {homeItemData.length === 0 &&
          this.props?.homeItem?.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                 {/* removed "| Ace Hardware Plumbing Services" in title */}
                {pageTitle ? `${pageTitle}` : browserSuffix}
              </title>
              <meta
                name="description"
                content={homeItemData[0]?.Description?.Value}
              />
            </Helmet>
            <HeaderWrapper headerItemData={headerItemData} />

            <BathroomRRBanner contentData={resultsData[0]} />
            <BathroomRRBreadcrumbs contentData={resultsData[0]} />
            <BathroomRRServiceSpecific contentData={resultsData[0]} zipCodeList={localOfficeZipcodes} localOfficeList={localOfficeResults} />
            <BathroomRRServiceDetails
              contentData={resultsData[0]}
              phoneData={phoneData}
              franchiseSubServices={franchiseSubServicesArray}
            />
            {/* <ScheduleItGetEstimate contentData={combinedProps} />
            <div className="findoffice col-">
              <FindAnOfficeTray contentData={combinedPropsFindOffice} />
            </div> */}

            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate }
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{ ...tray, ...{ franchiseData: franchiseHomePage && franchiseHomePage[0] } },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                } else {
                  combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}
            <FooterWrapper />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
});

export default withRouter(connect(mapStateToProps)(BathroomRefreshRepairPage));
