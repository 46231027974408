import React from "react";
import { ingeniuxURL } from "../../../config/urls";
import { isExternalUrl } from "../../../config/utility";
import "./FranchiseEstimateSubmissionMap.css";

const FranchiseEstimateSubmissionAddressMap = (props) => {
    const serviceMap = props?.contentData?.FranchiseCorporateControl?.ServicesMap;
    const locationMapSource = (url) => {
        let imgSrc;
        if (!url) return;
        if (isExternalUrl(url)) {
            return imgSrc = url;
        }
        return imgSrc = `${ingeniuxURL}${url}`
    }

    return (
        <figure className="franchise-estimate-submission-map">
            {serviceMap?.Name === "ServicesMap" && (
                !serviceMap?.URL ? "Loading..."
                    :
                    <img src={locationMapSource(serviceMap?.URL)} width="500" height="400" />
            )}
        </figure>
    );
};

export default FranchiseEstimateSubmissionAddressMap;
