import axios from "axios";
import {handleErrorBoundary, fetchBlogPageRequest, fetchBlogPageSuccess, fetchBlogPageError } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchBlogPage(pageLink) {
  return dispatch => {
    dispatch(fetchBlogPageRequest());
    axios
      .get(
        `${ingeniuxURL}api/page?path=${pageLink}`
      )
      .then(response => {
        dispatch(fetchBlogPageSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchBlogPageError(error));
      });
  };
}
