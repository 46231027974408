import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchBlogPage } from "../../actions/fetchBlogPage";
import { fetchBlogData } from "../../actions/fetchBlogData";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchIndividualBlog } from "../../actions/fetchIndividualBlog";
import { setCorporateNumber } from "../../actions/setCorporatePhoneNumber";
import BlogData from "../../constants/BlogData.json";
import Blog from "./Blog";
import BlogAside from "./BlogAside";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getFranchiseDataFromLS,
  getMetaAndTileTags,
} from "../../config/utility";
import {
  extractingLastPath,
  getResultsArray,
  extractingSTLPath,
  extractingTTLPath,
  extractingFTLPath,
  loadApiData,
  saveApiData,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import "./index.css";

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryState: "",
      authorState: "",
      yearState: "",
      individualPostState: "",
      pageState: "",
      searchInputState: "",
      globalSiteCrl: loadApiData("x31"),
      recentBlogPosts: [],
    };
  }

  // anywhere we have 'blogindex' in the fetchBlogData api, we are using the AceBlogIndex api,
  // which is used for filtering blogs based on year, category, and author
  // anywhere we have 'search', in the fetchBlogData api, we are using the AceContentSearch api,
  // which is used for filtering blogs based on search bar keyword but also just to get all
  // the blogs there possibly are
  componentDidMount() {
    window.scrollTo(0, 0);
    const serviceApiPath = this.props?.apiRef?.apiPath
      ? this.props?.apiRef?.apiPath
      : this.props?.apiRef;
    this.props.dispatch(fetchBlogPage("/Blog"));

    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }

    // path would be something like blog/category/backyard, for example
    if (extractingSTLPath(serviceApiPath) === "category") {
      this.setState({ categoryState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `categoryName=${extractingLastPath(serviceApiPath)}`
        )
      );
    }

    // path would be something like blog/author/danielle.bergstein, for example
    if (extractingSTLPath(serviceApiPath) === "author") {
      this.setState({ authorState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `author=${extractingLastPath(serviceApiPath)}`
        )
      );
    }

    // path would be something like blog/search/four/page/2, for example
    if (
      extractingSTLPath(serviceApiPath) === "search" &&
      extractingFTLPath(serviceApiPath) === "page"
    ) {
      this.setState({ searchInputState: extractingLastPath(serviceApiPath) });
      this.setState({ pageState: extractingTTLPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "search",
          `filters=type%3DBlogPost&query=${extractingLastPath(
            serviceApiPath
          )}&page=${extractingTTLPath(serviceApiPath)}`
        )
      );
    }

    // path would be something like blog/search/four, for example
    if (extractingSTLPath(serviceApiPath) === "search") {
      this.setState({ searchInputState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "search",
          `filters=type%3DBlogPost&query=${extractingLastPath(serviceApiPath)}`
        )
      );
    }
    // path would be something like blog/year/2020, for example
    else if (extractingSTLPath(serviceApiPath) === "year") {
      this.setState({ yearState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `startDate=${extractingLastPath(
            serviceApiPath
          )}-01-01&endDate=${extractingLastPath(serviceApiPath)}-12-31`
        )
      );
    }
    // path would be something like blog/year/2020/page/2, for example
    else if (
      extractingSTLPath(serviceApiPath) === "page" &&
      extractingFTLPath(serviceApiPath) === "year"
    ) {
      this.setState({ yearState: extractingTTLPath(serviceApiPath) });
      this.setState({ pageState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `startDate=${extractingTTLPath(
            serviceApiPath
          )}-01-01&endDate=${extractingTTLPath(serviceApiPath)}-12-31
       &page=${extractingLastPath(serviceApiPath)}`
        )
      );
    }
    // path would be something like blog/category/backyard/page/2, for example
    else if (
      extractingSTLPath(serviceApiPath) === "page" &&
      extractingFTLPath(serviceApiPath) === "category"
    ) {
      this.setState({ categoryState: extractingTTLPath(serviceApiPath) });
      this.setState({ pageState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `categoryName=${extractingTTLPath(
            serviceApiPath
          )}&page=${extractingLastPath(serviceApiPath)}`
        )
      );
    }
    // path would be something like blog/author/danielle.bergstein/page/2, for example
    else if (
      extractingSTLPath(serviceApiPath) === "page" &&
      extractingFTLPath(serviceApiPath) === "author"
    ) {
      this.setState({ authorState: extractingTTLPath(serviceApiPath) });
      this.setState({ pageState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "blogindex",
          `author=${extractingTTLPath(
            serviceApiPath
          )}&page=${extractingLastPath(serviceApiPath)}`
        )
      );
    }
    // path would be something like blog/page/2, for example
    else if (extractingSTLPath(serviceApiPath) === "page") {
      this.setState({ pageState: extractingLastPath(serviceApiPath) });
      this.props.dispatch(
        fetchBlogData(
          "search",
          `filters=type%3DBlogPost&sort=DatePublished%3Ddec&resultsPerPage=15&page=${extractingLastPath(
            serviceApiPath
          )}`
        )
      );
    }
    // path would be blog. this is the blog page when we first open it up and sorting the blog posts
    // in descending order so that the most recent blog post shows up first
    else {
      this.props.dispatch(
        fetchBlogData(
          "search",
          "filters=type%3DBlogPost&resultsPerPage=15&sort=DatePublished%3Ddec"
        )
      );
    }

    // path would be something like blog/BlogTitle, for example
    if (extractingSTLPath(serviceApiPath) === "blog") {
      this.setState({ individualPostState: serviceApiPath });
      this.props.dispatch(fetchIndividualBlog(serviceApiPath));
    }

    //fetching the recent blogPosts which sorted by the date updated
    const recentBlogData = async () => {
      try {
        const blogPostsData = BlogData;
        const blogPostsSortedByDate = blogPostsData?.results.sort(
          (blogPostA, blogPostB) => {
            return (
              new Date(blogPostB?.DatePublished?.Value) -
              new Date(blogPostA?.DatePublished?.Value)
            );
          }
        );

        this.setState((prevState) => ({
          ...prevState,
          recentBlogPosts: blogPostsSortedByDate?.slice(0,5),
        }));
      } catch (err) {
        console.log(err);
      }
    };
    recentBlogData();
  }

  calculateBannerPadding = () => {
    this.setState({ calculateBannerPadding: true });
  };

  setCorporatePhoneNumber = (number) => {
    this.props.dispatch(setCorporateNumber(number));
  };

  render() {
    const resultsData = getResultsArray(this.props.homeItem);
    const individualResultsData = getResultsArray(this.props.individualItem);
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );

    //Reading the local storage data to the 'headerItemData
    let headerItemData = loadApiData("x31");

    // saving GlobalSiteControl to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }

    const franchiseRedStrap = getFranchiseDataFromLS("recentFranchisePath");
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    const browserPageTitle =
      getMetaAndTileTags(window.location.pathname)?.title || "";
    const pageMetaTag = getMetaAndTileTags(window.location.pathname)?.meta;
    const metaTagDescription =
      this.state?.individualPostState && window.location.pathname !== "/blog"
        ? pageMetaTag
        : `${getMetaAndTileTags(window.location.pathname)?.meta}`;
    return (
      <>
        <Helmet>
          <title>{`${browserPageTitle} ${
            !browserPageTitle ? "" : "-"
          } Blog | ${browserSuffix}`}</title>
        </Helmet>
        <HeaderWrapper
          headerItemData={{
            ...headerItemData,
            metaDescription: metaTagDescription,
          }}
          calculateBannerPadding={this.calculateBannerPadding}
          setGlobalCorporateNumber={this.setCorporatePhoneNumber}
          corporateNumber={this.props.corporatePhoneNumber}
        />
        <div className={franchiseRedStrap ? `blogBox mrgntop` : `blogBox`}>
          <div className="blogBoxOne">
            {/* the Blog component is the left side of the Blog page that keeps refreshing with data
            contentData is the data from the AceContentSearch api, which holds data of all blog posts
            */}

            <Blog
              contentData={this.props.bItem}
              categoryName={this.state?.categoryState}
              authorName={this.state?.authorState}
              individualPost={this.state?.individualPostState}
              archiveYear={this.state?.yearState}
              page={this.state?.pageState}
              searchInput={this.state?.searchInputState}
              individualPostContent={individualResultsData[0]}
              bookOnlineLink={headerItemData[0]?.Header?.Page[0]?.Name}
              breadcrumbs={resultsData[0]?.BlogIndex}
            />
          </div>

          <div className="blogBoxTwo">
            {/* replaced BlogCategories with Root */}
            {/* BlogAside is the right side of the Blog page
            it uses data from the AcePage api. the endpoint is just 'blog'
            from this, we get blog category list data and recent blog post data
            from this api, we can also look at blog data from a specific blog post.
            for example: blog/four-city-tour-reflections
            */}

            <BlogAside
              headerItemData={headerItemData}
              contentData={
                resultsData[0]?.BlogIndex?.TaxonomyNavigation?.BlogCategories
                  ?.Children?.Category
              }
              recentData={this.state?.recentBlogPosts}
            />
            {/* this data is not sufficient to show the
              recent 5 blog posts recentData={resultsData[0]?.BlogIndex?.Navigation?.Page} */}
          </div>
        </div>
        <FooterWrapper
          headerItemData={headerItemData}
          corporateNumber={this.props.corporatePhoneNumber}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.blogPageReducer.item,
  loading: state.blogPageReducer.loading,
  error: state.blogPageReducer.error,
  bItem: state.blogReducer.blogItem,
  corporatePhoneNumber:
    state?.corporatePhoneNumberReducer?.corporatePhoneNumber,
  bLoading: state.blogReducer.blogLoading,
  bError: state.blogReducer.blogError,
  headerItem: state.headerReducer.headerItem,
  individualItem: state.individualBlogReducer.item,
  individualLoading: state.individualBlogReducer.loading,
  individualError: state.individualBlogReducer.error,
});

export default withRouter(connect(mapStateToProps)(BlogPage));
