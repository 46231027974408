import axios from "axios";
import {handleErrorBoundary,
  fetchPage404Request,
  fetchPage404Success,
  fetchPage404Error,
} from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetch404Page(pageLink) {
  return (dispatch) => {
    dispatch(fetchPage404Request());
    axios
      .get(`${ingeniuxURL}api/page?path=${pageLink}`)
      .then((response) => {
        dispatch(fetchPage404Success(response.data));
      })
      .catch((error) => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchPage404Error(error));
      });
  };
}
