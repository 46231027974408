import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import "./YourTeamContentWrap.css";

const YourcontentDataWrap = ({ contentData }) => {
  return (
    <body className="meet-the-team-content">
      <div className="body-wrap">
        <section id="content" className="content-section twelve">
          <div className="content-wrap">
            <div className="team-container row">
              <div className="bios-wrap">
                <div className="bio">
                  <div className="col-md-6 col-dd-2 photo">
                    {contentData?.ProfileImage?.Value && (
                      <img
                        className="bio6280935486"
                        src={`${ingeniuxURL}${contentData?.ProfileImage?.Value}`}
                        alt={contentData?.ProfileImage?.AlternateText}
                        title={contentData?.ProfileImage?.AlternateText}
                        width="250" height="150"
                      />
                    )}
                  </div>
                  <div className="col-md-6 col-ddd-3 title">
                    <h2 className="heading">
                      {contentData?.Name?.Value}
                      <p>{contentData?.Title?.Value}</p>
                    </h2>
                  </div>
                  <div className="col-ddd-7 full-bio">
                    <Markup content={contentData?.Copy?.Value} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default YourcontentDataWrap;