import axios from "axios";
import {handleErrorBoundary, fetchFranchiseDetailPageRequest, fetchFranchiseDetailPageSuccess, fetchFranchiseDetailPageError } from "./action";
import { ingeniuxURL } from "../config/urls";

export  function fetchFranchiseDetailPage(pageLink) {
  return dispatch => {
    dispatch(fetchFranchiseDetailPageRequest());
    axios.get(
      `${ingeniuxURL}api/page?path=${pageLink}`
    )
      .then(response => {
        dispatch(fetchFranchiseDetailPageSuccess(response.data));
      })      
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchFranchiseDetailPageError(error));
      });
  };
}
