import React from "react"
import Modal from "react-modal";
import { BsXLg } from "react-icons/bs";

import "./modal.css";


Modal.setAppElement("#root");


export const ModalWindow = ({ show, handleModalToggle, size = {}, children }) => {
  const { modalWidth, modalHeight, fitContent } = size;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  
const setWidth = () => {
  let vWid;
  if (vw <= 600) {
      return vWid = "90%"
  }
  return vWid = modalWidth;
}

const setHeight = () => {
  let vHg;
  if (vw <= 600 && fitContent) {
      return vHg = "fit-content"
  }
  return vHg = modalHeight;
}

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,.8)",
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "40%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0",
      boxShadow: "0 0 16px black",
      overflow: "visible",
      height: setHeight(),
      width: setWidth(),
    },
  };

  const closeBtn = {
    position: "absolute",
    top: "-35px",
    right: "0",
    fill: "#fff",
    cursor: "pointer"
  };

  const contentStyle = {
    overflow: "auto",
    // changed height from 100 to 101 percent so that scrollbar only shows when necessary
    height: "101%"
  }

  return (

    <Modal isOpen={show} onRequestClose={handleModalToggle} style={customStyles} >
      <BsXLg size={30} style={closeBtn} onClick={handleModalToggle} />
      <div style={contentStyle}>
        {children}
      </div>
    </Modal>

  )
}

export default ModalWindow;