import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_HEADER_DATA_REQUEST,
  FETCH_HEADER_DATA_SUCCESS,
  FETCH_HEADER_DATA_ERROR,
  FETCH_BLOG_DATA_REQUEST,
  FETCH_BLOG_DATA_SUCCESS,
  FETCH_BLOG_DATA_ERROR,
  FETCH_BLOG_PAGE_REQUEST,
  FETCH_BLOG_PAGE_SUCCESS,
  FETCH_BLOG_PAGE_ERROR,
  FETCH_PAGE_404_REQUEST,
  FETCH_PAGE_404_SUCCESS,
  FETCH_PAGE_404_ERROR,
  FETCH_FRANCHISE_HOME_REQUEST,
  FETCH_FRANCHISE_HOME_SUCCESS,
  FETCH_FRANCHISE_HOME_ERROR,
  FETCH_FRANCHISE_DETAILPAGE_REQUEST,
  FETCH_FRANCHISE_DETAILPAGE_SUCCESS,
  FETCH_FRANCHISE_DETAILPAGE_ERROR,
  FETCH_LOCAL_OFFICE_REQUEST,
  FETCH_LOCAL_OFFICE_SUCCESS,
  FETCH_LOCAL_OFFICE_ERROR,
  FETCH_INDIVIDUAL_BLOG_REQUEST,
  FETCH_INDIVIDUAL_BLOG_SUCCESS,
  FETCH_INDIVIDUAL_BLOG_ERROR,
  FETCH_LAST_VISTED_FRANCHISE_REQUEST,
  FETCH_LAST_VISTED_FRANCHISE_SUCCESS,
  FETCH_LAST_VISTED_FRANCHISE_ERROR,
  START_HANDLE_ERRORBOUNDARY,
  END_HANDLE_ERRORBOUNDARY,
  HANDLE_ERRORBOUNDARY,
  SET_CORPORATE_PHONE_NUMBER,
} from "./actionType";

export function fetchDataRequest() {
  return {
    type: FETCH_DATA_REQUEST,
  };
}

export function fetchDataSuccess(item) {
  return {
    type: FETCH_DATA_SUCCESS,
    item,
  };
}

export function fetchDataError(error) {
  return {
    type: FETCH_DATA_ERROR,
    payload: { error },
  };
}

export function fetchHeaderDataRequest() {
  return {
    type: FETCH_HEADER_DATA_REQUEST,
  };
}

export function fetchHeaderDataSuccess(item) {
  return {
    type: FETCH_HEADER_DATA_SUCCESS,
    item,
  };
}

export function fetchHeaderDataError(error) {
  return {
    type: FETCH_HEADER_DATA_ERROR,
    payload: { error },
  };
}

export function fetchBlogDataRequest() {
  return {
    type: FETCH_BLOG_DATA_REQUEST,
  };
}

export function fetchBlogDataSuccess(item) {
  return {
    type: FETCH_BLOG_DATA_SUCCESS,
    item,
  };
}

export function fetchBlogDataError(error) {
  return {
    type: FETCH_BLOG_DATA_ERROR,
    payload: { error },
  };
}

export function fetchBlogPageRequest() {
  return {
    type: FETCH_BLOG_PAGE_REQUEST,
  };
}

export function fetchBlogPageSuccess(item) {
  return {
    type: FETCH_BLOG_PAGE_SUCCESS,
    item,
  };
}

export function fetchBlogPageError(error) {
  return {
    type: FETCH_BLOG_PAGE_ERROR,
    payload: { error },
  };
}

// 404 page
export function fetchPage404Request() {
  return {
    type: FETCH_PAGE_404_REQUEST,
  };
}

export function fetchPage404Success(item) {
  return {
    type: FETCH_PAGE_404_SUCCESS,
    item,
  };
}

export function fetchPage404Error(error) {
  return {
    type: FETCH_PAGE_404_ERROR,
    payload: { error },
  };
}

// 404 page
//ErrorBoundary
export function startHandleErrorBoundary() {
  return {
    type: START_HANDLE_ERRORBOUNDARY,
   
  };
}
export function endHandleErrorBoundary() {
  return {
    type: END_HANDLE_ERRORBOUNDARY,

  };
}
export function handleErrorBoundary(error) {
  return {
    type: HANDLE_ERRORBOUNDARY,
    payload: { error },
  };
}
//END ErrorBoundary

/* Last Visted Franchise Header data */
export function fetchLastVistedFranchisePageRequest() {
  return {
    type: FETCH_LAST_VISTED_FRANCHISE_REQUEST,
  };
}

export function fetchLastVistedFranchisePageSuccess(item) {
  return {
    type: FETCH_LAST_VISTED_FRANCHISE_SUCCESS,
    item,
  };
}

export function fetchLastVistedFranchisePageError(error) {
  return {
    type: FETCH_LAST_VISTED_FRANCHISE_ERROR,
    payload: { error },
  };
}
/* Franchise Home page data */
export function fetchFranchiseHomePageRequest() {
  return {
    type: FETCH_FRANCHISE_HOME_REQUEST,
  };
}

export function fetchFranchiseHomePageSuccess(item) {
  return {
    type: FETCH_FRANCHISE_HOME_SUCCESS,
    item,
  };
}

export function fetchFranchiseHomePageError(error) {
  return {
    type: FETCH_FRANCHISE_HOME_ERROR,
    payload: { error },
  };
}

/* Franchise Detail page data */
export function fetchFranchiseDetailPageRequest() {
  return {
    type: FETCH_FRANCHISE_DETAILPAGE_REQUEST,
  };
}

export function fetchFranchiseDetailPageSuccess(item) {
  return {
    type: FETCH_FRANCHISE_DETAILPAGE_SUCCESS,
    item,
  };
}

export function fetchFranchiseDetailPageError(error) {
  return {
    type: FETCH_FRANCHISE_DETAILPAGE_ERROR,
    payload: { error },
  };
}

export function fetchLocalOfficeRequest() {
  return {
    type: FETCH_LOCAL_OFFICE_REQUEST,
  };
}

export function fetchLocalOfficeSuccess(item) {
  return {
    type: FETCH_LOCAL_OFFICE_SUCCESS,
    item,
  };
}

export function fetchLocalOfficeError(error) {
  return {
    type: FETCH_LOCAL_OFFICE_ERROR,
    payload: { error },
  };
}

export function fetchIndividualBlogRequest() {
  return {
    type: FETCH_INDIVIDUAL_BLOG_REQUEST,
  };
}

export function fetchIndividualBlogSuccess(item) {
  return {
    type: FETCH_INDIVIDUAL_BLOG_SUCCESS,
    item,
  };
}

export function fetchIndividualBlogError(error) {
  return {
    type: FETCH_INDIVIDUAL_BLOG_ERROR,
    payload: { error },
  };
}

export function setCorporatePhoneNumber(corporatePhoneNumber){
return {
  type: SET_CORPORATE_PHONE_NUMBER,
  payload: {corporatePhoneNumber}
}
}


