let initialState = {
    serviceLoading: false,
    serviceItem: [],
    serviceError: null
  };
  
  function serviceReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_SERVICES_REQUEST":
        return {
          ...state,
          serviceLoading: true,
          serviceError: null
        };
      case "FETCH_SERVICES_SUCCESS":
        return {
          ...state,
          serviceLoading: false,
          serviceItem: action.item
        };
      case "FETCH_SERVICES_ERROR":
        return {
          ...state,
          serviceLoading: false,
          serviceError: action.payload.error,
          serviceItem: []
        };
      default:
        return state;
    }
  }
  
  export default serviceReducer;
  