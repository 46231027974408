import React from 'react';
import { Markup } from "interweave";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./PromiseRightLayout.css";

const currentPath = window.location.pathname;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        // items: 5
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        // items: 3
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        // items: 2
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const PromiseRightLayout = ({ contentData, bgImage }) => {
    return (
        <div className="right-promise-wrap twelve lazy-bg" style={{
            backgroundImage: bgImage
            // , height: `${contentData?.Image?.Height}px` 
        }}>
            <div className="right-promise-container row">
                <div className="right-promise col-dd-6">
                    <div className="right-promise-slider-wrap">
                        <div className="right-promise-container">
                            <div className="right-promise-slider">
                                {contentData?.CopyWidget &&
                                    <Carousel responsive={responsive} showDots={true} infinite={true} autoPlay={true} arrows={false}>
                                        {contentData?.CopyWidget?.map((slideData, index) => {
                                            return (

                                                <div className="slide" key={index}>
                                                    <div className="heading">
                                                        {slideData?.Heading?.Value}
                                                    </div>

                                                    <p>
                                                        <Markup content={slideData?.Copy?.Value} />
                                                    </p>
                                                    <a href={`${slideData?.Page?.ID ? '/' + slideData?.Page?.URL : (currentPath.split("/").length === 4 && currentPath.split("/")[1] !== "" && currentPath.split("/")[2] !== "" && currentPath.split("/")[2] !== "") ? '/offices/' + currentPath.split("/")[2] + '/' + slideData?.Page?.URL : slideData?.Page?.URL}`} className="more">
                                                        {slideData?.Page?.Link}
                                                    </a>
                                                </div>

                                            )
                                        })}
                                    </Carousel>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromiseRightLayout;