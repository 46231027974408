let initialState = {
    headerLoading: false,
    headerItem: {},
    headerError: null
  };
  
  function headerReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_HEADER_DATA_REQUEST":
        return {
          ...state,
          headerLoading: true,
          headerError: null
        };
      case "FETCH_HEADER_DATA_SUCCESS":
        return {
          ...state,
          headerLoading: false,
          headerItem: action.item
        };
      case "FETCH_HEADER_DATA_ERROR":
        return {
          ...state,
          headerLoading: false,
          headerError: action.payload.error,
          headerItem: {}
        };
      default:
        return state;
    }
  }
  
  export default headerReducer;
  