import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../config/utility";
import "./index.css";
import Accreditations from '../common/Accreditations';

export const WhyChooseUs = (props) => {
    const { contentData: { WhyChooseUs = {}, dataforReplaceMethod = {} } = {} } = props;
    const { franchiseName = "", franchiseCity = "", franchiseState = "", stateAbbr = "" } = dataforReplaceMethod;
    return (
        <div className="franchise-why-choose-us-content-wrap">
            <div className="top-content row">
                <div className="container">
                    <Accreditations contentData={WhyChooseUs} />
                    <div className="left-col pad-right col-sd-12 col-dd-8">
                        <h1>
                            <Markup content={WhyChooseUs?.IntroHeading?.Value
                                ?.replaceAll("{City}", franchiseCity)
                                ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                                ?.replace("{FranchiseName}", franchiseName)
                                ?.replace("{State}", franchiseState)
                                ?.replace("{StateAbbreviation}", stateAbbr)
                            } />
                        </h1>
                        <Markup content={WhyChooseUs?.WhyChooseUsIntroCopy?.Value
                            ?.replace("/acehandymandss", "")
                            ?.replaceAll("{City}", franchiseCity)
                            ?.replace("{Phone}", formatPhone(getFranchiseDataFromLS("recentFranchisPhone")))
                            ?.replace("{FranchiseName}", franchiseName)
                            ?.replace("{State}", franchiseState)
                            ?.replace("{StateAbbreviation}", stateAbbr)
                        } />
                    </div>
                    <div className="right-col col-dd-4">
                        <div id="accreditations">
                            <h3>Accreditations</h3>
                            <div className="accreditations-wrap clearfix ">
                                {Array.isArray(WhyChooseUs?.Logo) && WhyChooseUs?.Logo?.map(logo => {
                                    return <figure>
                                        {logo?.Value && <img className=""
                                            src={`${ingeniuxURL}${logo?.Value}`}
                                            alt={logo?.AlternateText}
                                            title={logo?.AlternateText}
                                            width="100" height="100"
                                        />}
                                    </figure>
                                })}
                            </div>
                        </div>
                        <figure className="splash clearfix">

                            {WhyChooseUs?.SplashImage?.Value && <img
                                className="photo"
                                src={`${ingeniuxURL}${WhyChooseUs?.SplashImage?.Value}`}
                                alt={WhyChooseUs?.SplashImage?.AlternateText}
                                title={WhyChooseUs?.SplashImage?.AlternateText}
                                width="300" height="200"
                            />}

                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs;
