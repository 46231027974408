import React from "react"
import Modal from "react-modal";

import "./OurWorkModal.css";


Modal.setAppElement("#root");


export const OurWorkModalWindow = ({ show, handleModalToggle, size, children }) => {

  const { modalWidth, modalHeight } = size;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,.8)",
      zIndex: 1000,
    },
    content: {
      // top: "42%",
      top: "50%",
      left: "50%",
      right: "40%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0",
      // boxShadow: "0 0 16px black",
      overflow: "visible",
      height: modalHeight,
      width: modalWidth,
      backgroundColor: "rgba(0,0,0,0)",
      borderStyle:"none",
    },
  };

  const closeBtn = {
    // position: "absolute",
    // top: "-35px",
    // right: "0",
    // fill: "#fff",
    // cursor: "pointer"

    zIndex: "500",
    top: "0",
    right: "110px",
    left: "auto",
    fontSize: "0",

    position: "absolute",
    // top: "-43px",
    top: "0px",
    // right: -8px;
    // font-size: 0;
    fontWeight: "700",
    display: "block",
    overflow: "visible",
    width: "42px",
    height: "42px",
    margin: "0",
    padding: "0",
    cursor: "pointer",
    // webkitTransition: "color .2s",
    transition: "color .2s",
    textDecoration: "none",
    color: "#FFF",
    border: "0",
    outline: "0",
    background: "transparent"
  };

  const contentStyle = {
    // overflow: "visible",
    height: "100%"
  }

  return (

    <Modal isOpen={show} onRequestClose={handleModalToggle} style={customStyles} >
      <button className="remodal-close" style={closeBtn} onClick={handleModalToggle}></button>
      <div className="modal-sytle" style={contentStyle}>
        {children}
      </div>
    </Modal>

  )
}

export default OurWorkModalWindow;