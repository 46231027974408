import ImageGalleryColumnLayout from "../ImageGalleryColumnLayout/ImageGalleryColumnLayout";
import ImageGalleryFullWidth from "./ImageGalleryFullWidth";

import React from "react";
const ImageGalleryLayout = ({
  contentData,
  AddCaptions,
  ImagesPerRow,
  FullWidth,
}) => {
  return FullWidth === "False" &&
    contentData?.AddCaptions?.Value === "True" &&
    contentData?.ImageLink?.length < 4 &&
    ImagesPerRow !== "2" ? (
    <ImageGalleryColumnLayout
      contentData={contentData}
      ImagesPerRow={ImagesPerRow}
      AddCaptions={AddCaptions}
      FullWidth={FullWidth}
    />
  ) : (
    <ImageGalleryFullWidth
      contentData={contentData}
      ImagesPerRow={ImagesPerRow}
      AddCaptions={AddCaptions}
      FullWidth={FullWidth}
    />
  );
};

export default ImageGalleryLayout;
