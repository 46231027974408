import React from 'react';
import { Markup } from "interweave";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./PromiseLeftLayout.css";

const currentPath = window.location.pathname;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        // items: 5
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        // items: 3
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        // items: 2
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const PromiseLeftLayout = ({ contentData, bgImage }) => {
    return (
        <div className="promise-wrap twelve lazy-bg" style={{ backgroundImage: bgImage }}>

            <div className="container row">
                <div className="col-dd-6">
                </div>
                <div className="col-dd-6">
                    <div className="promise-slider-wrap single-border">

                        <div className="container">
                            <div className="promise-slider">
                                <Carousel responsive={responsive} showDots={true} infinite={true} autoPlay={true} arrows={false}
                                >
                                    {Array?.isArray(contentData?.CopyWidget) ? contentData?.CopyWidget?.map((slideData, index) => {

                                        return (

                                            <div className="slide" key={index}>
                                                <div className="heading">
                                                    {slideData?.Heading?.Value}
                                                </div>

                                                <p>
                                                    <Markup content={slideData?.Copy?.Value} />
                                                </p>
                                                <a href={`${slideData?.Page?.ID ? '/'+slideData?.Page?.URL : (currentPath.split("/").length === 3 && currentPath.split("/")[1] !== "" && currentPath.split("/")[2] !== "") ? currentPath.split("/")[2] + '/' + slideData?.Page?.URL : slideData?.Page?.URL}`} className="more">
                                                    {slideData?.Page?.Link}
                                                </a>
                                            </div>

                                        )
                                    })
                                        :
                                        <div className="slide" >
                                            <div className="heading">
                                                {contentData?.CopyWidget?.Heading?.Value}
                                            </div>

                                            <p>
                                                <Markup content={contentData?.CopyWidget?.Copy?.Value} />
                                            </p>
                                            <a href={`${contentData?.CopyWidget?.Page?.ID ? '/'+contentData?.CopyWidget?.Page?.URL : (currentPath.split("/").length === 3 && currentPath.split("/")[1] !== "" && currentPath.split("/")[2] !== "") ? currentPath.split("/")[2] + '/' + contentData?.CopyWidget?.Page?.URL : contentData?.CopyWidget?.Page?.URL}`} className="more">
                                                {contentData?.CopyWidget?.Page?.Link}
                                            </a>
                                        </div>
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromiseLeftLayout;