import React, { useState } from "react";
import "./menuBar.css";
import { HashLink as Link } from 'react-router-hash-link';
import { extractPath, isExternalUrl } from "../../config/utility";

const ChildMenuBar = ({ menuItem, menuOpen, setMenuOpen, setMenuItems, showNav, setShowNav }) => {
    return (
        <div className="dropdown-wrap">
            <ul>
                <li className="top back" onClick={() => setMenuOpen(true)}><a><i className="fa-solid fa-angle-left" /> Back</a></li>
                <li className="link category">
                    <Link
                        to={`/${menuItem?.URL}`}
                    >
                        {menuItem?.Link}
                    </Link>
                </li>
                {menuItem?.SubLink?.map((subLink, index) => {
                    
                    return (
                        <li className="subpage" key={index}>
                            <Link
                                to={`/${subLink.URL}`}
                                onClick={() => { setMenuOpen(!menuOpen); setShowNav(showNav) }}
                            >
                                {subLink?.Link}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ChildMenuBar;
