let initialState = {
  loading: false,
  item404: [],
  error: null,
};

function page404Reducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PAGE_404_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_PAGE_404_SUCCESS":
      return {
        ...state,
        loading: false,
        item404: action.item,
      };
    case "FETCH_PAGE_404_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        item404: [],
      };
    default:
      return state;
  }
}

export default page404Reducer;
