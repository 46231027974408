let initialState = {
    blogLoading: false,
    blogItem: [],
    blogError: null
  };
  
  function blogReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_BLOG_DATA_REQUEST":
        return {
          ...state,
          blogLoading: true,
          blogError: null
        };
      case "FETCH_BLOG_DATA_SUCCESS":
        return {
          ...state,
          blogLoading: false,
          blogItem: action.item
        };
      case "FETCH_BLOG_DATA_ERROR":
        return {
          ...state,
          blogLoading: false,
          blogError: action.payload.error,
          blogItem: []
        };
      default:
        return state;
    }
  }
  
  export default blogReducer;
  