import React from "react";
import { ingeniuxURL } from "../../../../config/urls";
import "../ImageGalleryFullWidth/ImageGalleryFullWidth.css";

const ImageGalleryHalfWidth = ({
  contentData,
  AddCaptions,
  ImagesPerRow,
  FullWidth,
}) => {
  return (
    <section id="careers-collage-content" className="content-section twelve">
      <div
        className={`row careers-collage ${
          contentData.homeGallery ? "home-gallery-half-width" : ""
        }`}
      >
        <div>
          {contentData?.ImageLink?.map((imgL) => {
            return (
              <div
                className={
                  FullWidth === "False"
                    ? ImagesPerRow === "2"
                      ? "carrier category space"
                      : ""
                    : ImagesPerRow === "2"
                    ? "carrier category non-space"
                    : ""
                }
              >
                {imgL?.Image?.Value && (
                  <img
                    src={`${ingeniuxURL}${imgL?.Image?.Value}`}
                    alt={imgL?.Image?.AlternateText}
                    title={imgL?.Image?.AlternateText}
                    width="250"
                    height="150"
                  />
                )}
                <div
                  className={
                    AddCaptions === "False"
                      ? "list no-caption"
                      : "list add-caption"
                  }
                >
                  <span>{imgL?.Caption?.Value}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ImageGalleryHalfWidth;
