import {
    FETCH_LAST_VISTED_FRANCHISE_REQUEST,
    FETCH_LAST_VISTED_FRANCHISE_SUCCESS,
    FETCH_LAST_VISTED_FRANCHISE_ERROR
} from "../actions/actionType"

let initialState = {
    loading: false,
    franchseLastVisited: {},
    error: null
};

function franchiseLastVisitedReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LAST_VISTED_FRANCHISE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_LAST_VISTED_FRANCHISE_SUCCESS:
            return {
                ...state,
                loading: false,
                franchseLastVisited: action.item,
                error: null
                
            };
        case FETCH_LAST_VISTED_FRANCHISE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                franchseLastVisited: {}
            };
        default:
            return state;
    }
}

export default franchiseLastVisitedReducer;
