import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ChildMenuBar from "./ChildMenuBar";
import DynamicLinkList from "./DynamicLinks";
import { isExternalUrl } from "../../config/utility";
import "./menuBar.css";


const MenuBar = ({ headerContent, showNav, setShowNav, styles }) => {
    const [menuOpen, setMenuOpen] = useState(true)
    const [menuItems, setMenuItems] = useState();

    return (
        <div className="whole-content" style={styles}>
            <div className={showNav && menuOpen ? " mobile-nav-slide" : "mobile-nav-slide active"}>
                <div className="content">

                    <nav className="mobile-nav-slide active">
                        <ul>
                            {!showNav && <>
                                <li className="top close"><a href="#"><i className="fa-solid fa-xmark" onClick={() => setShowNav(!showNav)}></i> Close</a></li>
                                {headerContent?.map((menuItem, index) => {
                                    const isExternal = isExternalUrl(menuItem?.URL);
                                    return <li className="link has-dropdown" key={index}>
                                        {/* <Link to={menuItem?.Schema === "VirtualLink" ? `${menuItem?.CustomURL}` : `/${menuItem?.URL}`} target={menuItem?.Schema === "VirtualLink" ? "_blank" : "_self"} rel={menuItem?.Schema === "VirtualLink" ? "noopener noreferrer" : ""}>{menuItem?.Link}</Link> */}
                                        <DynamicLinkList
                                            pagePath={isExternal ? `${menuItem?.CustomURL}` : `/${menuItem?.URL}`}
                                            isExternal={isExternal}
                                            pageApiUrl={`/${menuItem?.URL}`}
                                            pageName={menuItem?.Link}
                                        />
                                        {menuItem?.SubLink && <i className="fa-solid fa-angle-right" value={menuItem?.SubLink} onClick={(data) => {
                                            setMenuOpen(!menuOpen)
                                            setMenuItems(menuItem)
                                        }}></i>}

                                    </li>
                                })}
                            </>}
                            {!menuOpen && <ChildMenuBar menuItem={menuItems} setMenuOpen={setMenuOpen} />}
                            <li className="get-estimate-menu-header-cta">	
                                <Link to={`/#GetEstimateLink`} data-tab="get-a-quote">	
                                <span>Get An Estimate</span>	
                                </Link>	
                            </li>	
                            {/* <li className="get-estimate-menu-header-cta">	
                                <Link to={`/#GetColorConsultLink`} data-tab="get-a-quote">	
                                <span>Schedule A Free Color Consulatation</span>	
                                </Link>	
                            </li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default MenuBar;
