import React, { useEffect } from "react";
import { Markup } from "interweave";
import "./scheduleIt.css";
// import ZipcodeSearch from "../common/ZipcodeSearch";
import { ingeniuxURL } from "../../config/urls";
import ZipChooser from "./ZipChooser";

const ScheduleIt = ({ scheduleData, hashId, zipCodeList, localOfficeList }) => {

  // useEffect(() => {
  //   setTimeout(() => {

  //     const name = document.getElementById(hashId)

  //     window.scrollTo({ top: name?.offsetTop - 215, left: 0, behavior: 'smooth' });
  //   }, 1000)
  // }, [])
  
  // commented this because it was affecting landing/scrolling to top of page
//   useEffect(() => {
//     setTimeout(() => {
//         const name = document.getElementById(hashId);
//         if(window.location.pathname.includes("offices")){
//           if(window.screen.width > 600){
//             window.scrollTo({top:name?.offsetTop-215, left:0, behavior:'smooth'});
//         } else {
//             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
//         }  
//         }else if(!window.location.pathname.includes("offices")){
//           if(window.screen.width > 600){
//             window.scrollTo({top:name?.offsetTop-265, left:0, behavior:'smooth'});
//         } else {
//             window.scrollTo({top:name?.offsetTop-272, left:0, behavior:'smooth'});
//         }  
//         }
                  
//     }, 200)
// }, [])

  const viewPortWidth = window.screen.width;
  return (
    <div id={viewPortWidth > 600 ? hashId : ""} className="tab-content-wrap">
      <div className="get-estimate-container">
        <div id="book-online" className="tab-content row current">
          <figure className="col-ddd-6 splash-image" style={{ width: "50%" }}>
          {scheduleData?.ScheduleImage?.Value && <img className="lazy loaded"
              data-src={`${ingeniuxURL}${scheduleData?.ScheduleImage?.Value}`}
              alt={scheduleData?.ScheduleImage?.AlternateText}
              title={scheduleData?.ScheduleImage?.AlternateText}
              width={scheduleData?.ScheduleImage?.Width}
              height={scheduleData?.ScheduleImage?.Height} loading="lazy"
              src={`${ingeniuxURL}${scheduleData?.ScheduleImage?.Value}`}
              data-ll-status="loaded" />}</figure>
          <div className="col-md-12 col-dd-6 content" style={{marginTop: "75px"}}>
            <h3>{scheduleData?.ScheduleHeading?.Value}</h3>
            <Markup content={scheduleData?.ScheduleCopy?.Value} />
            {/* <ZipcodeSearch 
      search={scheduleData?.FindAnOffice?.Children?.SubmitAlignment !== "InlineRight"}
      zipData={{
        PlaceholderText: scheduleData?.FindAnOffice?.PlaceholderText?.Value,
        TextBelowInput: scheduleData?.FindAnOffice?.TextBelowInput?.Value,
      }}
      /> */}
            {/* <ZipChooser search={scheduleData?.FindAnOffice?.SubmitAlignment.Value !== "InlineRight"}
              zipInputs={{
                PlaceholderText: scheduleData?.FindAnOffice?.PlaceholderText?.Value,
                TextBelowInput: scheduleData?.FindAnOffice?.TextBelowInput?.Value,
              }}
              zipCodeList={zipCodeList}
              localOfficeList={localOfficeList}
              toSchedulingPage={true}
            /> */}
            <ZipChooser submitAlignment={scheduleData?.FindAnOffice?.SubmitAlignment?.Value}
              headingAlignment={scheduleData?.FindAnOffice?.HeadingAlignment?.Value}
              zipInputs={{
                PlaceholderText: scheduleData?.FindAnOffice?.PlaceholderText?.Value,
                TextBelowInput: scheduleData?.FindAnOffice?.TextBelowInput?.Value,
                Heading: scheduleData?.FindAnOffice?.Heading?.Value,
                HandymanMatters: scheduleData?.FindAnOffice?.HandymanMatters?.Value
              }}
              zipCodeList={zipCodeList}
              localOfficeList={localOfficeList}
              toSchedulingPage={true}
            />
          </div>
        </div>
      </div>
    </div>

  )
}

export default ScheduleIt; 