import axios from "axios";
import {handleErrorBoundary, fetchLocalOfficeRequest, fetchLocalOfficeSuccess, fetchLocalOfficeError } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchLocalOfficeData(pageLink) {
  return dispatch => {
    dispatch(fetchLocalOfficeRequest());
    axios
      .get(
        `${ingeniuxURL}api/${pageLink}`
      )
      .then(response => {
        dispatch(fetchLocalOfficeSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchLocalOfficeError(error));
      });
  };
}
