import React, { useState } from "react";
import { useLocation , useNavigate} from "react-router-dom";
import "./campaignForm.css";
import axios from "axios";
import { azureEmailingUrl, ingeniuxURL } from "../../config/urls";
import {
  regxEmail,
  regxUSPhone,
  regxUSZipcode,
  storeFranchiseDataToLS,
  aceBusinessName,
  extractPath,
  getFranchiseDataFromLS,
} from "../../config/utility";

const CampaignForm = ({
  contentData,
  franchiseDataToGetEstimate: { franchiseId, franchiseName, stateAbbr } = {},
}) => {
  const navigate = useNavigate();
  const initStateYotrackData = {
    formID: "91254806635157",
    website: "",
    simple_spc: "91254806635157-91254806635157",
    q12_typeA: "",
    q13_send_to: "",
    q14_from_page: "",
    q15_business_name: "",
    q17_territory: "not-swapped",
    q19_uid: "",
    event_id: "",
  };

  //States for all the input fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState();
  const [franchiseEmailId, setFranchiseEmailId] = useState("");
  const [zipcodeValue, setZipcodeValue] = useState("");
  const [zipLenErr, setZipLenErr] = useState(false);
  const [matchLoc, setMatchLoc] = useState("");
  const [yotrackData, setYotrackData] = useState(initStateYotrackData);

  //States for validity check of the input fields
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  //States for fields which are having error
  const [isErrorPhoneNo, setIsErrorPhoneNo] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorZipCode, setIsErrorZipCode] = useState(false);

  // CampaignId
  const { pathname, search } = useLocation();
  const queryParamObject = new URLSearchParams(search);
  const campaignID = queryParamObject.get('campaignid');

  const handymanId = process.env.REACT_APP_HANDYMANID;
  const corpClientId = process.env.REACT_APP_CORP_CLIENTID;
  const corpEmailId = process.env.REACT_APP_JOTFORM_EMAIL;

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  var matchingZipcode = "";
  const handleZipcodeChange = (e) => {
    e.preventDefault();
    let isValidZip;
    const zipVal = e.target.value;
    setZipcodeValue(zipVal);
    isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipVal);

    if (!isValidZip || zipVal === "") {
      setZipLenErr(false);
    }
    for (let i = 0; i < contentData?.zipCodeList?.length; i++) {
      for (let j = 0; j < contentData?.zipCodeList[i]?.length; j++) {
        if (zipVal === contentData?.zipCodeList[i][j]) {
          matchingZipcode = zipVal;
        }
      }
    }
    setZipCode(zipVal);

    if (!zipVal) setZipLenErr(false);
    else if (zipVal?.length === 5 && isValidZip) {
      //checking the LocalOffices array contains any match with user entered ZIPCODE
      const locationMatch = contentData?.localOfficeList?.filter(
        (office) =>
          office?.OwnedZipcodes?.some((zip) => zip === zipVal) ||
          office?.AllowedZipcodes?.some((zip) => zip === zipVal)
      );

      storeFranchiseDataToLS(
        "recentEstimateSubmissionPath",
        `/${locationMatch[0]?.HomePageLink?.URL}`
      );
      const zipLocationMatch =
        locationMatch && locationMatch[0]?.HomePageLink?.URL;
      zipLocationMatch && setMatchLoc(zipLocationMatch);
      const franchiseClientId = locationMatch && locationMatch[0]?.ClientId;
      if (zipLocationMatch) {
        getFranchiseEmailId(zipLocationMatch);
      }
      //siteTrackingId available as part of localoffice api, assigning to
      //siteTrackId fromt he matched location
      const siteTrackId = locationMatch && locationMatch[0]?.SiteTrackingId;
      if (siteTrackId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q14_from_page: siteTrackId,
        }));
      }

      //siteId either FranchiseClientId or corpId
      let siteId;
      if (franchiseClientId) {
        siteId = franchiseClientId;
      } else siteId = corpClientId;

      //YoTrack script added in the index.html, hence accessible only vis window object.
      window.YoTrack(handymanId, siteId, function (err, api) {
        console.log("err", err, "api", api);
      });

      if (locationMatch?.length > 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: "",
          q15_business_name: `${aceBusinessName} ${franchiseName}, ${stateAbbr}`,
          q17_territory: "no-location-returned",
        }));
      } else if (locationMatch?.length > 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: `${aceBusinessName} ${locationMatch[0]?.FranchiseName}, ${locationMatch[0]?.StateAbbreviation}`,
          q17_territory: extractPath(
            locationMatch[0]?.HomePageLink?.URL
          )?.replace("/", ""),
        }));
      } else if (locationMatch?.length === 0 && !franchiseId) {
        setYotrackData((prevState) => ({
          ...prevState,
          q12_typeA: corpEmailId,
          q15_business_name: aceBusinessName,
          q17_territory: "fma-loc-not-found",
        }));
      }
      setZipLenErr(false);
    } else {
      setZipLenErr(true);
    }
  };

  const getFranchiseEmailId = (franchisePath) => {
    const apiURL = `api/page?path=/${franchisePath}`;
    let franchiseEmailId = "";
    axios.get(`${ingeniuxURL}${apiURL}`).then((response) => {
      const resData = response?.data?.results;
      if (resData) {
        const franchiseCorpControl =
          resData[0]?.FranchiseHomePage?.FranchiseCorporateControl;
        franchiseEmailId = franchiseCorpControl?.EmailID?.Value;
        setFranchiseEmailId(franchiseEmailId);
      }
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let payloadData = {};
    const isValidPhone = regxUSPhone.test(phoneNumber);
    const isValidEmail = regxEmail.test(email);
    const isValidZipcode = regxUSZipcode.test(zipCode);

    //Validating all the fields
    if (!isValidPhone || phoneNumber === "") {
      setIsErrorPhoneNo(true);
      setIsValidPhone(false);
    }
    if (!isValidEmail || email === "") {
      setIsErrorEmail(true);
      setIsValidEmail(false);
    }
    if (!isValidZipcode || zipCode === "") {
      setIsErrorZipCode(true);
    }
    if (firstName === "") {
      setIsValidFirstName(false);
      
    }
    if (lastName === "") {
      setIsValidLastName(false);
      
    }
    if (
      isValidPhone === false ||
      isValidEmail === false ||
      isValidZipcode === false ||
      isValidFirstName === false ||
      isValidLastName === false
    ) {
      console.log("error");
    } else {
      setIsErrorZipCode(false);
      setIsErrorPhoneNo(false);
      setIsErrorEmail(false);

      payloadData = {
        firstName: firstName,
        lastname: lastName,
        phoneNumber: phoneNumber,
        emailAddress: email,
        zipCode: zipCode,
        franchiseemailId: franchiseEmailId,
        source: "plumbing-campaign",
        campaignID
      };
      const formData = new FormData();
      Object.keys(payloadData).forEach((key) =>
        formData.append(key, payloadData[key])
      );

      axios
        .post(azureEmailingUrl, formData)
        .then((res) => {
          setFirstName('');
          setLastName('');
          setPhoneNumber(null);
          setEmail('');
          setZipCode(null);
        
          setTimeout(() => {
            const recentFranchisePath = getFranchiseDataFromLS(
              "recentFranchisePath"
            );
            const recentEstimateSubmissionPath = getFranchiseDataFromLS(
              "recentEstimateSubmissionPath"
            );

            const rgxforSlashes = /^\/|\/$/g;

            if (matchLoc && recentFranchisePath) {
              if (recentFranchisePath === recentEstimateSubmissionPath) {
                const franchisePath = recentFranchisePath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              } else if (recentFranchisePath !== recentEstimateSubmissionPath) {
                const franchisePath = recentEstimateSubmissionPath?.replace(
                  rgxforSlashes,
                  ""
                );
                navigate(`/${franchisePath}/contact_success`);
              }
            } else if (matchLoc) {
              const franchisePath = recentEstimateSubmissionPath?.replace(
                rgxforSlashes,
                ""
              );
              navigate(`/${franchisePath}/contact_success`);
            } else {
              navigate("/contact_success");
            }
          }, 3000);
        })
        .catch((err) => {
          console.log("API ERROR: ", err);
        });
    }
  };

  return (
    <div className="red-bg">
      <div className="container">
        <div className="header">
          <h3>
            Ace Hardware Plumbing Services, Bringing Helpful To Your
            Home.&#8482;
          </h3>
        </div>
        <div className="main-container">
        <form onSubmit={handleSubmit}>
            <div className="row1">
              <div className="fields">
                <label>
                  <span className="fieldNames">First Name&#42;</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  required
                  onChange={handleFirstNameChange}
                  value={firstName}
                />
              </div>
              <div className="fields">
                {" "}
                <label>
                  <span className="fieldNames">Last Name&#42;</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  onChange={handleLastNameChange}
                  value={lastName}
                />
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">Phone Number&#42;</span>
                </label>
                <input
                  type="tel"
                  className="inputField"
                  required
                  onChange={handlePhoneChange}
                  value={phoneNumber}
                />
                {isErrorPhoneNo && (
                  <span className="error-field">Phone No is Incorrect</span>
                )}
              </div>
            </div>
            <div className="row2">
              <div className="fields">
                <label>
                  <span className="fieldNames">Email&#42;</span>
                </label>
                <input
                  type="email"
                  className="inputField"
                  required
                  onChange={handleEmailChange}
                  value={email}
                />
                {isErrorEmail && (
                  <span className="error-field">Email is Incorrect</span>
                )}
              </div>
              <div className="fields">
                <label>
                  <span className="fieldNames">Zip Code&#42;</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  pattern="[0-9]{5}"
                  required
                  onChange={handleZipcodeChange}
                  value={zipCode}
                />
                {isErrorZipCode && (
                  <span className="error-field">Zipcode is Incorrect</span>
                )}
              </div>
            </div>
            <div className="submitBtn">
              <button className="btnSubmit">
                Get Free Service Call Coupon&#42;
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;
