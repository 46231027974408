import React from "react";
import { Markup } from "interweave";

import "./NewBodyCopy.css";

export const NewBodyCopy = (props) => {
    return (
        <div className="content-wrap row">
            <div className="newbodycopy-container">
                <div className="content-full col-dd-10">
                    <p><strong><span style={{ textDecoration: "underline" }}> </span></strong></p>
                    {
                        Array?.isArray(props?.contentData?.BodyCopyTray) ? 
                        props?.contentData?.BodyCopyTray?.map((tray, index) => {
                            return <div style={{border:`${tray?.AddOutline?.Value === "Yes" ? '1px solid #000' : 'none'}`}}>
                                <h1 style={{
                                    textAlign: `${tray?.HeadingAlignment?.Value === 'Left' ? 'left' :
                                        tray?.HeadingAlignment?.Value === 'Center' ? 'center' : 'right'}`
                                }}>
                                    <Markup content={tray?.Heading?.Value}/>
                                </h1>
                                <Markup content={tray?.Copy?.Value} />
                                {
                                    tray?.Page?.Name === "CTA"
                                    &&
                                    <div style={{textAlign:'center'}}><a className="bodycopytray-cta" target="_blank"
                                        href={tray?.Page?.URL}>
                                        {tray?.Page?.Link}
                                    </a></div>
                                }
                            </div>
                        })
                        :
                        <div style={{border:`${props?.contentData?.BodyCopyTray?.AddOutline?.Value === "Yes" ? '1px solid #000' : 'none'}`}}>
                                <h1 style={{
                                    textAlign: `${props?.contentData?.BodyCopyTray?.HeadingAlignment?.Value === 'Left' ? 'left' :
                                    props?.contentData?.BodyCopyTray?.HeadingAlignment?.Value === 'Center' ? 'center' : 'right'}`
                                }}>
                                    <Markup content={props?.contentData?.BodyCopyTray?.Heading?.Value} />
                                </h1>
                                <Markup content={props?.contentData?.BodyCopyTray?.Copy?.Value} />
                                {
                                    props?.contentData?.BodyCopyTray?.Page?.Name === "CTA"
                                    &&
                                    <div style={{textAlign:'center'}}><a className="bodycopytray-cta" target="_blank"
                                        href={props?.contentData?.BodyCopyTray?.Page?.URL}>
                                        {props?.contentData?.BodyCopyTray?.Page?.Link}
                                    </a></div>
                                }
                            </div>
                    }
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    )
}

export default NewBodyCopy;
