import React from "react";
import "./ImageGalleryColumnLayout.css";
import { Markup } from "interweave";
import { ingeniuxURL } from "../../../../config/urls";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const ImageGalleryColumnLayout = ({
  contentData,
  AddCaptions,
  ImagesPerRow,
  FullWidth,
}) => {
  const location = useLocation();

  return (
    <section className="container">
      <div className="image-gallery-column-container">
        {contentData?.ImageLink?.map((imgL) => {
          let imgURL = "";
          if (
            location?.pathname === "/our-services" ||
            location?.pathname === "/" ||
            location?.pathname.startsWith("/offices")
          ) {
            imgURL = `/${imgL?.Page?.URL}`;
          } else if (location?.pathname.startsWith("/our-services/")) {
            imgURL = `${location?.pathname}${imgL?.Page?.URL.replace("/", "")}`;
          } else {
            imgURL = `/${imgL?.Page?.URL}`;
          }
          return (
            <div className={`image-gallery-column-item`}>
              {imgL?.Image?.Value && (
                <img
                  src={`${ingeniuxURL}${imgL?.Image?.Value}`}
                  alt={imgL?.Image?.AlternateText}
                  title={imgL?.Image?.AlternateText}
                  width="200" height="100"
                />
              )}
              <div className={`item-text`}>
                {imgL?.Page?.Name === "Link" && imgL?.Page.URL ? (
                  <Link
                    to={
                      location?.pathname === "/our-services"
                        ? `/${imgL?.Page?.URL}`
                        : `${imgL?.SURL}${imgL?.Page?.URL}`
                    }
                  >
                    {imgL?.Caption?.Value}
                  </Link>
                ) : (
                  <Markup content={imgL?.Caption?.Value} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ImageGalleryColumnLayout;
