import React from "react";
import "./BlogBreadcrumbs.css";
import { HashLink as Link } from "react-router-hash-link";

const BlogBreadcrumbs = ({ contentData, individualPostContent }) => {
  const isSpecificBlogPage =
    individualPostContent && window.location.pathname !== "/blog";
  return (
    <div className="blogbreadcrumbs-body-wrap">
      <section id="content" className="content-section twelve">
        <div className="breadcrumbs">
          <div className="container">
            <ul>
              {contentData?.BreadcrumbNavigation.map((breadCrum, i, row) => {
                if (i + 1 === row.length) {
                  return isSpecificBlogPage ? (
                    <li>
                      <Link to={`/${breadCrum.URL}`}>{breadCrum.Name}</Link>
                    </li>
                  ) : (
                    <li>
                      <span>{breadCrum?.Name}</span>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <Link to={`/${breadCrum?.URL}`}> {breadCrum?.Name}</Link>{" "}
                      {" | "}
                    </li>
                  );
                }
              })}
              {isSpecificBlogPage && (
                <li>
                  <span>
                    {" "}
                    {" | "}
                    {individualPostContent?.BlogPost?.Heading?.Value}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogBreadcrumbs;
