import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import axios from "axios";
import ModalWindow from "../FranchiseCommon/Modal";
import BreadCrumb from "../FranchiseCommon/BreadCrumb";
import HoursOfOperation from "./HoursOfOperation";
import { ingeniuxURL, rateaBizReviewsDataLink } from "../../config/urls";
import {
  getFranchiseDataFromLS,
  formatPhone,
  isExternalUrl,
} from "../../config/utility";
import DynamicLinkList from "../common/DynamicLinks";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";
import "./homeDetails.css";
import { Markup } from "interweave";
import { GOOGLE_MAPS_IMAGE_LINK } from "./Constant";

const ModalFullServiceCityList = ({
  modalTitle,
  cityListData,
  basePageURL,
}) => {
  return (
    <div className="serviceCityModalcontainer">
      <h3>{modalTitle}</h3>
      <ul className="citylist">
        {cityListData?.map((city, index) => {
          return (
            <li key={index}>
              {city?.template?.SURL ? (
                <DynamicLinkList
                  pagePath={`city${city?.template?.CityURL?.Value}`}
                  pageApiUrl={city?.template?.SURL}
                  pageName={city?.city}
                  basePageURL={basePageURL}
                />
              ) : (
                <span>{city?.city}</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const LicenseNumber = ({ licNumber }) => {
  return (
    <>
      {licNumber && (
        <div className="license-numbers">
          <FaRegCopy /> License Numbers - <strong>{licNumber}</strong>
        </div>
      )}
    </>
  );
};

const SvgPhoneIconNextToFranchiseHeading = ({ attributes = {} }) => {
  const { fill, height, width, margin } = attributes;
  return (
    <>
      <svg
        style={{ margin: margin ? margin : "" }}
        version="1.1"
        height={height ? height : "30"}
        width={width ? width : "30"}
        fill={fill ? fill : "#000"}
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <title>{"Phone icon"}</title>
        <path
          d="M0,48c0,256.5,207.9,464,464,464c11.3,0,20.9-7.8,23.4-18.6l24-104c2.6-11.3-3.3-22.9-14-27.6l-112-48
c-9.8-4.2-21.2-1.4-28,6.9l-49.6,60.6c-78.3-36.7-141.2-100.5-177.2-177.2l60.6-49.6c8.3-6.7,11.1-18.2,6.9-28l-48-112
C145.5,3.9,133.9-2,122.6,0.6l-104,24C7.7,27.1,0,36.8,0,48z"
        />
      </svg>
    </>
  );
};

const HomeDetails = React.memo(({ contentData }) => {
  const initState = {
    reviewsDetails: {
      reviews: [],
      stats: {},
    },
    error: "",
  };
  const frnchiseCorpCtrl = contentData?.FranchiseCorporateControl;
  const rateaBizUId = frnchiseCorpCtrl?.RateABizUUID;
  const rabId = rateaBizUId?.Value;

  const [showMap, setShowMap] = useState(false);
  const [reviewsData, setReviewsData] = useState(initState);
  const [swapPhone, setSwapPhone] = useState("");

  //fetch the reviews
  useEffect(() => {
    if (rabId) {
      const rabURL = `${rateaBizReviewsDataLink}${rabId}/reviews.json`;
      const reviewsCount = 5;
      axios
        .get(rabURL)
        .then((res) => {
          if (res.status === 200) {
            if (res?.data?.stats?.count > 0) {
              const topReviews = res?.data?.reviews?.slice(0, reviewsCount);
              setReviewsData((prevState) => ({
                ...prevState,
                reviewsDetails: {
                  reviews: topReviews,
                  stats: res?.data?.stats,
                },
              }));
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status > 400) {
            setReviewsData((prevState) => ({
              ...prevState,
              error: "Oops! something went wrong in getting the reviews.",
            }));
          }
        });
    }
  }, [rabId]);

  const location = useLocation();

  useEffect(() => {
    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      setSwapPhone(localPhone);
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [swapPhone]);

  const handleModalToggle = (e, setterFn) => {
    e.preventDefault();
    setterFn((prevSt) => !prevSt);
  };

  const {
    reviewsDetails: {
      reviews,
      stats: { averageRating, count: reviewCount, recommendationRate },
    },
  } = reviewsData;

  const locationMapSource = (url) => {
    if (!url) return;
    if (isExternalUrl(url)) {
      return url;
    }
    return url && `${ingeniuxURL}${url}`;
  };

  const serviceDivRef = useRef(null);

  useEffect(() => {
    const hashValue = location?.hash?.replace(/^#/, "");
    if (hashValue === "servicingCity") {
      setTimeout(() => {
        const name = hashValue && document.getElementById(hashValue);
        window.scrollTo({
          top: name?.offsetTop - 135,
          left: 0,
          behavior: "smooth",
        });
      }, 370);
    }
  }, [location]);

  return (
    <div className="home">
      {contentData && (
        <section id="content" className="content-section twelve">
          <div className="breadcrumb-franchise-home">
            <BreadCrumb breadcrumbData={contentData?.BreadcrumbNavigation} />
          </div>
          <div className="content-wrap">
            <div className="homedetails-container row top-wrap">
              <div className="col-sd-12 col-dd-5 left-col">
                <div className="location-info">
                  <h1 className="heading">
                    <div className="aceplusphone">
                      {frnchiseCorpCtrl?.FranchiseName?.Value}
                      <div className="phone">
                        <div className="phone-svg">
                          <SvgPhoneIconNextToFranchiseHeading />
                        </div>

                        <span className="phone-number simpli-btn simpli-call">
                          <a
                            style={{ fontSize: "20px" }}
                            href={
                              swapPhone
                                ? `tel:${swapPhone}`
                                : frnchiseCorpCtrl?.TelephoneScript?.Value
                                ? `tel:${frnchiseCorpCtrl?.TelephoneScript?.Value}`
                                : `tel:${frnchiseCorpCtrl?.PhoneNumber?.Value}`
                            }
                            className="color-primary"
                          >
                            {swapPhone
                              ? formatPhone(swapPhone)
                              : frnchiseCorpCtrl?.PhoneNumber?.Value}
                          </a>
                        </span>
                      </div>
                    </div>
                  </h1>
                  <div className="area-map">
                    <div className="area-container">
                      <div className="map-area">
                        <a
                          href=""
                          onClick={(e) => handleModalToggle(e, setShowMap)}
                          className="color-primary"
                          style={{ fontSize: "17px" }}
                        >
                          <div className="location-svg">
                            <i
                              className={`${contentData?.MapIcon?.Value} color-primary`}
                              aria-hidden="true"
                            ></i>
                          </div>
                          {contentData?.Page[0]?.Link}
                        </a>
                      </div>
                      <div className="service-area">
                        <a
                          href="#servicingCity"
                          className="color-primary"
                          style={{ fontSize: "17px" }}
                        >
                          View All Cities Served
                        </a>
                      </div>
                    </div>
                    <ModalWindow
                      show={showMap}
                      handleModalToggle={(e) =>
                        handleModalToggle(e, setShowMap)
                      }
                      size={{
                        modalWidth: "80%",
                        modalHeight: "auto",
                        fitContent: true,
                      }}
                    >
                      {frnchiseCorpCtrl?.ServicesMap?.URL &&
                      frnchiseCorpCtrl?.ServicesMap?.URL.includes(
                        GOOGLE_MAPS_IMAGE_LINK
                      ) ? (
                        <img
                          src={locationMapSource(
                            frnchiseCorpCtrl?.ServicesMap?.URL
                          )}
                          alt=""
                          width="250"
                          height="150"
                        />
                      ) : (
                        <iframe
                          className="areaMapIframe"
                          src={frnchiseCorpCtrl?.ServicesMap?.URL}
                          width="100%"
                          height="375"
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      )}
                    </ModalWindow>
                  </div>

                  <HoursOfOperation
                    hoursData={
                      frnchiseCorpCtrl?.FranchiseOwnersControlNew
                        ?.HoursOfOperations
                    }
                  />
                  <div className="book247-button">
                    <a
                      name="book-online"
                      href={`${contentData?.Page[1]?.URL}`}
                      className="btn"
                      data-tab="book-online"
                    >
                      {contentData?.Page[1]?.Link}
                    </a>
                  </div>
                  <LicenseNumber
                    licNumber={
                      frnchiseCorpCtrl?.FranchiseOwnersControlNew
                        ?.LicenseNumbers?.Value
                    }
                  />
                  <div
                    id="servicingCity"
                    ref={serviceDivRef}
                    className="service-areas"
                  >
                    {/* to make "Servicing" text bold */}
                    <Markup
                      content={contentData?.Servicing?.Value?.replace(
                        contentData?.Servicing?.Value,
                        `<h3 style=\"font-weight: bold; margin-top: 13px;\">${contentData?.Servicing?.Value}</h3>`
                      )}
                    />{" "}
                    <div className="service-area">
                      {contentData?.ServiceCitySEO?.map((city, index) => {
                        return (
                          <div key={index} className="city-names">
                            <>
                              {city?.template?.ID ? (
                                <DynamicLinkList
                                  pagePath={`city${city?.template?.CityURL?.Value}`}
                                  pageName={city?.city}
                                  basePageURL={contentData?.SURL}
                                />
                              ) : (
                                <span>{city?.city}</span>
                              )}
                            </>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sd-12 col-dd-6 right-col franchise-home-right-col">
                {contentData?.Trays?.map((tray, index) => {
                  if (
                    Object.keys(dynamicTrayList)?.find(
                      (trayName) => trayName === tray?.TrayName
                    )
                  ) {
                    let combinedProps;

                    combinedProps = { ...tray };

                    if (tray.TrayName === "SEOTemplate") {
                      return (
                        <DynamicTrays
                          trayName={tray?.TrayName}
                          contentData={{
                            ...combinedProps,
                            isHomeDetailsSEOTray: true,
                          }}
                          key={index}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
});

export default HomeDetails;
