import axios from "axios";
import {handleErrorBoundary, fetchHeaderDataRequest, fetchHeaderDataSuccess, fetchHeaderDataError } from "./action";
import { ingeniuxURL } from "../config/urls";

export function fetchHeaderPage(pageId) {
  return dispatch => {
    dispatch(fetchHeaderDataRequest());
    axios
      .get(
        `${ingeniuxURL}api/page?path=${pageId}`
      )
      .then(response => {
        return dispatch(fetchHeaderDataSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        return dispatch(fetchHeaderDataError(error));
      });
  };
}
