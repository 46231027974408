import React from "react";
import { Markup } from "interweave";

import { ingeniuxURL } from "../../../config/urls";
import { formatPhone, getFranchiseDataFromLS } from "../../../config/utility";
import "./detailpageBanner.css";
import WhyChooseUsBanner from "../../WhyChooseUsPage/WhyChooseUsBanner";

export const DetailPageBanner = (props) => {
  const {
    bannerContent,
    headingContent,
    paintStrokes,
    franchiseName = "",
    franchiseCity = "",
    franchiseState = "",
    stateAbbr = "",
  } = props;
  const [leftStroke, middleStroke, rightStroke] = paintStrokes;
  return bannerContent?.Value ? (
    <section id="detail-banner">
      <div
        className="detail-page-banner"
        style={{
          backgroundImage:bannerContent?.Value && `url(${ingeniuxURL}${bannerContent?.Value})`,
        }}
      >
        <div className="container">
          <div className="banner-heading-container">
            <div
              className="paintstroke-left"
              style={{
                backgroundImage:leftStroke?.Value && `url(${ingeniuxURL}${leftStroke?.Value})`,
              }}
            ></div>
            <h1
              className="header h1"
              style={{
                backgroundImage:middleStroke?.Value && `url(${ingeniuxURL}${middleStroke?.Value})`,
                padding: 0,
              }}
            >
              <Markup
                content={headingContent?.Value?.replace("{City}", franchiseCity)
                  ?.replace("{State}", franchiseState)
                  ?.replace("{FranchiseName}", franchiseName)
                  ?.replace("{StateAbbreviation}", stateAbbr)
                  ?.replace(
                    "{Phone}",
                    formatPhone(getFranchiseDataFromLS("recentFranchisPhone"))
                  )}
              />
            </h1>
            <div
              className="paintstroke-right"
              style={{
                backgroundImage:rightStroke?.Value && `url(${ingeniuxURL}${rightStroke?.Value})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="why-choose-us-franchise">
      <WhyChooseUsBanner contentData={headingContent?.Value} />
    </div>
  );
};
