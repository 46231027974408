import { Markup } from "interweave";
import React, { useState, useEffect } from "react";
import LocalOfficeNonExistent from "./LocalOfficeNonExistent";
import "./ShortZip.css"

const ShortZip = (props) => {
    const [formValue, setFormValue] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const scheduleAppointmentLink = process.env.REACT_APP_DISPATHME;

    var matchingZipcode = "";
    var matchingZipcodeExtOrgId = "";
    var matchingZipcodeHomeLink = "";

    useEffect(() => {
        const isZipCode = window.location.search;
        const isSubmittedZip = localStorage.getItem('HomeZipSearchSubmitted')==='true';
        if (isZipCode && isSubmittedZip) {
            setShowModal(true);
            localStorage.removeItem('HomeZipSearchSubmitted');
        }
    }, [])

    const handleChange = (e) => {
        setFormValue(e.target.value);
    }
    const handleFocus = () => {
        setIsValid(true);
        setFormValue("");
    }

    const handleSubmit = (e) => {
        // prevent page from refreshing
        e.preventDefault();
        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(formValue);
        if (!isValidZip || formValue === "") {
            setIsValid(false);
            setFormValue("Please enter a valid zip code");
        }

        for (let i = 0; i < props?.zipCodeList?.length; i++) {
            for (let j = 0; j < props?.zipCodeList[i]?.length; j++) {
                if (formValue === props?.zipCodeList[i][j]) {
                    matchingZipcode = formValue;
                    matchingZipcodeExtOrgId = props?.localOfficeList[i]?.ExternalOrganizationId;
                    matchingZipcodeHomeLink = props?.localOfficeList[i]?.HomePageLink?.URL;
                }
            }
        }

        if (matchingZipcode !== "" && props?.toSchedulingPage) {
            // console.log("found a matching zip code");
            setShowModal(false);
            if (matchingZipcodeExtOrgId) window.location.href = `${scheduleAppointmentLink}${matchingZipcodeExtOrgId}`;
            // window.location.href='https://www.google.com';
        } else if (matchingZipcode !== "" && !props?.toSchedulingPage) {
            // console.log("found a matching zip code");
            setShowModal(false);
            window.location.href = `/${matchingZipcodeHomeLink}?id=${matchingZipcode}`
            // window.location.pathname = matchingZipcodeHomeLink;
            // window.location.href='https://www.google.com';
        } else if (matchingZipcode === "" && isValidZip) {
            window.location.search = `?id=${formValue}`
            localStorage.setItem('HomeZipSearchSubmitted',true)
            // setShowModal(true);

            // boolean set to true to show modal
        }
    }
    return (
        <div className="short-zip">
            {props?.zipData?.Heading &&
                <center>
                    <h3 className="white">
                        <Markup content={props?.zipData?.Heading} />
                    </h3>
                </center>
            }

            <form className="bannerSearchForm one-line ctaSearchFormMini ctaSearchFormStacked" onSubmit={handleSubmit}>
                <span className={`input ${isValid ? '' : 'error'}`}>
                    <input type="text"
                        id="searchAreaMini"
                        name="searchAreaMini"
                        className="searchInput"
                        placeholder={props?.zipData?.PlaceholderText}
                        title={props?.zipData?.PlaceholderText}
                        value={formValue}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        maxLength="5"
                        autoComplete="off"
                        size="30" />
                </span>
                <LocalOfficeNonExistent showModal={showModal} setShowModal={setShowModal} />
                {/* {showModal && <LocalOfficeNonExistent showComponent={showModal} />} */}
                {/* if boolean is true show modal otherwise don't */}

                {/* <div className="no-results">
                    <span className="close">x</span>
      We're sorry, there isn't an Ace Handyman Services office in your immediate area.<br />
                    <a href="https://www.acehandymanservices.com/offices/">
                        Please click here to find the areas we do service in your state.
                    </a>
                </div> */}
                <div className="instructions">
                    {props?.zipData?.TextBelowInput}
                </div>
                <span className="search">
                    <button type="submit" className="submit btn">{props?.zipData?.Submit ? props?.zipData?.Submit : 'Submit'}</button>
                </span>
            </form>
            <p style={{ color: "#fff", lineHeight: "1.2em", marginTop: "25px", marginBottom: "0" }}>
                <Markup content={props?.zipData?.HandymanMatters} />
            </p>
        </div>
    )
}

export default ShortZip;