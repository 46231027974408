import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import HeaderFranchise from "../FranchiseCommon/Header/HeaderFranchise";
import FootersFranchise from "../FranchiseCommon/Footer/FooterFranchise";
import DetailPageBanner from "../FranchiseCommon/DetailPageBanner";
import BreadCrumb from "../FranchiseCommon/BreadCrumb";
import CityDetailTemplate from "../FranchiseHomePage/ProfessionalServices";
import {
  getResultsArray,
  storeFranchiseDataToLS,
  getFranchiseDataFromLS,
  extractPath,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
} from "../../config/utility";
import withRouter from "../common/withRouter";
import DynamicTrays, { dynamicTrayList } from "../common/DynamicTrays";
import "./index.css";

class FranchiseServiceCityDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // deleteItemFromLS("recentFranchisPhone");
    //there are 2 scenarios

    //scenario 1
    //franchiseURL - when navigate to other page and press the browser back button
    //the city page data lost and franchise baseurl which is sent from the parent page also lost
    //to fix this issue, taking the last visited franchise url from localStorage (LS)
    //and hitting the api to populate the franchisehome data, from that getting ServiCitySEO

    //scenario -2
    //when the user directly hitting the city detail page URL, in such case the base URL
    //will be null, LS base URL will be null and only URL param data available (this.props?.apiRef),
    //that is split at "/city" and taking the base URL
    const franchiseURL = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : getFranchiseDataFromLS("recentFranchisePath")
      ? getFranchiseDataFromLS("recentFranchisePath")
      : this.props?.apiRef?.split("/city")[0];
    const corpHomeItem = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );

    //To eliminate the hash url, splitting the path.
    const rgxforSlashes = /^\/|\/$/g;
    const franchiseAbsPath = franchiseURL?.split("/#");
    storeFranchiseDataToLS("recentFranchisePath", franchiseAbsPath[0]);
    const localStateSURL =
      this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
    const localStorageSURL = getFranchiseDataFromLS("recentFranchisePath");
    //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
    //then load the franchisehomepage api again.

    //scenario 2 - in else block if the cached data and the visited franchise SURLS
    //are no the same, removed the cached data from the Session storage and
    //fetch the api again.
    if (
      (this.state.franchiseHomeData?.length === 0 ||
        this.state.franchiseHomeData === undefined) &&
      franchiseHomePage?.length === 0
    ) {
      this.props.dispatch(fetchFranchiseHome(franchiseURL?.toLowerCase()));
    } else if (
      this.state.franchiseHomeData &&
      localStateSURL?.replace(rgxforSlashes, "") !==
        localStorageSURL?.replace(rgxforSlashes, "")
    ) {
      deleteItemFromSS("franchiseHomeData");
      this.props.dispatch(fetchFranchiseHome(franchiseURL?.toLowerCase()));
    }

    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      corpHomeItem?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.apiRef?.apiPath !== this.props?.apiRef?.apiPath ||
      prevProps?.apiRef !== this.props?.apiRef
    ) {
      const franchiseURL = this.props?.apiRef?.basePageURL
        ? this.props?.apiRef?.basePageURL
        : getFranchiseDataFromLS("recentFranchisePath")
        ? getFranchiseDataFromLS("recentFranchisePath")
        : this.props?.apiRef?.split("/city")[0];
      this.props.dispatch(fetchFranchiseHome(franchiseURL?.toLowerCase()));
    }
  }

  getHeaderCTAData = (dynamicTrays, homeTrays) => {
    if (
      dynamicTrays.find((tray) =>
        ["ScheduleandEstimateTray", "ScheduleandEstimateTabTray"].includes(
          tray.TrayName
        )
      ) ||
      homeTrays.find((tray) =>
        ["ScheduleandEstimateTray", "ScheduleandEstimateTabTray"].includes(
          tray.TrayName
        )
      )
    ) {
      const scheduleEstimateLocalTray = dynamicTrays.filter((tray) =>
        ["ScheduleandEstimateTray", "ScheduleandEstimateTabTray"].includes(
          tray.TrayName
        )
      );
      const scheduleEstimateHomeTray = homeTrays.filter((tray) =>
        ["ScheduleandEstimateTray", "ScheduleandEstimateTabTray"].includes(
          tray.TrayName
        )
      );

      const scheduleEstimateTray =
        scheduleEstimateLocalTray && scheduleEstimateLocalTray.length
          ? scheduleEstimateLocalTray
          : scheduleEstimateHomeTray;

      let scheduleAppointmentCTA;
      let getEstimateCTA;
      if (Object.keys(scheduleEstimateTray[0]).length) {
        const { scheduleTabText, EstimateTabText } = scheduleEstimateTray[0];
        scheduleAppointmentCTA = Boolean(scheduleTabText?.Value);
        getEstimateCTA = Boolean(EstimateTabText?.Value);
      }
      return { scheduleAppointmentCTA, getEstimateCTA };
    }
    return { scheduleAppointmentCTA: false, getEstimateCTA: false };
  };

  render() {
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const corpHomeData = getResultsArray(
      this.props?.corpHomeItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    let franchiseHomePage = loadApiData("franchiseHomeData");
    let corpHomeItem = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (corpHomeItem === undefined || corpHomeItem?.length === 0) &&
      corpHomeData
    ) {
      saveApiData("x31", corpHomeData);
      corpHomeItem = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }
    const pagePath = this.props?.apiRef?.pagePath
      ? this.props?.apiRef?.pagePath
      : this.props?.apiRef;
    const absPagePath = pagePath && pagePath?.replace(/\/+$/, "");
    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.City?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const franchiseCity = franchiseCorpCtrl?.City?.Value;
    const franchiseState = franchiseCorpCtrl?.State?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const dynamicCMSFields = {
      franchiseName,
      franchiseCity,
      franchiseState,
      stateAbbr,
    };
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    //filtering the ServiceCitySEO data with the pagePath data
    //example: /lakewood_services
    const pageData =
      franchiseHomePage &&
      franchiseHomePage[0]?.ServiceCitySEO.filter(
        (city) => city?.template?.CityURL?.Value === extractPath(absPagePath)
      );
    const pageTemplate = pageData && pageData[0]?.template;
    const franchiseURL = this.props?.apiRef?.basePageURL
      ? this.props?.apiRef?.basePageURL
      : getFranchiseDataFromLS("recentFranchisePath");
    const cityDetailPageProps = { ...pageTemplate, ...dynamicCMSFields };
    //creating breadcrumb data from Servic city pages
    //as they are not having sepeate urls to load the content.
    //All city detail page data from the Franchise Home Page
    const breadcrumbNavigationCityPage = [
      {
        URL: "",
        Name: "Home",
      },
      // {
      //     "URL": "./offices",
      //     "Name": "FIND A CRAFTSMAN",
      // },
      {
        URL: `.${franchiseURL}`,
        Name: `Ace Hardware Plumbing Services ${
          franchiseHomePage && franchiseHomePage[0]?.Name
        }`,
      },
      {
        URL: "",
        Name: pageData && pageData[0]?.city,
      },
    ];

    const breadcrumbsStructuredData =
      franchiseCorpCtrl?.BreadcrumbsStructuredData?.Value;
    const localBusinessStructuredData =
      franchiseCorpCtrl?.LocalBusinessStructuredData?.Value;

    let isScheduleAppointmentCTA;
    let isGetEstimateCTA;

    if (franchiseHomePage && pageTemplate && pageTemplate?.Trays) {
      const homeTrays = franchiseHomePage && franchiseHomePage[0]?.Trays;
      const { scheduleAppointmentCTA, getEstimateCTA } = this.getHeaderCTAData(
        pageTemplate.Trays,
        homeTrays
      );
      isScheduleAppointmentCTA = scheduleAppointmentCTA;
      isGetEstimateCTA = getEstimateCTA;
    }
    return (
      <>
        <Helmet>
          <title>{pageTemplate?.Title?.Value}</title>
          <meta name="description" content={pageTemplate?.Description?.Value} />
          {/* Adding BreadcrumbsStructuredData to Head of the page */}
          {breadcrumbsStructuredData &&
            (() => {
              try {
                const data = JSON.parse(breadcrumbsStructuredData);
                return (
                  <script type="application/ld+json">
                    {JSON.stringify(data)
                      .replace("context", "@context")
                      .replaceAll("type", "@type")}
                  </script>
                );
              } catch {
                return null;
              }
            })()}
          {/* Adding LocalBusinessStructuredData to Head of the page */}
          {localBusinessStructuredData &&
            (() => {
              try {
                const data = JSON.parse(localBusinessStructuredData);
                return (
                  <script type="application/ld+json">
                    {JSON.stringify(data)
                      .replace("context", "@context")
                      .replaceAll("type", "@type")}
                  </script>
                );
              } catch {
                return null;
              }
            })()}
        </Helmet>
        <HeaderFranchise
          alertContent={corpHomeItem[0]?.Alerts}
          // alertContent={franchiseHomePage[0]?.FranchiseCorporateControl?.Alerts}
          headerContent={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
          }
          otherProps={[
            franchiseHomePage &&
              franchiseHomePage[0]?.FranchiseCorporateControl,
            franchiseHomePage && franchiseHomePage[0]?.SURL,
          ]}
          corpHeaderProps={[
            corpHomeItem && corpHomeItem[0]?.Header?.BookOnlineIcon,
            corpHomeItem && corpHomeItem[0]?.Header?.GetEstimateIcon,
            corpHomeItem && corpHomeItem[0]?.Header?.Page[0],
            corpHomeItem && corpHomeItem[0]?.Header?.Page[1],
          ]}
          scheduleAppointmentCTA={isScheduleAppointmentCTA}
          getEstimateCTA={isGetEstimateCTA}
          metaDescription={pageTemplate?.Description?.Value}
        />
        <DetailPageBanner
          bannerContent={pageTemplate?.Banner}
          headingContent={pageTemplate?.Heading}
          paintStrokes={[
            pageTemplate?.LeftCapPaintStroke,
            pageTemplate?.MiddlePaintStroke,
            pageTemplate?.RightCapPaintStroke,
          ]}
        />
        <div className="city-detailpage-container">
          <BreadCrumb
            breadcrumbData={breadcrumbNavigationCityPage}
            cityPage={true}
          />
          <CityDetailTemplate contentData={cityDetailPageProps} />
        </div>

        {franchiseHomePage &&
          pageTemplate &&
          pageTemplate?.Trays.map((tray, index) => {
            if (
              Object.keys(dynamicTrayList)?.find(
                (trayName) => trayName === tray?.TrayName
              )
            ) {
              let combinedProps;
              if (tray?.TrayName === "FranchisePackages") {
                const data =
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl
                    ?.FranchiseOwnersControlNew?.Packages;
                let packageCpyData = [];
                Object.keys(sessionStorage).forEach((key) => {
                  key !== "undefined" &&
                    packageCpyData.push({
                      name: key,
                      // name: key?.split("-").join(" "),
                      copyText: sessionStorage.getItem(key),
                    });
                });
                combinedProps = {
                  ...{
                    ...tray,
                    ...{ otherProps: data },
                    ...{ packageCopy: packageCpyData },
                  },
                };
              } else if (tray?.TrayName === "ReviewCarousel") {
                const rateaBizUId =
                  franchiseHomePage &&
                  franchiseHomePage[0]?.FranchiseCorporateControl?.RateABizUUID;
                combinedProps = {
                  ...{
                    ...tray,
                    ...(franchiseHomePage && franchiseHomePage[0]),
                    ...{ rateaBiz: rateaBizUId },
                    ...{ reviewsTrays: tray },
                  },
                };
              } else if (tray?.TrayName === "ServicesTray") {
                combinedProps = {
                  ...{
                    ...tray,
                    ...{
                      franchiseData: franchiseHomePage && franchiseHomePage[0],
                    },
                  },
                };
              } else if (
                tray?.TrayName === "ScheduleandEstimateTabTray" ||
                tray?.TrayName === "ScheduleandEstimateTray"
              ) {
                combinedProps = {
                  ...{
                    ...tray,
                    ...{ zipCodeList: localOfficeZipcodes },
                    ...{ localOfficeList: localOfficeResults },
                    ...{ franchiseDataToGetEstimate },
                  },
                };
              } else {
                combinedProps = { ...tray };
              }

              return (
                <DynamicTrays
                  trayName={tray?.TrayName}
                  contentData={combinedProps}
                  key={index}
                />
              );
            }
          })}

        <FootersFranchise
          footerContent={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseFooter
          }
          otherProps={
            franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl
          }
          corpFooterProps={[
            corpHomeItem && corpHomeItem[0]?.Footer?.FooterLogo,
            corpHomeItem && corpHomeItem[0]?.Footer?.TextAbovePhoneNumber,
            corpHomeItem && corpHomeItem[0]?.Footer?.Copyright,
            corpHomeItem && corpHomeItem[0]?.Footer?.CopyrightLinks?.Page,
            corpHomeItem && corpHomeItem[0]?.Footer?.DisclaimerNotes,
            franchiseHomePage && franchiseHomePage[0]?.Name,
            franchiseHomePage && franchiseHomePage[0]?.SURL,
          ]}
          headerContentForMenu={
            franchiseHomePage &&
            franchiseHomePage[0]?.FranchiseCorporateControl?.FranchiseHeader
              ?.MenuTab
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
  corpHomeItem: state?.headerReducer?.headerItem,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default withRouter(
  connect(mapStateToProps)(FranchiseServiceCityDetailPage)
);
