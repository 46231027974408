import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Collapsible from "react-collapsible";
import { Markup } from "interweave";

import ScheduleIt from "./ScheduleIt";
import GetEstimate from "./GetEstimate";
import GetEstimateJotFrom from "../JotForm";
import { ingeniuxURL } from "../../config/urls";
import { getFranchiseDataFromLS } from "../../config/utility";
import ZipChooser from "../common/ZipChooser";
import "./ScheduleItGetEstimate.css";

const SvgScheduleIcon = () => {
  return (
    <svg
      id="iconSchedule"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.3 29.7"
    >
      <title>Book Online</title>
      <path
        className="st0"
        d="M33.6 29.7H.7c-.4 0-.7-.3-.7-.7V2c0-.4.3-.7.7-.7h32.9c.4 0 .7.3.7.7v27c0 .3-.3.7-.7.7zM1.4 28.2h31.4V2.6H1.4v25.6z"
      ></path>
      <path
        className="st0"
        d="M7.1 6c-.3 0-.7-.3-.7-.7V.7c0-.4.4-.7.7-.7s.7.3.7.7v4.6c.1.4-.3.7-.7.7zM13.8 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM20.5 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM27.2 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM6.6 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM11.9 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM17.2 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM22.5 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM6.6 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM11.9 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.2 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM27.7 13.3c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM6.6 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM23.5 20.7h-2.3c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.9.8-1.7 1.7-1.7h2.3c.9 0 1.7.8 1.7 1.7V19c0 1-.7 1.7-1.7 1.7zm-2.3-4.2c-.1 0-.2.1-.2.2V19c0 .1.1.2.2.2h2.3c.1 0 .2-.1.2-.2v-2.3c0-.1-.1-.2-.2-.2h-2.3zM27.7 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.1 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c.1.4-.3.7-.7.7zM11.9 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7z"
      ></path>
    </svg>
  );
};

const SvgEstimateIcon = () => {
  return (
    <svg
      id="iconQuote"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.2 32.8"
    >
      <title>Get an Estimate</title>
      <path
        className="st0"
        d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
      ></path>
      <path
        className="st0"
        d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
      ></path>
    </svg>
  );
};

const TabSelector = ({ tabs, currentTab, changeTab, counts }) => {
  const iconsObject = {
    schedule: SvgScheduleIcon,
    estimate: SvgEstimateIcon,
  };
  const TabIcons = (props) => {
    const SelectComponent = iconsObject[props?.componentName];
    return <SelectComponent />;
  };
  return (
    <div className="home-tab-selector-container">
      <div className="tab-options">
        {tabs.map((tab, key) => {
          return (
            <button
              className={currentTab === tab ? " tab-option" : "tabs-option"}
              key={key}
              onClick={() => {
                changeTab(tab);
              }}
            >
              <TabIcons componentName={Object.keys(iconsObject)[key]} />
              <h2 tabIndex={0}>{tab}</h2>
              {counts[key] > 0 && <div>{counts[key]}</div>}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ScheduleItGetEstimate = ({ contentData = {} }) => {
  const {
    zipCodeList = [],
    localOfficeList = [],
    franchiseDataToGetEstimate = {},
  } = contentData;

  const GETESTIMATELINK = "GetEstimateLink";
  const SCHEDULEITLINK = "BookOnlineLink";
  const BOOKINGCTAS = "booking-ctas";

  const TAB_LET_SCHEDULE = contentData?.ScheduleTabText?.Value;
  const TAB_GET_ESTIMATE = contentData?.EstimateTabText?.Value;

  // changed useState value from SCHEDULEITLINK to "" to prevent site from scrolling after load
  const [tab, setTab] = useState("");
  const [hash, setHash] = useState("");
  const [swapPhone, setSwapPhone] = useState("");

  let location = useLocation();
  
  useEffect(() => {
    if (contentData?.ScheduleTabText?.Value) {
      setTab(contentData.ScheduleTabText.Value);
    } else {
      setTab(contentData?.EstimateTabText?.Value);
    }
  }, [contentData]);

  useEffect(() => {
    const hashValue = location?.hash?.replace(/^#/, "");
    let tabVal, hashId;

    if (hashValue === GETESTIMATELINK) {
      tabVal = TAB_GET_ESTIMATE;
      hashId = GETESTIMATELINK;
    } else if (hashValue === SCHEDULEITLINK) {
      tabVal = TAB_LET_SCHEDULE;
      hashId = SCHEDULEITLINK;
    } else if (hashValue === BOOKINGCTAS) {
      tabVal = TAB_LET_SCHEDULE;
      hashId = BOOKINGCTAS;
    } else {
      tabVal = contentData?.ScheduleTabText?.Value
        ? TAB_LET_SCHEDULE
        : TAB_GET_ESTIMATE;
      hashId = "";
    }
    setHash(hashId);
    setTab(tabVal);

    /* commented this because it was applying gap above schedule tab in franchise homepage */
    // setTimeout(() => {
    //     const name = document.getElementById(hashId)

    //     window.scrollTo({ top: name?.offsetTop - 268, left: 0, behavior: 'smooth' });

    // }, 200)

    setTimeout(() => {
      const name = document.getElementById(hashId);
      console.log("Location HASH:", location?.hash);
      if (window.location.pathname.includes("offices")) {
        if (window.screen.width > 600) {
          // changed from 215 to 290
          window.scrollTo({
            top: name?.offsetTop - 290,
            left: 0,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: name?.offsetTop - 272,
            left: 0,
            behavior: "smooth",
          });
        }
      } else if (
        !window.location.pathname.includes("offices") &&
        !location.hash
      ) {
        if (window.screen.width > 600) {
          window.scrollTo({
            top: name?.offsetTop - 295,
            left: 0,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: name?.offsetTop - 272,
            left: 0,
            behavior: "smooth",
          });
        }
      } else if (location.hash) {
        console.log("Block Executed:");
        if (window.screen.width > 600) {
          window.scrollTo({
            top: name?.offsetTop - 240,
            left: 0,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: name?.offsetTop - 220,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    }, 350);
  }, [location?.hash]);

  useEffect(() => {
    let localPhone;

    let intervalId = setInterval(() => {
      localPhone = getFranchiseDataFromLS("recentFranchisPhone");
      setSwapPhone(localPhone);
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [swapPhone]);

  const viewPortWidth = window.screen.width;
  return (
    <div className="home-content">
      <div className="tabbed-content-container row twelve">
        <div className="hide-md">
          <div className="get-estimate-container">
            <div className="heading">
              {contentData?.TextBelowPhoneNumber?.Value}
            </div>
            {contentData?.ScheduleTabText?.Value &&
            <Collapsible
              open={
                viewPortWidth < 600 && hash === SCHEDULEITLINK ? true : false
              }
              trigger={
                <span className="btn toggle-heading" data-tab="book-online">
                  <svg
                    id="iconSchedule"
                    height="16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 34.3 29.7"
                  >
                    <title>Book Online</title>
                    <path
                      className="st0"
                      d="M33.6 29.7H.7c-.4 0-.7-.3-.7-.7V2c0-.4.3-.7.7-.7h32.9c.4 0 .7.3.7.7v27c0 .3-.3.7-.7.7zM1.4 28.2h31.4V2.6H1.4v25.6z"
                    />
                    <path
                      className="st0"
                      d="M7.1 6c-.3 0-.7-.3-.7-.7V.7c0-.4.4-.7.7-.7s.7.3.7.7v4.6c.1.4-.3.7-.7.7zM13.8 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM20.5 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM27.2 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM6.6 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM11.9 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM17.2 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM22.5 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM6.6 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM11.9 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.2 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM27.7 13.3c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM6.6 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM23.5 20.7h-2.3c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.9.8-1.7 1.7-1.7h2.3c.9 0 1.7.8 1.7 1.7V19c0 1-.7 1.7-1.7 1.7zm-2.3-4.2c-.1 0-.2.1-.2.2V19c0 .1.1.2.2.2h2.3c.1 0 .2-.1.2-.2v-2.3c0-.1-.1-.2-.2-.2h-2.3zM27.7 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.1 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c.1.4-.3.7-.7.7zM11.9 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7z"
                    />
                  </svg>{" "}
                  {contentData?.ScheduleTabText?.Value}
                </span>
              }
            >
              <div className="content" style={{ padding: "20px" }}>
                <div className="get-estimate-container">
                  {/* removed {hash} from the div id below and replaced it with "book-online" 
                                    for schedule and get estimate links to work in the header */}
                  <div
                    id={viewPortWidth < 600 ? hash : ""}
                    className="tab-content row current"
                  >
                    <figure
                      className="col-ddd-6 splash-image"
                      style={{ width: "50%" }}
                    >
                      {contentData?.ScheduleImage?.Value && <img
                        className="lazy loaded"
                        data-src={`${ingeniuxURL}${contentData?.ScheduleImage?.Value}`}
                        alt={contentData?.ScheduleImage?.AlternateText}
                        title={contentData?.ScheduleImage?.AlternateText}
                        width={contentData?.ScheduleImage?.Width}
                        height={contentData?.ScheduleImage?.Height}
                        loading="lazy"
                        src={`${ingeniuxURL}${contentData?.ScheduleImage?.Value}`}
                        data-ll-status="loaded"
                      />}
                    </figure>
                    <div className="col-md-12 col-dd-6 content">
                      <h3>{contentData?.ScheduleHeading?.Value}</h3>
                      <Markup content={contentData?.ScheduleCopy?.Value} />
                      {/* <ZipChooser search={contentData?.FindAnOffice?.SubmitAlignment.Value !== "InlineRight"}
                                                zipInputs={{
                                                    PlaceholderText: contentData?.FindAnOffice?.PlaceholderText?.Value,
                                                    TextBelowInput: contentData?.FindAnOffice?.TextBelowInput?.Value,
                                                }}
                                                zipCodeList={zipCodeList}
                                                localOfficeList={localOfficeList}
                                                toSchedulingPage={true}
                                            /> */}
                      <ZipChooser
                        submitAlignment={
                          contentData?.FindAnOffice?.SubmitAlignment?.Value
                        }
                        headingAlignment={
                          contentData?.FindAnOffice?.HeadingAlignment?.Value
                        }
                        zipInputs={{
                          PlaceholderText:
                            contentData?.FindAnOffice?.PlaceholderText?.Value,
                          TextBelowInput:
                            contentData?.FindAnOffice?.TextBelowInput?.Value,
                          Heading: contentData?.FindAnOffice?.Heading?.Value,
                        }}
                        zipCodeList={zipCodeList}
                        localOfficeList={localOfficeList}
                        toSchedulingPage={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
            }

            {contentData?.EstimateTabText?.Value && 
            <Collapsible
              open={
                viewPortWidth < 600 && hash === GETESTIMATELINK ? true : false
              }
              trigger={
                <span className="btn toggle-heading" data-tab="get-a-quote">
                  <svg
                    id="iconQuote"
                    height="16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 23.2 32.8"
                  >
                    <title>Get an Estimate</title>
                    <path
                      className="st0"
                      d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
                    />
                    <path
                      className="st0"
                      d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
                    />
                  </svg>{" "}
                  {contentData?.EstimateTabText?.Value}
                </span>
              }
            >
              <div className="content" style={{ padding: "20px" }}>
                <div className="get-estimate-container">
                  <div
                    id={viewPortWidth < 600 ? hash : ""}
                    className="tab-content row current"
                  >
                    {/* jotform */}
                    <GetEstimateJotFrom
                      viewPortWidth={viewPortWidth}
                      franchiseDataToGetEstimate={franchiseDataToGetEstimate}
                      contentData={contentData}
                    />
                    <figure className="col-dd-6 splash-image">
                    {contentData?.EstimateImage?.Value && <img
                        className="lazy loaded"
                        data-src={`${ingeniuxURL}${contentData?.EstimateImage?.Value}`}
                        alt={contentData?.EstimateImage?.AlternateText}
                        title={contentData?.EstimateImage?.AlternateText}
                        width={contentData?.EstimateImage?.Width}
                        height={contentData?.EstimateImage?.Height}
                        loading="lazy"
                        src={`${ingeniuxURL}${contentData?.EstimateImage?.Value}`}
                        data-ll-status="loaded"
                      />}
                    </figure>
                  </div>
                </div>
              </div>
            </Collapsible>
            }
          </div>
        </div>

        <div className="tab-line row" id="booking-ctas">
          <div className="get-estimate-container">
            <div className="col-sd-12 col-dd-7" style={{ width: "100%" }}>
              {/* <TabSelector
                tabs={
                  contentData?.ScheduleTabText?.Value
                    ? [TAB_LET_SCHEDULE, TAB_GET_ESTIMATE]
                    : [TAB_GET_ESTIMATE]
                }
                currentTab={tab}
                changeTab={setTab}
                counts={[0, 0]}
              /> */}

              {/* <div
                className="tagline col-sd-12 col-dd-5"
                style={{ float: "right", marginTop: "-90px" }}
              >
                <div className="responsive-tagline">
                  <span>{contentData?.TextBeforePhoneNumber?.Value}</span>

                  <span className="phone">
                    <SvgPhoneIcon />
                    <a
                      href={
                        swapPhone
                          ? `tel:${swapPhone}`
                          : corpTel
                          ? `tel:${corpTel}`
                          : `tel:${corpDispPhone}`
                      }
                    >
                      {swapPhone ? formatPhone(swapPhone) : corpDispPhone}
                    </a>
                  </span>

                  <div className="heading">
                    {contentData?.TextBelowPhoneNumber?.Value}
                  </div>
                </div>
              </div> */}

              {tab === TAB_LET_SCHEDULE && (
                <ScheduleIt
                  scheduleData={contentData}
                  hashId={hash}
                  zipCodeList={zipCodeList}
                  localOfficeList={localOfficeList}
                />
              )}
              {tab === TAB_GET_ESTIMATE && (
                <GetEstimate
                  estimateData={contentData}
                  hashId={hash}
                  franchiseDataToGetEstimate={franchiseDataToGetEstimate}
                  handymanmatters={
                    contentData?.FindAnOffice?.HandymanMatters?.Value
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default ScheduleItGetEstimate;
