import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

class CannonicalTagWrapper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Router>{this.props.children}</Router>;
  }
}

export default CannonicalTagWrapper;