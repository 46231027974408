import React, { useEffect, useState } from "react";
import "./blog.css";
import BlogPostContent from "../common/BlogPostContent";
import ReactPaginate from "react-paginate";
import { ingeniuxURL } from "../../config/urls";
import { getStickyHeaderHeight } from "../../config/utility";
import BlogBreadcrumbs from "./BlogBreadcrumbs";

const Blog = ({
  contentData,
  categoryName,
  authorName,
  individualPost,
  archiveYear,
  searchInput,
  individualPostContent,
  bookOnlineLink,
  breadcrumbs,
}) => {
  var data = contentData;
  var categoryData = [];
  var authorData = [];

  if (categoryName) {
    for (var i = 0; i < contentData?.results?.length; i++) {
      if (
        Array.isArray(
          contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
        )
      ) {
        for (
          var j = 0;
          j <
          contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
            ?.length;
          j++
        ) {
          if (
            contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories[j]
              ?.Name === categoryName
          ) {
            categoryData.push(contentData?.results[i]);
          }
        }
      } else if (
        contentData?.results[i]?.BlogPost?.IGX_Categories?.BlogCategories
          ?.Name === categoryName
      ) {
        categoryData.push(contentData?.results[i]);
      }
    }
  }
  if (authorName) {
    for (var i = 0; i < contentData?.results?.length; i++) {
      if (
        Array.isArray(
          contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors
        )
      ) {
        for (
          var j = 0;
          j <
          contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors?.length;
          j++
        ) {
          if (
            contentData?.results[i]?.BlogPost?.IGX_Categories?.Authors[
              j
            ]?.Name?.replace(/\s+/g, "") === authorName
          ) {
            authorData.push(contentData?.results[i]);
          }
        }
      } else if (
        contentData?.results[
          i
        ]?.BlogPost?.IGX_Categories?.Authors?.Name?.replace(/\s+/g, "") ===
        authorName
      ) {
        authorData.push(contentData?.results[i]);
      }
    }
  }

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const currentPage = localStorage.getItem("currentPage");
    const currentOffset = localStorage.getItem("currentOffset");
    if (currentPage) {
      setPage(parseInt(currentPage));
    }
    if (currentOffset) {
      setItemOffset(parseInt(currentOffset));
    }
    return () => {
      localStorage.removeItem("currentPage");
      localStorage.removeItem("currentOffset");
    };
  }, []);

  // useEffect for pagination implementation
  useEffect(() => {
    // endOffset is initialized to 0 + 10 = 10
    const endOffset = itemOffset + itemsPerPage;
    // currentItems is set so that it takes the current 10 blog posts
    // itemOffset and endOffset is always updating based on what pagination page number we are on
    if (endOffset > data?.results?.length) {
      setCurrentItems(data?.results?.slice(itemOffset));
    } else {
      setCurrentItems(data?.results?.slice(itemOffset, endOffset));
    }
    // pageCount is set to the totalPages value in the api response
    setPageCount(Math.ceil(data?.totalPages));
  }, [itemOffset, itemsPerPage, data, page]);

  const prevLabel = page == 1 || page === "" ? null : "Previous";
  const nLabel = page == pageCount ? null : "Next";
  const handlePageClick = (event) => {
    // event?.selected is the page number we select
    const newOffset = (event?.selected * itemsPerPage) % data?.results?.length;

    localStorage.setItem("currentPage", event?.selected + 1);
    localStorage.setItem("currentOffset", newOffset);
    // if there is nothing for the category, author, archive, individualPost, search states,
    // then we will navigate to the main blog page, page number whatever the user selects
    if (
      !categoryName &&
      !authorName &&
      !archiveYear &&
      !individualPost &&
      !searchInput
    ) {
      window.location.pathname = `blog/page/${event?.selected + 1}`;
    }
    // if there is a search state, then we navigate to the path specifically for the search scenario
    // these scenarios are also detailed in index.js file
    else if (searchInput) {
      window.location.pathname = `blog/page/${
        event?.selected + 1
      }/search/${searchInput}`;
    }
    // if there is a archive state, then we navigate to the path specifically for the archive scenario
    // these scenarios are also detailed in index.js file
    else if (archiveYear) {
      window.location.pathname = `blog/year/${archiveYear}/page/${
        event?.selected + 1
      }`;
    }
    // if there is a category state, then we navigate to the path specifically for the category scenario
    // these scenarios are also detailed in index.js file
    else if (categoryName) {
      window.location.pathname = `blog/category/${categoryName}/page/${
        event?.selected + 1
      }`;
    }
    // if there is a author state, then we navigate to the path specifically for the author scenario
    // these scenarios are also detailed in index.js file
    else if (authorName) {
      window.location.pathname = `blog/author/${authorName}/page/${
        event?.selected + 1
      }`;
    }
  };

  return (
    <>
      <div
        id="page"
        className="site"
        style={{ paddingTop: `${getStickyHeaderHeight() + 5}px` }}
      >
        <div className="site-inner">
          <a className="skip-link screen-reader-text" href="#content">
            Skip to content
          </a>
          <div id="blog-content" className="site-content">
            {window.location.pathname === "/blog" && (
              <div className="preview-page-title">
                <h1>Blogs</h1>
              </div>
            )}
            <BlogBreadcrumbs
              contentData={breadcrumbs}
              individualPostContent={individualPostContent}
            />
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">
                {categoryName && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Category: <span>{categoryName}</span>
                    </h1>
                  </div>
                )}
                {authorName && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Author: <span>{authorName}</span>
                    </h1>
                  </div>
                )}
                {archiveYear && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Year: <span>{archiveYear}</span>
                    </h1>
                  </div>
                )}
                {searchInput && (
                  <div className="page-header">
                    <h1 className="page-title">
                      Search Results for:{" "}
                      <span>{searchInput.replaceAll("%20", " ")}</span>
                    </h1>
                  </div>
                )}
                {individualPost && window.location.pathname !== "/blog" ? (
                  <>
                    <article
                      id="post-9251"
                      className="post-9251 post type-post status-publish format-standard hentry category-uncategorized"
                    >
                      <BlogPostContent
                        link={individualPostContent?.BlogPost?.SURL}
                        title={individualPostContent?.BlogPost?.Heading?.Value}
                        content={individualPostContent?.BlogPost?.Post?.Value?.replaceAll(
                          "Corporate%20Site",
                          `${ingeniuxURL}Corporate%20Site`
                        ).replaceAll(
                          "Images/Blog%20Images",
                          `${ingeniuxURL}Images/Blog%20Images`
                        )}
                        button={
                          individualPostContent?.BlogPost?.CTAColor?.Value
                        }
                        bookOnlineLink={bookOnlineLink}
                      />

                      <footer className="entry-footer">
                        <span className="byline">
                          <span className="author vcard">
                            <img
                              alt="Author"
                              src="https://secure.gravatar.com/avatar/0d2b66ffe273c68dffd6b47bff8e649d?s=49&amp;d=mm&amp;r=g"
                              srcset="https://secure.gravatar.com/avatar/0d2b66ffe273c68dffd6b47bff8e649d?s=98&amp;d=mm&amp;r=g 2x"
                              className="avatar avatar-49 photo"
                              height="49"
                              width="49"
                              loading="lazy"
                            />
                            <span className="screen-reader-text">Author </span>

                            {Array.isArray(
                              individualPostContent?.BlogPost?.IGX_Categories
                                ?.Authors
                            ) ? (
                              <a
                                href={`/blog/author/${individualPostContent?.BlogPost?.IGX_Categories.Authors[0]?.Name.replace(
                                  /\s+/g,
                                  ""
                                )}`}
                              >
                                {
                                  individualPostContent?.BlogPost
                                    ?.IGX_Categories.Authors[0]?.Name
                                }
                              </a>
                            ) : (
                              <a
                                href={`/blog/author/${individualPostContent?.BlogPost?.IGX_Categories.Authors?.Name.replace(
                                  /\s+/g,
                                  ""
                                )}`}
                              >
                                {
                                  individualPostContent?.BlogPost
                                    ?.IGX_Categories.Authors?.Name
                                }
                              </a>
                            )}
                          </span>
                        </span>
                        <span className="posted-on">
                          <span className="screen-reader-text">Posted on </span>
                          <a
                            href={individualPostContent?.BlogPost?.SURL}
                            rel="bookmark"
                          >
                            <time
                              className="entry-date published"
                              datetime="2024-07-07T19:01:29+00:00"
                            >
                              {
                                individualPostContent?.BlogPost?.DatePublished
                                  ?.Value
                              }
                            </time>
                            <time
                              className="updated"
                              datetime="2024-07-07T19:35:45+00:00"
                            >
                              {
                                individualPostContent?.BlogPost?.DateUpdated
                                  ?.Value
                              }
                            </time>
                          </a>
                        </span>
                        <span className="cat-links">
                          <span className="screen-reader-text">
                            Categories{" "}
                          </span>
                          {Array.isArray(
                            individualPostContent?.BlogPost?.IGX_Categories
                              ?.BlogCategories
                          ) ? (
                            individualPostContent?.BlogPost?.IGX_Categories?.BlogCategories?.map(
                              (cat) => {
                                return (
                                  <a
                                    href={`/blog/category/${cat?.Name.replace(
                                      /\s+/g,
                                      ""
                                    )}`}
                                  >
                                    {cat?.Name}
                                    {", "}
                                  </a>
                                );
                              }
                            )
                          ) : (
                            <a
                              href={`/blog/category/${individualPostContent?.BlogPost?.IGX_Categories.BlogCategories?.Name.replace(
                                /\s+/g,
                                ""
                              )}`}
                            >
                              {
                                individualPostContent?.BlogPost?.IGX_Categories
                                  .BlogCategories?.Name
                              }
                            </a>
                          )}
                        </span>
                      </footer>
                    </article>
                  </>
                ) : (
                  <>
                    <div className="preview-cards-container">
                      {/* this is using the currentItems which changes everytime we change the pagination page number */}
                      {currentItems?.map((res) => {
                        return (
                          <>
                            <BlogPostContent
                              res={res}
                              link={res?.BlogPost?.SURL}
                              title={res?.BlogPost?.Heading?.Value}
                              content={res?.BlogPost?.Post?.Value?.replaceAll(
                                "Corporate%20Site",
                                `${ingeniuxURL}Corporate%20Site`
                              ).replaceAll(
                                "Images/Blog%20Images",
                                `${ingeniuxURL}Images/Blog%20Images`
                              )}
                              button={res?.BlogPost?.CTAColor?.Value}
                              bookOnlineLink={bookOnlineLink}
                              isPreview
                            />
                          </>
                        );
                      })}
                    </div>
                    {pageCount > 1 && (nLabel || prevLabel) && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={nLabel}
                        forcePage={page - 1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(pageCount)}
                        previousLabel={prevLabel}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                        renderPrevItem={() => {
                          if (prevLabel) {
                            return (
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={handlePageClick}
                                >
                                  {prevLabel}
                                </a>
                              </li>
                            );
                          }
                          return null;
                        }}
                        renderNextItem={() => {
                          if (nLabel) {
                            return (
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={handlePageClick}
                                >
                                  {nLabel}
                                </a>
                              </li>
                            );
                          }
                          return null;
                        }}
                      />
                    )}
                  </>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
