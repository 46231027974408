import React, { Component } from "react";
import { connect } from "react-redux";
import { Markup } from "interweave";
import { Helmet } from "react-helmet";
import { HashLink as Link } from "react-router-hash-link";

import { fetchHomePage } from "../../actions/fetchData";
import ServicesSearchOptions from "./ServicesSearchOptions";
import { fetchHeaderPage } from "../../actions/fetchHeaderData";
import HeaderWrapper from "../common/HeaderWrapper";
import FooterWrapper from "../common/FooterWrapper";
import {
  getResultsArray,
  getFranchiseDataFromLS,
  loadApiData,
  saveApiData,
  deleteItemFromSS,
  bgPatternTriangles
} from "../../config/utility";
import { fetchFranchiseHome } from "../../actions/fetchFranchiseHome";
import { corpDynamicTrayList, CorpDynamicTrays } from "../common/DynamicTrays";
import ServicesTrayWithoutTab from "../FranchiseCommon/ServicePackages/ServicesPackages";
import { fetchLocalOfficeData } from "../../actions/fetchLocalOfficeData";
import "./services.css";
import Page404 from "../page-404";
import axios from 'axios';
import { ingeniuxURL } from "../../config/urls"


class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSiteCrl: loadApiData("x31"),
      localOfficeArray: loadApiData("localoffice"),
      franchiseHomeData: loadApiData("franchiseHomeData"),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchHomePage("/Services"));

    const headerItemData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOfficeResults = this?.props?.localOfficeItem;
    const franchiseHomePage = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
    
    if (
      (this.state.globalSiteCrl?.length === 0 ||
        this.state.globalSiteCrl === undefined) &&
      headerItemData?.length === 0
    ) {
      this.props.dispatch(fetchHeaderPage("x4878"));
    }
    if (
      (this.state.localOfficeArray?.length === 0 ||
        this.state.localOfficeArray === undefined) &&
      localOfficeResults?.length === 0
    ) {
      this.props.dispatch(fetchLocalOfficeData("localoffice/orderby/state"));
    }

    if (recentFranchisePath) {
      // this.props.dispatch(fetchFranchiseHome(recentFranchise));

      const localStateSURL =
        this.state.franchiseHomeData && this.state.franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      //scenario 1 - checking Sesson storage contains has the data or redux cache has the data
      //then load the franchisehomepage api again.

      //scenario 2 - in else block if the cached data and the visited franchise SURLS
      //are no the same, removed the cached data from the Session storage and
      //fetch the api again.

      if (
        (this.state.franchiseHomeData?.length === 0 ||
          this.state.franchiseHomeData === undefined) &&
        franchiseHomePage?.length === 0
      ) {
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      } else if (
        this.state.franchiseHomeData &&
        localStateSURL?.replace(rgxforSlashes, "") !==
        recentFranchisePath?.replace(rgxforSlashes, "")
      ) {
        deleteItemFromSS("franchiseHomeData");
        this.props.dispatch(fetchFranchiseHome(recentFranchisePath));
      }
    }
  }

 

  render() {
    const homeItemData = getResultsArray(this.props?.homeItem, "ServicesIndex");
    const resultsData = getResultsArray(this.props?.homeItem);
    const franchiseHomeData = getResultsArray(
      this.props?.franchiseHomeItem,
      "FranchiseHomePage"
    );
    const headerData = getResultsArray(
      this.props?.headerItem,
      "GlobalSiteControl"
    );
    const localOffices = this?.props?.localOfficeItem;

    //Reading the local storage data to the 'headerItemData', 'localOfficeResults', 'franchiseHomePage'
    let headerItemData = loadApiData("x31");
    let localOfficeResults = loadApiData("localoffice");
    let franchiseHomePage = loadApiData("franchiseHomeData");

    // saving GlobalSiteControl, localOffices to LocalStorage
    // if the LS content is empty and Redux cached data available
    if (
      (headerItemData === undefined || headerItemData?.length === 0) &&
      headerData
    ) {
      saveApiData("x31", headerData);
      headerItemData = loadApiData("x31");
    }
    if (
      (localOfficeResults === undefined || localOfficeResults?.length === 0) &&
      localOffices
    ) {
      saveApiData("localoffice", localOffices);
      localOfficeResults = loadApiData("localoffice");
    }

    //saving franchiseHomePage data to sessionStorage if it is not available in
    //sessionStorage from the redux state
    if (
      (franchiseHomePage === undefined || franchiseHomePage?.length === 0) &&
      franchiseHomeData
    ) {
      const localStorageFranchisePath = getFranchiseDataFromLS(
        "recentFranchisePath"
      );
      const apiFranchisePath = franchiseHomeData && franchiseHomeData[0]?.SURL;
      const rgxforSlashes = /^\/|\/$/g;
      if (
        localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        apiFranchisePath?.replace(rgxforSlashes, "")
      ) {
        // console.log(
        //   localStorageFranchisePath?.replace(rgxforSlashes, "") ===
        //     apiFranchisePath?.replace(rgxforSlashes, "")
        // );
        saveApiData("franchiseHomeData", franchiseHomeData);
        franchiseHomePage = loadApiData("franchiseHomeData");
      }
    }

    let allowedZipcodes = getResultsArray(
      localOfficeResults,
      "AllowedZipcodes"
    );
    let ownedZipcodes = getResultsArray(localOfficeResults, "OwnedZipcodes");
    let localOfficeZipcodes = allowedZipcodes;
    for (let i = 0; i < localOfficeZipcodes.length; i++) {
      localOfficeZipcodes[i] = localOfficeZipcodes[i].concat(ownedZipcodes[i]);
    }

    let combinedPropsFindOffice;
    combinedPropsFindOffice = {
      ...{
        ...headerItemData[0]?.Footer?.FindAnOffice,
        ...{ zipCodeList: localOfficeZipcodes },
        ...{ localOfficeList: localOfficeResults },
      },
    };

    const franchiseCorpCtrl =
      franchiseHomePage && franchiseHomePage[0]?.FranchiseCorporateControl;
    const franchiseId = franchiseCorpCtrl?.ClientID?.Value;
    const franchiseName = franchiseCorpCtrl?.FranchiseName?.Value;
    const stateAbbr = franchiseCorpCtrl?.StateAbbreviation?.Value;
    const franchiseDataToGetEstimate = {
      franchiseId,
      franchiseName,
      stateAbbr,
    };

    const pageTitle = homeItemData[0]?.Title?.Value;
    const browserSuffix =
      headerItemData &&
      headerItemData[0]?.GlobalSettings?.BrowserTitleSuffix?.Value;
    return (
      <>
        {this.props?.homeItem.totalResults === 0 ? (
          <Page404 />
        ) : (
          <>
            <Helmet>
              <title>
                 {/* removed "| Ace Hardware Plumbing Services" in title */}
                {pageTitle ? `${pageTitle}` : browserSuffix}
              </title>
              <meta
                name="description"
                content={homeItemData[0]?.Description?.Value}
              />
            </Helmet>
            <HeaderWrapper headerItemData={headerItemData} />

            <div className="body-wrap" style={{ backgroundImage:bgPatternTriangles && `url(${ingeniuxURL}${bgPatternTriangles})` }}>
              <section id="services-banner">
                <div className="banner-slide services">
                  <div className="services-container">
                    <div className="banner-text">
                      <h1 className="header">
                        {homeItemData[0]?.Heading?.Value}
                      </h1>
                    </div>
                  </div>
                </div>
              </section>
              <section id="services-content" className="content-section twelve">
                <div className="breadcrumbs">
                  <div className="services-container">
                    <ul>
                      {homeItemData[0]?.BreadcrumbNavigation?.map(
                        (breadCrum, i, row) => {
                          if (i + 1 === row.length) {
                            return (
                              <li>
                                <span> {breadCrum?.Name}</span>
                              </li>
                            );
                          } else {
                            return (
                              <li>
                                <Link to={`/${breadCrum?.URL}`}>
                                  {" "}
                                  {breadCrum?.Name}
                                </Link>{" "}
                                {" | "}
                              </li>
                            );
                          }
                        }
                      )}
                    </ul>
                  </div>
                </div>
                <div className="content-wrap">
                  <div className="services-container row">
                    {/* <div className="col-dd-8"> */}
                    {
                      <Markup
                        content={homeItemData[0]?.IntroCopy?.Value?.replace(
                          "/acehandymandss/x58.xml",
                          "/offices"
                        )?.replace("/x58.xml", "/offices")}
                      />
                    }

                    {/* </div> */}
                  </div>
                </div>
              </section>
            </div>
            {/* </div> */}

            {/* <PopularServices
              contentData={homeItemData[0]?.MostPopularServices}
            /> */}

            {/* commenting this for plumbing schema changes */}
            {/* <ServicesSearchOptions
              contentData={resultsData[0]}
              franchiseData={franchiseHomePage && franchiseHomePage[0]}
            /> */}
            
            {/* <div className="findoffice col-">
              <FindAnOfficeTray contentData={combinedPropsFindOffice} />
            </div> */}
                
            {homeItemData[0]?.Trays?.map((tray, index) => {
              if (
                Object.keys(corpDynamicTrayList)?.find(
                  (trayName) => trayName === tray?.TrayName
                )
              ) {
                let combinedProps;

                if (
                  tray?.TrayName === "ScheduleandEstimateTabTray" ||
                  tray?.TrayName === "ScheduleandEstimateTray"
                ) {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                      ...{ franchiseDataToGetEstimate }
                    },
                  };
                } else if (tray?.TrayName === "ServicesTray") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{
                        franchiseData:
                          franchiseHomePage && franchiseHomePage[0],
                      },
                    },
                  };
                } else if (tray?.TrayName === "FindAnOffice") {
                  combinedProps = {
                    ...{
                      ...tray,
                      ...{ zipCodeList: localOfficeZipcodes },
                      ...{ localOfficeList: localOfficeResults },
                    },
                  };
                }
                else {
                   combinedProps = { ...tray };
                }

                return (
                  <CorpDynamicTrays
                    trayName={tray?.TrayName}
                    contentData={combinedProps}
                    key={index}
                  />
                );
              }
            })}

            <ServicesTrayWithoutTab
              contentData={{
                ...resultsData[0]?.ServicesIndex?.ServicesTray,
                franchiseData: franchiseHomePage && franchiseHomePage[0],
              }}
            />

            <FooterWrapper headerItemData={headerItemData} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeItem: state.products.item,
  loading: state.products.loading,
  error: state.products.error,
  headerItem: state.headerReducer.headerItem,
  franchiseHomeItem: state?.franchiseHomePageReducer?.franchseHome,
  franchiseLoading: state?.franchiseHomePageReducer?.loading,
  franchiseError: state?.franchiseHomePageReducer?.error,
  localOfficeItem: state?.localOfficeReducer?.item,
  localOfficeLoading: state?.localOfficeReducer?.loading,
  localOfficeError: state?.localOfficeReducer?.error,
});

export default connect(mapStateToProps)(Services);
