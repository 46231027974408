import React from "react";
import './Accreditations.css'
import { ingeniuxURL } from "../../config/urls";


const Accreditations = ({ contentData }) => {
  return (
    <section>
      <div class="row">
        <div class="col-lg-12">
          <h3 class="accreditations-title">Accreditations</h3>
          <div className="accreditations-banner">
            {Array.isArray(contentData?.Logo) &&
              contentData?.Logo?.map((logo) => {
                return (
                  <figure>
                    {logo?.Value && <img
                      className=""
                      src={`${ingeniuxURL}${logo?.Value}`}
                      alt={logo?.AlternateText}
                      title={logo?.AlternateText}
                      width="50" height="50"
                    />}
                  </figure>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accreditations;
