import { Markup } from "interweave";
import React from "react";
import { getFranchiseDataFromLS,getStickyHeaderHeight } from "../../config/utility";
import { ingeniuxURL } from "../../config/urls";
import "./careersHeroWithOverlay.css";
import WhyChooseUsBanner from "../WhyChooseUsPage/WhyChooseUsBanner";

const CareersHeroWithOverlayTray = ({
  BackgroundImage,
  Heading,
  SubHeading = "",
  CopyText = "",
  PageUrl,
  PageLink,
  TextBoxBackgroundColor,
  TextBoxAlignment,
  Page = {},
  Copy = "",
}) => {
  return BackgroundImage ? (
    <section
      id={
        getFranchiseDataFromLS("recentFranchisePath") !== undefined
          ? "join-banner"
          : "banner-without-recentFranchise"
      }
      style={{paddingTop:`${getStickyHeaderHeight()+5}px`}}
    >
      <div
        className="careers"
        style={{
          backgroundImage:BackgroundImage && `url(${ingeniuxURL}${BackgroundImage})`,
          backgroundColor: "#000",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div className="careers-banner-text">
          <div className="banner-careers-wrap">
            <div
              className="careers-bg-wrap"
              style={{
                backgroundColor: `${
                  TextBoxBackgroundColor === "Dark"
                    ? "rgba(0, 0, 0, 0.8)"
                    : TextBoxBackgroundColor === "Light"
                    ? "rgba(0, 0, 0, 0.8)"
                    : "rgba(255, 255, 255, 0.8)"
                }`,
                float: `${
                  TextBoxAlignment === "Left"
                    ? "left"
                    : TextBoxAlignment === "Right"
                    ? "right"
                    : "none"
                }`,
              }}
            >
              <div className="header">
                <h1 className="headline h1">
                  <Markup content={Heading} />
                </h1>
                {Copy && (
                  <p className="headline p">
                    <Markup content={Copy} />
                  </p>
                )}
              </div>
              {CopyText && (
                <p className="copy-text">
                  <Markup content={CopyText} />
                </p>
              )}

              {Page?.Name === "CTA" && PageUrl && (
                <a className="btn careers-btn" target="_blank" href={PageUrl}>
                  {PageLink}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="why-choose-us-franchise" style={{ marginTop: "30px" }}>
      <WhyChooseUsBanner contentData={Heading} />
    </div>
  );
};

export default CareersHeroWithOverlayTray;
