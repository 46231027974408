import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMultiStepForm from "./useMultiStepForm";
import {
  BASIC_INFO,
  PERSONAL_INFO,
  EMPLOYMENT_INFO,
  EDUCATION_INFO,
  REFERENCES_INFO,
  CERTIFICATE_AND_SIGNATURE_INFO,
} from "./FormFields";
import axios from "axios";
import BasicDetailsForm from "./DetailForms/BasicDetailsForm/BasicDetailsForm";
import PersonalDetailsForm from "./DetailForms/PersonalDetailsForm/PersonalDetailsForm";
import EducationDetailsForm from "./DetailForms/EducationDetailsForm/EducationDetailsForm";
import EmploymentDetailsForm from "./DetailForms/EmploymentDetailsForm/EmploymentDetailsForm";
import ReferenceDetailsForm from "./DetailForms/ReferenceDetailsForm/ReferenceDetailsForm";
import CertificateAndSignatureForm from "./DetailForms/CertificateAndSignatureForm/CertificateAndSignatureForm";
import "./CareersForm.css";
import { multipartFormHeader,regxEmail,
  regxUSPhone,
  regxUSZipcode,
  emptyCheck } from "../../config/utility";
import { azureEmailingUrl } from "../../config/urls";

const formTitles = [
  "Basic Information",
  "Personal Information",
  "Employment Information",
  "Education Information",
  "References Information",
  "Certificate and Signature Information",
];

const CareersForm = ({ franchiseBaseUrl, franchiseEmailID, franchiseName }) => {
  const [data, setData] = useState({
    ...BASIC_INFO,
    ...PERSONAL_INFO,
    ...EMPLOYMENT_INFO,
    ...EDUCATION_INFO,
    ...REFERENCES_INFO,
    ...CERTIFICATE_AND_SIGNATURE_INFO,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateFields = (field, groupFieldName = "") => {
    if(handleValidation(field)) {
    setData((prevData) => {
      let updatedFieldList = { ...prevData };
      if (groupFieldName !== "") {
        updatedFieldList[groupFieldName] = {
          ...updatedFieldList[groupFieldName],
          ...field,
        };
      } else {
        updatedFieldList = { ...updatedFieldList, ...field };
      }
      return updatedFieldList;
    });
    }
  };

  const handleFileUpload = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      setData((prevData) => {
        let currentData = { ...prevData };
        currentData.resume = input.files[0];
        return currentData;
      });
    }
  };
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { steps, currentStepIndex, step, back, next, isFirstStep, isLastStep } =
    useMultiStepForm([
      <BasicDetailsForm {...data} updateFields={updateFields} errors={errors} franchiseBaseUrl={franchiseBaseUrl} franchiseName={franchiseName} />,
      <PersonalDetailsForm {...data} updateFields={updateFields} errors={errors} franchiseBaseUrl={franchiseBaseUrl} franchiseName={franchiseName}/>,
      <EmploymentDetailsForm {...data} updateFields={updateFields} errors={errors} franchiseBaseUrl={franchiseBaseUrl} franchiseName={franchiseName}/>,
      <EducationDetailsForm {...data} updateFields={updateFields} errors={errors} franchiseBaseUrl={franchiseBaseUrl} franchiseName={franchiseName}/>,
      <ReferenceDetailsForm
        {...data}
        updateFields={updateFields}
        handleFileUpload={handleFileUpload}
        errors={errors}
        franchiseBaseUrl={franchiseBaseUrl}
        franchiseName={franchiseName}
      />,
      <CertificateAndSignatureForm {...data} updateFields={updateFields} errors={errors} franchiseBaseUrl={franchiseBaseUrl} franchiseName={franchiseName} />,
    ]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  
  const handleValidation = (field) => {
    let isValid = true;    
    const newErrors = {...errors};
    
    if(currentStepIndex === 0) {
      if (!emptyCheck.test(field?.Position)) {
        isValid = false;
        newErrors.Position = 'Please enter position you are applying for';
        data.Position = '';
      } else {
        newErrors.Position = '';
      }
      
      if (!emptyCheck.test(field?.Name?.firstName)) {
        isValid = false;
        newErrors.firstName = 'Please enter first name';
        data.Name.firstName = '';
      } else {
        newErrors.firstName = '';
      }
      
      if (!emptyCheck.test(field?.Name?.middleName)) {
        isValid = false;
        newErrors.middleName = 'Please enter middle name';
        data.Name.middleName = '';
      } else {
        newErrors.middleName = '';
      }
      
      if (!emptyCheck.test(field?.Name?.lastName)) {
        isValid = false;
        newErrors.lastName = 'Please enter last name';
        data.Name.lastName = '';
      } else {
        newErrors.lastName = '';
      }
      
      if (!emptyCheck.test(field?.Address?.addressLine1)) {
        isValid = false;
        newErrors.addressLine1 = 'Please enter address line 1';
      } else {
        newErrors.addressLine1 = '';
      }
      
      if (!emptyCheck.test(field?.Address?.City)) {
        isValid = false;
        newErrors.City = 'Please enter city';
        data.City = '';
      } else {
        newErrors.City = '';
      }
      
      if (!emptyCheck.test(field?.Address?.State)) {
        isValid = false;
        newErrors.State = 'Please enter state';
        data.State = '';
      } else {
        newErrors.State = '';
      }
      
      if (!emptyCheck.test(field?.zipCode) || !emptyCheck.test(field?.Address?.zipCode)) {
        isValid = false;
        newErrors.zipCode = 'Please enter zip code';
        data.Address.zipCode = '';
      } else if(('zipCode' in field) && !regxUSZipcode.test(field?.zipCode)) {
        newErrors.zipCode = 'Please enter valid zip code';
        data.Address.zipCode = field?.zipCode;
      } else {
        newErrors.zipCode = '';
      }
      
      if (!emptyCheck.test(field?.yacr)) {
        isValid = false;
        newErrors.yacr = 'Please enter yacr';
        data.yacr = '';
      } else {
        newErrors.yacr = '';
      }
      
      if (field?.EmailAddress === '') {
          isValid = false;
          newErrors.EmailAddress = 'Please enter email address';
          data.EmailAddress = '';
      }  else if (('EmailAddress' in field) && !validateEmail(field?.EmailAddress)) {
          //isValid = false;
          newErrors.EmailAddress = 'Please enter valid email address';
          data.EmailAddress = field?.EmailAddress;
      } else {
          newErrors.EmailAddress = '';
      }
      
      if (!emptyCheck.test(field?.PhoneNumber)) {
        isValid = false;
        newErrors.PhoneNumber = 'Please enter phone number';
        data.PhoneNumber = '';
      } else if (('PhoneNumber' in field) && !regxUSPhone.test(field?.PhoneNumber)) {
        //isValid = false;
        newErrors.PhoneNumber = 'Please enter valid phone number';
        data.PhoneNumber = '';
      } else {
        newErrors.PhoneNumber = '';
      }      
    }

    if(currentStepIndex === 1) {
      if (!emptyCheck.test(field?.LegallyAuth)) { 
          isValid = false;
          newErrors.LegallyAuth = 'Please choose yes or no';
          data.LegallyAuth = '';
      } else {
          newErrors.LegallyAuth = '';
      }

      if (!emptyCheck.test(field?.LegalAge)) { 
          isValid = false;
          newErrors.LegalAge = 'Please choose yes or no';
          data.LegalAge = '';
      } else {
          newErrors.LegalAge = '';
      }

      if (!emptyCheck.test(field?.crimeConvicted)) { 
          isValid = false;
          newErrors.crimeConvicted = 'Please choose yes or no';
          data.crimeConvicted = '';
      } else {
          newErrors.crimeConvicted = '';
      }

    }

    if(currentStepIndex === 2) {
      if (!emptyCheck.test(field?.emp1Employer1)) { 
        isValid = false;
        newErrors.emp1Employer1 = 'Please enter employer name';
        data.emp1Employer1 = '';
      } else {
        newErrors.emp1Employer1 = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1EmployedFrom)) {
        isValid = false;
        newErrors.emp1EmployedFrom = 'Please enter start date of employment';
        data.emp1EmployedFrom = '';
      } else {
        newErrors.emp1EmployedFrom = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1EmployedTo)) {
        isValid = false;
        newErrors.emp1EmployedTo = 'Please enter end date of employment';
        data.emp1EmployedTo = '';
      } else {
        newErrors.emp1EmployedTo = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1TypeofWorkPerformed)) {
        isValid = false;
        newErrors.emp1TypeofWorkPerformed = 'Please enter type of work performed';
        data.emp1TypeofWorkPerformed = '';
      } else {
        newErrors.emp1TypeofWorkPerformed = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1StartingSalary)) {
        isValid = false;
        newErrors.emp1StartingSalary = 'Please enter starting salary';
        data.emp1StartingSalary = '';
      } else {
        newErrors.emp1StartingSalary = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1EndingSalary)) {
        isValid = false;
        newErrors.emp1EndingSalary = 'Please enter ending salary';
        data.emp1EndingSalary = '';
      } else {
        newErrors.emp1EndingSalary = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1ReasonForLeaving)) {
        isValid = false;
        newErrors.emp1ReasonForLeaving = 'Please enter reason for leaving';
        data.emp1ReasonForLeaving = '';
      } else {
        newErrors.emp1ReasonForLeaving = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1Address?.emp1AddressLine1)) {
        isValid = false;
        newErrors.emp1AddressLine1 = 'Please enter Address Line 1';
        data.emp1Address.emp1AddressLine1 = '';
      } else {
        newErrors.emp1AddressLine1 = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1Address?.emp1AddressLine2)) {
        isValid = false;
        newErrors.emp1AddressLine2 = 'Please enter Address Line 2';
        data.emp1Address.emp1AddressLine2 = '';
      } else {
        newErrors.emp1AddressLine2 = ''; // Clear error for the field if validation passes
      }

      if (!emptyCheck.test(field?.emp1Address?.emp1City)) {
        isValid = false;
        newErrors.emp1City = 'Please enter City';
        data.emp1Address.emp1City = '';
      } else {
        newErrors.emp1City = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1Address?.emp1State)) {
        isValid = false;
        newErrors.emp1State = 'Please enter State';
        data.emp1Address.emp1State = '';
      } else {
        newErrors.emp1State = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1NameofSupervisor)) {
        isValid = false;
        newErrors.emp1NameofSupervisor = 'Please enter name of supervisor';
        data.emp1NameofSupervisor = '';
      } else {
        newErrors.emp1NameofSupervisor = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1PhoneNumber)) {
        isValid = false;
        newErrors.emp1PhoneNumber = 'Please enter phone number of employer';
        data.emp1PhoneNumber = '';
      } else if (('emp1PhoneNumber' in field) && !regxUSPhone.test(field?.emp1PhoneNumber)) {
      // else if(regxUSPhone.test(field?.emp1PhoneNumber)){
        //isValid = false;
        newErrors.emp1PhoneNumber = 'Please enter valid phone number';
        //data.emp1PhoneNumber = '';
      } else {
        newErrors.emp1PhoneNumber = ''; // Clear error for the field if validation passes
      }
    
      if (!emptyCheck.test(field?.emp1Contact)) {
        isValid = false;
        newErrors.emp1Contact = 'Please select yes or no';
        data.emp1Contact = '';
      } else {
        newErrors.emp1Contact = '';
      }    
    }
    
    if(currentStepIndex === 4){
      // For Reference 1
      if (!emptyCheck.test(field?.ref1Name?.ref1FirstName)) {
        isValid = false;
        newErrors.ref1FirstName = "Please enter Reference 1 First Name";
        data.ref1Name.ref1FirstName = "";
      } else {
        newErrors.ref1FirstName = "";
      }

      if (!emptyCheck.test(field?.ref1Name?.ref1LastName)) {
        isValid = false;
        newErrors.ref1LastName = "Please enter Reference 1 Last Name";
        data.ref1Name.ref1LastName = "";
      } else {
        newErrors.ref1LastName = "";
      }

      if (field?.ref1EmailId === '') {
        isValid = false;
        newErrors.ref1EmailId = "Please enter Reference 1 Email";
        data.ref1EmailId = "";
      } else if (('ref1EmailId' in field) && !validateEmail(field?.ref1EmailId)) {
        //isValid = false;
        newErrors.ref1EmailId = 'Please enter valid email address';
        data.ref1EmailId = field?.ref1EmailId;
      } else {
        newErrors.ref1EmailId = "";
      }

      if (!emptyCheck.test(field?.ref1PhoneNumber)) {
        isValid = false;
        newErrors.ref1PhoneNumber = "Please enter Reference 1 Phone Number";
        data.ref1PhoneNumber = "";
      } else if (('ref1PhoneNumber' in field) && !regxUSPhone.test(field?.ref1PhoneNumber)) {
        //isValid = false;
        newErrors.ref1PhoneNumber = 'Please enter valid phone number';
      } else {
        newErrors.ref1PhoneNumber = "";
      }

      // For Reference 2
      if (!emptyCheck.test(field?.ref2Name?.ref2FirstName)) {
        isValid = false;
        newErrors.ref2FirstName = "Please enter Reference 2 First Name";
        data.ref2Name.ref2FirstName = "";
      } else {
        newErrors.ref2FirstName = "";
      }

      if (!emptyCheck.test(field?.ref2Name?.ref2LastName)) {
        isValid = false;
        newErrors.ref2LastName = "Please enter Reference 2 Last Name";
        data.ref2Name.ref2LastName = "";
      } else {
        newErrors.ref2LastName = "";
      }

      if (field?.ref2EmailId === '') {
        isValid = false;
        newErrors.ref2EmailId = "Please enter Reference 2 Email";
        data.ref2EmailId = "";
      } else if (('ref2EmailId' in field) && !validateEmail(field?.ref2EmailId)) {
        //isValid = false;
        newErrors.ref2EmailId = 'Please enter valid email address';
        data.ref2EmailId = field?.ref2EmailId;
      } else {
        newErrors.ref2EmailId = "";
      }

      if (!emptyCheck.test(field?.ref2PhoneNumber)) {
        isValid = false;
        newErrors.ref2PhoneNumber = "Please enter Reference 2 Phone Number";
        data.ref2PhoneNumber = "";
      } else if (('ref2PhoneNumber' in field) && !regxUSPhone.test(field?.ref2PhoneNumber)) {
        //isValid = false;
        newErrors.ref2PhoneNumber = 'Please enter valid phone number';
        //data.PhoneNumber = '';
      } else {
        newErrors.ref2PhoneNumber = "";
      }

      // For Reference 3
      if (!emptyCheck.test(field?.ref3Name?.ref3FirstName)) {
        isValid = false;
        newErrors.ref3FirstName = "Please enter Reference 3 First Name";
        data.ref3Name.ref3FirstName = "";
      } else {
        newErrors.ref3FirstName = "";
      }

      if (!emptyCheck.test(field?.ref3Name?.ref3LastName)) {
        isValid = false;
        newErrors.ref3LastName = "Please enter Reference 3 Last Name";
        data.ref3Name.ref3LastName = "";
      } else {
        newErrors.ref3LastName = "";
      }

      if (field?.ref3EmailId === '') {
        isValid = false;
        newErrors.ref3EmailId = "Please enter Reference 3 Email";
        data.ref3EmailId = "";
      } else if (('ref3EmailId' in field) && !validateEmail(field?.ref3EmailId)) {
        //isValid = false;
        newErrors.ref3EmailId = 'Please enter valid email address';
        data.ref3EmailId = field?.ref3EmailId;
      } else {
        newErrors.ref3EmailId = "";
      }

      if (!emptyCheck.test(field?.ref3PhoneNumber)) {
        isValid = false;
        newErrors.ref3PhoneNumber = "Please enter Reference 3 Phone Number";
        data.ref3PhoneNumber = "";
      } else if (('ref3PhoneNumber' in field) && !regxUSPhone.test(field?.ref3PhoneNumber)) {
        //isValid = false;
        newErrors.ref3PhoneNumber = 'Please enter valid phone number';
        //data.PhoneNumber = '';
      } else {
        newErrors.ref3PhoneNumber = "";
      }
    }

    if(currentStepIndex === 5) {
      if (('genuineAnswersAcknowledgement' in field) && field?.genuineAnswersAcknowledgement === false) {
        isValid = false;
        newErrors.genuineAnswersAcknowledgement = ('genuineAnswersAcknowledgement' in field && field.genuineAnswersAcknowledgement === false) ? "Please check the checkbox" : "Error: genuineAnswersAcknowledgement is not checked";
        data.genuineAnswersAcknowledgement = field?.genuineAnswersAcknowledgement;
      } else {
        newErrors.genuineAnswersAcknowledgement = "";
      }
      
      if (('termsAndConditionsAcknowledgement' in field) && field?.termsAndConditionsAcknowledgement === false) {
        isValid = false;
        newErrors.termsAndConditionsAcknowledgement = ('termsAndConditionsAcknowledgement' in field && field.termsAndConditionsAcknowledgement === false) ? "Please check the checkbox" : "Error: termsAndConditionsAcknowledgement is not checked";
        data.termsAndConditionsAcknowledgement = field?.termsAndConditionsAcknowledgement;
      } else {
        newErrors.termsAndConditionsAcknowledgement = "";
      }
      
      
      if (isValid && field?.genuineAnswersAcknowledgement && field?.termsAndConditionsAcknowledgement) {
        console.log("Submit can be done.");
      }
    }
    setErrors(newErrors);
    
    return isValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLastStep) {
      if(handleValidation(data)){
        next();
      }      
      window.scrollTo(0, 120);
    } else {
      if(handleValidation(data)) {
        setIsLoading(true);
        const careersPayload = { ...data, franchiseEmailID, source:'plumbing-career-notification'};
        // console.log("PAYLOAD:", careersPayload);
        setIsSubmitting(true);
        // api call to submit data

        // const formData = new FormData();
        // Object.keys(payloadData).forEach((key) =>
        //   formData.append(key, payloadData[key])
        // );

        // Compress the form data using LZString
        // const compressedData = LZString.compressToUTF16(JSON.stringify(careersPayload));

        axios
          .post(
            azureEmailingUrl,
            careersPayload,
            multipartFormHeader
          )
          .then(() => {
            setIsLoading(true);
            //setTimeout(() => {
            //if (res.status === 200) {
              navigate(`${franchiseBaseUrl}/careers/application_success`);
            //}, 3000);
            //}
          })
          .catch((err) => {
            console.log("Error:", err);
            setIsSubmitting(false);
            setIsLoading(false);
          });
      }
    }
  };
  return (
    <div className="careers-form-container">
      {/* <div>
        <BreadCrumb contentData={careersBreadCrumb} />
      </div> */}
      <form onSubmit={handleSubmit}>
        {isLoading && (
          <div className="loadergif">
            <div className="spinner"></div>
            <p className="loading-text">Loading...</p>
          </div>

        )}
        <h1 className="career-header">Careers</h1>
        <div className="step-info">          
          <strong>
            Step:{currentStepIndex + 1}/{steps.length}
          </strong>
        </div>
        {step}
        <div className="form-navigation-container">
          {!isFirstStep && (
            <button
              type="button"
              onClick={() => {
                back();
                window.scrollTo(0, 120);
              }}
            >
              Back
            </button>
          )}

          <button type="submit">
            {isLastStep ? "Submit" : "Next"}
            {isSubmitting && (
              <span className="submit-spinner">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CareersForm;
