import React from 'react'
import './index.css';
const PageLoader = () => {
  return (
    <>
    <div className="loading">
      <div className='uil-ring-css'>
        <div></div>
      </div>
      <p className='center'>Loading....</p>
    </div>
    </>


  )
}

export default PageLoader