import React from "react";
import { convert } from "html-to-text";
import { HashLink as Link } from "react-router-hash-link";
import PreviewCard from "../BlogPage/PreviewCard";
import "./BlogPostContent.css";

const BlogPostContent = ({
  link,
  title,
  content,
  button,
  bookOnlineLink,
  res,
  getFooter,
  isPreview,
}) => {
  function AllowIframes(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }
  const wrongIGXImagePath1 = `Images/${process.env.REACT_APP_API_BASE_URL}`;
  const correctIGXImagePath1 = `${process.env.REACT_APP_API_BASE_URL}Images/`;
  const wrongIGXImagePath2 = `src="Images/`;
  const correctIGXImagePath2 = `src="${process.env.REACT_APP_API_BASE_URL}Images/`;
  const wrongIGXImagePath3 = `Images/Plumbing/${process.env.REACT_APP_API_BASE_URL}`;
  const correctIGXImagePath3 = `Images/Plumbing/`;
  const wrongIGXImagePath4 = `Plumbing/${process.env.REACT_APP_API_BASE_URL}`;
  const correctIGXImagePath4 = `${process.env.REACT_APP_API_BASE_URL}Plumbing/`;

  const previewText =
    content &&
    convert(content, {
      selectors: [{ selector: "a", options: { ignoreHref: true } }],
    }).substr(0, 100);

  return (
    <div className="blogContent">
      {!isPreview && (
        <div className="blog-entry-header">
          <h1 className="entry-title">{title}</h1>
        </div>
      )}

      {isPreview && (
        <PreviewCard
          title={title}
          previewText={previewText}
          link={link}
          bookOnlineLink={bookOnlineLink}
          button={button}
          res={res}
          getFooter={getFooter}
        />
      )}

      {!isPreview && (
        <AllowIframes
          iframe={content
            ?.replaceAll(wrongIGXImagePath1, correctIGXImagePath1)
            ?.replaceAll(wrongIGXImagePath2, correctIGXImagePath2)
            ?.replaceAll(wrongIGXImagePath3, correctIGXImagePath3)
            ?.replaceAll(wrongIGXImagePath4, correctIGXImagePath4)
            ?.replaceAll(/<img [^>]*alt="Book Online Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Book Online Today![^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Schedule Now Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Contact Us Today![^"]*"[^>]*>/gi, "")
            ?.replaceAll(/<img [^>]*alt="Learn More Button[^"]*"[^>]*>/gi, "")
            ?.replaceAll(
              /<a href="\/offices">(.*?)<\/a>/gi,
              "<a href='/offices' title='Link to Find a Craftsman page'>Learn More</a>"
            )
            ?.replaceAll(".xml", "")}
        />
      )}

      {/* <button type="button" className="booktodaybtn" style={{ background: `${button === "Red" ? '#d40029' : button === "Yellow" ? 'yellow' : 'green'}` }}> */}
      {!isPreview && button && (
        <Link
          to={`/#GetEstimateLink`}
          className="bookbtn"
          style={{
            background: `${
              button === "Red"
                ? "#d40029"
                : button === "Yellow"
                ? "yellow"
                : "green"
            }`,
            color: "#fff",
          }}
          title="Get an Estimate"
        >
          GET AN ESTIMATE
        </Link>
      )}
      {/* </button> */}
    </div>
  );
};

export default BlogPostContent;
