import { useState } from "react";
const useMultiStepForm = (steps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const next = () => {
    setCurrentStepIndex((prevIndex) => {
      if (prevIndex >= steps.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const back = () => {
    setCurrentStepIndex((prevIndex) => {
      if (prevIndex <= 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    next,
    back,
    steps,
    isFirstStep:currentStepIndex===0,
    isLastStep:currentStepIndex===steps.length-1,
  };
};

export default useMultiStepForm;
