import axios from "axios";
import {handleErrorBoundary, fetchFranchiseHomePageRequest, fetchFranchiseHomePageSuccess, fetchFranchiseHomePageError } from "./action";
import { ingeniuxURL } from "../config/urls";

export function fetchFranchiseHome(pageLink) {
  return dispatch => {
    dispatch(fetchFranchiseHomePageRequest());
    axios
      .get(
        `${ingeniuxURL}api/page?path=${pageLink}`
      )
      .then(response => {
        dispatch(fetchFranchiseHomePageSuccess(response.data));
      })
      .catch(error => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchFranchiseHomePageError(error));
      });
  };
}
