import React, { useState, useEffect, useCallback } from "react";
import { Markup } from "interweave";
import { Helmet } from "react-helmet";

import DynamicLinkList from "../../common/DynamicLinks";
import "./headerFranchise.css";
import { ingeniuxURL } from "../../../config/urls";
import {
  isExternalUrl,
  extractPath,
  getFranchiseDataFromLS,
  formatPhone,
  storeFranchiseDataToLS,
} from "../../../config/utility";
import SvgPhoneIcon from "../../common/SvgPhoneIcon";
import MenuBar from "./MenuBar";
import MetaTag from "../../common/SEO/MetaTag";

const HeaderFranchise = React.memo(
  ({
    metaData,
    franchiseMetaData = [],
    alertContent,
    headerContent,
    otherProps = [],
    corpHeaderProps = [],
    scheduleAppointmentCTA,
    getEstimateCTA,
    metaDescription,
  }) => {
    const [otherProp, frnachiseBaseURL] = otherProps;
    const [bookOnlineLink, getEstimateLink] = corpHeaderProps;
    const [showNav, setShowNav] = useState(true);
    const [swapPhone, setSwapPhone] = useState("");
    const globalSettings = corpHeaderProps && corpHeaderProps[4];
    const favIcon16x16 = globalSettings?.FavIcon16x16;
    const favIcon32x32 = globalSettings?.FavIcon32x32;
    const favIconAppleTouch = globalSettings?.FavIconAppleTouch;
    const theme = globalSettings?.ThemeColor;

    useEffect(() => {
      localStorage.setItem("previousPath", "franchise");
    }, []);

    useEffect(() => {
      const storeFranchisePhoneToLS = () => {
        const franchisePhone = otherProps && otherProps[0]?.PhoneNumber?.Value;
        if (franchisePhone)
          storeFranchiseDataToLS("recentFranchisPhone", franchisePhone);
      };
      storeFranchisePhoneToLS();
    }, [otherProps && otherProps[0]?.ClientID?.Value]);

    useEffect(() => {
      let localPhone;

      let intervalId = setInterval(() => {
        localPhone = getFranchiseDataFromLS("recentFranchisPhone");
        setSwapPhone(localPhone);
      }, 10);

      return () => {
        clearInterval(intervalId);
      };
    }, [swapPhone]);

    const headerArray = [];
    const partition = (array) => {
      if (array) {
        let pass = [];
        let fail = [];
        array.forEach((element) => {
          pass.push(element);
        });
        return [pass];
      }
    };
    let headerData = headerContent?.MenuTab?.map((headerData) => {
      if (Array.isArray(headerData?.Page)) {
        const [pass] = partition(headerData?.Page);
        pass.map((menuItem) =>
          headerArray.push({
            Link: menuItem?.Link,
            URL: menuItem?.URL,
          })
        );
      } else {
        headerArray.push({
          Link: headerData?.Page?.Link,
          URL: headerData?.Page?.URL,
          Schema: headerData?.Page?.Schema,
          CustomURL: headerData?.Page?.CustomURL,
        });
      }
      return headerArray;
    });
    return (
      <>
        <Helmet>
          <meta name="msapplication-TileColor" content={theme?.Value} />
          <meta name="theme-color" content={theme?.Value}></meta>
          <meta
            name="bowser-title-suffix"
            content={globalSettings?.BrowserTitleSuffix?.Value}
          ></meta>
          <link
            rel="shortcut icon"
            type="image/png"
            href={
              `${favIcon16x16?.Value}` && `${ingeniuxURL}${favIcon16x16?.Value}`
            }
            sizes={`${favIcon16x16?.Width}x${favIcon16x16?.Height}`}
          />
          <link
            rel="shortcut icon"
            type="image/png"
            href={
              `${favIcon32x32?.Value}` && `${ingeniuxURL}${favIcon32x32?.Value}`
            }
            sizes={`${favIcon32x32?.Width}x${favIcon32x32?.Height}`}
          />
          <link
            rel="apple-touch-icon"
            type="image/png"
            href={
              `${favIconAppleTouch?.Value}` &&
              `${ingeniuxURL}${favIconAppleTouch?.Value}`
            }
            sizes={`${favIconAppleTouch?.Width}x${favIconAppleTouch?.Height}`}
          />
          {franchiseMetaData &&
            franchiseMetaData[0] &&
            franchiseMetaData[0]?.map((headMetaTag) => (
              <meta
                name={headMetaTag?.name?.Value}
                content={headMetaTag?.content?.Value}
              />
            ))}
          {franchiseMetaData &&
            franchiseMetaData[1] &&
            franchiseMetaData[1]?.map((franchiseScript) => {
              const { innerContent, type, content, Src } = franchiseScript;

              if (innerContent?.Value) {
                if (
                  innerContent?.Value.includes(
                    '<script type="application/ld+json">'
                  )
                ) {
                  const sanitizedContent = innerContent?.Value?.replace(
                    /<script\s+type="application\/ld\+json">|<\/script>/gi,
                    ""
                  )
                    ?.replaceAll("context", "@context")
                    ?.replaceAll("type", "@type")
                    ?.replaceAll("graph", "@graph");
                  return (
                    <script type="application/ld+json" src={content?.Value}>
                      {sanitizedContent}
                    </script>
                  );
                } else {
                  return (
                    <script
                      type={type?.Value ?? "text/javascript"}
                      src={content?.Value}
                    >
                      {innerContent?.Value?.replaceAll("context", "@context")
                        ?.replaceAll("type", "@type")
                        ?.replaceAll("graph", "@graph")}
                    </script>
                  );
                }
              } else {
                return (
                  <script
                    type={type?.Value ?? "text/javascript"}
                    src={Src?.Value}
                  />
                );
              }
            })}
        </Helmet>
        {franchiseMetaData &&
          franchiseMetaData[2] &&
          franchiseMetaData[2]?.map((franchiseFooterScript) =>
            franchiseFooterScript?.innerContent?.Value ? (
              <script
                type={franchiseFooterScript?.type?.Value ?? "text/javascript"}
                src={franchiseFooterScript?.content?.Value}
              >
                {franchiseFooterScript?.innerContent?.Value}
              </script>
            ) : (
              <script
                type={franchiseFooterScript?.type?.Value ?? "text/javascript"}
                src={franchiseFooterScript?.Src?.Value}
              />
            )
          )}
        {franchiseMetaData &&
          franchiseMetaData[3] &&
          franchiseMetaData[3]?.map((franchiseNoScript) => (
            <noscript
              type={franchiseNoScript?.type?.Value ?? "text/javascript"}
              src={franchiseNoScript?.content?.Value}
            >
              {franchiseNoScript?.innerContent?.Value}
            </noscript>
          ))}
        {metaData?.NoScript && (
          <MetaTag
            headerMetaData={{ ...metaData, MetaDescription: metaDescription }}
          />
        )}
        <div id="top">
          <div id="sticky-wrapper" className="sticky-wrapper is-sticky">
            <header className="franchise-header">
              {alertContent?.DisplayAlert?.Value === "True" && (
                <div
                  id="alert-bar"
                  style={{
                    display: `${
                      alertContent?.AlertText?.Value === "" ||
                      !alertContent?.AlertText?.Value
                        ? "none"
                        : "block"
                    }`,
                  }}
                >
                  <div className="home-container fluid max">
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <center>
                            <a target="_blank" href={alertContent?.Page?.URL}>
                              <Markup
                                content={alertContent?.AlertText?.Value}
                              />
                            </a>
                          </center>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="franschise-head-wrap home-container row twelve">
                <div className="container mobile-ctas">
                  <div className="row twelve">
                    <div className="col-sd-6">
                      {/* made width 110% so the text doesn't wrap to next line */}
                      {scheduleAppointmentCTA && (
                        <a
                          href={`#${bookOnlineLink?.Name}`}
                          style={{ width: "100%", textAlign: "start" }}
                        >
                          {/* Now we are getting black color icon. so we are hardcoded the icons as of now */}
                          <svg
                            style={{ marginBottom: "-5px" }}
                            id="iconSchedule"
                            height="16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 34.3 29.7"
                          >
                            <title>Book Online</title>
                            <path
                              className="st0"
                              d="M33.6 29.7H.7c-.4 0-.7-.3-.7-.7V2c0-.4.3-.7.7-.7h32.9c.4 0 .7.3.7.7v27c0 .3-.3.7-.7.7zM1.4 28.2h31.4V2.6H1.4v25.6z"
                            ></path>
                            <path
                              className="st0"
                              d="M7.1 6c-.3 0-.7-.3-.7-.7V.7c0-.4.4-.7.7-.7s.7.3.7.7v4.6c.1.4-.3.7-.7.7zM13.8 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM20.5 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM27.2 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM6.6 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM11.9 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM17.2 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM22.5 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM6.6 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM11.9 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.2 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM27.7 13.3c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM6.6 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM23.5 20.7h-2.3c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.9.8-1.7 1.7-1.7h2.3c.9 0 1.7.8 1.7 1.7V19c0 1-.7 1.7-1.7 1.7zm-2.3-4.2c-.1 0-.2.1-.2.2V19c0 .1.1.2.2.2h2.3c.1 0 .2-.1.2-.2v-2.3c0-.1-.1-.2-.2-.2h-2.3zM27.7 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.1 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c.1.4-.3.7-.7.7zM11.9 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7z"
                            ></path>
                          </svg>

                          <span>{bookOnlineLink?.Link?.toUpperCase()}</span>
                        </a>
                      )}
                    </div>
                    <div className="col-sd-6" style={{ width: "100%" }}>
                      {/* made width 110% so the text doesn't wrap to next line */}
                      {getEstimateCTA && (
                        <a
                          href={
                            getEstimateLink?.Name
                              ? `#${getEstimateLink.Name}`
                              : `${otherProps[1]}${"#GetEstimateLink"}`
                          }
                          style={{ width: "100%" }}
                        >
                          {/* Now we are getting black color icon. so we are hardcoded the icons as of now */}
                          <svg
                            style={{ marginBottom: "-5px" }}
                            id="iconQuote"
                            height="16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 23.2 32.8"
                          >
                            <title>Get an Estimate</title>
                            <path
                              className="st0"
                              d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
                            ></path>
                            <path
                              className="st0"
                              d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
                            ></path>
                          </svg>

                          <span>
                            {getEstimateLink?.Link
                              ? getEstimateLink.Link.toUpperCase()
                              : "GET AN ESTIMATE"}
                          </span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row twelve">
                    <div className="logo col-sd-5 col-md-6 col-dd-3">
                      {/* removed / from href so that logo click doesn't redirect to corp home page */}
                      <a href={frnachiseBaseURL}>
                        {headerContent?.Logo?.Value ? (
                          <img
                            src={`${ingeniuxURL}${headerContent?.Logo?.Value}`}
                            alt={headerContent?.Logo?.AlternateText}
                            width="230"
                            height="90"
                          />
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                    <div className="header-top-wrap col-sd-7 col-md-6 col-dd-9">
                      <div className="header-top-container">
                        {/* on Safir Mac the phone number wrapping down, to fix that gave whiteSpace nowrap */}
                        <div
                          className="header-top"
                          style={{ textAlign: "center", whiteSpace: "nowrap" }}
                        >
                          <div className="franschise-anchors">
                            {scheduleAppointmentCTA && (
                              <a href={`#${bookOnlineLink?.Name}`}>
                                {/* Now we are getting black color icon. so we are hardcoded the icons as of now */}
                                <svg
                                  style={{ marginBottom: "-8px" }}
                                  id="iconSchedule"
                                  height="22"
                                  width="22"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 34.3 29.7"
                                >
                                  <title>Book Online</title>
                                  <path
                                    className="st0"
                                    d="M33.6 29.7H.7c-.4 0-.7-.3-.7-.7V2c0-.4.3-.7.7-.7h32.9c.4 0 .7.3.7.7v27c0 .3-.3.7-.7.7zM1.4 28.2h31.4V2.6H1.4v25.6z"
                                  ></path>
                                  <path
                                    className="st0"
                                    d="M7.1 6c-.3 0-.7-.3-.7-.7V.7c0-.4.4-.7.7-.7s.7.3.7.7v4.6c.1.4-.3.7-.7.7zM13.8 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM20.5 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM27.2 6c-.4 0-.7-.3-.7-.7V.7c0-.4.3-.7.7-.7s.7.3.7.7v4.6c0 .4-.3.7-.7.7zM6.6 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM11.9 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM17.2 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM22.5 13.4c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .3-.3.7-.7.7zM6.6 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM11.9 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.2 25c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM27.7 13.3c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM6.6 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM23.5 20.7h-2.3c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.9.8-1.7 1.7-1.7h2.3c.9 0 1.7.8 1.7 1.7V19c0 1-.7 1.7-1.7 1.7zm-2.3-4.2c-.1 0-.2.1-.2.2V19c0 .1.1.2.2.2h2.3c.1 0 .2-.1.2-.2v-2.3c0-.1-.1-.2-.2-.2h-2.3zM27.7 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7zM17.1 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c.1.4-.3.7-.7.7zM11.9 19.2c-.4 0-.7-.3-.7-.7v-1.2c0-.4.3-.7.7-.7s.7.3.7.7v1.2c0 .4-.3.7-.7.7z"
                                  ></path>
                                </svg>

                                <span>
                                  {bookOnlineLink?.Link?.toUpperCase()}
                                </span>
                              </a>
                            )}

                            {getEstimateCTA && (
                              <a
                                href={
                                  getEstimateLink?.Name
                                    ? `#${getEstimateLink.Name}`
                                    : `${otherProps[1]}${"#GetEstimateLink"}`
                                }
                                style={{ textDecoration: "none" }}
                              >
                                {/* Now we are getting black color icon. so we are hardcoded the icons as of now */}
                                <svg
                                  style={{ marginBottom: "-8px" }}
                                  id="iconQuote"
                                  height="22"
                                  width="22"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 23.2 32.8"
                                >
                                  <title>Get an Estimate</title>
                                  <path
                                    className="st0"
                                    d="M21.3 32.8H1.9c-1 0-1.9-.9-1.9-1.9v-29C0 .9.9 0 1.9 0h13c.4 0 .7.3.7.7s-.3.7-.7.7h-13c-.3 0-.5.2-.5.5v29c0 .2.2.5.5.5h19.4c.2 0 .5-.2.5-.5V8.4c0-.4.3-.7.7-.7s.7.3.7.7v22.5c0 1-.8 1.9-1.9 1.9z"
                                  ></path>
                                  <path
                                    className="st0"
                                    d="M22.5 9.1H16c-1 0-1.9-.9-1.9-1.9V.7c0-.3.2-.6.4-.7.3-.1.6-.1.8.2L23 7.9c.2.2.3.5.2.8s-.4.4-.7.4zm-6.9-6.6v4.8c0 .2.2.5.5.5h4.8l-5.3-5.3zM11.6 7.7H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h6.2c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 13.1H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 16.2H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM17.8 19.3H5.4c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h12.4c.4 0 .7.3.7.7s-.3.7-.7.7zM11.6 22.5H5.4c-.4 0-.7-.3-.7-.7s.3-.8.7-.8h6.2c.4 0 .7.3.7.7s-.3.8-.7.8z"
                                  ></path>
                                </svg>

                                <span>
                                  {getEstimateLink?.Link
                                    ? getEstimateLink.Link.toUpperCase()
                                    : "GET AN ESTIMATE"}
                                </span>
                              </a>
                            )}
                          </div>

                          <div className="header-phone franchise-home">
                            <div
                              className="yo-local"
                              style={{ display: "none" }}
                            >
                              <span className="yo-local-phone yo-local-href-phone"></span>
                            </div>
                            {headerContent && (
                              <div className="yo-corporate">
                                {(swapPhone ||
                                  otherProp?.PhoneNumber?.Value) && (
                                  <SvgPhoneIcon />
                                )}
                                <a
                                  href={
                                    swapPhone
                                      ? `tel:${swapPhone}`
                                      : otherProp?.TelephoneScript?.Value
                                      ? `tel:${otherProp?.TelephoneScript?.Value}`
                                      : `tel:${otherProp?.PhoneNumber?.Value}`
                                  }
                                >
                                  {swapPhone
                                    ? formatPhone(swapPhone)
                                    : otherProp?.PhoneNumber?.Value}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div id="page-wrapper">
                        <div id="mobile-nav" className="franchise-home">
                          <i
                            className="fa-solid fa-bars"
                            onClick={() => setShowNav(!showNav)}
                          >
                            <MenuBar
                              showNav={showNav}
                              setShowNav={setShowNav}
                              headerContent={headerData && headerData[0]}
                              franchiseBaseURL={frnachiseBaseURL}
                            >
                              <div
                                id="content-wrapper"
                                style={{
                                  left: showNav ? "280px" : "65px",
                                  width: showNav
                                    ? "calc(100% - 280px)"
                                    : "calc(100% - 55px)",
                                }}
                              ></div>
                            </MenuBar>
                          </i>
                        </div>
                      </div>

                      <nav className="franchise-topnav">
                        <ul className="first-level">
                          {headerData &&
                            headerData[0]?.map((menuItem, index) => {
                              const isExternal = isExternalUrl(menuItem?.URL);
                              const extractedPth = extractPath(
                                menuItem?.URL
                              ).toLowerCase();
                              return (
                                <li key={index}>
                                  <DynamicLinkList
                                    pagePath={
                                      isExternal
                                        ? `${menuItem?.URL}`
                                        : `${frnachiseBaseURL}${extractedPth}`
                                    }
                                    isExternal={isExternal}
                                    pageApiUrl={`${frnachiseBaseURL}${extractedPth}`}
                                    pageName={menuItem?.Link}
                                    basePageURL={frnachiseBaseURL}
                                  />
                                  {menuItem?.SubLink && (
                                    <div className="dropdown">
                                      <div className="home-container">
                                        <div className="header col-md-4">
                                          <DynamicLinkList
                                            pagePath={`${frnachiseBaseURL}${extractedPth}`}
                                            pageApiUrl={`${frnachiseBaseURL}${extractedPth}`}
                                            pageName={menuItem?.Link}
                                            basePageURL={frnachiseBaseURL}
                                          />
                                        </div>
                                        <div className="dropdown-nav col-sd-12 col-md-8">
                                          <ul>
                                            {menuItem?.SubLink?.map(
                                              (subLink, index) => {
                                                const extractedSubPath =
                                                  extractPath(subLink?.URL);
                                                return (
                                                  <li key={index}>
                                                    <DynamicLinkList
                                                      pagePath={`${
                                                        subLink?.ID
                                                          ? "/" + subLink?.URL
                                                          : frnachiseBaseURL +
                                                            extractedSubPath
                                                      }`}
                                                      pageApiUrl={`${
                                                        subLink?.ID
                                                          ? "/" + subLink?.URL
                                                          : frnachiseBaseURL +
                                                            extractedSubPath
                                                      }`}
                                                      pageName={subLink?.Link}
                                                      basePageURL={
                                                        frnachiseBaseURL
                                                      }
                                                    />
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </>
    );
  }
);

export default HeaderFranchise;
