import React, { Component } from "react";
import { Helmet } from "react-helmet";

import withRouter from "../components/common/withRouter";

class CannonicalTagProvider extends Component {
  constructor(props) {
    super(props);
  }

  correctTrailingSlash(URL) {
    let slashCorrectedURL = URL;
    /* removing starting / since base domainURL already ends with slash */
    if (slashCorrectedURL.startsWith("/")) {
      slashCorrectedURL = slashCorrectedURL.substr(1, URL.length - 1);
    } 
    // else {
    //   slashCorrectedURL = `/${slashCorrectedURL}`;
    // }
    /* removing ending slash to avoid duplicate URLS */
    if (slashCorrectedURL.endsWith("/")) {
      slashCorrectedURL = slashCorrectedURL.substr(
        0,
        slashCorrectedURL.length - 1
      );
    }
    return slashCorrectedURL;
  }

  isFranchise(urlObj) {
    /* If basePageURL has value ,its FrnachiseURL*/
    return Object.keys(urlObj).includes("basePageURL") && urlObj["basePageURL"];
  }
  getCanonicalURL() {
    let routerPath = this.props?.apiRef;
    /* check if routerPath is an object or string(copypaste scenario) */
    if (typeof routerPath === "object") {
      /* check if its Franchise or Corporate URL based on baseURL value */
      if (this.isFranchise(routerPath)) {
        /* Check for frnachise city or normal franchise page */
        if (window.location.href.split("/")[5]==='city') {
          let cityURL = `${routerPath?.basePageURL}${routerPath?.basePageURL?.endsWith('/')?'':'/'}${routerPath?.pagePath}`;
          return `${this.correctTrailingSlash(cityURL)}`;
        }
        return `${this.correctTrailingSlash(routerPath?.apiPath)}`;
      }
      return `${this.correctTrailingSlash(routerPath?.pagePath)}`;
    } else {
      /* Copy Past Scenario */
      return this.correctTrailingSlash(routerPath);
    }
  }
  render() {
    let canonicalURL = this.getCanonicalURL();
    const currentPath = window.location.href;
    window.history.replaceState("", "", `${currentPath?.replace(/\/+$/, "")}`);
    let prodURL = `https://acehardwareplumbing.com/`;
    //Meta tag implementation
    const canonicalTag = document.querySelector("[rel=canonical]");
    if (canonicalTag) {
      // If canonical Tag present update
      canonicalTag.href = `${prodURL}${canonicalURL}`;
    } else {
      // If canonical Tag not present create
      const node = document.createElement("link");
      node.setAttribute("rel", "canonical");
      node.setAttribute("href", `${prodURL}${canonicalURL}`);
      const viewportMeta = document.querySelector("[name=viewport]");
      viewportMeta.before(node);
    }

    return <>{this.props.children}</>;
  }
}

export default withRouter(CannonicalTagProvider);