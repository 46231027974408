import { SET_CORPORATE_PHONE_NUMBER, SET_NUMBER_SELECTION_STATUS } from "../actions/actionType";

let initialState = {
  corporatePhoneNumber: {
    name: "North Oklahoma City - 405-785-4550",
    value: "405-785-4550",
    isDefault: true,
    id: "North Oklahoma City",
  },
 };

function corporatePhoneNumberReducer(state = initialState, action) {
  
  switch (action.type) {
    case SET_CORPORATE_PHONE_NUMBER:
      return {
        ...state,
        corporatePhoneNumber: { ...action.payload.corporatePhoneNumber },
      };

  
    default:
      return state;
  }
}

export default corporatePhoneNumberReducer;
