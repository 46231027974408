import React from "react";

import { findAndGetObjData, weekDays, weekConfig, formatTimeTo12Hrs } from "../../config/utility"

export const HoursOfOperation = ({ hoursData = {} }) => {
    const weekDaysObj = findAndGetObjData(hoursData, weekDays);
    return (
        <div className="hours-wrap">
            <h3>{hoursData?.OurHours?.Value}</h3>
            <table id="hours">
                <tbody>
                    {Object.keys(weekDaysObj)?.map((day, index) => {
                        return (
                            <tr key={index} style={{backgroundColor: "#fff"}}>
                                <td>{day}</td>
                                <td className={`${day} hours`}>
                                    {Boolean(weekDaysObj[day]?.Closed?.Value === "true") ? weekConfig?.CLOSED
                                        :
                                        Boolean(weekDaysObj[day]?.ByAppointment.Value === "true") ? weekConfig?.BYAPPOINTMENT
                                            :
                                            <>
                                                {formatTimeTo12Hrs(weekDaysObj[day]?.StartTime?.Value)} {" - "}
                                                {formatTimeTo12Hrs(weekDaysObj[day]?.EndTime?.Value)}
                                            </>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default HoursOfOperation