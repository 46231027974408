//regular expression constants

//US phone number
export const regxUSPhone =
  /(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$)/;
//standard email
export const regxEmail =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
//US zipcode
export const regxUSZipcode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

// empty field check
export const emptyCheck = /^(?!\s*$)[a-zA-Z0-9.+\s'-]+$/;

// const used in the serviceSearchOptions
export const tabNames = {
  BYROOM: "By Room",
  BYAREA: "By Area",
  BYSERVICE: "By Service",
};
export const hashParams = {
  BYROOM: "search-by-room",
  BYAREA: "search-by-area",
  BYSERVICE: "search-by-service",
};

//returns YoTrack header
export const yoTrackAxiosConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const multipartFormHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const jsonHeader = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const handymanId = "handymanmatters";
export const clientId = "342343";
export const corpEmailId = "comments@handymanmatters.com";
export const aceBusinessName = "Ace Handyman Services";

//read cookie value
export const readCookieValue = (name) => {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};

//getting the results [] from the IGX api
export const getResultsArray = (inputData, expectedObj) => {
  const data = [];
  if (inputData?.results) {
    for (let i = 0, len = inputData?.results?.length; i < len; i++) {
      !expectedObj
        ? data.push(inputData?.results[i])
        : data.push(inputData?.results[i][expectedObj]);
    }
  } else if (Array.isArray(inputData)) {
    for (let i = 0, len = inputData?.length; i < len; i++) {
      data.push(inputData[i][expectedObj]);
    }
  }
  return data;
};

//for external URL check to open in new tab
//@param path or URL
export const isExternalUrl = (url) => {
  return /^https?:\/\//.test(url);
};

//this function used for creating dynamic routing
//output ex: /offices/north-metro-denver/why-choose-us will be /why-choose-us
//@param - path
export const extractPath = (pathString) => {
  let finalPath;
  if (!pathString) return;
  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }
  if (finalPath.length === 1 && finalPath[0].includes(" ")) {
    finalPath[0] = finalPath[0].replace(" ", "-");
  }
  return `/${finalPath[finalPath?.length - 1]}`;
};

// checks if the recent franchise page's path visited matches the last franchise path already stored
// in local storage. if there's no match, only then does the last path get stored in local storage.
// this is so that we don't redundantly set an item into local storage that is already in local storage.
export const storeFranchiseDataToLS = (typeOfData, recentData) => {
  const setData = (type, data) => {
    if (localStorage.getItem(type) !== data) {
      return localStorage.setItem(type, data);
    }
  };
  return setData(typeOfData, recentData);
};

//get data from LocalStorage
export const getFranchiseDataFromLS = (typeOfData) => {
  if (localStorage.getItem(typeOfData?.toString())) {
    return localStorage.getItem(typeOfData?.toString());
  }
};

//remove item from local storage
export const deleteItemFromLS = (lsKey) => {
  return localStorage.removeItem(lsKey);
};

//YoTrack api called mutiple places,
//@input - franchiseId - Array,
//@output - object
export const yoTrackPostData = (franchiseIds) => {
  return {
    bucket: "unpaid",
    jsonp: "false",
    clientIds: franchiseIds,
  };
};

// this is meant to be used in components/FindACraftsman/Offices.js
// this returns an array of franchise ids from each local office so that it can be used as the
// value for the 'clientIds' field when getting the phone numbers for each client id from yo track
export const clientIdList = (localOffices) => {
  let clientIdArray = [];
  for (let i = 0; i < localOffices.length; i++) {
    clientIdArray.push(parseInt(localOffices[i]?.FranchiseId));
  }
  return clientIdArray;
};

// to get specific franchise id by checking if any of the local offices' franchise names matches
// the FranchiseName value of the current franchise page the user is on
export const clientIdSpecific = (localOffices, franchiseName) => {
  let clientIdArray = [];
  for (let i = 0; i < localOffices.length; i++) {
    if (localOffices[i]?.FranchiseName === franchiseName) {
      return parseInt(localOffices[i]?.FranchiseId);
    }
  }
  for (let i = 0; i < localOffices.length; i++) {
    clientIdArray.push(parseInt(localOffices[i]?.FranchiseId));
  }
  return clientIdArray;
};

//this function to compare and find the matched object in the api response
//and return the matched objet data.
//@param - inputData - Object, comparerData - array or string
//output - object
export const findAndGetObjData = (inputData, comparerData) => {
  let resultObj;
  if (inputData && Array.isArray(inputData)) {
    const arrayData = inputData?.filter(el => comparerData?.includes(el?.Schema));
    resultObj = { ...arrayData[0] };
  }
  else
    resultObj = inputData && Object.keys(inputData)
      .filter(key => comparerData?.includes(key))
      .reduce((obj, key) => {
        obj[key] = inputData[key];
        return obj;
      }, {});
  return resultObj;
}

//@desc - formatting the phone number to US after
//receiving from YoTrack.
//@param- phone string. Removing the non-digits from
//phoneNumberString).replace(/\D/g, '')
//@output - formatted phone, ex: 123-456-7890
export const formatPhone = (phoneNumberString) => {
  var onlyDigits = ("" + phoneNumberString).replace(/\D/g, "");
  var match = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
};

// This function is for removing the extra path (like acehandymandss) from the main.js
//output ex: /acehandymandss/Services/:id/doors will be /Services/:id/doors
export const removeExtraPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return `/${finalPath.slice(2).join("/")}`;
};

// This function is for extracting the first path (like /acehandymandss)
//output ex: /acehandymandss/Services/:id/doors will give the output as /acehandymandss
export const ExtractingFirstPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return `/${finalPath[1]}`;
};

// added below for blogs page
export const extractingLastPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  // console.log("finalpa", finalPath.slice(2).join("/"));
  return finalPath[finalPath.length - 1];
};

export const extractingSTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 2];
};

export const extractingTTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 3];
};

export const extractingFTLPath = (pathString) => {
  let finalPath;

  if (pathString.charAt(0) === "/") {
    finalPath = pathString.split("/");
  } else {
    finalPath = pathString.split("/");
  }

  return finalPath[finalPath.length - 4];
};

//for Franchise Home Page - Hours of operation
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const weekConfig = {
  CLOSED: "Closed",
  BYAPPOINTMENT: "By Appointment",
};
//format the 24hs time to 12 hrs format
export const formatTimeTo12Hrs = (timeString) => {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  const mins = Number(minute < 59) ? minute : "00";
  const hourDigits = hour % 12 || 12;
  const hours = hourDigits;
  return hours + ":" + mins + (hour < 12 ? " am" : " pm");
};

//For performance improvement, storing the api data which is not frequently changing to
//sessionStorage
export const loadApiData = (apiName) => {
  if (!apiName) return;
  try {
    const serializedData = sessionStorage.getItem(apiName);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const saveApiData = (apiName, apiData) => {
  if (!apiName) return;
  try {
    const serializedApiData = JSON.stringify(apiData);
    sessionStorage.setItem(apiName, serializedApiData);
  } catch (err) {
    return undefined;
  }
};

export const deleteItemFromSS = (ssKey) => {
  return sessionStorage.removeItem(ssKey);
};

//Keeping this bg-pattern image in utility as this image generically referenced in the css
//hence moving the css reference to JSX as inline style

//loading data from Session the common page background pattern image from x31 api
const x31Data = loadApiData("x31");
const patternImg =
  x31Data && x31Data[0]?.GlobalSettings?.GeneralPageBackground?.Value;
export const bgPatternTriangles = patternImg;


// Title and Meta Tag maps for blog pages and offices page
export const getMetaAndTileTags = (pathName) => {
  const urlMetaMap = {
    '/blog':
    {
      title: 'Advice & Insights from Ace Hardware Plumbing Services',
      meta: 'Read the latest news about Ace Hardware Plumbing, get helpful DIY repair tips and view our recommendations for the best plumbing.'
    },
    '/offices':
    {
      title: 'Locations of Ace Hardware Plumbing Services from OKC to DFW',
      meta: "Ace Hardware Plumbing Services is rapidly expanding across the country and will soon be in a city near you. View current locations"
    },
    '/blog/what-tools-do-plumbers-use-to-clear-a-clog':
    {
      title: 'What Tools Do Plumbers Use to Clear a Clog?',
      meta: "Clog-busting technology has come a long way in the past decade. Learn about drain cleaning machines and what they can do!"
    },
    '/blog/finding-a-leak-under-the-kitchen-sink':
    {
      title: "Finding a Leak Under the Kitchen Sink. Advice from a Plumber",
      meta: "If it's a big leak, it's usually easy to spot. If the leak is tiny, it may be harder to find. Follow these steps to discover small leaks under the kitchen sink."
    },
    '/blog/what-type-of-gas-line-do-i-have':
    {
      title: 'What Type of Gas Line Do I Have? Advice from a Plumber',
      meta: 'Using natural gas for cooking & heating has several advantages. However, when you need a repair, knowing the type of gas line is important.'
    },
    '/blog/repairing-and-replacing-drain-lines':
    {
      title: 'Repairing and Replacing Drain Lines. Advice from a Plumber',
      meta: " If your drains and/or toilets continue to clog or back up, this most often indicates a problem with drainpipes. Get it corrected with advice from a plumber."
    },

  }

  return urlMetaMap[pathName];

}

// Utility function to generate unique id when there is no id or duplicate Id 
export const generateUniqueId = (idPrefix) => {
  let uniqueId = "";
  const uniqueNumber = Date.now().toString(32) + Math.random().toString(16);
  uniqueId = `${idPrefix}-${uniqueNumber}`;
  return uniqueId;
}



// Corporate Header Phone Number Dropdown Data 

export const headerPhoneNumberData = [
  {
    name: 'North Oklahoma City - 405-367-6845',
    value: '405-367-6845',
    isDefault: true,
    id:'North Oklahoma City',
  },
  {
    name: 'North DFW - 972-801-9798',
    value: '972-801-9798',
    isDefault: false,
    id:'North DFW'
  },
  {
    name: 'Jackson Plumbing , Heating & Cooling - 256-353-0051',
    value: '256-353-0051',
    isDefault: false,
    id:'Jackson Plumbing , Heating & Cooling '
  },
  {
    name: 'A-Total Plumbing - 770-507-5424',
    value: '770-507-5424',
    isDefault: false,
    id:'A-Total Plumbing'
  },
  {
    name: 'Parrish Services - 703-659-6069',
    value: '703-659-6069',
    isDefault: false,
    id:'Parrish Services'
  }
];

export const getStickyHeaderHeight = ()=>{
  const stickyHeader = document.getElementsByTagName('header')?.[0];
  if(stickyHeader){
    return stickyHeader.offsetHeight;
  }
}

//function to determine NoIndexTag addition
export const setNoIndexTag = () => {
  let setTag = false;
  let noIndexPages = ['referral-program-terms-and-conditions','campaign'];
  setTag = noIndexPages.some((page) => window.location.pathname.includes(page));
  return setTag;
};