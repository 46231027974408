import React from "react";
import "./PreviewCard.css";

const PreviewCard = ({ title, previewText, link }) => {
  return (
    <div className="preview-card">
      <h3 className="preview-title">
        <a href={link}>{title}</a>
      </h3>
      <p>
        {previewText}...
        <a href={link}>Read More</a>
      </p>
    </div>
  );
};

export default PreviewCard;
