import axios from "axios";
import {startHandleErrorBoundary,endHandleErrorBoundary,handleErrorBoundary, fetchDataRequest, fetchDataSuccess, fetchDataError } from "./action";
import { ingeniuxURL } from "../config/urls";
export function fetchHomePage(pageLink) {
  return (dispatch) => {

    dispatch(fetchDataRequest());
    dispatch(startHandleErrorBoundary());
    axios
    .get(`${ingeniuxURL}api/page?path=${pageLink}`)
    .then((response) => {
      dispatch(fetchDataSuccess(response.data));
      dispatch(endHandleErrorBoundary());
      })
      .catch((error) => {
        dispatch(handleErrorBoundary(error));
        dispatch(fetchDataError(error));
      });
  };
}
